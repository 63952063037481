import ColumnCrudTableType from "../../../componentes/CRUD/columnCrudTableType";
import moment from "moment";

export const variablesDashBoard: {
  empresa: string | null;
  de: Date | null;
  ate: Date | null;
  registro: string;
} = {
  empresa: null,
  de: moment()
    .add(-1, "day")
    .toDate(),
  ate: moment().toDate(),
  registro: "VENDA",
};

export const variablesDashBoardInitial = JSON.parse(JSON.stringify(variablesDashBoard));

export const variablesDashBoardExibicao: {
  empresa: string | null;
  de: Date | null;
  ate: Date | null;
  registro: string;
} = {
  empresa: null,
  de: moment()
    .add(-1, "day")
    .toDate(),
  ate: moment().toDate(),
  registro: "Venda",
};

export const mapVariablesDashBoard = {
  empresa: "Conciliador",
  de: "Venda de",
  ate: "Venda até",
  registro: "Registros de",
};

export const colunasDashBoard: Array<ColumnCrudTableType> = [];

export const actionsDashBoard = [];
