import MomentUtils from "@date-io/moment";
import { TextField } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import locale from "moment/locale/pt-br";
import React, { PureComponent } from "react";
import { FilterProps } from "../../../componentes/CRUD/crudProps";
import { SelectCombo } from "../../../componentes/Select";
import { ListarOperadorasDocument } from "../../../graphql/components";
import { variablesExtratosOriginais, variablesExtratosOriginaisInitial } from "./utilsExtratosOriginais";
import FilterContext from "../../../componentes/CRUD/filterContext";

type State = typeof variablesExtratosOriginais;

class FilterExtratosOriginais extends PureComponent<FilterProps, State> {
  static contextType = FilterContext;
  public static filterExtratosOriginaisVariables: any;
  constructor(props: FilterProps) {
    super(props);
    this.state = { ...variablesExtratosOriginaisInitial };
    FilterExtratosOriginais.filterExtratosOriginaisVariables = JSON.parse(JSON.stringify(variablesExtratosOriginaisInitial));
  }

  render() {
    return (
      <Grid container spacing={2} style={{ marginBottom: 60 }}>
        <Grid item xs={12} md={4}>
          <TextField
            id="codigo"
            label="Cod. Arquivo"
            type="number"
            value={this.state.values.codarquivo ? this.state.values.codarquivo : ""}
            name="Código"
            margin="normal"
            variant="outlined"
            fullWidth
            onChange={e => {
              const value: number | null = e.target.value ? parseInt(e.target.value) : null;
              this.setState({ values: { ...this.state.values, codarquivo: value } }, () => {
                this.props.variables.values.codarquivo = this.state.values.codarquivo;
                this.props.variablesValorExibicao.values.codarquivo = this.state.values.codarquivo;
                this.context.setVariables({ ...this.state });
              });
            }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            id="nome"
            label="Nome Arquivo"
            type="text"
            value={this.state.values.nomearquivo ? this.state.values.nomearquivo : ""}
            name="Nome"
            margin="normal"
            variant="outlined"
            fullWidth
            onChange={e => {
              this.setState({ values: { ...this.state.values, nomearquivo: e.target.value } }, () => {
                this.props.variables.values.nomearquivo = this.state.values.nomearquivo;
                this.props.variablesValorExibicao.values.nomearquivo = this.state.values.nomearquivo;
                this.context.setVariables({ ...this.state });
              });
            }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <SelectCombo
            label="Operadora"
            name="operadora"
            nameQuery="listarOperadoras"
            query={ListarOperadorasDocument}
            value={this.state.values.codoperadora ? this.state.values.codoperadora : ""}
            handleChange={e => {
              // const valor = e.currentTarget.value;
              const label = (e.currentTarget as any).innerText;
              const value: number | null = e.target.value ? parseInt(e.target.value as string) : null;
              this.setState({ values: { ...this.state.values, codoperadora: value } }, () => {
                this.props.variables.values.codoperadora = this.state.values.codoperadora;
                this.props.variablesValorExibicao.values.codoperadora = label;
                this.context.setVariables({ ...this.state });
              });
            }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <MuiPickersUtilsProvider utils={MomentUtils} locale={locale}>
            <KeyboardDatePicker
              maxDateMessage="Data inválida"
              minDateMessage="Data inválida"
              autoOk
              style={{ width: "100%" }}
              label="De"
              name="de"
              id="de"
              invalidDateMessage="Data inválida"
              disableToolbar
              variant="inline"
              value={this.state.de}
              onChange={e => {
                if (e && e.toDate()) {
                  this.setState({ de: e.toDate() }, () => {
                    this.props.variables.de = this.state.de;
                    this.props.variablesValorExibicao.de = this.state.de;
                    this.context.setVariables({ ...this.state });
                  });
                } else {
                  this.setState({ de: null }, () => {
                    this.props.variables.de = this.state.de;
                    this.props.variablesValorExibicao.de = this.state.de;
                    this.context.setVariables({ ...this.state });
                  });
                }
              }}
              format="DD/MM/YYYY"
              placeholder="dd/mm/aaaa"
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={12} md={4}>
          <MuiPickersUtilsProvider utils={MomentUtils} locale={locale}>
            <KeyboardDatePicker
              maxDateMessage="Data inválida"
              minDateMessage="Data inválida"
              autoOk
              style={{ width: "100%" }}
              label="Até"
              name="ate"
              id="ate"
              invalidDateMessage="Data inválida"
              disableToolbar
              variant="inline"
              value={this.state.ate}
              onChange={e => {
                if (e && e.toDate()) {
                  this.setState({ ate: e.toDate() }, () => {
                    this.props.variables.ate = this.state.ate;
                    this.props.variablesValorExibicao.ate = this.state.ate;
                    this.context.setVariables({ ...this.state });
                  });
                } else {
                  this.setState({ ate: null }, () => {
                    this.props.variables.ate = this.state.ate;
                    this.props.variablesValorExibicao.ate = this.state.ate;
                    this.context.setVariables({ ...this.state });
                  });
                }
              }}
              format="DD/MM/YYYY"
              placeholder="dd/mm/aaaa"
            />
          </MuiPickersUtilsProvider>
        </Grid>
      </Grid>
    );
  }
}

export default FilterExtratosOriginais;
