import React from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";
import { connect } from "react-redux";
import { IState } from "../../redux/reducers";

interface PrivateRouteProps extends RouteProps {
  component: any;
  isSignedIn?: boolean;
}

const PrivateRoute = (props: PrivateRouteProps) => {
  const { component: Component, isSignedIn, ...rest } = props;
  return (
    <Route
      {...rest}
      render={(propss) =>
        isSignedIn ? (
          <Component {...propss} />
        ) : (
          <Redirect
            to={{
              pathname: "/",
            }}
          />
        )
      }
    />
  );
};

const mapStateToProps = (state: IState) => ({
  isSignedIn: state.loginReducer.isSignedIn,
  ...state,
});

const PrivateRouteMapped = connect(
  mapStateToProps,
  null
)(PrivateRoute);

export default PrivateRouteMapped;
