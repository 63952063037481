import { Button, FormControl, InputLabel, makeStyles, MenuItem, OutlinedInput, Select } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import classNames from "classnames";
import React, { useCallback, useContext, useState } from "react";
import { RightDrawerProps } from "../../../componentes/CRUD/crudProps";
import SelectedRowsContext from "../../../componentes/CRUD/selectedRowsContext";
import {
  GetTefsComponent,
  ListarAllTefsDaoProps,
  SalvarTefsProps,
  UsuarioMestreTefsBean,
  withListarAllTefsDao,
  withSalvarTefs
} from "../../../graphql/components";

const useStyles = makeStyles(theme => ({
  formControl: {
    marginTop: theme.spacing(1),
    width: "100%"
  },
  select: {
    marginTop: theme.spacing(1),
    height: 48,
    width: "100%"
  }
}));

const Tefs: React.FC<SalvarTefsProps<ListarAllTefsDaoProps<RightDrawerProps>>> = ({
  closeRightDrawer,
  setMessage,
  titleAction,
  mutate,
  data
}) => {
  const classes = useStyles();
  const inputLabel = React.useRef(null);
  const [labelWidth, setLabelWidth] = React.useState(0);
  React.useEffect(() => {
    if (inputLabel != null && inputLabel.current != null) {
      setLabelWidth((inputLabel!.current as any).offsetWidth as number);
    }
  }, []);
  const context = useContext(SelectedRowsContext);
  const [tef, setTef] = useState<number | string>("");
  const [tefs, setTefs] = useState<Array<UsuarioMestreTefsBean>>([]);

  const addTef = useCallback(() => {
    const dataTef = data.listarAllTefsDAO?.find(t => t.codtefs === tef);
    if (tefs.findIndex(t => t.codTef === tef) < 0) {
      setTefs([
        ...tefs,
        {
          codTef: dataTef?.codtefs,
          nomeTef: dataTef?.nome
        }
      ]);
      setTef("");
    }
  }, [tef, tefs, data.listarAllTefsDAO]);

  if (!context || context.length < 1 || !data || !data.listarAllTefsDAO) {
    return null;
  }

  return (
    <>
      <div style={{ width: "80%", marginLeft: "10%", marginTop: 20 }}>
        <FormControl variant="outlined" className={classNames(classes.formControl)}>
          <InputLabel ref={inputLabel} htmlFor="tef">
            Tef
          </InputLabel>
          <Select
            fullWidth
            value={tef}
            onChange={e => {
              setTef(e.target.value as number);
            }}
            name="tef"
            id="tef"
            className={classNames(classes.select)}
            inputProps={{
              name: "tef",
              id: "tef"
            }}
            input={<OutlinedInput labelWidth={labelWidth} name="tef-out" id={`outlined-tef`} />}
          >
            <MenuItem value="">
              <em>Selecione</em>
            </MenuItem>
            {data.listarAllTefsDAO.map(tef => (
              <MenuItem key={tef.codtefs} value={tef.codtefs!}>
                {tef.nome}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {tef && (
          <Button onClick={addTef} variant="outlined" color="primary" style={{ marginTop: 10, width: "100%" }}>
            Adicionar Tef
          </Button>
        )}
      </div>
      <GetTefsComponent variables={{ codpessoa: context[0].codpessoa }}>
        {({ data }) => {
          if (data && data.getTefs) {
            const allTefs = tefs.concat(data.getTefs);
            return (
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Tef</TableCell>
                    <TableCell align="right">Ação</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {allTefs.map(tef => (
                    <TableRow key={tef.codTef}>
                      <TableCell component="th" scope="row">
                        {tef.nomeTef}
                      </TableCell>
                      <TableCell align="right">Excluir</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            );
          }
          return null;
        }}
      </GetTefsComponent>
      <Button onClick={addTef} variant="outlined" color="primary" style={{ marginTop: 10, width: "100%" }}>
        Salvar Alterações
      </Button>
      <div style={{ position: "absolute", bottom: 10, width: "100%" }}>
        <Button variant="contained" color="secondary" onClick={closeRightDrawer} style={{ width: "100%" }}>
          Fechar
        </Button>
      </div>
    </>
  );
};

const withMutation = withSalvarTefs<RightDrawerProps>();

const withListarAllTefs = withListarAllTefsDao<SalvarTefsProps<RightDrawerProps>>();

export default withMutation(withListarAllTefs(Tefs));
