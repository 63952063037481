import { Button, Card, Paper } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import Slide from "@material-ui/core/Slide";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import React, { PureComponent } from "react";
import PapperBlock from "../../../../componentes/PapperBlock/PapperBlock";
import TreeTable from "../ArvoreConciliadores/TreeTable";
import Status from "../status";

interface Props {}
interface State {
  open: boolean;
  listaEC: boolean;
  loading: boolean;
  values: {
    codoperadora: number | null;
    statusFiltro: string | undefined;
  };
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export class PosicaoContratoClienteConciliador extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      open: false,
      listaEC: false,
      loading: false,
      values: {
        codoperadora: null,
        statusFiltro: ""
      }
    };
  }

  openListaEC = () => this.setState({ listaEC: true });

  handleChange = (event: number | null) => {
    this.setState({ values: { ...this.state.values, codoperadora: event } });
  };

  handleChangeStatus = (value: string) => {
    this.setState({ values: { ...this.state.values, statusFiltro: value } });
  };

  handleCloseListaEC = () => this.setState({ listaEC: false });

  render() {
    return (
      <React.Fragment>
        <PapperBlock title="Monitor de Posição de Contratos">
          <Card elevation={20} style={{ padding: 15 }}>
            <Status changeValue={this.handleChange} changeValueStatus={this.handleChangeStatus} />
          </Card>

          <Paper style={{ marginTop: 10 }}>
            <Button
              variant="text"
              color="primary"
              style={{ margin: 10, width: "92%" }}
              onClick={this.openListaEC}
              disabled={this.state.loading}
            >
              {this.state.loading ? "Estamos processando sua requisição" : "Pesquisar"}
            </Button>
          </Paper>
        </PapperBlock>

        {this.state.listaEC && (
          <Dialog fullScreen open={this.state.listaEC} onClose={this.handleCloseListaEC} TransitionComponent={Transition as any}>
            <AppBar style={{ position: "relative" }}>
              <Toolbar>
                <IconButton color="inherit" onClick={this.handleCloseListaEC} aria-label="Close">
                  <CloseIcon />
                </IconButton>
                <Typography variant="h6" color="inherit" style={{ flex: 1 }}>
                  Monitor de Posição de Contratos
                </Typography>
              </Toolbar>
            </AppBar>
            <TreeTable codOperadora={this.state.values.codoperadora} statusFiltro={this.state.values.statusFiltro} />
          </Dialog>
        )}
      </React.Fragment>
    );
  }
}

export default PosicaoContratoClienteConciliador;
