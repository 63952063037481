import { DataProxy } from "apollo-cache";
import { forkJoin } from "rxjs";
import { CallbackActionType } from "../../../../componentes/CRUD/actionsTable";
import ColumnCrudTableType from "../../../../componentes/CRUD/columnCrudTableType";
import {
  DeletePlanosPosTaxasMutation,
  DeletePlanosPosTaxasMutationVariables,
  ListarPlanosPosTaxasDocument,
  ListarPlanosPosTaxasQuery,
  ListarPlanosPosTaxasQueryVariables
} from "../../../../graphql/components";

export const variablesPlanosPosTaxas: {
  codplano: number | null;
} = {
  codplano: null
};

export const deleteMutationPlanosPosTaxas: CallbackActionType<
  DeletePlanosPosTaxasMutation,
  DeletePlanosPosTaxasMutationVariables
> = (mutation, record, setMessage) => {
  if (mutation) {
    const deletesPromises: Array<any> = [];
    let codplano: number;
    record.forEach(r => {
      codplano = r.cod;
      deletesPromises.push(
        mutation({
          variables: { values: { editValues: { codplano: r.codplano } } },
          update: (store: DataProxy, { data }) => {
            if (data!.deletePlanosPosTaxas) {
              const oldResult = store.readQuery<ListarPlanosPosTaxasQuery, ListarPlanosPosTaxasQueryVariables>({
                query: ListarPlanosPosTaxasDocument,
                variables: { values: { codplano: codplano } }
              });
              if (oldResult) {
                const index = oldResult.listarPlanosPOSTaxas.findIndex(u => u.codplanostaxas === r.codplanostaxas);
                oldResult.listarPlanosPOSTaxas.splice(index, 1);
                const result = [...oldResult.listarPlanosPOSTaxas];
                store.writeQuery({
                  query: ListarPlanosPosTaxasDocument,
                  data: result,
                  variables: variablesPlanosPosTaxas
                });
              }
            }
          }
        }).catch(error => {
          return new Promise(resolve => resolve(error.message));
        })
      );
    });
    forkJoin(deletesPromises).subscribe(
      response => {
        const totalErrors = response.filter(r => typeof r === "string").length;
        if (totalErrors > 0 && totalErrors !== deletesPromises.length) {
          setMessage(
            true,
            "warning",
            "Alguns registros não foram excluídos. Dentro de 5 segundos iremos atualizar a página para manter os dados coerentes."
          );
          setTimeout(() => window.location.reload(), 6500);
        } else if (totalErrors === 0) {
          setMessage(true, "success", "Registro(s) excluídos com sucesso!");
        }
        return true;
      },
      errors => {
        console.log(errors);
      }
    );
  }
};

export const colunasPlanosPosTaxas: Array<ColumnCrudTableType> = [
  {
    name: "Plano",
    options: {
      filter: true
    }
  },
  {
    name: "Produto",
    options: {
      filter: true
    }
  }
];

export const columnsOrderPlanosPosTaxas = ["plano", "converterProduto"];

export const columnsOrder = ["plano", "converterProduto"];
