import Chip from "@material-ui/core/Chip";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import React from "react";
import Ionicon from "react-ionicons";
import { NavLink } from "react-router-dom";
import convertIcon from "../../utils/iconUtils";
import styles from "./sidebar-jss";

const NavLinkComponent = React.forwardRef((props: any, ref) => <NavLink {...props} exact />);

interface SidebarLinkPropTypes {
  title: string;
  icon?: string;
  onClick?: any;
  isTitle?: boolean;
  classes: any;
  badge?: string;
  link: string;
  active?: boolean;
  inset?: boolean;
  isNeto?: boolean;
}

class SidebarLink extends React.Component<SidebarLinkPropTypes> {
  render() {
    const { title, icon, onClick, isTitle, classes, link, badge, active, inset = true, isNeto = false } = this.props;
    if (isTitle) {
      return (
        <ListSubheader disableSticky component="div" className={classes.title} id={title}>
          {title}
        </ListSubheader>
      );
    }
    let ionicon = null;
    if (icon) {
      ionicon = convertIcon(icon);
    }
    return (
      <ListItem
        button
        className={classNames(classes.head, icon ? classes.iconed : "")}
        onClick={onClick}
        component={NavLinkComponent}
        to={"/app/" + link}
        activeClassName={classNames(active ? classes.active : "")}
        style={isNeto ? { paddingLeft: 68 } : undefined}
        id={title}
      >
        {icon && (
          <ListItemIcon className={classes.icon}>
            <Ionicon icon={ionicon} />
          </ListItemIcon>
        )}
        <ListItemText classes={{ primary: classes.primary }} inset={inset} primary={title} />
        {badge && <Chip color="primary" label={badge} className={classes.badge} />}
      </ListItem>
    );
  }
}

export default withStyles(styles as any)(SidebarLink);
