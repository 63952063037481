import React from "react";
import { RightDrawerProps } from "../../../componentes/CRUD/crudProps";
import { Card, Typography, Grid, Button } from "@material-ui/core";
import "./visualizarNota.css";
import SelectedRowsContext from "../../../componentes/CRUD/selectedRowsContext";
import {
  PrevisualizarNotaComponent,
  FaturamentoMovimentoReenviarEmailNotaAceitarReenviarNotaComponent,
  FaturamentoMovimentoReenviarEmailNotaAceitarReenviarNotaMutation,
  FaturamentoMovimentoReenviarEmailNotaAceitarReenviarNotaMutationVariables
} from "../../../graphql/components";
import Loading from "../../../componentes/Loading/Loading";
import { MutationFunction } from "react-apollo";

const PreVisualizarNota: React.FC<RightDrawerProps> = ({ closeRightDrawer, setMessage }) => {
  const close = React.useCallback(() => closeRightDrawer(), [closeRightDrawer]);
  const [processando, setProcessando] = React.useState(false);

  const action = async (
    mutation: MutationFunction<
      FaturamentoMovimentoReenviarEmailNotaAceitarReenviarNotaMutation,
      FaturamentoMovimentoReenviarEmailNotaAceitarReenviarNotaMutationVariables
    >,
    operacao: string
  ) => {
    setProcessando(true);
    try {
      const response = await mutation();
      if (response && response.data && response.data.faturamentoMovimentoReenviarEmailNotaAceitarReenviarNota) {
        if (operacao === "reenviarEmail") {
          setMessage(true, "success", "Email reenviado com sucesso!");
        } else if (operacao === "reenviarNota") {
          setMessage(true, "success", "Nota reenviada com sucesso!");
        } else if (operacao === "aceitar") {
          setMessage(true, "success", "Nota aceita com sucesso!");
        } else if (operacao === "processar") {
          setMessage(true, "success", "Nota processada com sucesso!");
        }
      }
    } catch (e) {
    } finally {
      setProcessando(false);
    }
  };

  const selecionado = React.useContext(SelectedRowsContext);
  if (!selecionado || selecionado.length === 0) {
    return null;
  }
  return (
    <React.Fragment>
      <Typography className="title" variant="subtitle1">
        Faturamento Movimento
      </Typography>
      <PrevisualizarNotaComponent variables={{ values: { chave: selecionado[0].chave, apurado: selecionado[0].apurado } }}>
        {({ loading, data }) => {
          if (loading) {
            return <Loading />;
          }
          if (data && data.previsualizarNota) {
            return (
              <Card elevation={4} className="card">
                <Grid container spacing={8}>
                  <Grid item xs={12}>
                    <Typography variant="caption" className="rem66">
                      Mensagem:
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <textarea
                      rows={7}
                      className="textarea"
                      value={data.previsualizarNota ? data.previsualizarNota : ""}
                      maxLength={500}
                      readOnly
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FaturamentoMovimentoReenviarEmailNotaAceitarReenviarNotaComponent
                      variables={{
                        values: { chave: selecionado[0].chave, apurado: selecionado[0].apurado },
                        operacao: "processar"
                      }}
                    >
                      {mutation => (
                        <Button
                          variant="outlined"
                          color="primary"
                          className="button100"
                          onClick={action.bind(null, mutation, "processar")}
                          disabled={processando}
                        >
                          Processar
                        </Button>
                      )}
                    </FaturamentoMovimentoReenviarEmailNotaAceitarReenviarNotaComponent>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button variant="outlined" color="secondary" className="button100" onClick={close}>
                      Fechar
                    </Button>
                  </Grid>
                </Grid>
              </Card>
            );
          }
          return null;
        }}
      </PrevisualizarNotaComponent>
    </React.Fragment>
  );
};

export default PreVisualizarNota;
