const Routes = {
  home: { route: "/app/home", titulo: "Home" },
  ajuda: { route: "/app/ajuda", titulo: "Manual" },
  alteraSenha: { route: "/app/alterasenha", titulo: "Altera Senha" },
  painel: { route: "/app/painel", titulo: "Painel" },
  usuario: { route: "/app/usuario", titulo: "Usuário" },
  perfil: { route: "/app/perfil", titulo: "Perfil" },
  terminal: { route: "/app/terminal", titulo: "Terminal" },
  relatorios: { route: "/app/relatorios", titulo: "Relatórios" },
  taxasAntecipacao: { route: "/app/taxasantecipacao", titulo: "Taxas de Antecipação" },
  posicaoContratoClienteConciliador: { route: "/app/posicaocontratoclienteconciliador", titulo: "Posição do Contrato" },
  manutencaoArquivosConciliacaoAberto: { route: "/app/manutencaoarquivosconciliacaoaberto", titulo: "Arquivos em Aberto" },
  inclusaoTicket: { route: "/app/inclusaoticket", titulo: "Incluir Ticket" },
  dashboard: { route: "/app/dashboard", titulo: "Dashboard" },
  pesquisaImportacaoTicket: { route: "/app/pesquisaimportacaoticket", titulo: "Importar Ticket" },
  manutencaoArquivosExtratosOriginais: { route: "/app/manutencaoarquivosextratosoriginais", titulo: "Extratos Originais" },
  manutencaoArquivos: { route: "/app/manutencaoarquivos", titulo: "Manutenção de Arquivos Único" },
  pesquisaTicket: { route: "/app/pesquisaticket", titulo: "Pesquisar Ticket" },
  notFound: { route: "/app/not-found", titulo: "Página Desconhecida" },
  error: { route: "/app/error", titulo: "Erro" },
  errorNotLogged: { route: "/error", titulo: "Erro" },
  notAllowed: { route: "/app/notAllowed", titulo: "Cadastro Pendente" },
  integracao: { route: "/app/integracao", titulo: "Integrações" },
  planosConciliadores: { route: "/app/planosconciliadores", titulo: "Planos & Taxas" },
  planosTerminal: { route: "/app/planos", titulo: "Planos POS" },
  login: { route: "/", titulo: "" },
  spc: { route: "/spc", titulo: "" },
  spcIsVis: { route: "/spc?isVis=true", titulo: "" },
  loginIsVis: { route: "/?isVis=true", titulo: "" },
  cliente: { route: "/app/cliente", titulo: "Cliente" },
  parametros: { route: "/app/parametros", titulo: "Parâmetros" },
  faturamentomovimento: { route: "/app/faturamentomovimento", titulo: "Faturamento Movimento" },
  fechaMovimentoPrevisao: { route: "/app/fechamovimentoprevisao", titulo: "Fechamento de Movimento Previsão" },
  fechaMovimentoReimpressao: { route: "/app/fechamovimentoreimpressao", titulo: "Movimentos Fechados" },
  fechamovimento: { route: "/app/fechamovimento", titulo: "Movimentos Abertos" },
  listarUsuariosMestres: { route: "/app/buscamestres", titulo: "Listar Usuários Mestres" },
  buscaMestresOperadora: { route: "/app/buscamestresoperadora", titulo: "Listar Usuários Mestres" },
  buscaMestresRevenda: { route: "/app/buscamestresrevenda", titulo: "Listar Usuários Mestres Revenda" },
  atendimento: { route: "/app/atendimento", titulo: "Atendimento" },
  treinamento: { route: "/app/treinamento", titulo: "Treinamento" },
  dadosNFe: { route: "/app/dadosnfe", titulo: "Dados NFe" },
  signUp: { route: "/signUp", titulo: "Cadastro" },
};

export default Routes;

export const getTitulo = (path: string) => {
  // @ts-ignore
  const route = Object.keys(Routes).find((key) => Routes[key].route === path);
  if (route) {
    // @ts-ignore
    return Routes[route].titulo;
  }
  return "";
};
