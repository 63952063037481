import MomentUtils from "@date-io/moment";
import { Card, Grid } from "@material-ui/core";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { FormikProps } from "formik";
import locale from "moment/locale/pt-br";
import React from "react";
import DualListBox from "react-dual-listbox";
import "react-dual-listbox/lib/react-dual-listbox.css";
import { lang } from "../../../.././componentes/Utils/languageDualList";
import SelectedRowsContext from "../../../../componentes/CRUD/selectedRowsContext";
import PapperBlock from "../../../../componentes/PapperBlock/PapperBlock";
import { SelectCombo } from "../../../../componentes/Select";
import {
  GetItensUnidadesPlanosConciliadoresComponent,
  GetPlanosConciliadoresContratoQuery,
  ListarItensPlanosConciliadoresDocument
} from "../../../../graphql/components";
import "./formPlanosContrato.css";
import { FormValues } from "./newEditPlanosContrato";

type Props = {
  formikBag: FormikProps<FormValues>;
  data?: GetPlanosConciliadoresContratoQuery;
};

const FormPlanosContrato = ({ formikBag, data }: Props) => {
  const context = React.useContext(SelectedRowsContext);
  const { codplanosconciliadores } = context[0];

  React.useEffect(() => {
    if (data) {
      const listConciOperadora = data.getPlanosConciliadoresContrato.listConciOperadora
        ? data.getPlanosConciliadoresContrato.listConciOperadora
        : [];
      const listConciOperadoraGeral = data.getPlanosConciliadoresContrato.listConciOperadoraGeral
        ? data.getPlanosConciliadoresContrato.listConciOperadoraGeral
        : [];
      // setChecked(listConciOperadora);
      formikBag.setValues({
        vigenciaDe: data.getPlanosConciliadoresContrato.editValues.vigenciaDe,
        vigenciaAte: data.getPlanosConciliadoresContrato.editValues.vigenciaAte,
        codplanosconciliadores: data.getPlanosConciliadoresContrato.editValues.codplanosconciliadores!,
        listConciOperadora: listConciOperadora,
        listConciOperadoraGeral: listConciOperadoraGeral
      });
    } else {
      formikBag.setFieldValue("codplanosconciliadores", codplanosconciliadores);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (selected: any) => {
    formikBag.setFieldValue("listConciOperadora", selected);
  };

  return (
    <React.Fragment>
      <fieldset style={{ marginTop: 15, marginRight: 10 }}>
        <legend>Dados contrato</legend>
        <Grid container spacing={8} title="Contratos">
          <Grid item xs={12} md={4}>
            <SelectCombo
              label="Plano"
              errorText={formikBag.errors.codplanosconciliadores}
              handleBlur={formikBag.handleBlur}
              handleChange={formikBag.handleChange}
              name="codplanosconciliadores"
              disabled={true}
              value={formikBag.values.codplanosconciliadores ? formikBag.values.codplanosconciliadores! : ""}
              query={ListarItensPlanosConciliadoresDocument}
              touched={formikBag.touched.codplanosconciliadores ? formikBag.touched.codplanosconciliadores : false}
              nameQuery="listarItensPlanosConciliadores"
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <MuiPickersUtilsProvider utils={MomentUtils} locale={locale}>
              <KeyboardDatePicker
                maxDateMessage="Data inválida"
                minDateMessage="Data inválida"
                autoOk
                disableToolbar
                variant="inline"
                style={{ width: "100%" }}
                label="De"
                name="de"
                id="de"
                value={formikBag.values.vigenciaDe}
                invalidDateMessage="Data inválida"
                onChange={e => {
                  if (e && e.toDate()) {
                    formikBag.setFieldValue("vigenciaDe" as string, e.toDate());
                  } else {
                    formikBag.setFieldValue("vigenciaDe" as string, null);
                  }
                }}
                format="DD/MM/YYYY"
                placeholder="dd/mm/aaaa"
                disabled={formikBag.isSubmitting}
              />
            </MuiPickersUtilsProvider>
          </Grid>

          <Grid item xs={12} md={4}>
            <MuiPickersUtilsProvider utils={MomentUtils} locale={locale}>
              <KeyboardDatePicker
                maxDateMessage="Data inválida"
                minDateMessage="Data inválida"
                autoOk
                disableToolbar
                variant="inline"
                style={{ width: "100%" }}
                label="Até"
                name="ate"
                id="ate"
                value={formikBag.values.vigenciaAte}
                invalidDateMessage="Data inválida"
                onChange={e => {
                  if (e && e.toDate()) {
                    formikBag.setFieldValue("vigenciaAte" as string, e.toDate());
                  } else {
                    formikBag.setFieldValue("vigenciaAte" as string, null);
                  }
                }}
                format="DD/MM/YYYY"
                placeholder="dd/mm/aaaa"
                disabled={formikBag.isSubmitting}
              />
            </MuiPickersUtilsProvider>
          </Grid>

          {formikBag.values.codplanosconciliadores && (
            <GetItensUnidadesPlanosConciliadoresComponent
              variables={{ codplanosconciliadores: formikBag.values.codplanosconciliadores }}
            >
              {({ data }) => {
                if (data && data.getItensUnidadesPlanosConciliadores) {
                  return (
                    <React.Fragment>
                      <Grid item xs={12} md={12}>
                        <PapperBlock title="Planos Contrato">
                          <Card elevation={20} style={{ padding: 15 }}>
                            <DualListBox
                              name="conciconciliadoras"
                              disabled={formikBag.isSubmitting}
                              canFilter
                              lang={lang}
                              showHeaderLabels={true}
                              showNoOptionsText={true}
                              filterPlaceholder={"Pesquisar..."}
                              options={data.getItensUnidadesPlanosConciliadores}
                              selected={formikBag.values.listConciOperadora}
                              onChange={onChange}
                            />
                          </Card>
                        </PapperBlock>
                      </Grid>
                    </React.Fragment>
                  );
                }
                return null;
              }}
            </GetItensUnidadesPlanosConciliadoresComponent>
          )}
        </Grid>
      </fieldset>
    </React.Fragment>
  );
};

export default FormPlanosContrato;
