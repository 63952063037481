import React, { CSSProperties } from "react";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Ionicon from "react-ionicons";
import styles from "./papperStyle-jss";
import Search from "@material-ui/icons/Search";
import { Fab } from "@material-ui/core";

interface Props {
  classes: any;
  title: String;
  desc?: string;
  openFilter?: () => void;
  icon?: String;
  children: React.ReactNode;
  whiteBg?: Boolean;
  whiteBgNormal?: Boolean;
  colorMode?: Boolean;
  noMargin?: Boolean;
  overflowX?: Boolean;
  isTableMobile?: boolean;
  stylesDesc?: CSSProperties;
  marginRight?: number;
  minHeight?: number;
}

class PapperBlock extends React.Component<Props> {
  render() {
    const {
      classes,
      title,
      desc,
      children,
      whiteBg,
      whiteBgNormal,
      noMargin,
      colorMode,
      overflowX,
      icon,
      openFilter,
      stylesDesc,
      marginRight,
      minHeight,
      isTableMobile,
    } = this.props;
    return (
      <div>
        <Paper
          className={classNames(
            classes.root,
            noMargin && classes.noMargin,
            colorMode && classes.colorMode,
            isTableMobile && classes.tableMobile
          )}
          elevation={0}
          style={{ marginRight, transition: "all 0.4s", minHeight }}
        >
          <div className={classes.descBlock} style={stylesDesc}>
            {icon && (
              <span className={classes.iconTitle}>
                <Ionicon icon={icon} />
              </span>
            )}
            <div className={classes.titleText}>
              <Typography variant="h6" component="h2" className={classes.title}>
                {title}
                {openFilter && (
                  <Fab
                    variant="extended"
                    size="medium"
                    color="primary"
                    aria-label="Add"
                    className={classes.margin}
                    style={{ float: "right" }}
                    onClick={openFilter}
                  >
                    <Search className={classes.extendedIcon} style={{ marginRight: 7 }} />
                    Filtrar
                  </Fab>
                )}
              </Typography>
              <Typography
                component="div"
                className={classes.description}
                dangerouslySetInnerHTML={desc ? { __html: desc } : undefined}
              />
            </div>
          </div>
          <section
            className={classNames(
              classes.content,
              whiteBg && classes.whiteBg,
              whiteBgNormal && classes.whiteBgNormal,
              overflowX && classes.overflowX,
              isTableMobile && classes.tableMobile
            )}
          >
            {children}
          </section>
        </Paper>
      </div>
    );
  }
}

export default withStyles(styles as any)(PapperBlock);
