import Button from "@material-ui/core/Button";
import { Formik, FormikActions, FormikProps } from "formik";
import React, { PureComponent } from "react";
import * as Yup from "yup";
import NewEditProps from "../../../../componentes/CRUD/crudProps";
import EditContext from "../../../../componentes/CRUD/editContext";
import Loading from "../../../../componentes/Loading/Loading";
import {
  GetPlanosPosTaxasComponent,
  GetPlanosPosTaxasDocument,
  GetPlanosPosTaxasQuery,
  GetPlanosPosTaxasQueryVariables,
  ListarPlanosPosTaxasDocument,
  ListarPlanosPosTaxasQuery,
  PlanosTerminalPosTaxas,
  SalvarPlanosPosTaxasProps,
  withSalvarPlanosPosTaxas
} from "../../../../graphql/components";
import FormPlanosTaxas from "./formPlanosTaxas";

const PlanosTaxasSchema = Yup.object().shape({
  codplanos: Yup.number(),
  codoperadora: Yup.number().nullable(true),
  codprodutos: Yup.number(),
  de: Yup.number().nullable(true),
  ate: Yup.number().nullable(true),
  taxa: Yup.number().nullable(true),
  listDeTaxas: Yup.array().min(1, "Ao menos uma taxa deve ser adicionada.")
});

const initialValues = {
  codplano: (null as unknown) as number,
  codoperadora: ("" as unknown) as number,
  codprodutos: ("" as unknown) as number,
  taxas: {
    de: (null as unknown) as number,
    ate: (null as unknown) as number,
    taxa: ("" as unknown) as number
  },
  listDeTaxas: [] as Array<any>
};

export type FormValues = typeof initialValues;

class NewEditPlanosTaxas extends PureComponent<SalvarPlanosPosTaxasProps<NewEditProps>> {
  static contextType = EditContext;

  submit = async (values: FormValues, actions: FormikActions<FormValues>, editContext: any | null) => {
    try {
      const response = await this.props.mutate!({
        variables: {
          values: {
            editValues: {
              codplano: values.codplano,
              codprodutos: values.codprodutos,
              de: values.taxas.de,
              ate: values.taxas.ate,
              taxa: values.taxas.taxa
            },
            listDeTaxas: values.listDeTaxas.map(item => {
              delete item["__typename"];
              return item;
            })
          },
          insert: editContext ? false : true
        },

        update: (store, result) => {
          if (result.data && result.data.salvarPlanosPosTaxas) {
            const data = store.readQuery<ListarPlanosPosTaxasQuery>({
              query: ListarPlanosPosTaxasDocument,
              variables: { values: { codplano: values.codplano } }
            });

            if (editContext && editContext.codplanostaxas) {
              const edited = store.readQuery<GetPlanosPosTaxasQuery, GetPlanosPosTaxasQueryVariables>({
                query: GetPlanosPosTaxasDocument,
                variables: { values: { codplanostaxas: editContext.codplanostaxas } }
              });
              if (edited && edited.getPlanosPOSTaxas.editValues.codplanostaxas) {
                const registro = data!.listarPlanosPOSTaxas.find(
                  item => item.codplanostaxas === edited.getPlanosPOSTaxas.editValues.codplanostaxas
                );
                if (registro) {
                  registro.taxa = values.taxas.taxa;
                  registro.de = values.taxas.de;
                  registro.ate = values.taxas.ate;
                  const newResult = data!.listarPlanosPOSTaxas.slice(0);
                  store.writeQuery<ListarPlanosPosTaxasQuery>({
                    query: ListarPlanosPosTaxasDocument,
                    data: { listarPlanosPOSTaxas: newResult },
                    variables: { values: { nome: null, taxa: null, vigenciaate: null, vigenciade: null } }
                  });

                  /*
                  edited.getTaxasAntecipacao.codoperadora = values.codoperadora;
                  edited.getTaxasAntecipacao.listConciOperadora = values.listConciOperadora;
                  edited.getTaxasAntecipacao.editValues.nome = values.nome;
                  edited.getTaxasAntecipacao.editValues.taxa = values.taxa;
                  edited.getTaxasAntecipacao.editValues.vigenciaate = values.vigenciaate;
                  edited.getTaxasAntecipacao.editValues.vigenciade = values.vigenciaate;
                  */

                  store.writeQuery({
                    query: GetPlanosPosTaxasDocument,
                    data: { ...edited, __typename: "PlanosTerminalPosTaxas" },
                    variables: { values: { codplanostaxas: this.context.editValues.codplanostaxas } }
                  });
                }
              }
            } else {
              // const userInfo = store.readQuery<GetUserInfoQuery>({ query: GetUserInfoDocument });
              const planos: PlanosTerminalPosTaxas = {
                __typename: "PlanosTerminalPosTaxas",
                de: values.taxas.de,
                ate: values.taxas.ate
                //codpessoapai: userInfo!.getUserInfo.codPessoaPai
              };
              store.writeQuery<ListarPlanosPosTaxasQuery>({
                query: ListarPlanosPosTaxasDocument,
                data: { listarPlanosPOSTaxas: [...data!.listarPlanosPOSTaxas, planos] },
                variables: { values: { codplano: planos.codplano } }
              });
            }
          }
        }
      });

      if (response && response.data && response.data.salvarPlanosPosTaxas) {
        actions.setSubmitting(false);
        this.props.close();
        this.props.setMessage(true, "success", "Planos taxas salvo com sucesso!");
      }
    } catch (e) {
    } finally {
      actions.setSubmitting(false);
    }
  };

  render() {
    return (
      <div style={{ overflowY: "auto", height: "90%", overflowX: "hidden" }}>
        {
          <EditContext.Consumer>
            {editContext => (
              <Formik
                initialValues={initialValues}
                onSubmit={(values: FormValues, actions: FormikActions<FormValues>) => this.submit(values, actions, editContext)}
                validationSchema={PlanosTaxasSchema}
                render={(formikBag: FormikProps<FormValues>) => (
                  <form autoComplete="new-password" role="presentation" onSubmit={formikBag.handleSubmit}>
                    {!editContext && <FormPlanosTaxas formikBag={formikBag} />}
                    {editContext && (
                      <GetPlanosPosTaxasComponent
                        variables={{
                          values: { codplano: (editContext! as any).codplano!, codprodutos: (editContext! as any).codprodutos }
                        }}
                      >
                        {({ data, loading }) => {
                          if (loading) {
                            return <Loading />;
                          }
                          if (data && data.getPlanosPOSTaxas) {
                            return <FormPlanosTaxas formikBag={formikBag} data={data!} />;
                          }
                          return null;
                        }}
                      </GetPlanosPosTaxasComponent>
                    )}
                    <div
                      style={{
                        float: "right",
                        margin: 10,
                        display: "flex",
                        justifyContent: "space-between",
                        width: "180px",
                        flexDirection: "row"
                      }}
                    >
                      <Button variant="contained" color="primary" disabled={formikBag.isSubmitting} type="submit">
                        Salvar
                      </Button>
                      <Button
                        variant="contained"
                        color="secondary"
                        disabled={formikBag.isSubmitting}
                        type="reset"
                        onClick={() => formikBag.resetForm(initialValues)}
                      >
                        Limpar
                      </Button>
                    </div>
                  </form>
                )}
              />
            )}
          </EditContext.Consumer>
        }
      </div>
    );
  }
}

const withMutation = withSalvarPlanosPosTaxas<NewEditProps>({ withRef: true });

export default withMutation(NewEditPlanosTaxas);
