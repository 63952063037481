import MomentUtils from "@date-io/moment";
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, TextField } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from "moment";
import locale from "moment/locale/pt-br";
import React, { PureComponent } from "react";
import { FilterProps } from "../../../componentes/CRUD/crudProps";
import { variablesPesquisaTickets, variablesPesquisaTicketsInitial } from "./utilsPesquisaTickets";
import FilterContext from "../../../componentes/CRUD/filterContext";

type StateDate = {
  minDate: Date | null;
  maxDate: Date | null;
};

type State = typeof variablesPesquisaTickets & StateDate;

class FilterPesquisaTickets extends PureComponent<FilterProps, State> {
  static contextType = FilterContext;
  public static filterPesquisaTicketsVariables: any;
  constructor(props: FilterProps) {
    super(props);
    this.state = {
      ...variablesPesquisaTicketsInitial,
      minDate: moment()
        .subtract(1, "month")
        .toDate(),
      maxDate: moment().toDate()
    };
    FilterPesquisaTickets.filterPesquisaTicketsVariables = JSON.parse(JSON.stringify(variablesPesquisaTicketsInitial));
  }

  render() {
    return (
      <Grid container spacing={2} style={{ marginBottom: 30 }}>
        <Grid item xs={12} md={4}>
          <TextField
            disabled={this.props.isFiltering}
            id="nsu"
            label="Doc/NSU"
            type="number"
            value={this.state.nsu ? this.state.nsu : ""}
            name="nsu"
            margin="normal"
            variant="outlined"
            fullWidth
            onChange={e => {
              this.setState({ nsu: e.currentTarget.value }, () => {
                this.props.variables.nsu = this.state.nsu;
                this.props.variablesValorExibicao.nsu = this.state.nsu;
                const { minDate, maxDate, ...state } = this.state;
                this.context.setVariables({ ...state });
              });
            }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            disabled={this.props.isFiltering}
            id="tid"
            label="TID"
            type="text"
            value={this.state.tid ? this.state.tid : ""}
            name="tid"
            margin="normal"
            variant="outlined"
            fullWidth
            onChange={e => {
              this.setState({ tid: e.target.value }, () => {
                this.props.variables.tid = this.state.tid;
                this.props.variablesValorExibicao.tid = this.state.tid;
                const { minDate, maxDate, ...state } = this.state;
                this.context.setVariables({ ...state });
              });
            }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            disabled={this.props.isFiltering}
            id="autorizacao"
            label="Autorização"
            type="text"
            value={this.state.autorizacao ? this.state.autorizacao : ""}
            name="autorizacao"
            margin="normal"
            variant="outlined"
            fullWidth
            onChange={e => {
              this.setState({ autorizacao: e.target.value }, () => {
                this.props.variables.autorizacao = this.state.autorizacao;
                this.props.variablesValorExibicao.autorizacao = this.state.autorizacao;
                const { minDate, maxDate, ...state } = this.state;
                this.context.setVariables({ ...state });
              });
            }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            disabled={this.props.isFiltering}
            id="terminal"
            label="Terminal"
            type="text"
            value={this.state.terminal ? this.state.terminal : ""}
            name="terminal"
            margin="normal"
            variant="outlined"
            fullWidth
            onChange={e => {
              this.setState({ terminal: e.target.value }, () => {
                this.props.variables.terminal = this.state.terminal;
                this.props.variablesValorExibicao.terminal = this.state.terminal;
                const { minDate, maxDate, ...state } = this.state;
                this.context.setVariables({ ...state });
              });
            }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <MuiPickersUtilsProvider utils={MomentUtils} locale={locale}>
            <KeyboardDatePicker
              maxDateMessage="Data inválida"
              minDateMessage="Data inválida"
              autoOk
              variant="inline"
              disabled={this.props.isFiltering}
              style={{ width: "100%" }}
              // minDate={this.state.minDate}
              label="De"
              name="de"
              id="de"
              invalidDateMessage="Data inválida"
              allowKeyboardControl
              disableToolbar
              value={this.state.datavenda}
              onChange={e => {
                if (e && e.toDate()) {
                  const maxDate = moment(e.toDate())
                    .add(1, "month")
                    .toDate();
                  this.setState({ datavenda: e.toDate(), maxDate }, () => {
                    this.props.variables.datavenda = this.state.datavenda;
                    this.props.variablesValorExibicao.datavenda = this.state.datavenda;
                    const { minDate, maxDate, ...state } = this.state;
                    this.context.setVariables({ ...state });
                  });
                } else {
                  this.setState({ datavenda: null, maxDate: null }, () => {
                    this.props.variables.datavenda = this.state.datavenda;
                    this.props.variablesValorExibicao.datavenda = this.state.datavenda;
                    const { minDate, maxDate, ...state } = this.state;
                    this.context.setVariables({ ...state });
                  });
                }
              }}
              format="DD/MM/YYYY"
              placeholder="dd/mm/aaaa"
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={12} md={4}>
          <MuiPickersUtilsProvider utils={MomentUtils} locale={locale}>
            <KeyboardDatePicker
              maxDateMessage="Data inválida"
              minDateMessage="Data inválida"
              autoOk
              variant="inline"
              disabled={this.props.isFiltering}
              style={{ width: "100%" }}
              //maxDate={this.state.maxDate}
              label="Até"
              name="ate"
              id="ate"
              invalidDateMessage="Data inválida"
              allowKeyboardControl
              disableToolbar
              value={this.state.datavendaate}
              onChange={e => {
                if (e && e.toDate()) {
                  const minDate = moment(e.toDate())
                    .subtract(1, "month")
                    .toDate();
                  this.setState({ datavendaate: e.toDate(), minDate }, () => {
                    this.props.variables.datavendaate = this.state.datavendaate;
                    this.props.variablesValorExibicao.datavendaate = this.state.datavendaate;
                    const { minDate, maxDate, ...state } = this.state;
                    this.context.setVariables({ ...state });
                  });
                } else {
                  this.setState({ datavendaate: null, minDate: null }, () => {
                    this.props.variables.datavendaate = this.state.datavendaate;
                    this.props.variablesValorExibicao.datavendaate = this.state.datavendaate;
                    const { minDate, maxDate, ...state } = this.state;
                    this.context.setVariables({ ...state });
                  });
                }
              }}
              format="DD/MM/YYYY"
              placeholder="dd/mm/aaaa"
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            disabled={this.props.isFiltering}
            id="nossonumero"
            label="Nosso número"
            type="text"
            value={this.state.nossonumero ? this.state.nossonumero : ""}
            name="nossonumero"
            margin="normal"
            variant="outlined"
            fullWidth
            onChange={e => {
              this.setState({ nossonumero: e.target.value }, () => {
                this.props.variables.nossonumero = this.state.nossonumero;
                this.props.variablesValorExibicao.nossonumero = this.state.nossonumero;
                const { minDate, maxDate, ...state } = this.state;
                this.context.setVariables({ ...state });
              });
            }}
          />
        </Grid>
        <Grid item xs={12} md={8}>
          <FormControl component="div">
            <FormLabel>Tipo</FormLabel>
            <RadioGroup
              row
              aria-label="tipo"
              name="tipo"
              value={this.state.tipo}
              onChange={(e: any) => {
                this.setState({ tipo: e.target.value }, () => {
                  this.props.variables.tipo = this.state.tipo;
                  this.props.variablesValorExibicao.tipo =
                    this.state.tipo === "A" ? "ADMINISTRADORA" : this.state.tipo === "C" ? "CLIENTE" : "CLIENTE INTEGRADOR";
                  const { minDate, maxDate, ...state } = this.state;
                  this.context.setVariables({ ...state });
                });
              }}
            >
              <FormControlLabel value="A" control={<Radio />} label="ADMINISTRADORA" disabled={this.props.isFiltering} />
              <FormControlLabel value="C" control={<Radio />} label="CLIENTE" disabled={this.props.isFiltering} />
              <FormControlLabel value="CU" control={<Radio />} label="CLIENTE INTEGRADOR" disabled={this.props.isFiltering} />
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>
    );
  }
}

export default FilterPesquisaTickets;
