import ColumnCrudTableType from "../../../componentes/CRUD/columnCrudTableType";
import moment from "moment";
import { forkJoin } from "rxjs";
import {
  DownloadManutencaoArquivosMutation,
  DownloadManutencaoArquivosMutationVariables,
  ReprocessarManutencaoArquivosMutation,
  ReprocessarManutencaoArquivosMutationVariables,
  DeletarManutencaoArquivosMutation,
  DeletarManutencaoArquivosMutationVariables,
  ListarManutencaoArquivosDocument
} from "../../../graphql/components";
import downloadUtils from "../../../utils/downloadUtils";
import { CallbackActionType } from "../../../componentes/CRUD/actionsTable";

export const getMuiThemeoManutencaoArquivoUnico = {
  root: {
    padding: 5
  },
  body: {
    fontSize: 11
  }
};

export const baixarArquivoManutencaoArquivoUnico: CallbackActionType<
  DownloadManutencaoArquivosMutation,
  DownloadManutencaoArquivosMutationVariables
> = async (mutation, record, setMessage) => {
  record.forEach(async (r: any) => {
    const response = await mutation!({
      variables: { values: { codarquivoDownload: r.codarquivo.toString() } }
    });
    if (response) {
      const { data } = response;
      if (data) {
        downloadUtils(data.downloadManutencaoArquivos!);
        if (setMessage) {
          setMessage(true, "success", "Registro baixado com sucesso!");
        }
      }
    }
  });
};

export const reprocessarArquivoManutencaoArquivoUnico: CallbackActionType<
  ReprocessarManutencaoArquivosMutation,
  ReprocessarManutencaoArquivosMutationVariables
> = async (mutation, record, setMessage) => {
  let hasError = 0;
  for (let r of record) {
    const response = await mutation!({ variables: { values: { codarquivo: r.codarquivo, tipo: r.tipo } } });
    if (!response || !response.data || !response.data.reprocessarManutencaoArquivos) {
      hasError++;
    }
  }
  if (hasError === record.length) {
    setMessage!(true, "success", "Registro reprocessado com sucesso!");
  } else if (hasError > 0) {
    setMessage!(true, "success", "Registro reprocessado com sucesso!");
  } else {
    setMessage!(true, "success", "Registro reprocessado com sucesso!");
  }
};

export const deletarArquivoManutencaoArquivoUnico: CallbackActionType<
  DeletarManutencaoArquivosMutation,
  DeletarManutencaoArquivosMutationVariables
> = (mutation, record, setMessage) => {
  const deletesPromises: Array<any> = [];
  record.forEach(r => {
    deletesPromises.push(
      mutation!({
        variables: { values: { codarquivo: record[0].codarquivo, tipo: record[0].tipo } },
        update: (store: any) => {
          const oldResult: any = store.readQuery({
            query: ListarManutencaoArquivosDocument,
            variables: variablesManutencaoArquivoUnico
          });
          if (oldResult) {
            const index = oldResult.listarEstabelecimentos.findIndex((e: any) => e.codarquivo === record[0].codarquivo);
            oldResult.listarEstabelecimentos.splice(index, 1);
            store.writeQuery({
              query: ListarManutencaoArquivosDocument,
              data: oldResult,
              variables: variablesManutencaoArquivoUnico
            });
          }
        }
      }).catch((error: any) => {})
    );
  });
  forkJoin(deletesPromises).subscribe(
    response => {
      const totalErrors = response.filter(r => typeof r === "string").length;
      if (totalErrors > 0 && totalErrors !== deletesPromises.length) {
        setMessage(
          true,
          "warning",
          "Alguns registros não foram excluídos. Dentro de 5 segundos iremos atualizar a página para manter os dados coerentes."
        );
        setTimeout(() => window.location.reload(), 6500);
      } else if (totalErrors === 0) {
        setMessage(true, "success", "Registro(s) excluídos com sucesso!");
      }
    },
    errors => {
      console.log(errors);
    }
  );
};

export const variablesManutencaoArquivoUnico: {
  values: {
    codarquivo: number | null;
    nomearquivo: string | null;
    tipo: string | null;
    status: string | null;
    codoperadora: number | null;
    origem: string | null;
  };
  unidadeConciliadora: string | null;
  de: Date | null;
  ate: Date | null;
} = {
  values: {
    codarquivo: null,
    nomearquivo: null,
    tipo: null,
    status: null,
    codoperadora: null,
    origem: null
  },
  unidadeConciliadora: null,
  de: moment()
    .subtract(1, "month")
    .toDate(),
  ate: moment().toDate()
};

export const variablesManutencaoArquivoUnicoInitial = JSON.parse(JSON.stringify(variablesManutencaoArquivoUnico));

export const variablesManutencaoArquivoUnicoExibicao: {
  values: {
    codarquivo: number | null;
    nomearquivo: string | null;
    tipo: string | null;
    status: string | null;
    codoperadora: string | null;
    origem: string | null;
  };
  unidadeConciliadora: string | null;
  de: Date | null;
  ate: Date | null;
} = {
  values: {
    codarquivo: null,
    nomearquivo: null,
    tipo: null,
    status: null,
    codoperadora: null,
    origem: null
  },
  unidadeConciliadora: null,
  de: moment()
    .subtract(1, "month")
    .toDate(),
  ate: moment().toDate()
};

export const mapVariablesFiltroManutencaoArquivoUnico = {
  values: {
    codarquivo: "Cod. Arquivo",
    nomearquivo: "Nome Arquivo",
    status: "Status",
    tipo: "Tipo",
    codoperadora: "Operadora",
    origem: "Origem"
  },
  unidadeConciliadora: "Conciliador",
  de: "Data Envio de",
  ate: "Data Envio até"
};

export const columnsOrderManutencaoArquivoUnico = [
  "codarquivo",
  "dataenvio",
  "datarecebimento",
  "dataprocessamento",
  "tipo",
  "status",
  "nomearquivo",
  "mensagem"
];

export const colunasManutencaoArquivoUnico: Array<ColumnCrudTableType> = [
  {
    name: "ID",
    options: {
      filter: true
    }
  },
  {
    name: "Envio",
    options: {
      filter: true,
      customBodyRender: (value: any) => moment(new Date(value)).format("DD/MM/YYYY hh:mm:ss")
    }
  },
  {
    name: "Recebimento",
    options: {
      filter: true,
      customBodyRender: (value: any) => moment(new Date(value)).format("DD/MM/YYYY hh:mm:ss")
    }
  },
  {
    name: "Processamento",
    options: {
      filter: true,
      customBodyRender: (value: any) => moment(new Date(value)).format("DD/MM/YYYY hh:mm:ss")
    }
  },
  {
    name: "Tipo",
    options: {
      filter: true
    }
  },
  {
    name: "Status",
    options: {
      filter: true
    }
  },
  {
    name: "Arquivo",
    options: {
      filter: true
    }
  },
  {
    name: "Mensagem",
    options: {
      filter: true
    }
  }
];
