import { DataProxy } from "apollo-cache";
import moment from "moment";
import { forkJoin } from "rxjs";
import { CallbackActionType } from "../../../componentes/CRUD/actionsTable";
import ColumnCrudTableType from "../../../componentes/CRUD/columnCrudTableType";
import {
  DeleteTaxasAntecipacaoMutation,
  DeleteTaxasAntecipacaoMutationVariables,
  ListarTaxasAntecipacaoDocument,
  ListarTaxasAntecipacaoQuery
} from "../../../graphql/components";

export const variablesTaxasAntecipacao: {
  nome: string | null;
  vigenciade: Date | null;
  vigenciaate: Date | null;
  taxa: number | null;
} = {
  nome: null,
  vigenciade: null,
  vigenciaate: null,
  taxa: null
};

export const mapVariablesFiltroTaxasAntecipacao = {
  nome: "Nome",
  vigenciade: "De",
  vigenciaate: "Até",
  taxa: "Taxa"
};

export const deleteMutationTaxasAntecipacao: CallbackActionType<
  DeleteTaxasAntecipacaoMutation,
  DeleteTaxasAntecipacaoMutationVariables
> = (mutation, record, setMessage) => {
  if (mutation) {
    const deletesPromises: Array<any> = [];
    record.forEach(r => {
      deletesPromises.push(
        mutation({
          variables: { values: { editValues: { codtaxaantecipacao: r.codtaxaantecipacao } } },
          update: (store: DataProxy, { data }) => {
            if (data!.deleteTaxasAntecipacao) {
              const oldResult = store.readQuery<ListarTaxasAntecipacaoQuery>({
                query: ListarTaxasAntecipacaoDocument,
                variables: { values: variablesTaxasAntecipacao }
              });
              if (oldResult) {
                const index = oldResult.listarTaxasAntecipacao.findIndex(u => u.codtaxaantecipacao === r.codtaxaantecipacao);
                oldResult.listarTaxasAntecipacao.splice(index, 1);
                const result = [...oldResult.listarTaxasAntecipacao];
                store.writeQuery({
                  query: ListarTaxasAntecipacaoDocument,
                  data: result,
                  variables: variablesTaxasAntecipacao
                });
              }
            }
          }
        }).catch(error => {
          return new Promise(resolve => resolve(error.message));
        })
      );
    });
    forkJoin(deletesPromises).subscribe(
      response => {
        const totalErrors = response.filter(r => typeof r === "string").length;
        if (totalErrors > 0 && totalErrors !== deletesPromises.length) {
          setMessage(
            true,
            "warning",
            "Alguns registros não foram excluídos. Dentro de 5 segundos iremos atualizar a página para manter os dados coerentes."
          );
          setTimeout(() => window.location.reload(), 6500);
        } else if (totalErrors === 0) {
          setMessage(true, "success", "Registro(s) excluídos com sucesso!");
        }
        return true;
      },
      errors => {
        console.log(errors);
      }
    );
  }
};

export const colunasTaxasAntecipacao: Array<ColumnCrudTableType> = [
  {
    name: "Nome",
    options: {
      filter: true
    }
  },
  {
    name: "De",
    options: {
      filter: true,
      customBodyRender: (value: any) => moment(new Date(value)).format("DD/MM/YYYY")
    }
  },
  {
    name: "Até",
    options: {
      filter: true,
      customBodyRender: (value: any) => moment(new Date(value)).format("DD/MM/YYYY")
    }
  },
  {
    name: "Taxa",
    options: {
      filter: true
    }
  }
];
