import React, { useState } from "react";
import classNames from "classnames";
import { NavLink } from "react-router-dom";
import { Typography, FormControl, TextField, InputAdornment, IconButton, Button } from "@material-ui/core";
import LogoConcilia from "../LogoConcilia";
import ListErrors from "../ListErrors/ListErrors";
import "../../styles/custom/forms/LoginForm.css";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import logo from "../../styles/images/concilia-solucoes/concilia_logo_azul_claro.png";
import ReCAPTCHA from 'react-google-recaptcha';

type Props = {
  errors: boolean;
  handleSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  classes: any;
  change: (event: React.FormEvent<HTMLInputElement>) => void;
  valueRecaptcha: string | null;
  showPassword: boolean;
  handleClickShowPassword: () => void;
  handleMouseDownPassword: (event: any) => void;
  loading: boolean;
  openEsqueciMinhaSenha: () => void;
  setLoginRef: (ref: any) => void;
  goToSignUp: () => void;
};

const LoginConcilia: React.FC<Props> = ({
                                            errors,
                                            handleSubmit,
                                            classes,
                                            change,
                                            showPassword,
                                            handleClickShowPassword,
                                            handleMouseDownPassword,
                                            loading,
                                            openEsqueciMinhaSenha,
                                            setLoginRef,
                                            goToSignUp,
                                        }) => {


    const [recaptchaToken, setRecaptchaToken] = useState<string | null>(null);

    const handleRecaptchaChange = (token: string | null) => {
        setRecaptchaToken(token);
    };

    const handleFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!recaptchaToken) {
            alert('Por favor, complete o reCAPTCHA.');
            return;
        }
        handleSubmit(event);
    };

    return (
        <div className={classNames(classes.fullWrap, classes.petal)}>
            <div className={classes.topBar}>
                <NavLink to="/">
                    <img className="LoginForm_logo" src={logo} title={`${process.env.REACT_APP_VERSION}`} alt="Logo" />
                </NavLink>
            </div>
            <Typography variant="h6" className={classes.title} gutterBottom>
                Seja bem vindo ao
            </Typography>
            <section className={classes.pageFormWrap}>
                <div className={classes.btnArea}>
                    <LogoConcilia classe="logo-conciliador" />
                </div>
            </section>
            <Typography variant="caption" className={classes.subtitle} gutterBottom align="center">
                Informe seus dados para entrar no sistema
            </Typography>

            <ListErrors errors={errors} />

            <section className={classes.pageFormWrap}>
                <form onSubmit={handleFormSubmit}>
                    <div>
                        <FormControl className={classes.formControl}>
                            <TextField
                                inputRef={(ref) => setLoginRef(ref)}
                                onChange={(e: any) => change(e)}
                                autoFocus
                                variant="outlined"
                                name="username"
                                placeholder="Seu usuário"
                                label="Seu usuário"
                                required
                                inputProps={{ style: { textTransform: "uppercase" } }}
                                className={classes.field}
                            />
                        </FormControl>
                    </div>
                    <div>
                        <FormControl className={classes.formControl}>
                            <TextField
                                onChange={(e: any) => change(e)}
                                variant="outlined"
                                name="password"
                                type={showPassword ? "text" : "password"}
                                label="Sua senha"
                                placeholder="Sua senha"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="Toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                required
                                className={classes.field}
                            />
                        </FormControl>
                    </div>
                    <div className={classes.optArea}>
                        <Button size="small" onClick={openEsqueciMinhaSenha} className={classes.buttonLink}>
                            <u>Esqueci minha senha</u>
                        </Button>
                        <Button size="small" onClick={goToSignUp} className={classes.buttonLink}>
                            <u>Cadastre-se</u>
                        </Button>
                    </div>
                    <div className={classes.btnArea}>
                        <Button variant="contained" fullWidth color="primary" size="large" type="submit" disabled={loading}>
                            Continuar
                        </Button>
                    </div>
                    <div className="recaptcha-container">
                        <ReCAPTCHA
                            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY || ""}
                            onChange={handleRecaptchaChange}
                        />
                    </div>
                </form>
            </section>
        </div>
    );
};

export default LoginConcilia;
