import Button from "@material-ui/core/Button";
import { Formik, FormikActions, FormikProps } from "formik";
import React, { PureComponent } from "react";
import * as Yup from "yup";
import NewEditProps from "../../../componentes/CRUD/crudProps";
import EditContext from "../../../componentes/CRUD/editContext";
import Loading from "../../../componentes/Loading/Loading";
import { GetUsuarioComponent, SalvarMestreRevendaProps, withSalvarMestreRevenda } from "../../../graphql/components";
import FormUsuarioMestre from "./formUsuarioMestre";

const stringObrigatorio = "é obrigatório.";

const UsuarioSchema = Yup.object().shape({
  responsavel: Yup.string().required(`Responsável ${stringObrigatorio}`),
  telefone: Yup.string(),
  email: Yup.string()
    .email("Email inválido")
    .required(`Email ${stringObrigatorio}`)
});

const initialValues = {
  responsavel: "",
  telefone: "",
  email: ""
};

export type FormValues = typeof initialValues;

class NewEditUsuarioMestre extends PureComponent<SalvarMestreRevendaProps<NewEditProps>> {
  static contextType = EditContext;

  submit = async (values: FormValues, actions: FormikActions<FormValues>) => {
    try {
      const response = await this.props.mutate!({
        variables: {
          insert: this.context ? false : true,
          values: {
            editValues: {
              responsavel: values.responsavel,
              codpessoa: this.context.codpessoa
            },
            searchValues: {
              codpessoa: this.context.codpessoa
            },
            pessoa: {
              pessoa: {
                email: values.email,
                telefone: values.telefone
              }
            }
          }
        }
      });
      if (response && response.data && response.data.salvarMestreRevenda) {
        actions.setSubmitting(false);
        this.props.close();
        this.props.setMessage(true, "success", "Registro salvo com sucesso!");
      }
    } catch (e) {
      actions.setSubmitting(false);
    }
  };

  render() {
    return (
      <div style={{ overflowY: "auto", height: "90%", overflowX: "hidden" }}>
        <Formik
          initialValues={initialValues}
          onSubmit={this.submit}
          validationSchema={UsuarioSchema}
          render={(formikBag: FormikProps<FormValues>) => (
            <form autoComplete="new-password" role="presentation" onSubmit={formikBag.handleSubmit}>
              {!this.context && <FormUsuarioMestre formikBag={formikBag} />}
              {this.context && (
                <GetUsuarioComponent
                  variables={{
                    values: {
                      codpessoa: this.context.codpessoa,
                      codperfil: this.context.codperfil,
                      codpessoapai: this.context.codpessoapai,
                      login: this.context.login
                    }
                  }}
                  fetchPolicy="no-cache"
                >
                  {({ data, loading }) => {
                    if (loading) {
                      return <Loading />;
                    }
                    if (data && data.getUsuario) {
                      return <FormUsuarioMestre formikBag={formikBag} data={data!} />;
                    }
                    return null;
                  }}
                </GetUsuarioComponent>
              )}
              <div
                style={{
                  float: "right",
                  margin: 10,
                  display: "flex",
                  justifyContent: "space-between",
                  width: "180px",
                  flexDirection: "row"
                }}
              >
                <Button variant="contained" color="primary" disabled={formikBag.isSubmitting} type="submit">
                  Salvar
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  disabled={formikBag.isSubmitting}
                  type="reset"
                  onClick={() => formikBag.resetForm(initialValues)}
                >
                  Limpar
                </Button>
              </div>
            </form>
          )}
        />
      </div>
    );
  }
}

const withMutation = withSalvarMestreRevenda<NewEditProps>({ withRef: true });

export default withMutation(NewEditUsuarioMestre);
