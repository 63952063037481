import { Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import Refresh from "@material-ui/icons/Refresh";
import React from "react";
import Loading from "../../../../componentes/Loading/Loading";
import { xSmallTableStyle } from "../../../../componentes/Table/smallTableStyle";
import { ClientesLinhaBean, DeletarTicketComponent, ReprocessarTicketComponent } from "../../../../graphql/components";
import { TableProps } from "./panelTableHOC";
import dayjs from "dayjs";

interface Props {
  classes?: any;
  data: Array<ClientesLinhaBean>;
}

const ClienteOLX = ({ classes, data, loading, callMutation, callMutationDelete }: TableProps<Props>) => {
  return (
    <Table className={classes.table}>
      <TableHead>
        <TableRow>
          <TableCell>Nome Arq.</TableCell>
          <TableCell align="right">Sts Arq.</TableCell>
          <TableCell align="right">Arq. Recebido</TableCell>
          <TableCell align="right">Pagamento</TableCell>
          <TableCell align="right">Venda</TableCell>
          <TableCell align="right">Vlr</TableCell>
          <TableCell align="right">Parc.</TableCell>
          <TableCell align="right">AD_ID</TableCell>
          <TableCell align="right">PAYMENT_ID</TableCell>
          <TableCell align="right">ORDER_ID</TableCell>
          <TableCell align="right">ITEM_ID</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          {data.map(row => (
            <TableRow key={row.codarquivo}>
              <TableCell>{row.nomearquivo}</TableCell>
              <TableCell>{row.statusArquivo}</TableCell>
              <TableCell>{dayjs(row.datarecebimento).format("DD/MM/YYYY")}</TableCell>
              <TableCell>{dayjs(row.dataprevistapagamento).format("DD/MM/YYYY")}</TableCell>
              <TableCell>{dayjs(row.datatransacao).format("DD/MM/YYYY")}</TableCell>
              <TableCell>{row.valorbruto}</TableCell>
              <TableCell>
                {row.numparcela}/{row.qtdparcelas}
              </TableCell>
              <TableCell>{row.nossonumero}</TableCell>
              <TableCell>{row.bandeira}</TableCell>
              <TableCell>{row.nsu}</TableCell>
              <TableCell>{row.tid}</TableCell>
              <TableCell>
                <>
                  {loading && <Loading />}
                  <ReprocessarTicketComponent>
                    {mutate => {
                      if (loading) {
                        return null;
                      }
                      return (
                        !loading && (
                          <Tooltip title="Reprocessar" placement="top" onClick={callMutation.bind(null, mutate, row)}>
                            <Refresh style={{ color: "gray", fontSize: 15, cursor: "pointer" }} />
                          </Tooltip>
                        )
                      );
                    }}
                  </ReprocessarTicketComponent>
                  <DeletarTicketComponent>
                    {mutate => {
                      if (loading) {
                        return null;
                      }
                      return (
                        !loading && (
                          <Tooltip title="Deletar" placement="top" onClick={callMutationDelete.bind(null, mutate, row)}>
                            <DeleteIcon style={{ color: "gray", fontSize: 15, cursor: "pointer" }} />
                          </Tooltip>
                        )
                      );
                    }}
                  </DeletarTicketComponent>
                </>
              </TableCell>
            </TableRow>
          ))}
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default withStyles(xSmallTableStyle as any)(ClienteOLX);
