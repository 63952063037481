import React from "react";
import { withStyles, Paper, Button, LinearProgress, Zoom } from "@material-ui/core";

const styles = (theme: any) => ({
  paper: {
    marginTop: 5
  },
  button: {
    margin: 10,
    width: "92%"
  }
});

interface Props {
  classes: any;
  isSubmitting: boolean;
  title?: string;
}

const ButtonGerarRelatorio = ({ classes, isSubmitting, title = "Gerar relatório" }: Props) => {
  return (
    <Paper className={classes.paper} elevation={20}>
      <Button variant="text" color="primary" className={classes.button} disabled={isSubmitting} type="submit">
        {isSubmitting ? "Estamos processando sua requisição" : title}
      </Button>
      <Zoom in={isSubmitting}>
        <LinearProgress color="secondary" />
      </Zoom>
    </Paper>
  );
};

export default withStyles(styles as any)(ButtonGerarRelatorio);
