export const PAINEL_CADASTRO_ACTIONS_TYPE = {
  SELECT_OPERADORA: "SELECT_OPERADORA"
};

export const selectOperadoraToEdit = (nomeOperadora: string, codOperadora: number) => ({
  type: PAINEL_CADASTRO_ACTIONS_TYPE.SELECT_OPERADORA,
  payload: {
    nomeOperadora,
    codOperadora
  }
});
