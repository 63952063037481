import { Grid } from "@material-ui/core";
import { Field, FormikProps } from "formik";
import React from "react";
import FormikInputText from "../../../componentes/MaskedInput/FomikInput";
import FormikMaskedInputText from "../../../componentes/MaskedInput/FormikMaskedInput";
import { GetUsuarioQuery } from "../../../graphql/components";
import { FormValues } from "./newEditUsuarioMestre";

type Props = {
  formikBag: FormikProps<FormValues>;
  data?: GetUsuarioQuery;
};

const FormUsuarioMestre = ({ formikBag, data }: Props) => {
  React.useEffect(() => {
    if (data) {
      formikBag.setValues({
        telefone: data.getUsuario.pessoa.pessoa.telefone!,
        email: data.getUsuario.pessoa.pessoa.email!,
        responsavel: data.getUsuario.editValues.responsavel!
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <React.Fragment>
      <fieldset style={{ marginTop: 15, marginRight: 10 }}>
        <Grid container spacing={2} title="Dados Usuário">
          <Grid item xs={12} md={4}>
            <Field name="responsavel" component={FormikInputText} label="Responsável pela empresa" />
          </Grid>
          <Grid item xs={12} md={4}>
            <Field
              id="telefone"
              name="telefone"
              component={FormikMaskedInputText}
              label="Telefone"
              mask={["(", /\d/, /\d/, ")", " ", /\d/, /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/]}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Field name="email" component={FormikInputText} label="Email" />
          </Grid>
        </Grid>
      </fieldset>
    </React.Fragment>
  );
};

export default FormUsuarioMestre;
