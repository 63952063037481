import React from "react";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import decorationLight from "../../styles/images/decoration_light.svg";
import decorationDark from "../../styles/images/decoration_dark.svg";
import petalLight from "../../styles/images/petal_bg.svg";
import styles from "./appStyles-jss";

class Decoration extends React.Component<DecorationProps> {
  render() {
    const {
      classes,
      mode,
      gradient,
      decoration,
      bgPosition,
      horizontalMenu
    } = this.props;
    const getBgPosition = (position:any) => {
      if (position === "header") {
        return classes.headerBg;
      }
      if (position === "half") {
        return classes.halfBg;
      }
      return classes.fullBg;
    };
    const renderDecor = (type: any, position:any) => {
      const mergeDeco = type + "-" + position;
      if (mergeDeco === "dark-half") {
        return (
          <img
            src={decorationDark}
            alt="decoration"
            className={classes.decorationWave}
          />
        );
      }
      if (mergeDeco === "dark-full") {
        return (
          <img
            src={petalLight}
            alt="decoration"
            className={classes.decorationPetal}
          />
        );
      }
      if (mergeDeco === "light-half") {
        return (
          <img
            src={decorationLight}
            alt="decoration"
            className={classes.decorationWave}
          />
        );
      }
      if (mergeDeco === "light-full") {
        return (
          <img
            src={petalLight}
            alt="decoration"
            className={classes.decorationPetal}
          />
        );
      }
      return false;
    };
    return (
      <div className={classes.bgWrap}>
        <div
          className={classNames(
            classes.bgbar,
            horizontalMenu && classes.highTop,
            gradient ? classes.gradientBg : classes.solidBg,
            getBgPosition(bgPosition)
          )}
        >
          {decoration && renderDecor(mode, bgPosition)}
        </div>
      </div>
    );
  }
}

interface DecorationProps {
  classes: any;
  mode: any;
  gradient: any;
  decoration: any;
  bgPosition: any;
  horizontalMenu: any;
}

export default withStyles(styles as any)(Decoration);
