import { withStyles, Theme, Grid } from "@material-ui/core";
import { FormikProps } from "formik";
import React from "react";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import ToggleButton from "@material-ui/lab/ToggleButton";

const styles = (theme: Theme) => ({
  formControl: {
    width: "100%"
  },
  toggleContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    margin: `${theme.spacing()}px 0`,
  }
});

export interface ToggleOptionsValues {
  label: string;
  value: string;
}

interface Props {
  formikBag: FormikProps<any>;
  classes: any;
  label: string;
  value: any;
  fieldToChange: string;
  values: Array<ToggleOptionsValues>;
  fieldSize?: boolean | 4 | "auto" | 1 | 2 | 3 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | undefined;
  xSmall?: boolean;
}

const ToggleOptions = ({ formikBag, classes, label, value, fieldToChange, values, fieldSize = "auto", xSmall }: Props) => {
  return (
    <Grid item xs={12} md={fieldSize} style={xSmall ? { fontSize: "x-small" } : {}}>
      <div className={classes.toggleContainer}>
        <span style={{ color: "gray" }}>{label}</span>
        <ToggleButtonGroup
          id={fieldToChange}
          style={{ marginLeft: 10 }}
          value={value}
          exclusive
          onChange={(event: React.MouseEvent<HTMLElement, MouseEvent>, value: any) => {
            if (value != null) {
              formikBag.setFieldValue(fieldToChange, value);
            }
          }}
        >
          {values.map(value => (
            <ToggleButton key={value.value} value={value.value} disabled={formikBag.isSubmitting}>
              {value.label}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      </div>
    </Grid>
  );
};

export default withStyles(styles as any)(ToggleOptions);
