import { menuSistemaTypes } from "../../actionConstants";
import ActionTypeDefault from "../../actionTypeDefault";

export const initialState = {
  menuUsuario: [
    {
      key: 3,
      name: "Home",
      icon: "ios-menu-outline",
      url: "http:/",
      link: "/",
      ordem: 0,
      flgtitulo: 0,
      child: [
        {
          key: 15,
          name: "Home 1",
          icon: "ios-menu-outline",
          url: "http:/",
          link: "/",
          ordem: 0,
          flgtitulo: 0
        }
      ]
    }
  ]
};

export default (state = initialState, action: ActionTypeDefault) => {
  switch (action.type) {
    case menuSistemaTypes.REQUEST_MENU:
      return {
        ...state,
        loading: true
      };
    case menuSistemaTypes.RECEIVE_MENU:
      return {
        ...state,
        menuUsuario: action.payload,
        loading: false
      };
    default:
      return state;
  }
};
