import { DataProxy } from "apollo-cache";
import { forkJoin } from "rxjs";
import { CallbackActionType } from "../../../componentes/CRUD/actionsTable";
import ColumnCrudTableType from "../../../componentes/CRUD/columnCrudTableType";
import {
  DeletePerfilMutation,
  DeletePerfilMutationVariables,
  ListarPerfilDocument,
  ListarPerfilQuery
} from "../../../graphql/components";

export const variablesPerfil: { codperfil: number | null; nome: string | null } = {
  codperfil: null,
  nome: null
};

export const deleteMutationPerfil: CallbackActionType<DeletePerfilMutation, DeletePerfilMutationVariables> = (
  mutation,
  record,
  setMessage
) => {
  if (mutation) {
    const deletesPromises: Array<any> = [];
    record.forEach(r => {
      deletesPromises.push(
        mutation({
          variables: { values: { editValues: { codperfil: r.codperfil, nome: r.nome } } },
          update: (store: DataProxy, { data }) => {
            if (data!.deletePerfil) {
              const oldResult = store.readQuery<ListarPerfilQuery>({
                query: ListarPerfilDocument,
                variables: { values: variablesPerfil }
              });
              if (oldResult) {
                const index = oldResult.listarPerfil.findIndex(u => u.codperfil === r.codperfil);
                oldResult.listarPerfil.splice(index, 1);
                const result = [...oldResult.listarPerfil];
                store.writeQuery<ListarPerfilQuery>({
                  query: ListarPerfilDocument,
                  data: { listarPerfil: result },
                  variables: variablesPerfil
                });
              }
            }
          }
        })
      );
    });
    forkJoin(deletesPromises).subscribe(
      response => {
        const totalErrors = response.filter(r => typeof r === "string").length;
        if (totalErrors > 0 && totalErrors !== deletesPromises.length) {
          setMessage(
            true,
            "warning",
            "Alguns registros não foram excluídos. Dentro de 5 segundos iremos atualizar a página para manter os dados coerentes."
          );
          setTimeout(() => window.location.reload(), 6500);
        } else if (totalErrors === 0) {
          setMessage(true, "success", "Registro(s) excluídos com sucesso!");
        }
      },
      errors => {
        console.log(errors);
      }
    );
  }
};

export const columnsOrderPerfil = ["nome"];

export const colunasPerfil: Array<ColumnCrudTableType> = [
  {
    name: "Nome",
    options: {
      filter: true
    }
  }
];
