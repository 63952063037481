import React from "react";
import ErrorWrap from "../../../componentes/Error/ErrorWrap";

const NotAllowed = () => (
  <div>
    <ErrorWrap title="Ops..." desc="Você ainda não completou seu cadastro." location="/app/home" />
  </div>
);

export default NotAllowed;
