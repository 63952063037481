import { Button, Grid, Step, StepLabel, Stepper, TextField } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import { Formik, FormikProps } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";
import Masked from "../../../componentes/MaskedInput/MaskedInput";
import PapperBlock from "../../../componentes/PapperBlock/PapperBlock";
import styles from "../../Templates/appStyles-jss";
import LeftSidebarLayout from "../../Templates/layouts/LeftSidebarLayout";

const obrigatorio = "é obrigatório.";

const Schema = Yup.object().shape({
  cpfCnpj: Yup.string().required(`Bairro ${obrigatorio}`),
  apelidoLoja: Yup.string().required(`Apelido da loga ${obrigatorio}`),
  codExterno: Yup.string().required(`Código Externo ${obrigatorio}`),
  ramoAtividade: Yup.string().required(`Ramo Atividade ${obrigatorio}`),
  estado: Yup.string().required(`Estado ${obrigatorio}`),
  cidade: Yup.string().required(`Cidade ${obrigatorio}`),
  bairro: Yup.string().required(`Bairro ${obrigatorio}`),
  ruaAv: Yup.string().required(`Rua/Av ${obrigatorio}`),
  numero: Yup.string().required(`Número ${obrigatorio}`),
  complemento: Yup.string(),
  cep: Yup.string().required(`CEP ${obrigatorio}`),
  telefone: Yup.string().required(`Telefone ${obrigatorio}`),
  email: Yup.string().required(`Email ${obrigatorio}`),
  inscEstadual: Yup.string().required(`Inscrição estadual ${obrigatorio}`),
  inscMunicipal: Yup.string().required(`Inscrição Municipal ${obrigatorio}`),
  login: Yup.string(),
  senha: Yup.string(),
  debito: Yup.string(),
  credito: Yup.string(),
  parceladoA: Yup.string(),
  parceladoB: Yup.string(),
  ticket: Yup.string(),
});

const initialValues = {
  cpfCnpj: "",
  apelidoLoja: "",
  codExterno: "",
  ramoAtividade: "",
  estado: "",
  cidade: "",
  bairro: "",
  ruaAv: "",
  numero: "",
  complemento: "",
  cep: "",
  telefone: "",
  email: "",
  inscEstadual: "",
  inscMunicipal: "",
  login: "",
  senha: "",
  debito: "",
  credito: "",
  parceladoA: "",
  parceladoB: "",
  ticket: "",
};

type FormValues = typeof initialValues;

interface Props {
  classes: any;
}

const SignUp: React.FC<Props> = ({ classes }) => {
  const textInput = React.useRef();
  React.useEffect(() => {
    if (textInput && textInput.current) {
      (textInput.current as any).focus();
    }
  }, []);
  const doc = document.documentElement;
  const scroll = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
  const [transform] = useState(scroll);
  const [currentStep, setCurrentStep] = useState(0);
  const titleException = React.useMemo(() => [], []);
  const mask = [/\d/, /\d/, ".", /\d/, /\d/, /\d/, ".", /\d/, /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/];
  const maskTelefone = ["(", /\d/, /\d/, ")", /\d/, /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/];

  const submit = React.useCallback(() => {}, []);

  return (
    <div className={classNames(classes.appFrameInner, classes.sideNav, "light-mode")}>
      <LeftSidebarLayout
        mode="light"
        gradient="true"
        deco="true"
        bgPosition="full"
        transform={transform}
        place="Cadastro"
        titleException={titleException}
        isComplete={false}
      >
        <PapperBlock
          title="Efetue o seu cadastro"
          marginRight={50}
          desc="Aqui você pode fazer seu cadastro no Conciliador VIS de forma prática e rápida. <br> <a href='/'>Clique aqui para voltar à tela inicial.</a>"
          whiteBgNormal
        >
          <Formik
            initialValues={initialValues}
            onSubmit={submit}
            validationSchema={Schema}
            render={(formikBag: FormikProps<FormValues>) => (
              <div style={{ minHeight: 250 }}>
                <Stepper activeStep={currentStep}>
                  {["Dados Iniciais", "Termo de Responsabilidade", "API Cielo", "EC", "Taxas", "Pagamento"].map((label) => (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
                {currentStep === 0 && (
                  <Grid container spacing={2} style={{ padding: 20 }}>
                    <Grid item xs={12} md={2}>
                      <TextField
                        label="CNPJ"
                        inputRef={textInput}
                        id="cpfCnpj"
                        name="cpfCnpj"
                        autoFocus
                        value={formikBag.values.cpfCnpj}
                        onChange={formikBag.handleChange}
                        margin="normal"
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                          inputComponent: Masked as any,
                        }}
                        inputProps={{
                          mask: mask,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={2}>
                      <TextField
                        label="Telefone"
                        id="telefone"
                        name="telefone"
                        autoFocus
                        value={formikBag.values.telefone}
                        onChange={formikBag.handleChange}
                        margin="normal"
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                          inputComponent: Masked as any,
                        }}
                        inputProps={{
                          mask: maskTelefone,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={2}>
                      <TextField
                        label="Email"
                        id="email"
                        name="email"
                        autoFocus
                        value={formikBag.values.email}
                        onChange={formikBag.handleChange}
                        margin="normal"
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                  </Grid>
                )}
                {currentStep === 1 && (
                  <Grid container spacing={2} style={{ padding: 20 }}>
                    <Grid item xs={12}>
                      Irure nulla dolor labore sit anim sit id officia exercitation cillum. Consequat consequat eu laborum
                      voluptate consectetur tempor enim elit non proident consectetur sit. Amet fugiat culpa minim id sunt.
                      Ullamco officia consequat labore consequat deserunt excepteur dolor consequat sunt nisi culpa excepteur.
                      Deserunt velit deserunt dolore incididunt do dolore anim sit. Dolor exercitation anim eu aute ut. Eiusmod
                      proident dolore nisi eu nisi ullamco consequat exercitation mollit sint amet ad sunt. Incididunt culpa
                      veniam ullamco duis eu anim enim adipisicing quis voluptate labore. Duis ea ea sunt ut magna ut velit
                      exercitation irure amet. Exercitation ea velit duis culpa. Nostrud minim laboris excepteur id sit eu est et
                      ipsum consequat ad laborum duis non. Lorem velit commodo quis id minim aute dolore non tempor. Amet qui
                      mollit deserunt ex Lorem tempor eiusmod ullamco aliqua eiusmod duis qui. Commodo cupidatat fugiat et non in
                      proident dolor pariatur ut sint est. Commodo voluptate exercitation cillum consectetur incididunt veniam
                      labore amet pariatur aute est commodo eu. Elit do pariatur officia do duis ad exercitation dolor ipsum
                      deserunt. Dolore esse ad esse Lorem aute duis voluptate velit ullamco laborum esse et. Ipsum aliqua nostrud
                      ipsum ullamco dolor ad occaecat nostrud magna. Sit sunt dolor et amet. Est minim et officia sunt enim dolor
                      Lorem aliqua magna. Non reprehenderit ut laboris cillum consectetur duis nostrud mollit aute proident ad
                      proident cillum. Duis eu veniam aute dolore. Cupidatat elit irure aliquip minim ullamco tempor. Excepteur
                      sint adipisicing nostrud adipisicing do pariatur et non nostrud labore sit minim cupidatat occaecat.
                      Deserunt duis ad id aliqua non irure. Aute ex irure sunt laborum excepteur ad sint irure commodo voluptate
                      cillum. Nisi ullamco laborum eiusmod laborum ut non ut ipsum reprehenderit cupidatat consequat. Et occaecat
                      occaecat non aute nostrud non irure eiusmod officia ex do adipisicing dolor.
                    </Grid>
                  </Grid>
                )}
                {currentStep === 2 && (
                  <Grid container spacing={2} style={{ padding: 20 }}>
                    <Grid item xs={12} md={2}>
                      <TextField
                        label="Login"
                        id="login"
                        name="login"
                        autoFocus
                        value={formikBag.values.email}
                        onChange={formikBag.handleChange}
                        margin="normal"
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                    <Grid item xs={12} md={2}>
                      <TextField
                        label="Senha"
                        id="senha"
                        name="senha"
                        value={formikBag.values.senha}
                        onChange={formikBag.handleChange}
                        margin="normal"
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                  </Grid>
                )}
                {currentStep === 3 && (
                  <Grid container spacing={2} style={{ padding: 20 }}>
                    <Grid item xs={12} md={2}>
                      <TextField
                        label="EC"
                        id="codExterno"
                        name="codExterno"
                        autoFocus
                        value={formikBag.values.codExterno}
                        onChange={formikBag.handleChange}
                        margin="normal"
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                  </Grid>
                )}
                {currentStep === 4 && (
                  <Grid container spacing={2} style={{ padding: 20 }}>
                    <Grid item xs={12} md={2}>
                      <TextField
                        label="Débito"
                        id="debito"
                        name="debito"
                        autoFocus
                        value={formikBag.values.debito}
                        onChange={formikBag.handleChange}
                        margin="normal"
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                    <Grid item xs={12} md={2}>
                      <TextField
                        label="Crédito"
                        id="credito"
                        name="credito"
                        value={formikBag.values.credito}
                        onChange={formikBag.handleChange}
                        margin="normal"
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                    <Grid item xs={12} md={2}>
                      <TextField
                        label="Parcelado 0-6"
                        id="parceladoA"
                        name="parceladoA"
                        value={formikBag.values.parceladoA}
                        onChange={formikBag.handleChange}
                        margin="normal"
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                    <Grid item xs={12} md={2}>
                      <TextField
                        label="Parcelado 7-12"
                        id="parceladoB"
                        name="parceladoB"
                        value={formikBag.values.parceladoB}
                        onChange={formikBag.handleChange}
                        margin="normal"
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                    <Grid item xs={12} md={2}>
                      <TextField
                        label="Ticket"
                        id="ticket"
                        name="ticket"
                        value={formikBag.values.ticket}
                        onChange={formikBag.handleChange}
                        margin="normal"
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                  </Grid>
                )}
                {currentStep === 5 && (
                  <Grid container spacing={2} style={{ padding: 20 }}>
                    <Grid item xs={12} md={2}>
                      PAGAMENTO
                    </Grid>
                  </Grid>
                )}
                <div className={classes.buttons}>
                  {currentStep !== 0 && (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => setCurrentStep(currentStep - 1)}
                      className={classes.button}
                      size="large"
                    >
                      Anterior
                    </Button>
                  )}
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      setCurrentStep(currentStep + 1);
                    }}
                    style={{ float: "right" }}
                    size="large"
                  >
                    {currentStep === 5 ? "Finalizar Cadastro" : currentStep === 1 ? "Aceitar termos e condições" : "Próximo"}
                  </Button>
                </div>
              </div>
            )}
          />
        </PapperBlock>
      </LeftSidebarLayout>
    </div>
  );
};

export default withStyles(styles as any)(SignUp);
