import React from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";
import { connect } from "react-redux";
import { IState } from "../../redux/reducers";
import Routes from "../../utils/routes";

interface AllowedRouteProps extends RouteProps {
  component: any;
  isAllowed?: boolean;
  redirect?: boolean;
}

const AllowedRoute = (props: AllowedRouteProps) => {
  const { component: Component, isAllowed, ...rest } = props;
  return (
    <Route
      {...rest}
      render={(propsRoute) =>
        isAllowed ? (
          <Component {...propsRoute} />
        ) : (
          <Redirect
            to={{
              pathname: Routes.notAllowed.route,
            }}
          />
        )
      }
    />
  );
};

const mapStateToProps = (state: IState) => ({
  isAllowed: state.allowReducer.isAllowed,
});

const AllowedRouteMapped = connect(
  mapStateToProps,
  null
)(AllowedRoute);

export default AllowedRouteMapped;
