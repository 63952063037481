import { FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup } from "@material-ui/core";
import { Moment } from "moment";
import React, { PureComponent } from "react";
import ConciliaDatePicker from "../../../componentes/ConciliaDatePicker";
import { FilterProps } from "../../../componentes/CRUD/crudProps";
import { SelectCombo } from "../../../componentes/Select";
import { ListarItensTerminalVinculoDocument, ListarItensUnidadesConciliadorasDocument } from "../../../graphql/components";
import { variablesFaturamentoMovimento } from "./utilsFaturamentoMovimento";

type CustomState = {};

type State = typeof variablesFaturamentoMovimento & CustomState;

export default class FilterFaturamentoMovimentoA extends PureComponent<FilterProps, State> {
  constructor(props: FilterProps) {
    super(props);
    this.state = {
      ...variablesFaturamentoMovimento
    };
  }

  onChangeDe = (date: Moment) => {
    if (date) {
      this.setState({ datageracaode: date.toDate() }, () => {
        this.props.variables.datageracaode = date.toDate();
      });
    } else {
      this.setState({ datageracaode: null }, () => {
        this.props.variables.datageracaode = null;
      });
    }
  };

  onChangeAte = (date: Moment) => {
    if (date) {
      this.setState({ datageracaoate: date.toDate() }, () => {
        this.props.variables.datageracaoate = date.toDate();
      });
    } else {
      this.setState({ datageracaoate: null }, () => {
        this.props.variables.datageracaoate = null;
      });
    }
  };

  render() {
    return (
      <Grid container spacing={2} style={{ marginBottom: 30 }}>
        <Grid item xs={12} md={6}>
          <ConciliaDatePicker
            label="Processado De"
            name="datageracaode"
            value={this.state.datageracaode}
            onChange={this.onChangeDe}
            disabled={this.props.isFiltering}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <ConciliaDatePicker
            label="Processado Até"
            name="datageracaoate"
            value={this.state.datageracaoate}
            onChange={this.onChangeAte}
            disabled={this.props.isFiltering}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <SelectCombo
            label="Grupo Terminal"
            name="terminais"
            nameQuery="listarItensTerminalVinculo"
            query={ListarItensTerminalVinculoDocument}
            value={this.state.terminais ? this.state.terminais : ""}
            handleChange={e => {
              const valor = e.currentTarget.value as string;
              this.setState({ terminais: valor }, () => {
                this.props.variables.terminais = this.state.terminais;
                this.props.variablesValorExibicao.terminais = valor;
              });
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <SelectCombo
            label="Unidade Conciliadora"
            name="conciliador"
            nameQuery="listarItensUnidadesConciliadoras"
            query={ListarItensUnidadesConciliadorasDocument}
            value={this.state.unidadeConciliadora ? this.state.unidadeConciliadora : ""}
            handleChange={e => {
              const valor = e.currentTarget.value as string;
              this.setState({ unidadeConciliadora: valor }, () => {
                this.props.variables.unidadeConciliadora = this.state.unidadeConciliadora;
                this.props.variablesValorExibicao.unidadeConciliadora = valor;
              });
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl>
            <FormLabel>Processado</FormLabel>
            <RadioGroup
              row
              aria-label="tipo"
              name="tipo"
              value={this.state.tipo}
              onChange={(e: any) => {
                this.setState({ tipo: e.target.value }, () => {
                  this.props.variables.tipo = this.state.tipo;
                  this.props.variablesValorExibicao.tipo =
                    this.state.tipo === "A" ? "Ambos" : this.state.tipo === "N" ? "Não Processados" : "Processados";
                });
              }}
            >
              <FormControlLabel value="A" control={<Radio />} label="Ambos" disabled={this.props.isFiltering} />
              <FormControlLabel value="N" control={<Radio />} label="Não Processados" disabled={this.props.isFiltering} />
              <FormControlLabel value="P" control={<Radio />} label="Processados" disabled={this.props.isFiltering} />
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>
    );
  }
}
