const iconsTemplate: any = {
  "fa-home": "ios-home-outline",
  "fa-pencil-square": "ios-create-outline",
  "fa-credit-card-alt": "ios-card",
  "fa-tachometer": "ios-pie-outline",
  "fa-object-ungroup": "ios-open-outline",
  "fa-file-text": "ios-paper-outline",
  "fa-cogs": "ios-git-compare",
  "fa-question-circle": "ios-help",
  "fa-sign-out": "ios-log-out"
};

export default (icon: string) => {
  if (icon != null && icon !== "") {
    const iconParsed = icon.trim().split(" ")[1];
    return iconsTemplate[iconParsed];
  }

  return null;
};
