import React from "react";
import MaskedInput from "react-text-mask";

const Masked = React.memo((prop: any) => {
  const { inputRef, mask, ...other } = prop;
  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={mask}
      showMask
    />
  );
});

export default Masked;
