import { Paper } from "@material-ui/core";
import { MuiThemeProvider, Theme, withTheme } from "@material-ui/core/styles";
import React, { SetStateAction, useState } from "react";
import { MutationFunction } from "react-apollo";
import {
  ClientesLinhaBean,
  ReprocessarTicketMutationVariables,
  OperadoraLinhaBean,
  DeletarTicketMutationVariables
} from "../../../../graphql/components";
import SnackMessage from "../../../../componentes/SnakBar";

const styles = {
  paper: { padding: 10, marginTop: 15, width: "150%" }
};

export type TableProps<T = {}> = T & {
  loading: boolean;
  data?: Array<OperadoraLinhaBean> | Array<ClientesLinhaBean> | null;
  callMutation: (
    mutate: MutationFunction<any, ReprocessarTicketMutationVariables> | MutationFunction<any, DeletarTicketMutationVariables>,
    row: OperadoraLinhaBean | ClientesLinhaBean
  ) => Promise<void>;
  callMutationDelete: (
    mutate: MutationFunction<any, DeletarTicketMutationVariables> | MutationFunction<any, DeletarTicketMutationVariables>,
    row: OperadoraLinhaBean | ClientesLinhaBean
  ) => Promise<void>;
};

interface Props {
  theme?: Theme;
}

const withPanelTable = (
  TableAdmCliente: any,
  title: string,
  data?: Array<OperadoraLinhaBean> | Array<ClientesLinhaBean> | null
) =>
  withTheme(({ theme }: Props) => {
    const [message, setMessage] = useState("");
    const [messageVariant, setMessageVariant] = useState<"success" | "warning" | "error" | "info">("success");
    const [messageOpen, setMessageOpen] = useState(false);

    const buildMessage = React.useCallback(
      (isOpen: boolean, variant: SetStateAction<"error" | "success" | "warning" | "info">, message: string) => {
        setMessage(message);
        setMessageVariant(variant);
        setMessageOpen(isOpen);
      },
      []
    );

    const closeMessage = React.useCallback(() => setMessageOpen(false), []);

    const [loading, setLoading] = useState(false);
    const callMutation = React.useCallback(
      async (mutate: MutationFunction<any, ReprocessarTicketMutationVariables>, row: OperadoraLinhaBean | ClientesLinhaBean) => {
        setLoading(true);
        try {
          await mutate({ variables: { values: { codarquivo: row.codarquivo, tipo: row.tipo } } });
          setLoading(false);
          buildMessage(true, "success", "Operação realizada com sucesso!");
        } catch (e) {
          setLoading(false);
        }
      },
      [buildMessage]
    );

    const callMutationDelete = React.useCallback(
      async (mutate: MutationFunction<any, DeletarTicketMutationVariables>, row: OperadoraLinhaBean | ClientesLinhaBean) => {
        setLoading(true);
        try {
          await mutate({ variables: { values: { editValues: { codarquivo: row.codarquivo, tipo: row.tipo } } } });
          setLoading(false);
          buildMessage(true, "success", "Operação realizada com sucesso!");
        } catch (e) {
          setLoading(false);
        }
      },
      [buildMessage]
    );

    (theme!.overrides as any)["MuiTableCell"] = {
      root: {
        "&:last-child": {
          paddingRight: 0
        }
      }
    };
    if (!data || data.length === 0) {
      return null;
    }
    return (
      <Paper style={styles.paper} elevation={20}>
        {title}
        <MuiThemeProvider theme={theme!}>
          <TableAdmCliente loading={loading} callMutation={callMutation} callMutationDelete={callMutationDelete} data={data} />
        </MuiThemeProvider>
        <SnackMessage message={message} variant={messageVariant} open={messageOpen} close={closeMessage} />
      </Paper>
    );
  });

export default withPanelTable;
