import Button from "@material-ui/core/Button";
import { Formik, FormikActions, FormikProps } from "formik";
import React, { PureComponent } from "react";
import * as Yup from "yup";
import NewEditProps from "../../../../componentes/CRUD/crudProps";
import EditContext from "../../../../componentes/CRUD/editContext";
import Loading from "../../../../componentes/Loading/Loading";
import {
  GetPlanosPosComponent,
  SalvarPlanosPosProps,
  withSalvarPlanosPos,
  ListarPlanosPosQuery,
  ListarPlanosPosDocument,
  GetPlanosPosQuery,
  GetPlanosPosQueryVariables,
  GetPlanosPosDocument,
  PlanosDao,

} from "../../../../graphql/components";
import FormPlanosPos from "./formPlanosPOS";
import { variablesPlanosPOS } from "./utilsPlanosPOS";

const stringObrigatorio = "é obrigatório.";

const PlanosPosSchema = Yup.object().shape({
  codplano: Yup.number().nullable(true),
  nome: Yup.string().required(`Nome ${stringObrigatorio}`).max(50, `Nome não pode exceder 50 caracteres`),
  cobramensalidade: Yup.string(),
  valormensalidade: Yup.number(),
  diacorte: Yup.number(),
});

const initialValues = {
  codplano: (null as unknown) as number,
  nome: ("" as unknown) as string,
  cobramensalidade: ("" as unknown) as string,
  valormensalidade: ("" as unknown) as number,
  diacorte: ("" as unknown) as number,
};

export type FormValues = typeof initialValues;

class NewEditPlanosPOS extends PureComponent<SalvarPlanosPosProps<NewEditProps>> {
  static contextType = EditContext;

  submit = async (values: FormValues, actions: FormikActions<FormValues>) => {
    const response = await this.props.mutate!({
      variables: {
        values: {
          editValues: {
            codplano: values.codplano,
            nome: values.nome,
            cobramensalidade: values.cobramensalidade,
            valormensalidade: values.valormensalidade,
            diacorte: values.diacorte,
          }
        },
        insert: this.context ? false : true
      },
      update: (store, result) => {

        if (result.data && result.data.salvarPlanosPOS) {
          const data = store.readQuery<ListarPlanosPosQuery>({
            query: ListarPlanosPosDocument,
            variables: { values: {} }
          });
          if (this.context && this.context.codplano) {
            const edited = store.readQuery<GetPlanosPosQuery, GetPlanosPosQueryVariables>({
              query: GetPlanosPosDocument,
              variables: { values: { codplano: this.context.codplano} }
            });
            if (edited && edited.getPlanosPOS.editValues.codplano) {
              const registro = data!.listarPlanosPOS.find(item => item.codplano === edited.getPlanosPOS.editValues.codplano!);
              if (registro) {
                registro.codplano = values.codplano;
                registro.nome = values.nome;
                registro.cobramensalidade = values.cobramensalidade;
                registro.valormensalidade = values.valormensalidade;
                registro.diacorte = values.diacorte;
                const newResult = data!.listarPlanosPOS.slice(0);
                store.writeQuery<ListarPlanosPosQuery>({
                  query: ListarPlanosPosDocument,
                  data: { listarPlanosPOS: newResult },
                  variables: variablesPlanosPOS
                });

                edited.getPlanosPOS.editValues.codplano = values.codplano;
                edited.getPlanosPOS.editValues.nome = values.nome;
                edited.getPlanosPOS.editValues.cobramensalidade = values.cobramensalidade;
                edited.getPlanosPOS.editValues.valormensalidade = values.valormensalidade;
                edited.getPlanosPOS.editValues.diacorte = values.diacorte;;
                store.writeQuery({
                  query: GetPlanosPosDocument,
                  data: { ...edited, __typename: "PlanosCRUD" },
                  variables: {
                    values: {}
                  }
                });
              }
            }
          } else {
            const planos: PlanosDao = {
              __typename: "PlanosDAO",
                codplano: values.codplano,
                nome: values.nome,
                cobramensalidade: values.cobramensalidade,
                valormensalidade: values.valormensalidade,
                diacorte: values.diacorte
            
            };
            store.writeQuery<ListarPlanosPosQuery>({
              query: ListarPlanosPosDocument,
              data: { listarPlanosPOS: [...data!.listarPlanosPOS, planos] },
              variables: variablesPlanosPOS
            });
          }
        }

      }
    });
    if (response && response.data && response.data.salvarPlanosPOS) {
      actions.setSubmitting(false);
      this.props.close();
      this.props.setMessage(true, "success", "Plano pos gravado com sucesso!");
    }
  };

  render() {
    return (
      <div style={{ overflowY: "auto", height: "90%", overflowX: "hidden" }}>
        <Formik
          initialValues={initialValues}
          onSubmit={this.submit}
          validationSchema={PlanosPosSchema}
          render={(formikBag: FormikProps<FormValues>) => (
            <form autoComplete="new-password" role="presentation" onSubmit={formikBag.handleSubmit}>
              {!this.context && <FormPlanosPos formikBag={formikBag} />}
              {this.context && (
                <GetPlanosPosComponent
                  variables={{
                    values: { codplano: this.context.codplano }
                  }}
                >
                  {({ data, loading }) => {
                    if (loading) {
                      return <Loading />;
                    }
                    if (data && data.getPlanosPOS) {
                      return <FormPlanosPos formikBag={formikBag} data={data!} />;
                    }
                    return null;
                  }}
                </GetPlanosPosComponent>
              )}
              <div
                style={{
                  float: "right",
                  margin: 10,
                  display: "flex",
                  justifyContent: "space-between",
                  width: "180px",
                  flexDirection: "row"
                }}
              >
                <Button variant="contained" color="primary" disabled={formikBag.isSubmitting} type="submit">
                  Salvar
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  disabled={formikBag.isSubmitting}
                  type="reset"
                  onClick={() => formikBag.resetForm(initialValues)}
                >
                  Limpar
                </Button>
              </div>
            </form>
          )}
        />
      </div>
    );
  }
}

const withMutation = withSalvarPlanosPos<NewEditProps>({ withRef: true });

export default withMutation(NewEditPlanosPOS);
