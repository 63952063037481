import { Button, Grid, LinearProgress, Paper, Typography, Zoom } from "@material-ui/core";
import { Field, Formik, FormikActions, FormikProps } from "formik";
import React from "react";
import * as Yup from "yup";
import FormikInputText from "../../../componentes/MaskedInput/FomikInput";

const stringObrigatorio = "é obrigatório.";

const Schema = Yup.object().shape({
  responsavel: Yup.string().required(`Responsável ${stringObrigatorio}`)
});

const initialValues = {
  responsavel: ""
};

export type FormValuesDefinirSenha = typeof initialValues;

interface Props {
  submit: (values: FormValuesDefinirSenha, actions: FormikActions<FormValuesDefinirSenha>) => void;
}

const DefinirSenha: React.FC<Props> = ({ submit }) => {
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={submit}
      validationSchema={Schema}
      render={(formikBag: FormikProps<FormValuesDefinirSenha>) => (
        <form autoComplete="new-password" role="presentation" onSubmit={formikBag.handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                Nome do Responsável pela Empresa
              </Typography>
              <Field
                id="responsavel"
                name="responsavel"
                component={FormikInputText}
                label="Nome"
                inputProps={{ style: { textTransform: "uppercase" } }}
              />
            </Grid>
          </Grid>

          <Paper elevation={20} style={{ marginTop: 15 }}>
            <Button
              variant="text"
              color="primary"
              style={{ margin: 10, width: "92%" }}
              type="submit"
              disabled={formikBag.isSubmitting}
            >
              {formikBag.isSubmitting ? "Estamos processando sua requisição" : "Prosseguir"}
            </Button>

            <Zoom in={formikBag.isSubmitting}>
              <LinearProgress color="secondary" />
            </Zoom>
          </Paper>
        </form>
      )}
    />
  );
};

export default DefinirSenha;
