import { Hidden, IconButton } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { withStyles } from "@material-ui/core/styles";
import ExitToApp from "@material-ui/icons/ExitToApp";
import ApolloClient from "apollo-client";
import classNames from "classnames";
import { LocationDescriptor } from "history";
import React from "react";
import { ApolloConsumer } from "react-apollo";
import Ionicon from "react-ionicons";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { Link } from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";
import { LogoutProps, withLogout } from "../../graphql/components";
import { notAllowConciliador } from "../../redux/actions/allowAction";
import configureStore from "../../redux/configureStore";
import "../../styles/components/Messages.scss";
import avatarApi from "../../styles/images/avatars/pp_boy.svg";
import Routes from "../../utils/routes";
import styles from "./header-jss";
import Noticias from "./Noticias";
import IsVisContext from "../CRUD/isVisContext";

const to = (to: LocationDescriptor) => ({ to, component: Link as any });

interface UserMenuProps extends RouteComponentProps {
  classes: any;
  dark?: boolean;
  notAllowConciliador: typeof notAllowConciliador;
}

interface IUserMenuState {
  dark: boolean;
  anchorEl: any;
  openMenu: any;
  openNews: boolean;
  textNews: string;
  tituloNews: string;
}

class UserMenu extends React.Component<LogoutProps<UserMenuProps>, IUserMenuState> {
  static contextType = IsVisContext;
  context!: React.ContextType<typeof IsVisContext>;
  state = {
    anchorEl: null,
    openMenu: null,
    dark: false,
    openNews: false,
    textNews: "",
    tituloNews: "",
  };

  handleMenu = (menu: any) => (event: any) => {
    const { openMenu } = this.state;
    this.setState({
      openMenu: openMenu === menu ? null : menu,
      anchorEl: event.currentTarget,
    });
  };

  handleClose = () => this.setState({ anchorEl: null, openMenu: null });

  sairSistema = async (client: ApolloClient<object>) => {
    try {
      let response = await this.props.mutate({
        variables: {},
      });
      if (response) {
        if (response.data!.logout === "LOGGEDOUT") {
          document.cookie.split(";").forEach(function(c) {
            document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
          });
          await client.cache.reset();
          await client.clearStore();
          await configureStore().persistor.flush();
          await configureStore().persistor.purge();
          this.props.notAllowConciliador();
          if (this.context!.isVisSPC === "vis") {
            this.props.history.push(Routes.loginIsVis.route);
          } else if (this.context!.isVisSPC === "spc") {
            this.props.history.push(Routes.spc.route);
          } else {
            this.props.history.push(Routes.login.route);
          }
          window.location.reload();
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  toggleNews = () => this.setState((prevState) => ({ openNews: !prevState.openNews }));

  open = (texto: string, titulo: string, event: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
    this.setState({ anchorEl: null, openMenu: null, openNews: true, textNews: texto, tituloNews: titulo });

  render() {
    const { classes, dark } = this.props;
    const { anchorEl, openMenu } = this.state;
    return (
      <div>
        <Noticias classes={classes} dark={dark} />

        <Hidden xsDown>
          <span className={classes.separatorV} />
        </Hidden>

        <IconButton
          aria-haspopup="true"
          onClick={this.handleClose}
          {...to("/app/ajuda")}
          color="inherit"
          className={classNames(classes.notifIcon, dark ? classes.dark : classes.light)}
        >
          <Ionicon icon={"ios-help-circle-outline"} />
        </IconButton>

        <Hidden xsDown>
          <span className={classes.separatorV} />
        </Hidden>

        <Hidden xsDown>
          <Button onClick={this.handleMenu("user-setting")}>
            <Avatar src={avatarApi} />
          </Button>
        </Hidden>
        <Hidden mdUp>
          <IconButton
            aria-haspopup="true"
            onClick={this.handleMenu("user-setting")}
            color="inherit"
            className={classNames(classes.notifIcon, dark ? classes.dark : classes.light)}
            style={{ paddingLeft: 0 }}
          >
            <Ionicon icon={"ios-person-outline"} />
          </IconButton>
        </Hidden>
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={openMenu === "user-setting"}
          onClose={this.handleClose}
        >
          <MenuItem onClick={this.handleClose} {...to("/app/alterasenha")}>
            Altera Senha
          </MenuItem>
          <Divider />
          <MenuItem>
            <a href="https://www.conciliadormulti.com.br/Conciliador/menu/menu.jsf">Versão Antiga</a>
          </MenuItem>
          <Divider />
          <ApolloConsumer>
            {(client) => (
              <MenuItem onClick={this.sairSistema.bind(this, client as any)}>
                <ListItemIcon>
                  <ExitToApp />
                </ListItemIcon>
                Sair
              </MenuItem>
            )}
          </ApolloConsumer>
        </Menu>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  notAllowConciliador: bindActionCreators(notAllowConciliador, dispatch),
});

const logoutMutation = withLogout<UserMenuProps>({});
const withMutation = logoutMutation(UserMenu);

const UserMenuMapped = withMutation;

const PainelCadastroConnected = connect(
  null,
  mapDispatchToProps
)(UserMenuMapped);

export default withStyles(styles as any)(withRouter(React.memo(PainelCadastroConnected)));
