import CircularProgress from "@material-ui/core/CircularProgress";
import { withStyles, withTheme } from "@material-ui/core/styles";
import * as _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { GetIntegracaoDocument, GetMenusComponent, GetRelatoriosDocument, Menu } from "../../graphql/components";
import { playTransitionAction } from "../../redux/actions/uiActions";
import styles from "./sidebar-jss";
import SidebarCategory from "./SidebarCategory";
import SidebarLink from "./SidebarLink";

interface Props {
  classes: any;
  loadTransition?: (isLoaded: boolean) => void;
  dataMenu?: Array<any>;
}

class MainMenu extends React.Component<Props> {
  descendentesRelatorios: Array<any> = [];
  integracoes: Array<any> = [];

  handleClick() {
    const { loadTransition } = this.props;
    loadTransition!(false);
  }

  componentDidMount() {
    this.descendentesRelatorios = [];
  }

  getPais = (menus: Array<Menu>, menu: Menu, root: string) => {
    let retorno: Array<string> = [];
    const pai = menus.find((m) => m.label === menu.pai);
    if (pai && pai.pai && (!pai.page || pai.page.length === 0) && pai.label !== root) {
      retorno.push(pai!.label!);
      const pais = this.getPais(menus, pai, root);
      retorno = [...retorno, ...pais];
    } else if ((!pai!.page || pai!.page.length === 0) && pai!.label !== root) {
      retorno.push(pai!.label!);
    }
    return retorno;
  };

  getSubLinks = (menus: Array<Menu>, menu: Menu, typename: string, lista: Array<any>, root: string) => {
    const filhos = menus.filter((son) => son.pai === menu.label);
    if (filhos && filhos.length > 0) {
      filhos.forEach((me) => {
        this.getSubLinks(menus, me, typename, lista, root);
        const indexAdded = _.flatten(lista.map((t) => t.pais)).indexOf(me.label);
        const indexAddedMe = _.flatten(lista.map((t) => t.label)).indexOf(me.label);
        let indexPaiOfMe = -1;
        if (indexAddedMe > -1) {
          indexPaiOfMe = lista[indexAddedMe].pais.indexOf(me.pai);
        }
        if (indexAdded === -1 && (indexAddedMe === -1 || indexPaiOfMe === -1)) {
          if (menu.pai && menu.pai.length > 0) {
            const contPais = menus.reduce((m) => menus.filter((m) => m.label === me.pai).length, 0);
            if (contPais < 2 || menu.pai === root) {
              const pais = this.getPais(menus, menu, root);
              lista.push({
                label: me.label!,
                pais: [me.pai!, ...pais],
                __typename: typename,
                page: me.page,
              });
            }
          } else {
            lista.push({ label: me.label!, pais: [""], __typename: typename, page: me.page });
          }
        }
      });
    }
    return filhos;
  };

  getSubMenus = (menus: Array<Menu>, menu: Menu) => {
    const netos = menus.filter((son) => son.pai === menu.label);
    const pai = menus.find((son) => son.label === menu.pai);
    let inset = false;
    let avo = false;
    if (pai) {
      avo = menus.find((son) => son.label === pai.pai) != null;
      inset = avo ? true : false;
    }
    if (netos && netos.length > 0) {
      return (
        <SidebarCategory
          //inset={inset || pai != null}
          key={menu.id}
          title={menu.label!}
          icon={menu.icon!}
          isClickable={menu.page == null || menu.page === ""}
        >
          {netos.map((me) => this.getSubMenus(menus, me))};
        </SidebarCategory>
      );
    } else {
      return <SidebarLink inset={inset || pai != null} key={menu.id} title={menu.label!} link={menu.page!} isNeto={avo} />;
    }
  };

  render() {
    return (
      <GetMenusComponent>
        {({ data, loading, error, client }) => {
          if (loading) {
            return (
              <div style={{ textAlign: "center" }}>
                <CircularProgress />
              </div>
            );
          }
          if (error) {
            return null;
          }
          if (data && data.getMenus) {
            this.descendentesRelatorios = [];
            return data.getMenus
              .filter((menu) => menu.pai == null)
              .map((menu) => {
                if (menu.label === "Relatórios") {
                  this.getSubLinks(data.getMenus, menu, "Relatorio", this.descendentesRelatorios, "Relatórios");
                  try {
                    client.readQuery({ query: GetRelatoriosDocument });
                  } catch (e) {
                    client.writeData({ data: { getRelatorios: this.descendentesRelatorios } });
                  }
                  menu.page = "relatorios";
                  return (
                    <React.Fragment key={menu.id}>
                      <SidebarLink inset={false} title={menu.label} link={menu.page} icon={menu.icon} />
                    </React.Fragment>
                  );
                } else if (menu.label === "Integração") {
                  this.getSubLinks(data.getMenus, menu, "Integração", this.integracoes, "Integração");
                  try {
                    client.readQuery({ query: GetIntegracaoDocument });
                  } catch (e) {
                    client.writeData({ data: { getIntegracao: this.integracoes } });
                  }
                  menu.page = "integracao";
                  return <SidebarLink inset={false} key={menu.id!} title={menu.label!} link={menu.page!} icon={menu.icon!} />;
                } else if (menu.page) {
                  return (
                    <SidebarLink
                      inset={false}
                      key={menu.id!}
                      title={menu.label!}
                      link={menu.page === "menu" ? "home" : menu.page!}
                      icon={menu.icon!}
                    />
                  );
                }
                return (
                  <SidebarCategory
                    key={menu.id!}
                    title={menu.label!}
                    icon={menu.icon!}
                    isClickable={menu.page == null || menu.page === ""}
                  >
                    {data!.getMenus
                      .filter((filho) => filho.pai === menu.label)
                      .map((filho) => this.getSubMenus(data.getMenus, filho))}
                  </SidebarCategory>
                );
              }) as any;
          }
          return null;
        }}
      </GetMenusComponent>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  loadTransition: bindActionCreators(playTransitionAction, dispatch),
});

const MainMenuMapped = connect(
  null,
  mapDispatchToProps
)(MainMenu);

export default withTheme(withStyles(styles as any)(MainMenuMapped));
