import { DataProxy } from "apollo-cache";
import moment from "moment";
import { forkJoin } from "rxjs";
import { CallbackActionType } from "../../../../componentes/CRUD/actionsTable";
import ColumnCrudTableType from "../../../../componentes/CRUD/columnCrudTableType";
import {
  DeletePlanosConciliadoresContratoMutation,
  DeletePlanosConciliadoresContratoMutationVariables,
  ListarPlanosConciliadoresContratoDocument,
  ListarPlanosConciliadoresContratoQuery,
  ListarPlanosConciliadoresContratoQueryVariables,
} from "../../../../graphql/components";

export const variablesPlanosConciliadoresContrato: {
  codplanosconciliadorescontrato: number | null;
} = {
  codplanosconciliadorescontrato: null,
};

export const mapVariablesFiltroPlanosConciliadoresContratro = {
  vigenciade: "De",
  vigenciaate: "Até",
};

export const deleteMutationPlanosConciladoresContrato: CallbackActionType<
  DeletePlanosConciliadoresContratoMutation,
  DeletePlanosConciliadoresContratoMutationVariables
> = (mutation, record, setMessage) => {
  if (mutation) {
    const deletesPromises: Array<any> = [];
    let codplanosconciliadores: number;
    record.forEach((r) => {
      codplanosconciliadores = r.codplanosconciliadores;
      deletesPromises.push(
        mutation({
          variables: { values: { editValues: { codplanosconciliadorescontrato: r.codplanosconciliadorescontrato } } },
          update: (store: DataProxy, { data }) => {
            if (data!.deletePlanosConciliadoresContrato) {
              const oldResult = store.readQuery<
                ListarPlanosConciliadoresContratoQuery,
                ListarPlanosConciliadoresContratoQueryVariables
              >({
                query: ListarPlanosConciliadoresContratoDocument,
                variables: { values: { codplanosconciliadores: codplanosconciliadores } },
              });
              if (oldResult) {
                const index = oldResult.listarPlanosConciliadoresContrato.findIndex(
                  (u) => u.codplanosconciliadorescontrato === r.codplanosconciliadorescontrato
                );
                oldResult.listarPlanosConciliadoresContrato.splice(index, 1);
                const result = [...oldResult.listarPlanosConciliadoresContrato];
                store.writeQuery({
                  query: ListarPlanosConciliadoresContratoDocument,
                  data: result,
                  variables: variablesPlanosConciliadoresContrato,
                });
              }
            }
          },
        }).catch((error) => {
          return new Promise((resolve) => resolve(error.message));
        })
      );
    });
    forkJoin(deletesPromises).subscribe(
      (response) => {
        const totalErrors = response.filter((r) => typeof r === "string").length;
        if (totalErrors > 0 && totalErrors !== deletesPromises.length) {
          setMessage(
            true,
            "warning",
            "Alguns registros não foram excluídos. Dentro de 5 segundos iremos atualizar a página para manter os dados coerentes."
          );
          setTimeout(() => window.location.reload(), 6500);
        } else if (totalErrors === 0) {
          setMessage(true, "success", "Registro(s) excluídos com sucesso!");
        }
        return true;
      },
      (errors) => {
        console.log(errors);
      }
    );
  }
};

export const colunasPlanosContrato: Array<ColumnCrudTableType> = [
  {
    name: "Plano",
    options: {
      filter: true,
    },
  },
  {
    name: "De",
    options: {
      filter: true,
      customBodyRender: (value: any) => moment(new Date(value)).format("DD/MM/YYYY"),
    },
  },
  {
    name: "Até",
    options: {
      filter: true,
      customBodyRender: (value: any) => moment(new Date(value)).format("DD/MM/YYYY"),
    },
  },
];

export const columnsOrderPlanosContrato = ["plano", "vigenciaDe", "vigenciaAte"];

export const columnsOrder = ["plano", "vigenciaDe", "vigenciaAte"];
