import MomentUtils from "@date-io/moment";
import { TextField } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import locale from "moment/locale/pt-br";
import React, { PureComponent } from "react";
import { FilterProps } from "../../../componentes/CRUD/crudProps";
import { SelectCombo } from "../../../componentes/Select";
import {
  ListarItensStatusArquivoDocument,
  ListarItensTipoArquivoDocument,
  ListarItensUnidadesConciliadorasDocument,
  ListarOperadorasDocument
} from "../../../graphql/components";
import { variablesConciliacaoEmAberto, variablesConciliacaoEmAbertoInitial } from "./utilsConciliacaoEmAberto";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import FilterContext from "../../../componentes/CRUD/filterContext";

type State = typeof variablesConciliacaoEmAberto;

const styles = {
  container: { marginBottom: 60 },
  formControl: { minWidth: "225px", width: "99%" },
  picker: { minWidth: "325px" }
};

export class FilterConciliacaoEmAberto extends PureComponent<FilterProps, State> {
  static contextType = FilterContext;
  public static filterConciliacaoEmAbertoVariables: any;
  constructor(props: FilterProps) {
    super(props);
    this.state = { ...variablesConciliacaoEmAbertoInitial };
    FilterConciliacaoEmAberto.filterConciliacaoEmAbertoVariables = JSON.parse(
      JSON.stringify(variablesConciliacaoEmAbertoInitial)
    );
  }

  render() {
    return (
      <Grid container spacing={2} style={styles.container}>
        <Grid item xs={12} md={3}>
          <TextField
            id="codigo"
            label="Cod. Arquivo"
            type="number"
            value={this.state.searchValues.codarquivo ? this.state.searchValues.codarquivo : ""}
            name="Código"
            margin="normal"
            variant="outlined"
            fullWidth
            onChange={e => {
              const value: number | null = e.target.value ? parseInt(e.target.value) : null;
              this.setState({ searchValues: { ...this.state.searchValues, codarquivo: value } }, () => {
                this.props.variables.searchValues.codarquivo = this.state.searchValues.codarquivo;
                this.props.variablesValorExibicao.searchValues.codarquivo = this.state.searchValues.codarquivo;
                this.context.setVariables({ ...this.state });
              });
            }}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            id="nome"
            label="Nome Arquivo"
            type="text"
            value={this.state.searchValues.nomearquivo ? this.state.searchValues.nomearquivo : ""}
            name="Nome"
            margin="normal"
            variant="outlined"
            fullWidth
            onChange={e => {
              this.setState({ searchValues: { ...this.state.searchValues, nomearquivo: e.target.value } }, () => {
                this.props.variables.searchValues.nomearquivo = this.state.searchValues.nomearquivo;
                this.props.variablesValorExibicao.searchValues.nomearquivo = this.state.searchValues.nomearquivo;
                this.context.setVariables({ ...this.state });
              });
            }}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <SelectCombo
            label="Tipo"
            name="tipo"
            nameQuery="listarItensTipoArquivo"
            query={ListarItensTipoArquivoDocument}
            value={this.state.searchValues.tipo ? this.state.searchValues.tipo : ""}
            handleChange={e => {
              // const valor = e.currentTarget.value;
              const label = (e.currentTarget as any).innerText;
              this.setState({ searchValues: { ...this.state.searchValues, tipo: e.target.value as string } }, () => {
                this.props.variables.searchValues.tipo = this.state.searchValues.tipo;
                this.props.variablesValorExibicao.searchValues.tipo = label;
                this.context.setVariables({ ...this.state });
              });
            }}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <SelectCombo
            label="Status"
            name="status"
            nameQuery="listarItensStatusArquivo"
            query={ListarItensStatusArquivoDocument}
            value={this.state.searchValues.status ? this.state.searchValues.status : ""}
            handleChange={e => {
              // const valor = e.currentTarget.value;
              const label = (e.currentTarget as any).innerText;
              this.setState({ searchValues: { ...this.state.searchValues, status: e.target.value as string } }, () => {
                this.props.variables.searchValues.status = this.state.searchValues.status;
                this.props.variablesValorExibicao.searchValues.status = label;
                this.context.setVariables({ ...this.state });
              });
            }}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <SelectCombo
            label="Operadora"
            name="operadora"
            nameQuery="listarOperadoras"
            query={ListarOperadorasDocument}
            value={this.state.searchValues.codoperadora ? this.state.searchValues.codoperadora : ""}
            handleChange={e => {
              // const valor = e.currentTarget.value;
              const value: number | null = e.target.value ? parseInt(e.target.value as string) : null;
              const label = (e.currentTarget as any).innerText;
              this.setState({ searchValues: { ...this.state.searchValues, codoperadora: value } }, () => {
                this.props.variables.searchValues.codoperadora = this.state.searchValues.codoperadora;
                this.props.variablesValorExibicao.searchValues.codoperadora = label;
                this.context.setVariables({ ...this.state });
              });
            }}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <SelectCombo
            label="Conciliador"
            name="conciliador"
            nameQuery="listarItensUnidadesConciliadoras"
            query={ListarItensUnidadesConciliadorasDocument}
            value={this.state.searchValues.origem ? this.state.searchValues.origem : ""}
            handleChange={e => {
              // const valor = e.currentTarget.value;
              const label = (e.currentTarget as any).innerText;
              this.setState({ searchValues: { ...this.state.searchValues, origem: e.target.value as string } }, () => {
                this.props.variables.searchValues.origem = this.state.searchValues.origem;
                this.props.variablesValorExibicao.searchValues.origem = label;
                this.context.setVariables({ ...this.state });
              });
            }}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <MuiPickersUtilsProvider utils={MomentUtils} locale={locale}>
            <KeyboardDatePicker
              maxDateMessage="Data inválida"
              autoOk
              style={{ width: "100%" }}
              label="De"
              name="de"
              id="de"
              invalidDateMessage="Data inválida"
              variant="inline"
              value={this.state.de}
              onChange={e => {
                if (e && e.toDate()) {
                  this.setState({ de: e.toDate() }, () => {
                    this.props.variables.de = this.state.de;
                    this.props.variablesValorExibicao.de = this.state.de;
                    this.context.setVariables({ ...this.state });
                  });
                } else {
                  this.setState({ de: null }, () => {
                    this.props.variables.de = this.state.de;
                    this.props.variablesValorExibicao.de = this.state.de;
                    this.context.setVariables({ ...this.state });
                  });
                }
              }}
              format="DD/MM/YYYY"
              placeholder="dd/mm/aaaa"
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={12} md={3}>
          <MuiPickersUtilsProvider utils={MomentUtils} locale={locale}>
            <KeyboardDatePicker
              maxDateMessage="Data inválida"
              variant="inline"
              style={{ width: "100%" }}
              label="Até"
              name="ate"
              id="ate"
              invalidDateMessage="Data inválida"
              disableToolbar
              value={this.state.ate}
              onChange={e => {
                if (e && e.toDate()) {
                  this.setState({ ate: e.toDate() }, () => {
                    this.props.variables.ate = this.state.ate;
                    this.props.variablesValorExibicao.ate = this.state.ate;
                    this.context.setVariables({ ...this.state });
                  });
                } else {
                  this.setState({ ate: null }, () => {
                    this.props.variables.ate = this.state.ate;
                    this.props.variablesValorExibicao.ate = this.state.ate;
                    this.context.setVariables({ ...this.state });
                  });
                }
              }}
              format="DD/MM/YYYY"
              placeholder="dd/mm/aaaa"
            />
          </MuiPickersUtilsProvider>
        </Grid>
      </Grid>
    );
  }
}

export default FilterConciliacaoEmAberto;
