import { DataProxy } from "apollo-cache";
import { forkJoin } from "rxjs";
import { CallbackActionType } from "../../../componentes/CRUD/actionsTable";
import ColumnCrudTableType from "../../../componentes/CRUD/columnCrudTableType";
import {
  DeleteTerminalMutation,
  DeleteTerminalMutationVariables,
  ListarTerminalDocument,
  ListarTerminalQuery,
} from "../../../graphql/components";

export const variablesTerminal: {
  codexterno: string | undefined;
  nome: string | undefined;
  vinculo: string | undefined;
  codpessoacliente: number | undefined;
} = {
  codexterno: undefined,
  nome: undefined,
  vinculo: undefined,
  codpessoacliente: undefined,
};

export const deleteMutationTerminal: CallbackActionType<DeleteTerminalMutation, DeleteTerminalMutationVariables> = (
  mutation,
  record,
  setMessage
) => {
  if (mutation) {
    const deletesPromises: Array<any> = [];
    record.forEach((r) => {
      deletesPromises.push(
        mutation({
          variables: {
            values: {
              editValues: {
                codexterno: r.codexterno,
                nome: r.nome,
                codoperadora: r.codoperadora,
                codpessoa: r.codpessoa,
                status: r.status,
                codpessoapai: r.codpessoapai,
              },
            },
          },
          update: (store: DataProxy, { data }) => {
            if (data!.deleteTerminal) {
              const oldResult = store.readQuery<ListarTerminalQuery>({
                query: ListarTerminalDocument,
                variables: { values: variablesTerminal },
              });
              if (oldResult) {
                const terminal = oldResult.listarTerminal.find((u) => u.codexterno === r.codexterno);
                terminal!.status = "C";
                const result = [...oldResult.listarTerminal];
                store.writeQuery({
                  query: ListarTerminalDocument,
                  data: result,
                  variables: variablesTerminal,
                });
              }
            }
          },
        }).catch((error) => {})
      );
    });
    forkJoin(deletesPromises).subscribe(
      (response) => {
        const totalErrors = response.filter((r) => typeof r === "string").length;
        if (totalErrors > 0 && totalErrors !== deletesPromises.length) {
          setMessage(
            true,
            "warning",
            "Alguns registros não foram excluídos. Dentro de 5 segundos iremos atualizar a página para manter os dados coerentes."
          );
          setTimeout(() => window.location.reload(), 6500);
        } else if (totalErrors === 0) {
          setMessage(true, "success", "Registro(s) excluídos com sucesso!");
        }
      },
      (errors) => {
        console.log(errors);
      }
    );
  }
};

export const columnsOrderTerminal = ["nome", "vinculo", "status"];

export const colunasTerminal: Array<ColumnCrudTableType> = [
  {
    name: "Nome",
    options: {
      filter: true,
    },
  },
  {
    name: "Vínculo",
    options: {
      filter: true,
    },
  },
  {
    name: "Status",
    options: {
      filter: true,
    },
  },
];
