import lightPalette from "./lightPalette";
import darkPalette from "./darkPalette";

const themePalette = (color: any, mode: any) => {
  if (mode === "dark") {
    return darkPalette[color];
  }
  return lightPalette[color];
};

export default themePalette;
