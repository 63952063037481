import Button from "@material-ui/core/Button";
import { Formik, FormikActions, FormikProps } from "formik";
import React, { PureComponent } from "react";
import * as Yup from "yup";
import NewEditProps from "../../../../componentes/CRUD/crudProps";
import EditContext from "../../../../componentes/CRUD/editContext";
import SelectedRowsContext from "../../../../componentes/CRUD/selectedRowsContext";
import Loading from "../../../../componentes/Loading/Loading";
import {
  GetPlanosConciliadoresContratoComponent,
  ListarPlanosConciliadoresContratoDocument,
  SavePlanosConciliadoresContratoProps,
  withSavePlanosConciliadoresContrato
} from "../../../../graphql/components";
import FormPlanosContrato from "./formPlanosContrato";

const stringObrigatorio = "é obrigatório.";

const PlanosConciladoresContratoSchema = Yup.object().shape({
  codplanosconciliadores: Yup.number(),
  vigenciaDe: Yup.date().required(`Vigência "de" ${stringObrigatorio}`),
  vigenciaAte: Yup.date().required(`Vigência "até" ${stringObrigatorio}`),
  listConciOperadora: Yup.array(),
  listConciOperadoraGeral: Yup.array()
});

const initialValues = {
  codplanosconciliadores: (null as unknown) as number,
  vigenciaDe: (null as unknown) as Date,
  vigenciaAte: (null as unknown) as Date,
  listConciOperadora: [] as Array<string>,
  listConciOperadoraGeral: [] as Array<any>
};

export type FormValues = typeof initialValues;

class NewEditPlanosContrato extends PureComponent<SavePlanosConciliadoresContratoProps<NewEditProps>> {
  static contextType = SelectedRowsContext;

  submit = async (values: FormValues, actions: FormikActions<FormValues>, editContext: any | null) => {
    try {
      const response = await this.props.mutate!({
        refetchQueries: [
          {
            query: ListarPlanosConciliadoresContratoDocument,
            variables: { values: { codplanosconciliadores: values.codplanosconciliadores } }
          }
        ],
        variables: {
          values: {
            listConciOperadora: values.listConciOperadora,
            editValues: {
              codplanosconciliadores: values.codplanosconciliadores,
              vigenciaDe: values.vigenciaDe,
              vigenciaAte: values.vigenciaAte
            },
            searchValues: {
              codplanosconciliadorescontrato: editContext ? editContext.codplanosconciliadorescontrato : null,
              seq: editContext ? editContext.seq : null
            }
          },
          insert: editContext ? false : true
        }
      });

      if (response && response.data && response.data.savePlanosConciliadoresContrato) {
        this.props.close();
        this.props.setMessage(true, "success", "Plano conciliador contrato inserido com sucesso");
      }
    } catch (e) {
    } finally {
      actions.setSubmitting(false);
    }
  };

  render() {
    return (
      <div style={{ overflowY: "auto", height: "90%", overflowX: "hidden" }}>
        {
          <EditContext.Consumer>
            {editContext => (
              <Formik
                initialValues={initialValues}
                onSubmit={(values: FormValues, actions: FormikActions<FormValues>) => this.submit(values, actions, editContext)}
                validationSchema={PlanosConciladoresContratoSchema}
                render={(formikBag: FormikProps<FormValues>) => (
                  <form autoComplete="new-password" role="presentation" onSubmit={formikBag.handleSubmit}>
                    {!editContext && <FormPlanosContrato formikBag={formikBag} />}
                    {editContext && (
                      <GetPlanosConciliadoresContratoComponent
                        fetchPolicy="no-cache"
                        variables={{
                          values: { codplanosconciliadorescontrato: (editContext! as any).codplanosconciliadorescontrato }
                        }}
                      >
                        {({ data, loading }) => {
                          if (loading) {
                            return <Loading />;
                          }
                          if (data && data.getPlanosConciliadoresContrato) {
                            return <FormPlanosContrato formikBag={formikBag} data={data!} />;
                          }
                          return null;
                        }}
                      </GetPlanosConciliadoresContratoComponent>
                    )}
                    <div
                      style={{
                        float: "right",
                        margin: 10,
                        display: "flex",
                        justifyContent: "space-between",
                        width: "180px",
                        flexDirection: "row"
                      }}
                    >
                      <Button variant="contained" color="primary" disabled={formikBag.isSubmitting} type="submit">
                        Salvar
                      </Button>
                      <Button
                        variant="contained"
                        color="secondary"
                        disabled={formikBag.isSubmitting}
                        type="reset"
                        onClick={() => formikBag.resetForm(initialValues)}
                      >
                        Limpar
                      </Button>
                    </div>
                  </form>
                )}
              />
            )}
          </EditContext.Consumer>
        }
      </div>
    );
  }
}

const withMutation = withSavePlanosConciliadoresContrato<NewEditProps>({ withRef: true });

export default withMutation(NewEditPlanosContrato);
