import {
  CustomTreeData,
  DataTypeProvider,
  FilteringState,
  IntegratedFiltering,
  IntegratedSorting,
  SortingState,
  TreeDataState
} from "@devexpress/dx-react-grid";
import { Grid, Table, TableFilterRow, TableHeaderRow, TableTreeColumn } from "@devexpress/dx-react-grid-material-ui";
import { Theme } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import { fade } from "@material-ui/core/styles/colorManipulator";
import * as React from "react";
import Loading from "../../../../componentes/Loading/Loading";
import MonitorContrato from "../../../../componentes/StatusMonitorContrato/MonitorContrato";
import { GetPosicaoContratosPorClientesComponent } from "../../../../graphql/components";
import InformacoesPosicaoContrato from "../Informacoes/informacoesPosicaocontrato";

const getChildRows = (row: any, rootRows: any) => {
  const childRows = rootRows.filter((r: any) => r.parentId === (row ? row.id : null));
  return childRows.length ? childRows : null;
};

interface IProps {
  codOperadora: number | null;
  statusFiltro: string | undefined | null;
}

interface IState {
  open: boolean;
  codPessoa: string | undefined;
  codExterno: string | undefined;
  operadora: string | undefined;
  status: string | undefined;
  columns: { name: string; title: string }[];
  sorting: any[];
  currencyColumns: any[];
  tableColumnExtensions: any[];
  defaultExpandedRowIds: number[];
  dados: { id: string | number; parentId: string | number | null; sex: string; description: string; city: string; car: string }[];
}

const styles = (theme: Theme) => ({
  tableStriped: {
    "& tbody tr:nth-of-type(odd)": {
      backgroundColor: fade(theme.palette.primary.main, 0.15)
    }
  }
});

const TableComponentBase = ({ classes, ...restProps }: any) => <Table.Table {...restProps} className={classes.tableStriped} />;

export const TableComponent = withStyles(styles, { name: "TableComponent" })(TableComponentBase);

const StatusMonitorContratoFormatter = ({ value }: any) => <MonitorContrato status={value} />;

const StatusMonitorContratoTypeProvider = (props: any) => (
  <DataTypeProvider formatterComponent={StatusMonitorContratoFormatter} {...props} />
);

export default class TreeTable extends React.PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      open: false,
      codPessoa: undefined,
      codExterno: undefined,
      operadora: undefined,
      status: undefined,
      columns: [
        { name: "description", title: "Operadora" },
        { name: "childCount", title: "Qte" },
        { name: "identifier", title: "Status" },
        { name: "nome", title: "Organização" },
        { name: "codexterno", title: "EC" }
      ],
      tableColumnExtensions: [
        { columnName: "description", width: "285", align: "left", wordWrapEnabled: true },
        { columnName: "childCount", width: "75", align: "right" },
        { columnName: "identifier", width: "100", align: "center" },
        { columnName: "nome", width: "650", align: "left" },
        { columnName: "codexterno", width: "10%", align: "center" }
      ],
      defaultExpandedRowIds: [0],
      dados: [],
      currencyColumns: ["identifier"],
      sorting: []
    };
  }

  changeSorting = (sorting: any[]) => this.setState({ sorting });

  close = () => this.setState({ open: false });

  openInfo = (row: any) => {
    if (row.tipo === 3) {
      this.setState({
        open: true,
        codPessoa: row.codpessoa,
        codExterno: row.codexterno,
        operadora: row.operadora,
        status: row.identifier
      });
    }
  };

  TableRow = ({ row, ...restProps }: any) => (
    <Table.Row
      {...restProps}
      onClick={() => {
        this.openInfo(row);
      }}
    />
  );

  render() {
    const { currencyColumns, columns, tableColumnExtensions, defaultExpandedRowIds, sorting } = this.state;
    const { codOperadora, statusFiltro } = this.props;

    return (
      <Paper>
        <GetPosicaoContratosPorClientesComponent
          variables={{ values: { codoperadora: codOperadora!, statusFiltro: statusFiltro! } }}
        >
          {({ data, loading }) => {
            if (loading) return <Loading />;
            return (
              <React.Fragment>
                <Grid rows={data!.getPosicaoContratosPorClientes} columns={columns}>
                  <TreeDataState defaultExpandedRowIds={defaultExpandedRowIds} />
                  <FilteringState />
                  <SortingState />
                  <CustomTreeData getChildRows={getChildRows} />
                  <FilteringState defaultFilters={[]} />
                  <IntegratedFiltering />
                  <StatusMonitorContratoTypeProvider for={currencyColumns} />
                  <SortingState sorting={sorting} onSortingChange={this.changeSorting} />
                  <IntegratedSorting />
                  <Table tableComponent={TableComponent} columnExtensions={tableColumnExtensions} rowComponent={this.TableRow} />
                  <TableHeaderRow showSortingControls />
                  <TableFilterRow />
                  <TableTreeColumn for="description" />
                </Grid>

                {this.state.open && (
                  <InformacoesPosicaoContrato
                    open={this.state.open}
                    codPessoa={this.state.codPessoa}
                    codExterno={this.state.codExterno}
                    operadora={this.state.operadora}
                    status={this.state.status}
                    handleClose={this.close}
                  />
                )}
              </React.Fragment>
            );
          }}
        </GetPosicaoContratosPorClientesComponent>
      </Paper>
    );
  }
}
