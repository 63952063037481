import { Button, Grid, LinearProgress, Paper, Zoom, Portal } from "@material-ui/core";
import { Field, Formik, FormikActions, FormikProps } from "formik";
import React, { useState } from "react";
import voca from "voca";
import * as Yup from "yup";
import { RightDrawerProps } from "../../../componentes/CRUD/crudProps";
import FormikInputText from "../../../componentes/MaskedInput/FomikInput";
import FormikCurrencyInput from "../../../componentes/MaskedInput/FormikCurrencyInput";
import PapperBlock from "../../../componentes/PapperBlock/PapperBlock";
import { SelectCombo } from "../../../componentes/Select";
import SnackMessage from "../../../componentes/SnakBar";
import {
  InserirRapidoProps,
  ListarOperadorasDocument,
  withInserirRapido,
  ListarPlanosConciliadoresDocument,
} from "../../../graphql/components";
import { variablesPlanosConciliadores } from "./utilsPlanosConciliadores";
import ConciliaDatePicker from "../../../componentes/ConciliaDatePicker";

const stringObrigatorio = "é obrigatório.";

const SchemaInserirRapido = Yup.object().shape({
  codOperadora: Yup.number()
    .required(`Operadora ${stringObrigatorio}`)
    .nullable(true),
  nomePlano: Yup.string().nullable(true),
  taxaCredito: Yup.string().nullable(true),
  taxaCredito0a6: Yup.string().nullable(true),
  taxaCredito7a12: Yup.string().nullable(true),
  taxaDebito: Yup.string().nullable(true),
  taxaTicket: Yup.string().nullable(true),
  vigenciaDe: Yup.date().required(`Vigência "de" ${stringObrigatorio}`),
  vigenciaAte: Yup.date().required(`Vigência "até" ${stringObrigatorio}`),
});

const initialValuesDadosInserirRapido = {
  codOperadora: (null as unknown) as number,
  nomePlano: "",
  taxaCredito: (null as unknown) as string,
  taxaCredito0a6: (null as unknown) as string,
  taxaCredito7a12: (null as unknown) as string,
  taxaDebito: (null as unknown) as string,
  taxaTicket: (null as unknown) as string,
  vigenciaDe: (null as unknown) as Date,
  vigenciaAte: (null as unknown) as Date,
};

export type FormValuesInserirRapido = typeof initialValuesDadosInserirRapido;

const InserirRapido: React.FunctionComponent<RightDrawerProps<InserirRapidoProps>> = ({ closeRightDrawer, mutate }) => {
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [openSnackMessage, setOpenSnackMessage] = useState(false);
  const [variantSnack, setVariantSnack] = useState<"success" | "warning" | "error" | "info">("success");

  const closeMessage = () => {
    setOpenSnackMessage(false);
  };

  const inserirRapido = React.useCallback(
    async (values: FormValuesInserirRapido, actions: FormikActions<FormValuesInserirRapido>) => {
      setLoading(true);

      try {
        await mutate!({
          variables: {
            values: {
              codoperadora: values.codOperadora,
              nomePlano: values.nomePlano,
              taxaCredito: parseFloat(voca.replaceAll(voca.replaceAll(values.taxaCredito, ".", ""), ",", ".")),
              taxaCredito0a6: parseFloat(voca.replaceAll(voca.replaceAll(values.taxaCredito0a6, ".", ""), ",", ".")),
              taxaCredito7a12: parseFloat(voca.replaceAll(voca.replaceAll(values.taxaCredito7a12, ".", ""), ",", ".")),
              taxaDebito: parseFloat(voca.replaceAll(voca.replaceAll(values.taxaDebito, ".", ""), ",", ".")),
              taxaTicket: parseFloat(voca.replaceAll(voca.replaceAll(values.taxaTicket, ".", ""), ",", ".")),
              vigenciaDe: values.vigenciaDe,
              vigenciaAte: values.vigenciaAte,
            },
          },
          refetchQueries: [
            {
              query: ListarPlanosConciliadoresDocument,
              variables: { values: variablesPlanosConciliadores },
            },
          ],
        });
        setLoading(false);
        actions.setSubmitting(false);
        setMessage("Inserir rápido salvo com sucesso!");
        setOpenSnackMessage(true);
        setVariantSnack("success");
        // closeRightDrawer();
      } catch (e) {
        setLoading(false);
        actions.setSubmitting(false);
      }
    },
    [mutate]
  );

  return (
    <React.Fragment>
      <PapperBlock title="Inserir Rápido" icon="ios-timer-outline" desc="Forma rápida de cadastrar planos">
        <Formik
          initialValues={initialValuesDadosInserirRapido}
          onSubmit={inserirRapido}
          validationSchema={SchemaInserirRapido}
          render={(formikBag: FormikProps<FormValuesInserirRapido>) => (
            <form autoComplete="new-password" role="presentation" id="second" onSubmit={formikBag.handleSubmit}>
              <Grid item xs={12} md={12}>
                <SelectCombo
                  label="Operadora"
                  handleBlur={formikBag.handleBlur}
                  handleChange={formikBag.handleChange}
                  name="codOperadora"
                  value={formikBag.values.codOperadora}
                  query={ListarOperadorasDocument}
                  nameQuery="listarOperadoras"
                  disabled={loading}
                  errorText={formikBag.errors.codOperadora ? formikBag.errors.codOperadora.toString() : undefined}
                />
              </Grid>

              <Grid item xs={12} md={12}>
                <Field id="nomePlano" name="nomePlano" component={FormikInputText} label="Nome do Plano" disabled={loading} />
              </Grid>

              <Grid item xs={12} md={12}>
                <Field
                  id="taxaCredito"
                  name="taxaCredito"
                  component={FormikCurrencyInput}
                  label="Crédito a vista"
                  disabled={loading}
                />
              </Grid>

              <Grid item xs={12} md={12}>
                <Field
                  id="taxaCredito0a6"
                  name="taxaCredito0a6"
                  component={FormikCurrencyInput}
                  label="Crédito 0 a 6 parcelas"
                  disabled={loading}
                />
              </Grid>

              <Grid item xs={12} md={12}>
                <Field
                  id="taxaCredito7a12"
                  name="taxaCredito7a12"
                  component={FormikCurrencyInput}
                  label="Crédito 7 a 12 parcelas"
                  disabled={loading}
                />
              </Grid>

              <Grid item xs={12} md={12}>
                <Field id="taxaDebito" name="taxaDebito" component={FormikCurrencyInput} label="Débito" disabled={loading} />
              </Grid>

              <Grid item xs={12} md={12}>
                <Field id="taxaTicket" name="taxaTicket" component={FormikCurrencyInput} label="Benéficio" disabled={loading} />
              </Grid>
              <Grid item xs={12} md={12}>
                <ConciliaDatePicker
                  label="Vigência De"
                  name="vigenciaDe"
                  value={formikBag.values.vigenciaDe}
                  disabled={loading}
                  error={formikBag.errors.vigenciaDe}
                  touched={formikBag.touched.vigenciaDe}
                  onChange={(e) => {
                    if (e && e.toDate()) {
                      formikBag.setFieldValue("vigenciaDe" as string, e.toDate());
                    } else {
                      formikBag.setFieldValue("vigenciaDe" as string, null);
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <ConciliaDatePicker
                  label="Vigência Até"
                  name="vigenciaAte"
                  value={formikBag.values.vigenciaAte}
                  disabled={loading}
                  error={formikBag.errors.vigenciaAte}
                  touched={formikBag.touched.vigenciaAte}
                  onChange={(e) => {
                    if (e && e.toDate()) {
                      formikBag.setFieldValue("vigenciaAte" as string, e.toDate());
                    } else {
                      formikBag.setFieldValue("vigenciaAte" as string, null);
                    }
                  }}
                />
              </Grid>

              <Paper elevation={20} style={{ marginTop: 15 }}>
                <div
                  style={{
                    float: "right",
                    margin: 10,
                    display: "flex",
                    justifyContent: "space-between",
                    width: "180px",
                    flexDirection: "row",
                  }}
                >
                  <Button variant="contained" color="primary" disabled={loading} type="submit">
                    {loading ? "Estamos processando sua requisição" : "Salvar"}
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    disabled={formikBag.isSubmitting}
                    type="reset"
                    onClick={closeRightDrawer}
                  >
                    Fechar
                  </Button>
                </div>

                <Zoom in={loading}>
                  <LinearProgress color="secondary" />
                </Zoom>
              </Paper>
            </form>
          )}
        />
      </PapperBlock>
      <Portal>
        <SnackMessage message={message} variant={variantSnack} open={openSnackMessage} close={closeMessage} />
      </Portal>
    </React.Fragment>
  );
};

const withMutation = withInserirRapido<RightDrawerProps>({});

export default withMutation(InserirRapido);
