import * as React from "react";
import { withStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { Route, Link } from "react-router-dom";
import { LocationDescriptor } from "history";

const to = (to: LocationDescriptor) => ({ to, component: Link as any });

const styles = (theme: Theme) => ({
  errorWrap: {
    overflow: "auto",
    background: theme.palette.background.paper,
    boxShadow: theme.shadows[2],
    borderRadius: "50%",
    width: 500,
    height: 490,
    [theme.breakpoints.down("sm")]: {
      width: 300,
      height: 300
    },
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    position: "relative",
    margin: `${theme.spacing(3)}px auto`,
    marginTop: 0,
    "& h5": {
      [theme.breakpoints.down("sm")]: {
        fontSize: "1.2rem"
      }
    }
  },
  title: {
    color: theme.palette.primary.main,
    textShadow: `10px 6px 50px ${theme.palette.primary.main}`,
    [theme.breakpoints.up("sm")]: {
      marginBottom: theme.spacing(3)
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "4rem",
      marginBottom: theme.spacing(2)
    }
  },
  deco: {
    boxShadow: theme.shadows[2],
    position: "absolute",
    borderRadius: 2
  },
  button: {
    marginTop: 24
  }
});

interface Props {
  classes: any;
  desc: String;
  title: String;
  location?: string;
}

const ErrorWrap = (props: Props) => {
  const { classes, title, desc, location = "/app/home" } = props;
  return (
    <Route>
      <div className={classes.errorWrap}>
        <Typography className={classes.title} variant="h1">
          {title}
        </Typography>
        <Typography variant="h5">{desc}</Typography>
        <Button variant="contained" color="primary" className={classes.button} {...to(location)}>
          Voltar
        </Button>
      </div>
    </Route>
  );
};

export default withStyles(styles as any)(ErrorWrap);
