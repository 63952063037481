import Button from "@material-ui/core/Button";
import { Formik, FormikActions, FormikProps } from "formik";
import React, { PureComponent } from "react";
import * as Yup from "yup";
import NewEditProps from "../../../../componentes/CRUD/crudProps";
import EditContext from "../../../../componentes/CRUD/editContext";
import Loading from "../../../../componentes/Loading/Loading";
import {
  GetPlanosConciliadoresTaxasComponent,
  ListarPlanosConciliadoresTaxasDocument,
  SavePlanosConciliadoresTaxasProps,
  withSavePlanosConciliadoresTaxas,
} from "../../../../graphql/components";
import FormPlanosTaxas from "./formPlanosTaxas";

const PlanosConciliadoresTaxasSchema = Yup.object().shape({
  codplanosconciliadores: Yup.number(),
  codoperadora: Yup.number().nullable(true),
  codprodutos: Yup.number(),
  bincartao: Yup.string()
    .max(6, `Bin de cartão não pode exceder 6 caracteres`)
    .nullable(true),
  de: Yup.number()
    .integer()
    .nullable(true),
  ate: Yup.number()
    .integer()
    .nullable(true),
  valorde: Yup.string().nullable(true),
  valorate: Yup.string().nullable(true),
  taxa: Yup.string().nullable(true),
  listDeTaxas: Yup.array(),
  tarifacao: Yup.string(),
  taxabndes: Yup.string().nullable(true),
  tipoTarifa: Yup.string(),
  taxas: Yup.object().shape({
    tipotaxa: Yup.string(),
  }),
});

const initialValues = {
  codplanosconciliadores: (null as unknown) as number,
  codoperadora: ("" as unknown) as number,
  codprodutos: ("" as unknown) as number,
  bincartao: ("" as unknown) as string,

  de: (null as unknown) as number,
  ate: (null as unknown) as number,
  valorde: ("" as unknown) as number,
  valorate: ("" as unknown) as number,
  taxa: (null as unknown) as string,

  listDeTaxas: [] as Array<any>,
  tarifacao: ("" as unknown) as string,
  taxabndes: (null as unknown) as number,
  taxas: {
    tipotaxa: "P",
  },
};

export type FormValues = typeof initialValues;

class NewEditPlanosTaxas extends PureComponent<SavePlanosConciliadoresTaxasProps<NewEditProps>> {
  static contextType = EditContext;

  submit = async (values: FormValues, actions: FormikActions<FormValues>, editContext: any | null) => {
    try {
      console.log(values);
      const response = await this.props.mutate({
        refetchQueries: [
          {
            query: ListarPlanosConciliadoresTaxasDocument,
            variables: { values: { codplanosconciliadores: values.codplanosconciliadores } },
          },
        ],

        variables: {
          values: {
            editValues: {
              codplanosconciliadores: values.codplanosconciliadores,
              codprodutos: values.codprodutos,
              bincartao: values.bincartao,
            },
            searchValues: {
              codplanosconciliadores: this.context ? this.context.codplanosconciliadores : null,
              codprodutos: values.codprodutos,
            },
            taxabndes: values.taxabndes,
            tarifacao: values.tarifacao,
            listDeTaxas: values.listDeTaxas.map((item) => {
              delete item["__typename"];
              item["tipotaxa"] = values.taxas.tipotaxa;
              return item;
            }),
            taxas: {
              tipotaxa: values.taxas.tipotaxa,
            },
          },
          insert: false,
        },
      });

      if (response && response.data && response.data.savePlanosConciliadoresTaxas) {
        this.props.close();
        this.props.setMessage(true, "success", "Planos taxas salvo com sucesso!");
      }
    } catch (e) {
    } finally {
      actions.setSubmitting(false);
    }
  };

  render() {
    return (
      <div style={{ overflowY: "auto", height: "90%", overflowX: "hidden" }}>
        {
          <EditContext.Consumer>
            {(editContext) => (
              <Formik
                initialValues={initialValues}
                onSubmit={(values: FormValues, actions: FormikActions<FormValues>) => this.submit(values, actions, editContext)}
                validationSchema={PlanosConciliadoresTaxasSchema}
                render={(formikBag: FormikProps<FormValues>) => (
                  <form autoComplete="new-password" role="presentation" onSubmit={formikBag.handleSubmit}>
                    {!editContext && <FormPlanosTaxas formikBag={formikBag} />}
                    {editContext && (
                      <GetPlanosConciliadoresTaxasComponent
                        fetchPolicy="no-cache"
                        variables={{
                          values: {
                            codplanosconciliadores: (editContext! as any).codplanosconciliadores!,
                            codprodutos: (editContext! as any).codprodutos,
                          },
                        }}
                      >
                        {({ data, loading }) => {
                          if (loading) {
                            return <Loading />;
                          }
                          if (data && data.getPlanosConciliadoresTaxas) {
                            return <FormPlanosTaxas formikBag={formikBag} data={data} />;
                          }
                          return null;
                        }}
                      </GetPlanosConciliadoresTaxasComponent>
                    )}
                    <div
                      style={{
                        float: "right",
                        margin: 10,
                        display: "flex",
                        justifyContent: "space-between",
                        width: "180px",
                        flexDirection: "row",
                      }}
                    >
                      <Button variant="contained" color="primary" disabled={formikBag.isSubmitting} type="submit">
                        Salvar
                      </Button>
                      <Button
                        variant="contained"
                        color="secondary"
                        disabled={formikBag.isSubmitting}
                        type="reset"
                        onClick={() => formikBag.resetForm(initialValues)}
                      >
                        Limpar
                      </Button>
                    </div>
                  </form>
                )}
              />
            )}
          </EditContext.Consumer>
        }
      </div>
    );
  }
}

const withMutation = withSavePlanosConciliadoresTaxas<NewEditProps>({ withRef: true });

export default withMutation(NewEditPlanosTaxas);
