import { AppBar, IconButton, LinearProgress, Toolbar, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Drawer from "@material-ui/core/Drawer";
import Slide from "@material-ui/core/Slide";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import MoreIcon from "@material-ui/icons/MoreHoriz";
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import SpeedDialIcon from "@material-ui/lab/SpeedDialIcon";
import ApolloClient from "apollo-client";
import { ApolloQueryResult, OperationVariables } from "apollo-client";
import * as _ from "lodash";
import React, { SetStateAction, useCallback, useEffect, useState } from "react";
import { ApolloConsumer, Mutation, MutationFunction, Query } from "react-apollo";
import Loadable from "react-loadable";
import Confirm from "../Confirm";
import FloatingPanel from "../FloatingPanel";
import Loading from "../Loading/Loading";
import LoadingAction from "../LoadingAction";
import PapperBlock from "../PapperBlock/PapperBlock";
import SnackMessage from "../SnakBar";
import { writeDescFiltro } from "../Utils/pesquisa";
import ActionsTable, { CallbackActionType } from "./actionsTable";
import ColumnCrudTableType from "./columnCrudTableType";
import convertResultToTable from "./convertResultToTable";
import NewEditProps, { FilterProps, FullScreenDrawerProps, RightDrawerProps } from "./crudProps";
import EditContext from "./editContext";
import FilterContext from "./filterContext";
import GenericVariables from "./genericVariables";
import QueryType from "./queryType";
import "./rightDrawer.css";
import SelectedRowsContext from "./selectedRowsContext";
import TableCrud, { RowType } from "./table";
import { useHistory } from "react-router";
import * as H from "history";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" {...props} ref={ref} />;
});

let mutationConfirmed: MutationFunction<any, OperationVariables> | null = null;

let dataReturned: Array<any> = [];

let isExecuting = false;
let showWait = false;

interface Props {
  colunas: Array<ColumnCrudTableType>;
  titulo: string;
  variables: GenericVariables;
  query: QueryType;
  deleteMutation?: any;
  mapVariablesFiltro?: any;
  actionsTable?: ((records: Array<any>, history: H.History) => Array<ActionsTable>) | Array<ActionsTable>;
  actionsTableNotSelected?: Array<ActionsTable>;
  variablesValorExibicao?: GenericVariables;
  themeTable?: Object;
  columnsOrder?: Array<string>;
  rightDrawerOpen?: boolean;
  rightDrawerWidth?: number;
  fullScreenTitle?: string;
  paramSize?: "large" | "xLarge" | "xxLarge" | "x3Large" | "x4Large";
  skipInitialSearch?: boolean;
  hideAdd?: boolean;
  canEdit?: boolean;
  NewComponent?:
    | React.ComponentClass<NewEditProps>
    | React.FunctionComponent<NewEditProps>
    | (React.ComponentClass<NewEditProps, any> & Loadable.LoadableComponent)
    | (React.FunctionComponent<NewEditProps> & Loadable.LoadableComponent)
    | React.ComponentClass<NewEditProps<any>>
    | React.FunctionComponent<NewEditProps<any>>
    | (React.ComponentClass<NewEditProps<any>, any> & Loadable.LoadableComponent)
    | (React.FunctionComponent<NewEditProps<any>> & Loadable.LoadableComponent);
  FilterComponent?:
    | React.ComponentClass<FilterProps>
    | React.FunctionComponent<FilterProps>
    | (React.ComponentClass<FilterProps, any> & Loadable.LoadableComponent)
    | (React.FunctionComponent<FilterProps> & Loadable.LoadableComponent);
  RightDrawerComponent?:
    | React.ComponentClass<RightDrawerProps>
    | React.FunctionComponent<RightDrawerProps>
    | (React.ComponentClass<RightDrawerProps, any> & Loadable.LoadableComponent)
    | (React.FunctionComponent<RightDrawerProps> & Loadable.LoadableComponent);
  FullScreenDrawerComponent?:
    | React.ComponentClass<FullScreenDrawerProps>
    | React.FunctionComponent<FullScreenDrawerProps>
    | (React.ComponentClass<FullScreenDrawerProps, any> & Loadable.LoadableComponent)
    | (React.FunctionComponent<FullScreenDrawerProps> & Loadable.LoadableComponent);
}

const Wrapper = (props: Props) => {
  const [filterOpened, setFilterOpened] = useState(false);
  const [recordOpened, setRecordOpened] = useState(false);
  const [editRecord, setEditRecord] = useState(null);
  const [edit, setEdit] = useState(false);
  const [message, setMessage] = useState("");
  const [messageVariant, setMessageVariant] = useState<"success" | "warning" | "error" | "info">("success");
  const [messageOpen, setMessageOpen] = useState(false);
  const [speedDialOpen, setSpeedDialOpen] = useState(false);
  const [actions, setActions] = useState<Array<ActionsTable>>([]);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [confirmExcluirMsg, setConfirmExcluirMsg] = useState("");
  const [indexToDelete, setIndexToDelete] = useState<Array<number>>([]);
  const [selectedRecords, setSelectedRows] = useState<Array<any>>([]);
  const [descricaoFiltro, setDescricaoFiltro] = useState("");
  const {
    titulo,
    colunas,
    variables,
    query,
    mapVariablesFiltro,
    variablesValorExibicao,
    actionsTable,
    actionsTableNotSelected,
    themeTable,
    columnsOrder,
    rightDrawerOpen,
    rightDrawerWidth = 255,
    fullScreenTitle,
    skipInitialSearch = false,
    paramSize,
    hideAdd = false,
    canEdit = true,
  } = props;

  const [variablesState, setVariablesState] = useState<GenericVariables | null>(null);

  const [objFilterContext] = useState({
    variables: variablesState,
    setVariables: setVariablesState,
  });

  const [actionToExecute, setActionToExecute] = useState<ActionsTable | null>(null);
  let refetchMethod: (variables?: { values: GenericVariables } | undefined) => Promise<ApolloQueryResult<any>>;
  const history = useHistory();
  const [skipQuery, setSkipQuery] = useState(skipInitialSearch);
  const [titleAction] = useState<string | undefined>("");
  const [rightDrawerOpenState, setRightDrawerOpenState] = useState(rightDrawerOpen);
  const [fullScreenDrawerOpenState, setFullScreenDrawerOpenState] = useState(false);
  const [executing, setExecuting] = useState(false);
  const [isFiltering, setFiltering] = useState(false);
  const [messageLoading, setMessageLoading] = useState("");
  const openFullScreenDrawer = React.useCallback(() => setFullScreenDrawerOpenState(true), []);
  const closeFullScreenDrawer = React.useCallback(() => setFullScreenDrawerOpenState(false), []);
  const openRightDrawer = React.useCallback(() => setRightDrawerOpenState(true), []);
  const closeRightDrawer = React.useCallback(() => setRightDrawerOpenState(false), []);
  const handleCloseSpeedDial = React.useCallback(() => setSpeedDialOpen(false), []);
  const handleOpenSpeedDial = React.useCallback(() => setSpeedDialOpen(true), []);
  const closeMessage = React.useCallback(() => {
    setMessage("");
    setMessageOpen(false);
  }, []);
  const buildMessage = React.useCallback(
    (isOpen: boolean, variant: SetStateAction<"error" | "success" | "warning" | "info">, message: string) => {
      setMessage(message);
      setMessageVariant(variant);
      setMessageOpen(isOpen);
    },
    []
  );
  const toggleFilter = React.useCallback(() => setFilterOpened(!filterOpened), [filterOpened]);
  const onRowsSelect = React.useCallback(
    (currentRowsSelected: Array<RowType>, allRowsSelected: Array<RowType>) => {
      setIndexToDelete(allRowsSelected.map((r) => r.dataIndex));
      setSelectedRows(allRowsSelected.map((r) => dataReturned![r.dataIndex]));
      if (allRowsSelected && allRowsSelected.length === 1) {
        if (canEdit) {
          setEdit(true);
          const record = dataReturned![allRowsSelected[0].dataIndex];
          setEditRecord(record);
        }
      } else {
        if (canEdit) {
          setEdit(false);
          setEditRecord(null);
        }
      }

      if (allRowsSelected.length > 0 && actionsTable != null) {
        let actions: Array<ActionsTable> = [];
        if (_.isFunction(actionsTable)) {
          const data = allRowsSelected.map((row) => dataReturned![row.dataIndex]);
          actions = actionsTable(data, history);
        } else if (((actionsTable as unknown) as Array<ActionsTable>).length > 0) {
          actions = ((actionsTable as unknown) as Array<ActionsTable>).map((action) => ({
            iconAction: action.iconAction ? action.iconAction : <DeleteIcon />,
            titleAction: action.titleAction ? action.titleAction : "Deletar",
            actionFunction: action.actionFunction,
            mutation: action.mutation,
            isVisibleOnlySingleSelection: action.isVisibleOnlySingleSelection,
            confirmMessage: action.confirmMessage,
          }));
        }
        setActions(actions);
      } else {
        setActions([]);
        closeRightDrawer();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [actionsTable, closeRightDrawer]
  );
  const toggleRecordPanel = React.useCallback(() => {
    setRecordOpened(!recordOpened);
  }, [recordOpened]);

  const search = useCallback(() => {
    if (refetchMethod && skipQuery) {
      setSkipQuery(false);
      setFiltering(true);
    } else if (refetchMethod) {
      setFiltering(true);
      refetchMethod({ values: variablesState ? variablesState : variables })
        .then(() => {
          setFiltering(false);
          setDescricaoFiltro(getDescricaoFiltro());
          setFilterOpened(false);
        })
        .catch((e) => {
          setFiltering(false);
          setFilterOpened(false);
          console.log(e);
        });
    }
    // eslint-disable-next-line
  }, [variablesState, skipQuery, variables]);

  //Após alterar o skipQuery p false, só se pode dar um refetch após a alteração ser de fato realizada. O useEffect aqui age como um callbar do setSkipQuery

  useEffect(() => {
    if (!skipQuery) {
      refetchMethod({ values: variablesState ? variablesState : variables })
        .then(() => {
          setFiltering(false);
          setDescricaoFiltro(getDescricaoFiltro());
          toggleFilter();
        })
        .catch((e) => {
          setFiltering(false);
        });
    }
    // eslint-disable-next-line
  }, [skipQuery]);

  const handleClick = React.useCallback(
    (
      action: ActionsTable | undefined,
      mutate: MutationFunction<any, OperationVariables> | null,
      client: ApolloClient<object> | undefined
    ) => {
      if (action && action.titleAction === "Deletar" && props.deleteMutation) {
        setActionToExecute(action);
        setConfirmOpen(true);
        if (indexToDelete.length > 1) {
          setConfirmExcluirMsg("Deseja realmente excluir esses registros?");
        } else {
          setConfirmExcluirMsg("Deseja realmente excluir este registro?");
        }
      } else if (action && action.confirmMessage) {
        mutationConfirmed = mutate;
        setActionToExecute(action);
        setConfirmOpen(true);
        setConfirmExcluirMsg(action.confirmMessage);
      } else {
        excluir(action!.actionFunction, mutate ? mutate : undefined, client);
      }
    },
    // eslint-disable-next-line
    [indexToDelete, props]
  );

  const excluir = async (
    actionFunction?: CallbackActionType,
    mutate?: MutationFunction<any>,
    client?: ApolloClient<any> | ApolloClient<object>
  ) => {
    let data = indexToDelete.map((index) => dataReturned[index]);
    setExecuting(true);
    isExecuting = true;
    setTimeout(() => {
      if (isExecuting) {
        showWait = true;
        setMessageLoading("Estamos processando sua requisição...");
      }
    }, 500);
    try {
      if (actionToExecute != null) {
        await actionToExecute.actionFunction(
          mutate ? mutate : mutationConfirmed,
          data,
          buildMessage,
          openRightDrawer,
          openFullScreenDrawer,
          closeRightDrawer,
          closeFullScreenDrawer,
          client!,
          history,
          variablesState ? variablesState : variables
        );
        mutationConfirmed = null;
        setTimeout(() => setIndexToDelete([]), 400);
      } else if (actionFunction) {
        await actionFunction(
          mutate ? mutate : mutationConfirmed,
          data,
          buildMessage,
          openRightDrawer,
          openFullScreenDrawer,
          closeRightDrawer,
          closeFullScreenDrawer,
          client!,
          history,
          variablesState ? variablesState : variables
        );
        mutationConfirmed = null;
      }
      isExecuting = false;
      showWait = false;
      setExecuting(false);
      setMessageLoading("");
    } catch (e) {
    } finally {
      setConfirmOpen(false);
      isExecuting = false;
      showWait = false;
      setExecuting(false);
      setMessageLoading("");
    }
  };

  const closeConfirm = React.useCallback(() => {
    setConfirmOpen(false);
  }, []);

  const getDescricaoFiltro = React.useCallback(() => {
    if (skipQuery) {
      return "Efetue um filtro para visualizar os registros.";
    }
    if (!mapVariablesFiltro) {
      return "Exibindo todos os registros.";
    }
    const retornoPrefix = "<b>Filtro: </b>";
    let retorno = "";
    Object.keys(mapVariablesFiltro).forEach((key: string) => {
      const obj = variablesState ? variablesState[key] : variables[key];
      if (obj != null && obj!.toString().length > 0 && obj!.toString() !== "[object Object]") {
        retorno += writeDescFiltro(variablesValorExibicao, key, mapVariablesFiltro);
      } else if (obj != null && obj!.toString().length > 0) {
        if (variables[key]! instanceof Object) {
          Object.keys(obj!).forEach((subKey: string) => {
            if ((obj! as any)[subKey] != null && (obj! as any)[subKey].toString().length > 0) {
              retorno += writeDescFiltro(variablesValorExibicao![key]! as any, subKey, mapVariablesFiltro[key]);
            }
          });
        }
      }
    });
    return retorno.length > 0 ? `${retornoPrefix}${retorno}` : "";
  }, [variablesState, mapVariablesFiltro, skipQuery, variables, variablesValorExibicao]);

  React.useEffect(() => setDescricaoFiltro(getDescricaoFiltro()), [getDescricaoFiltro]);
  return (
    <React.Fragment>
      {props.FilterComponent && (
        <SwipeableDrawer
          anchor="top"
          open={filterOpened}
          onClose={toggleFilter}
          onOpen={toggleFilter}
          ModalProps={{ disableBackdropClick: true }}
        >
          <div style={{ minHeight: "200px" }}>
            <PapperBlock
              title="Filtro"
              icon="ios-search"
              desc="Insira os filtros para efetuar uma pesquisa customizada."
              whiteBg
              stylesDesc={{ marginBottom: "20px" }}
            >
              {props.FilterComponent && (
                <FilterContext.Provider value={objFilterContext}>
                  <props.FilterComponent
                    variables={variables}
                    variablesValorExibicao={variablesValorExibicao}
                    isFiltering={isFiltering}
                  />
                </FilterContext.Provider>
              )}
              <div style={{ marginTop: "15px", paddingTop: 25, position: "relative" }}>
                <Button
                  disabled={isFiltering}
                  variant="outlined"
                  color="primary"
                  onClick={search}
                  style={{ position: "absolute", bottom: 0, margin: "15px", right: 100 }}
                >
                  Filtrar
                </Button>
                <Button
                  disabled={isFiltering}
                  variant="outlined"
                  color="secondary"
                  onClick={toggleFilter}
                  style={{ position: "absolute", bottom: 0, margin: "15px", right: 0 }}
                >
                  Fechar
                </Button>
              </div>
            </PapperBlock>
          </div>
          {isFiltering && <LinearProgress color="secondary" style={{ height: 5.5 }} />}
        </SwipeableDrawer>
      )}
      <PapperBlock
        marginRight={rightDrawerOpenState ? rightDrawerWidth : undefined}
        minHeight={500}
        title={titulo}
        openFilter={props.FilterComponent ? toggleFilter : undefined}
        desc={descricaoFiltro}
        //isTableMobile={getUserAgent() === UserAgentType.BROSER || colunas.length <= 3 ? false : true}
      >
        <Query<any, any> variables={{ values: variables }} skip={skipQuery} query={query.query} errorPolicy="all">
          {({ data, loading, error, refetch }) => {
            refetchMethod = refetch;
            if (loading && !isFiltering) {
              return <Loading />;
            }
            if (
              (error == null || error === undefined) &&
              data != null &&
              data[query.queryName] != null &&
              data !== undefined &&
              data[query.queryName] !== undefined
            ) {
              dataReturned = data[query.queryName];
              const result = convertResultToTable(data[query.queryName], columnsOrder);
              return (
                <TableCrud data={result} columns={colunas} titulo={titulo} onRowsSelect={onRowsSelect} themeTable={themeTable} />
              );
            }
            return <TableCrud data={[]} columns={colunas} titulo={titulo} onRowsSelect={onRowsSelect} themeTable={themeTable} />;
          }}
        </Query>
        {(props.NewComponent || (actionsTable && actionsTable.length > 0 && actions.length > 0)) && (
          <ApolloConsumer>
            {(client) => (
              <SpeedDial
                ariaLabel="Adicionar"
                style={{ position: "fixed", bottom: "45px", right: "20px" }}
                icon={
                  (props.NewComponent && !hideAdd) || edit ? (
                    <SpeedDialIcon
                      onClick={toggleRecordPanel}
                      icon={edit && canEdit ? <EditIcon /> : <AddIcon />}
                      openIcon={edit && canEdit ? <EditIcon /> : <AddIcon />}
                    />
                  ) : (
                    <SpeedDialIcon icon={<MoreIcon />} openIcon={<MoreIcon />} />
                  )
                }
                onBlur={handleCloseSpeedDial}
                onClose={handleCloseSpeedDial}
                onFocus={handleOpenSpeedDial}
                onMouseEnter={handleOpenSpeedDial}
                onMouseLeave={handleCloseSpeedDial}
                open={speedDialOpen}
              >
                {!actions ||
                  (actions.length === 0 &&
                    actionsTableNotSelected &&
                    actionsTableNotSelected.map((action) => {
                      return (
                        <SpeedDialAction
                          key={action.titleAction}
                          icon={action.iconAction}
                          tooltipTitle={action.titleAction}
                          onClick={() => handleClick(action, null, client as any)}
                        />
                      );
                    }))}
                {actions.map((action) => {
                  if (action.isVisibleOnlySingleSelection && indexToDelete.length > 1) {
                    return null;
                  }
                  if (action.mutation) {
                    return (
                      <Mutation<any, any> key={action.titleAction} mutation={action.mutation!}>
                        {(mutate, { loading, client }) => {
                          if (loading) return null;
                          return (
                            <SpeedDialAction
                              key={action.titleAction}
                              icon={action.iconAction}
                              tooltipTitle={action.titleAction}
                              open={speedDialOpen}
                              onClick={() => handleClick(action, mutate, client as any)}
                            />
                          );
                        }}
                      </Mutation>
                    );
                  }
                  return (
                    <SpeedDialAction
                      key={action.titleAction}
                      icon={action.iconAction}
                      tooltipTitle={action.titleAction}
                      onClick={() => handleClick(action, null, client as any)}
                    />
                  );
                })}
                )
              </SpeedDial>
            )}
          </ApolloConsumer>
        )}
      </PapperBlock>

      {props.NewComponent && (
        <FloatingPanel openForm={recordOpened} closeForm={toggleRecordPanel} title={titulo} paramSize={paramSize}>
          <EditContext.Provider value={editRecord}>
            {recordOpened && <props.NewComponent setMessage={buildMessage} close={toggleRecordPanel} />}
          </EditContext.Provider>
        </FloatingPanel>
      )}
      <ApolloConsumer>
        {(client) => {
          if (props.deleteMutation) {
            return (
              <Mutation<any, any> mutation={props.deleteMutation}>
                {(mutate) => (
                  <Confirm
                    titulo="Atenção"
                    msg={confirmExcluirMsg}
                    close={closeConfirm}
                    okCallback={excluir.bind(null, undefined, mutate, client as any)}
                    open={confirmOpen}
                    disabled={executing}
                  />
                )}
              </Mutation>
            );
          } else if (!props.deleteMutation) {
            return (
              <Confirm
                titulo="Atenção"
                msg={confirmExcluirMsg}
                close={closeConfirm}
                okCallback={excluir.bind(null, undefined, undefined, client as any)}
                open={confirmOpen}
                disabled={executing}
              />
            );
          } else {
            return null;
          }
        }}
      </ApolloConsumer>

      <SelectedRowsContext.Provider value={selectedRecords!}>
        <Drawer
          variant="persistent"
          anchor="right"
          open={rightDrawerOpenState}
          style={{ width: rightDrawerWidth }}
          classes={{ paperAnchorRight: "rightDrawer", paperAnchorDockedRight: "rightDrawer" }}
        >
          <div style={{ overflowY: "auto", width: rightDrawerWidth }}>
            {props.RightDrawerComponent && (
              <props.RightDrawerComponent
                closeRightDrawer={closeRightDrawer}
                setMessage={buildMessage}
                titleAction={titleAction}
              />
            )}
          </div>
        </Drawer>
        {props.FullScreenDrawerComponent && (
          <Dialog
            fullScreen
            open={fullScreenDrawerOpenState}
            onClose={closeFullScreenDrawer}
            TransitionComponent={Transition as any}
          >
            <AppBar>
              <Toolbar>
                <IconButton color="inherit" onClick={closeFullScreenDrawer} aria-label="Close">
                  <CloseIcon />
                </IconButton>
                <Typography variant="h6" color="inherit">
                  {fullScreenTitle}
                </Typography>
              </Toolbar>
            </AppBar>
            <div style={{ margin: "10px", marginTop: "75px" }}>
              <props.FullScreenDrawerComponent
                closeFullScreenDrawer={closeFullScreenDrawer}
                setMessage={buildMessage}
                titleAction={titleAction}
              />
            </div>
          </Dialog>
        )}
      </SelectedRowsContext.Provider>
      <SnackMessage message={message} variant={messageVariant} open={messageOpen} close={closeMessage} />
      <LoadingAction open={showWait} message={messageLoading} />
    </React.Fragment>
  );
};

export default React.memo(Wrapper);
