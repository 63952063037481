import React from "react";
import { FieldProps, getIn } from "formik";
import { FormControl, InputLabel, FormHelperText, Input } from "@material-ui/core";
import MaskedInput from "react-text-mask";
import { InputBaseProps } from "@material-ui/core/InputBase";

interface TextInputProps extends FieldProps {
  label: string;
  mask: (string | RegExp)[];
  id: string;
  disabled?: boolean;
}

interface MaskedProps extends InputBaseProps {
  mask: Array<string>;
}

const Masked = React.memo((prop: any) => {
  const { inputRef, mask, ...other } = prop;
  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={mask}
      showMask
    />
  );
});

const input = (props: TextInputProps) => {
  const fieldError = getIn(props.form.errors, props.field.name);
  const showError = getIn(props.form.touched, props.field.name) && !!fieldError;
  return (
    <FormControl error={showError} aria-describedby="component-error-text" fullWidth>
      <InputLabel htmlFor={props.id} shrink>
        {props.label}
      </InputLabel>
      <Input
        id={props.id}
        {...props.field}
        autoComplete="new-password"
        inputComponent={Masked}
        inputProps={{ mask: props.mask }}
        disabled={props.disabled}
      />
      {showError && <FormHelperText id={`${props.id}-error`}>{fieldError}</FormHelperText>}
    </FormControl>
  );
};

const FormikMaskedInputText = React.memo(input);

export default FormikMaskedInputText;
