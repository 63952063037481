import ColumnCrudTableType from "../../../componentes/CRUD/columnCrudTableType";
import moment from "moment";
import downloadUtils from "../../../utils/downloadUtils";
import { CallbackActionType } from "../../../componentes/CRUD/actionsTable";
import { DownloadExtratosOriginaisMutation, DownloadExtratosOriginaisMutationVariables } from "../../../graphql/components";

export const baixarArquivoExtratosOriginais: CallbackActionType<
  DownloadExtratosOriginaisMutation,
  DownloadExtratosOriginaisMutationVariables
> = async (mutation, record, setMessage) => {
  let hasError = 0;
  for (let r of record) {
    const response = await mutation!({
      variables: { values: { codarquivoDownload: r.codarquivo.toString() } }
    });

    if (response && response.data && response.data.downloadExtratosOriginais) {
      downloadUtils(response.data.downloadExtratosOriginais!);
    } else {
      hasError++;
    }
  }
  if (hasError === record.length) {
    setMessage!(true, "success", "Download realizado com sucesso!");
  } else if (hasError > 0) {
    setMessage!(true, "success", "Download realizado com sucesso!");
  } else {
    setMessage!(true, "success", "Download realizado com sucesso!");
  }
};

export const variablesExtratosOriginais: {
  values: {
    codarquivo: number | null;
    nomearquivo: string | null;
    codoperadora: number | null;
  };
  de: Date | null;
  ate: Date | null;
} = {
  values: {
    codarquivo: null,
    nomearquivo: null,
    codoperadora: null
  },
  de: moment()
    .subtract(1, "month")
    .toDate(),
  ate: moment().toDate()
};

export const variablesExtratosOriginaisInitial = JSON.parse(JSON.stringify(variablesExtratosOriginais));

export const variablesExtratosOriginaisExibicao: {
  values: {
    codarquivo: number | null;
    nomearquivo: string | null;
    codoperadora: string | null;
  };
  de: Date | null;
  ate: Date | null;
} = {
  values: {
    codarquivo: null,
    nomearquivo: null,
    codoperadora: null
  },
  de: moment()
    .subtract(1, "month")
    .toDate(),
  ate: moment().toDate()
};

export const mapVariablesFiltroExtratosOriginais = {
  values: {
    codarquivo: "Cod. Arquivo",
    nomearquivo: "Nome Arquivo",
    codoperadora: "Operadora"
  },
  de: "Data Envio de",
  ate: "Data Envio até"
};

export const columnsOrderExtratosOriginais = ["codarquivo", "dataenvio", "datarecebimento", "tipo", "nomearquivo"];

export const colunasExtratosOriginais: Array<ColumnCrudTableType> = [
  {
    name: "ID",
    options: {
      filter: true
    }
  },
  {
    name: "Envio",
    options: {
      filter: true,
      customBodyRender: (value: any) => moment(new Date(value)).format("DD/MM/YYYY hh:mm:ss")
    }
  },
  {
    name: "Recebimento",
    options: {
      filter: true,
      customBodyRender: (value: any) => moment(new Date(value)).format("DD/MM/YYYY hh:mm:ss")
    }
  },
  {
    name: "Tipo",
    options: {
      filter: true
    }
  },
  {
    name: "Arquivo",
    options: {
      filter: true
    }
  }
];
