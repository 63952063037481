import React from "react";
import { RightDrawerProps } from "../../../componentes/CRUD/crudProps";
import "./visualizarNota.css";
import VisualizarNota from "./visualizarNota";
import PreVisualizarNota from "./preVisualizarNota";

const FaturamentoMovimentoNota: React.FC<RightDrawerProps> = ({ closeRightDrawer, setMessage, titleAction }) => {
  if (titleAction && titleAction === "Visualizar Nota") {
    return <VisualizarNota closeRightDrawer={closeRightDrawer} setMessage={setMessage} />;
  } else if (titleAction && titleAction === "Pré visualizar Nota") {
    return <PreVisualizarNota closeRightDrawer={closeRightDrawer} setMessage={setMessage} />;
  }
  return null;
};

export default FaturamentoMovimentoNota;
