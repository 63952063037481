import { login } from "../actionConstants";
import ActionTypeDefault from "../actionTypeDefault";

export interface InitialStateLogin {
  isSignedIn: boolean;
  page: string;
}

export const initialState: InitialStateLogin = {
  isSignedIn: true,
  page: ""
};

export default function reducer(state = initialState, action: ActionTypeDefault) {
  switch (action.type) {
    case login.NOT_AUTHENTICATED:
      return {
        ...state,
        isSignedIn: false
      };

    case login.AUTHENTICATED:
      return {
        ...state,
        isSignedIn: true,
        page: action.payload
      };
    default:
      return state;
  }
}
