import { Portal } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import { withStyles } from "@material-ui/core/styles";
import ActionDelete from "@material-ui/icons/Delete";
import FileIcon from "@material-ui/icons/Description";
import React from "react";
import Dropzone, { DropEvent, DropzoneRef } from "react-dropzone";
import Ionicon from "react-ionicons";
import "../../styles/components/vendors/react-dropzone/react-dropzone.css";
import SnackMessage from "../SnakBar";

const isImage = (file: any) => {
  const fileName = file.name || file.path;
  const suffix = fileName.substr(fileName.indexOf(".") + 1).toLowerCase();
  if (suffix === "jpg" || suffix === "jpeg" || suffix === "bmp" || suffix === "png") {
    return true;
  }
  return false;
};

const styles = (theme: any) => ({
  dropItem: {
    borderColor: theme.palette.divider,
    background: theme.palette.background.default,
    borderRadius: theme.rounded.medium,
    color: theme.palette.text.disabled,
    textAlign: "center",
  },
  uploadIconSize: {
    width: 72,
    height: 72,
    display: "inline-block",
    "& svg": {
      fill: theme.palette.secondary.main,
    },
  },
  rightIcon: {
    marginLeft: theme.spacing(),
    "& svg": {
      fill: theme.palette.common.white,
    },
  },
  button: {
    marginTop: 20,
  },
});

interface Props {
  files: Array<File>;
  text: string;
  acceptedFiles?: Array<string>;
  showPreviews: boolean;
  showButton?: boolean;
  maxSize: number;
  filesLimit: number;
  classes?: any;
  labelUpload?: string;
  uploadMethod: Function;
}

interface State {
  openSnackBar: boolean;
  errorMessage: string;
  files: Array<File>;
  acceptedFiles: Array<string>;
  pathImage?: any;
}

class MaterialDropZone extends React.Component<Props, State> {
  static defaultPoprs = {
    acceptedFiles: [],
    showButton: false,
  };
  constructor(props: Props) {
    super(props);

    this.state = {
      openSnackBar: false,
      errorMessage: "",
      files: this.props.files, // eslint-disable-line
      acceptedFiles: this.props.acceptedFiles!, // eslint-disable-line
    };
    this.onDrop = this.onDrop.bind(this);
  }

  closeSnackMessage = () => this.setState({ openSnackBar: false });

  onDrop = (filesVal: Array<File>) => {
    const { files } = this.state;
    const { filesLimit, uploadMethod } = this.props;
    let oldFiles = files;
    const filesLimitVal = filesLimit || "3";
    oldFiles = oldFiles.concat(filesVal);
    if (oldFiles.length > filesLimit) {
      this.setState({
        openSnackBar: true,
        errorMessage: "Cannot upload more than " + filesLimitVal + " items.",
      });
    } else {
      this.setState({ files: oldFiles });
      uploadMethod(oldFiles);
    }
  };

  onDropRejected = (files: File[], event: DropEvent) => {
    console.warn("Arquivo muito grande ou extensão inválida.");
    this.setState({
      openSnackBar: true,
      errorMessage: "Arquivo muito grande ou extensão inválida.",
    });
  };

  handleRequestCloseSnackBar = () => {
    this.setState({
      openSnackBar: false,
    });
  };

  handleRemove(file: any, fileIndex: number) {
    const thisFiles = this.state.files; // eslint-disable-line
    // This is to prevent memory leaks.
    window.URL.revokeObjectURL(file.preview);

    thisFiles.splice(fileIndex, 1);
    this.setState({ files: thisFiles });
  }

  render() {
    const { classes, showPreviews, maxSize, text, showButton, filesLimit, labelUpload, ...rest } = this.props;

    const { acceptedFiles, files, openSnackBar, errorMessage } = this.state;
    const fileSizeLimit = maxSize || 3000000;
    const deleteBtn = (file: File, index: number) => (
      <div className="middle">
        <IconButton onClick={() => this.handleRemove(file, index)}>
          <ActionDelete className="removeBtn" />
        </IconButton>
      </div>
    );
    const previews = (filesArray: Array<any>) =>
      filesArray.map((file: any, index: number) => {
        //const path = file.preview || "/pic/" + file.path;
        const reader = new FileReader();
        reader.onload = (e: any) => {
          this.setState({ pathImage: e.target.result });
        };
        reader.readAsDataURL(file);
        if (isImage(file) && this.state.pathImage) {
          return (
            <div key={index.toString()}>
              <div className="imageContainer col fileIconImg">
                <figure className="imgWrap">
                  <img className="smallPreviewImg" src={this.state.pathImage} alt="preview" />
                </figure>
                {deleteBtn(file, index)}
              </div>
            </div>
          );
        }
        return (
          <div key={index.toString()}>
            <div className="imageContainer col fileIconImg">
              <FileIcon className="smallPreviewImg" />
              {deleteBtn(file, index)}
            </div>
            {file.name}
          </div>
        );
      });
    let dropzoneRef: DropzoneRef | null;
    return (
      <React.Fragment>
        <Dropzone
          accept={acceptedFiles.join(",")}
          onDrop={this.onDrop}
          multiple={false}
          onDropRejected={this.onDropRejected}
          maxSize={fileSizeLimit}
          ref={(node) => (dropzoneRef = node)}
          {...rest}
        >
          {({ getRootProps, getInputProps }) => (
            <section>
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <div className="dropzoneTextStyle" style={{ marginTop: "10%" }}>
                  <p className="dropzoneParagraph">{text}</p>
                  <div className={classes.uploadIconSize}>
                    <Ionicon icon="ios-cloud-upload-outline" fontSize="72px" />
                  </div>
                </div>
              </div>
            </section>
          )}
        </Dropzone>
        <div style={{ float: "left", width: "100%", marginTop: "100px" }}>
          {showButton && (
            <Button
              className={classes.button}
              fullWidth
              variant="contained"
              onClick={() => {
                if (dropzoneRef) {
                  dropzoneRef.open();
                }
              }}
              color="secondary"
            >
              {labelUpload}
              <span className={classes.rightIcon}>
                <Ionicon icon="ios-cloud-upload-outline" fontSize="35px" />
              </span>
            </Button>
          )}
        </div>
        <div className="row preview" style={{ float: "left", width: "100%" }}>
          {showPreviews && previews(files)}
        </div>
        {openSnackBar && (
          <Portal>
            <SnackMessage
              variant="warning"
              close={this.closeSnackMessage}
              open={this.state.openSnackBar}
              message={errorMessage}
            />
          </Portal>
        )}
      </React.Fragment>
    );
  }
}

export default withStyles(styles as any)(MaterialDropZone);
