import Button from "@material-ui/core/Button";
import { Formik, FormikActions, FormikProps } from "formik";
import React, { PureComponent } from "react";
import * as Yup from "yup";
import NewEditProps from "../../../componentes/CRUD/crudProps";
import EditContext from "../../../componentes/CRUD/editContext";
import Loading from "../../../componentes/Loading/Loading";
import { GetPlanosConciliadoresComponent, ListarPlanosConciliadoresDocument, SavePlanosConciliadoresProps, withSavePlanosConciliadores } from "../../../graphql/components";
import FormPlanosConciliadores from "./formPlanosConciliadores";
import { variablesPlanosConciliadores } from "./utilsPlanosConciliadores";

const stringObrigatorio = "é obrigatório.";

const PlanosConciliadoresSchema = Yup.object().shape({
  codpessoapai: Yup.number().nullable(true),
  codplanosconciliadores: Yup.number().nullable(true),
  nome: Yup.string()
    .required(`Nome ${stringObrigatorio}`)
    .max(50, `Nome não pode exceder 50 caracteres`),
  tarifacao: Yup.string()
});

const initialValues = {
  nome: "",
  tarifacao: "P",
  codpessoapai: (null as unknown) as number,
  codplanosconciliadores: (null as unknown) as number
};

export type FormValues = typeof initialValues;

class NewEditPlanosConciliadores extends PureComponent<SavePlanosConciliadoresProps<NewEditProps>> {
  static contextType = EditContext;

  submit = async (values: FormValues, actions: FormikActions<FormValues>) => {
    try {
      const response = await this.props.mutate!({
        refetchQueries: [
          {
            query: ListarPlanosConciliadoresDocument,
            variables: { values: variablesPlanosConciliadores }
          }
        ],
        variables: {
          values: {
            editValues: {
              nome: values.nome,
              tarifacao: values.tarifacao,
              codplanosconciliadores: values.codplanosconciliadores,
              codpessoapai: values.codpessoapai
            },
            searchValues: {
              codplanosconciliadores: this.context ? this.context.codplanosconciliadores : null
            }
          },
          insert: this.context ? false : true
        },
      });
      if (response && response.data && response.data.savePlanosConciliadores) {
        this.props.close();
        this.props.setMessage(true, "success", "Plano gravado com sucesso!");
      }
    } catch (e) {
    } finally {
      actions.setSubmitting(false);
    }
  };

  render() {
    return (
      <div style={{ overflowY: "auto", height: "90%", overflowX: "hidden" }}>
        <Formik
          initialValues={initialValues}
          onSubmit={this.submit}
          validationSchema={PlanosConciliadoresSchema}
          render={(formikBag: FormikProps<FormValues>) => (
            <form autoComplete="new-password" role="presentation" onSubmit={formikBag.handleSubmit}>
              {!this.context && <FormPlanosConciliadores formikBag={formikBag} />}
              {this.context && this.context.codplanosconciliadores && (
                <GetPlanosConciliadoresComponent
                  fetchPolicy="no-cache"
                  variables={{
                    values: { codplanosconciliadores: this.context.codplanosconciliadores }
                  }}
                >
                  {({ data, loading }) => {
                    if (loading) {
                      return <Loading />;
                    }
                    if (data && data.getPlanosConciliadores) {
                      return <FormPlanosConciliadores formikBag={formikBag} data={data!} />;
                    } else {
                      return null;
                    }
                  }}
                </GetPlanosConciliadoresComponent>
              )}

              <div
                style={{
                  float: "right",
                  margin: 10,
                  display: "flex",
                  justifyContent: "space-between",
                  width: "180px",
                  flexDirection: "row"
                }}
              >
                <Button variant="contained" color="primary" disabled={formikBag.isSubmitting} type="submit">
                  Salvar
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  disabled={formikBag.isSubmitting}
                  type="reset"
                  onClick={() => formikBag.resetForm(initialValues)}
                >
                  Limpar
                </Button>
              </div>
            </form>
          )}
        />
      </div>
    );
  }
}

const withMutation = withSavePlanosConciliadores<NewEditProps>({ withRef: true });

export default withMutation(NewEditPlanosConciliadores);
