import Search from "@material-ui/icons/Search";
import moment from "moment";
import React from "react";
import ActionsTable, { CallbackActionType } from "../../../componentes/CRUD/actionsTable";
import ColumnCrudTableType from "../../../componentes/CRUD/columnCrudTableType";
import { FaturamentoMovimentoBean } from "../../../graphql/components";

export const openRighDrawer: CallbackActionType = async (
  mutation,
  record: Array<FaturamentoMovimentoBean>,
  setMessage,
  openRighScreenDrawer
) => {
  openRighScreenDrawer!();
};

export const variablesFaturamentoMovimento: {
  datageracaode: Date | null;
  datageracaoate: Date | null;
  terminais: string | null;
  unidadeConciliadora: string | null;
  tipo: string;
  statusnota: string | null;
} = {
  datageracaode: moment()
    .subtract(1, "month")
    .toDate(),
  datageracaoate: moment().toDate(),
  terminais: "",
  unidadeConciliadora: "",
  tipo: "A",
  statusnota: ""
};

export const variablesFaturamentoMovimentoExibicao: {
  datageracaode: Date | null;
  datageracaoate: Date | null;
  terminais: string | null;
  unidadeConciliadora: string | null;
  tipo: string;
  statusnota: string | null;
} = {
  datageracaode: moment()
    .subtract(1, "month")
    .toDate(),
  datageracaoate: moment().toDate(),
  terminais: "",
  unidadeConciliadora: "",
  tipo: "Ambos",
  statusnota: ""
};

export const mapVariablesFaturamentoMovimento = {
  datageracaode: "Processado De",
  datageracaoate: "Processado Até",
  terminais: "Grupo Terminal",
  unidadeConciliadora: "Unidade Conciliadora",
  tipo: "Tipo",
  statusnota: "Status Nota"
};

export const columnsOrderFaturamentoMovimento = [
  "datainclusao",
  "nome",
  "filtrode",
  "filtroate",
  "valorbruto",
  "valorliquidoadm",
  "valorliquidoterminal",
  "creditos",
  "debitos",
  "apurado",
  "dataprocessado"
];

export const colunasFaturamentoMovimento: Array<ColumnCrudTableType> = [
  {
    name: "Processado",
    options: {
      filter: true,
      customBodyRender: (value: any) => moment(new Date(value)).format("DD/MM/YYYY")
    }
  },
  {
    name: "Terminal",
    options: {
      filter: true
    }
  },
  {
    name: "Filtro de",
    options: {
      filter: true,
      customBodyRender: (value: any) => moment(new Date(value)).format("DD/MM/YYYY")
    }
  },
  {
    name: "Filtro até",
    options: {
      filter: true,
      customBodyRender: (value: any) => moment(new Date(value)).format("DD/MM/YYYY")
    }
  },
  {
    name: "Bruto",
    options: {
      filter: true
    }
  },
  {
    name: "Líquido ADM",
    options: {
      filter: true
    }
  },
  {
    name: "Líquido Term",
    options: {
      filter: true
    }
  },
  {
    name: "Créditos",
    options: {
      filter: true
    }
  },
  {
    name: "Débitos",
    options: {
      filter: true
    }
  },
  {
    name: "Apurado",
    options: {
      filter: true
    }
  },
  {
    name: "Enviado",
    options: {
      filter: true,
      customBodyRender: (value: any) => moment(new Date(value)).format("DD/MM/YYYY")
    }
  }
];

export const getActionsFaturamentoMovimento = (records: Array<FaturamentoMovimentoBean>): Array<ActionsTable> => {
  let retorno: Array<ActionsTable> = [
    {
      titleAction: "Visualizar Nota",
      iconAction: <Search />,
      actionFunction: openRighDrawer,
      isVisibleOnlySingleSelection: true
    }
  ];
  const exibeAcao = records.every(record => record != null && record.exibirLink != null && record.exibirLink.length > 0);

  if (exibeAcao) {
    retorno = retorno.concat({
      titleAction: "Pré visualizar Nota",
      iconAction: <Search />,
      actionFunction: openRighDrawer,
      isVisibleOnlySingleSelection: true
    });
  }
  return retorno;
};
