import { selectFormulario, ESCOLHA_FORM_ACTIONS_TYPE } from "../actions/escolhaFormActions";
import Formulario from "../../model/formulario";

type InitialState = {
  formulario: Formulario | null;
};

export const initialState = {
  formulario: null,
};

export default function reducer(state: InitialState = initialState, action: ReturnType<typeof selectFormulario>) {
  switch (action.type) {
    case ESCOLHA_FORM_ACTIONS_TYPE.ESCOLHA_FORM:
      return {
        ...state,
        formulario: action.payload,

      };
    default:
      return state;
  }
}
