import MomentUtils from "@date-io/moment";
import { TextField } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import locale from "moment/locale/pt-br";
import React, { PureComponent } from "react";
import { FilterProps } from "../../../componentes/CRUD/crudProps";
import { SelectCombo } from "../../../componentes/Select";
import {
  ListarItensOrigemArquivoRecebidoDocument,
  ListarItensStatusArquivoDocument,
  ListarItensTipoArquivoDocument,
  ListarItensUnidadesConciliadorasDocument,
  ListarOperadorasDocument
} from "../../../graphql/components";
import { variablesManutencaoArquivoUnicoInitial } from "./utilsManutencaoArquivosUnico";
import FilterContext from "../../../componentes/CRUD/filterContext";

type State = typeof variablesManutencaoArquivoUnicoInitial;

const styles = {
  container: { marginBottom: 60 },
  formControl: { minWidth: "225px", width: "99%" },
  picker: { minWidth: "325px" }
};

class FilterManutencaoArquivosUnico extends PureComponent<FilterProps, State> {
  static contextType = FilterContext;
  public static filterManutencaoArquivosUnicosVariables: any;
  constructor(props: FilterProps) {
    super(props);
    this.state = { ...variablesManutencaoArquivoUnicoInitial };
    FilterManutencaoArquivosUnico.filterManutencaoArquivosUnicosVariables = JSON.parse(
      JSON.stringify(variablesManutencaoArquivoUnicoInitial)
    );
  }

  render() {
    return (
      <Grid container spacing={2} style={styles.container}>
        <Grid item xs={12} md={4}>
          <TextField
            id="codigo"
            label="Cod. Arquivo"
            type="number"
            value={this.state.values.codarquivo ? this.state.values.codarquivo : ""}
            name="Código"
            margin="normal"
            variant="outlined"
            fullWidth
            onChange={e => {
              const value: number | null = e.target.value ? parseInt(e.target.value) : null;
              this.setState({ values: { ...this.state.values, codarquivo: value } }, () => {
                this.props.variables.values.codarquivo = this.state.values.codarquivo;
                this.props.variablesValorExibicao.values.codarquivo = this.state.values.codarquivo;
                this.context.setVariables({ ...this.state });
              });
            }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            id="nome"
            label="Nome Arquivo"
            type="text"
            value={this.state.values.nomearquivo ? this.state.values.nomearquivo : ""}
            name="Nome"
            margin="normal"
            variant="outlined"
            fullWidth
            onChange={e => {
              this.setState({ values: { ...this.state.values, nomearquivo: e.target.value } }, () => {
                this.props.variables.values.nomearquivo = this.state.values.nomearquivo;
                this.props.variablesValorExibicao.values.nomearquivo = this.state.values.nomearquivo;
                this.context.setVariables({ ...this.state });
              });
            }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <SelectCombo
            label="Tipo"
            name="tipo"
            nameQuery="listarItensTipoArquivo"
            query={ListarItensTipoArquivoDocument}
            value={this.state.values.tipo ? this.state.values.tipo : undefined}
            handleChange={e => {
              // const valor = e.currentTarget.value;
              this.setState({ values: { ...this.state.values, tipo: e.target.value as string } }, () => {
                this.props.variables.values.tipo = this.state.values.tipo;
                this.props.variablesValorExibicao.values.tipo = this.state.values.tipo;
                this.context.setVariables({ ...this.state });
              });
            }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <SelectCombo
            label="Origem"
            name="origem"
            nameQuery="listarItensOrigemArquivoRecebido"
            query={ListarItensOrigemArquivoRecebidoDocument}
            value={this.state.values.origem ? this.state.values.origem : undefined}
            handleChange={e => {
              const valor = e.currentTarget.value;
              this.setState({ values: { ...this.state.values, origem: e.target.value as string } }, () => {
                this.props.variables.values.origem = valor;
                this.props.variablesValorExibicao.values.origem = this.state.values.origem;
                this.context.setVariables({ ...this.state });
              });
            }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <SelectCombo
            label="Status"
            name="status"
            nameQuery="listarItensStatusArquivo"
            query={ListarItensStatusArquivoDocument}
            value={this.state.values.status ? this.state.values.status : undefined}
            handleChange={e => {
              const valor = e.currentTarget.value;
              const label = (e.currentTarget as any).innerText;
              this.setState({ values: { ...this.state.values, status: e.target.value as string } }, () => {
                this.props.variables.values.status = valor;
                this.props.variablesValorExibicao.values.status = label;
                this.context.setVariables({ ...this.state });
              });
            }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <SelectCombo
            label="Operadora"
            name="operadora"
            nameQuery="listarOperadoras"
            query={ListarOperadorasDocument}
            value={this.state.values.codoperadora ? this.state.values.codoperadora : undefined}
            handleChange={e => {
              // const valor = e.currentTarget.value;
              const label = (e.currentTarget as any).innerText;
              const value: number | null = e.target.value ? parseInt(e.target.value as string) : null;
              this.setState({ values: { ...this.state.values, codoperadora: value } }, () => {
                this.props.variables.values.codoperadora = this.state.values.codoperadora;
                this.props.variablesValorExibicao.values.codoperadora = label;
                this.context.setVariables({ ...this.state });
              });
            }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <SelectCombo
            label="Unidade Conciliadora"
            name="conciliador"
            nameQuery="listarItensUnidadesConciliadoras"
            query={ListarItensUnidadesConciliadorasDocument}
            value={this.state.unidadeConciliadora ? this.state.unidadeConciliadora : undefined}
            handleChange={e => {
              const valor = e.target.value;
              const label = (e.currentTarget as any).innerText;
              this.setState({ values: { ...this.state.values }, unidadeConciliadora: valor }, () => {
                this.props.variables.unidadeConciliadora = valor;
                this.props.variablesValorExibicao.unidadeConciliadora = label;
                this.context.setVariables({ ...this.state });
              });
            }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <MuiPickersUtilsProvider utils={MomentUtils} locale={locale}>
            <KeyboardDatePicker
              maxDateMessage="Data inválida"
              minDateMessage="Data inválida"
              autoOk
              variant="inline"
              style={{ width: "100%" }}
              label="De"
              name="de"
              id="de"
              invalidDateMessage="Data inválida"
              disableToolbar
              value={this.state.de}
              onChange={e => {
                if (e && e.toDate()) {
                  this.setState({ de: e.toDate() }, () => {
                    this.props.variables.de = this.state.de;
                    this.props.variablesValorExibicao.de = this.state.de;
                    this.context.setVariables({ ...this.state });
                  });
                } else {
                  this.setState({ de: null }, () => {
                    this.props.variables.de = this.state.de;
                    this.props.variablesValorExibicao.de = this.state.de;
                    this.context.setVariables({ ...this.state });
                  });
                }
              }}
              format="DD/MM/YYYY"
              placeholder="dd/mm/aaaa"
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={12} md={4}>
          <MuiPickersUtilsProvider utils={MomentUtils} locale={locale}>
            <KeyboardDatePicker
              maxDateMessage="Data inválida"
              minDateMessage="Data inválida"
              autoOk
              variant="inline"
              style={{ width: "100%" }}
              label="Até"
              name="ate"
              id="ate"
              invalidDateMessage="Data inválida"
              disableToolbar
              value={this.state.ate}
              onChange={e => {
                if (e && e.toDate()) {
                  this.setState({ ate: e.toDate() }, () => {
                    this.props.variables.ate = this.state.ate;
                    this.props.variablesValorExibicao.ate = this.state.ate;
                    this.context.setVariables({ ...this.state });
                  });
                } else {
                  this.setState({ ate: null }, () => {
                    this.props.variables.ate = this.state.ate;
                    this.props.variablesValorExibicao.ate = this.state.ate;
                    this.context.setVariables({ ...this.state });
                  });
                }
              }}
              format="DD/MM/YYYY"
              placeholder="dd/mm/aaaa"
            />
          </MuiPickersUtilsProvider>
        </Grid>
      </Grid>
    );
  }
}

export default FilterManutencaoArquivosUnico;
