import React from "react";
import { FieldProps, getIn } from "formik";
import { FormControl, FormHelperText, InputLabel, Input } from "@material-ui/core";

interface TextInputProps extends FieldProps {
  label: string;
  customChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  id: "string";
  password: boolean;
  number: boolean;
  disabled?: boolean;
  multiline?: boolean;
}

const input = (props: TextInputProps) => {
  const fieldError = getIn(props.form.errors, props.field.name);
  const showError = getIn(props.form.touched, props.field.name) && !!fieldError;
  const { id, customChange, password, label, disabled, number, multiline = false, ...rest } = props;
  const InputChange = props.customChange ? (
    <Input
      id={id}
      {...props.field}
      onChange={customChange}
      autoComplete="new-password"
      type={password ? "password" : number ? "number" : "text"}
      disabled={disabled}
      multiline={multiline}
      {...rest}
    />
  ) : (
    <Input
      id={id}
      {...props.field}
      autoComplete="new-password"
      type={password ? "password" : "text"}
      disabled={disabled}
      multiline={multiline}
      {...rest}
    />
  );

  return (
    <FormControl error={showError} aria-describedby="component-error-text" fullWidth>
      <InputLabel htmlFor={props.id} shrink>
        {props.label}
      </InputLabel>
      {InputChange}
      {showError && <FormHelperText id={`${props.id}-error`}>{fieldError}</FormHelperText>}
    </FormControl>
  );
};

const FormikInputText = React.memo(input);

export default FormikInputText;
