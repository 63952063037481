import moment from "moment";

export const writeDescFiltro = (obect: any, key: string, map: any) => {
  const label = map[key];
  let valor = obect[key];
  if (valor instanceof Date) {
    valor = moment(valor).format("DD/MM/YYYY");
  }
  return `<u>${label}</u>: <em>${valor}</em>&nbsp;&nbsp;&nbsp;`;
};
