import MomentUtils from "@date-io/moment";
import Grid from "@material-ui/core/Grid";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import moment from "moment";
import locale from "moment/locale/pt-br";
import React, { PureComponent } from "react";
import { FilterProps } from "../../../componentes/CRUD/crudProps";
import { SelectCombo } from "../../../componentes/Select";
import { ListarItensTerminalDocument, ListarItensUnidadesConciliadorasDocument } from "../../../graphql/components";
import { variablesImportacaoTicket, variablesImportacaoTicketInitial } from "./utilsImportacaoTicket";
import FilterContext from "../../../componentes/CRUD/filterContext";

type StateDate = {
  minDate: Date | null;
  maxDate: Date | null;
};
type State = typeof variablesImportacaoTicket & StateDate;

const styles = {
  container: { marginBottom: 60 },
  formControl: { minWidth: "225px", width: "99%" },
  picker: { minWidth: "325px" }
};

class FilterImportacaoTicket extends PureComponent<FilterProps, State> {
  static contextType = FilterContext;
  public static filterImportacaoTicketVariables: any;
  constructor(props: FilterProps) {
    super(props);
    this.state = {
      ...variablesImportacaoTicketInitial,
      minDate: variablesImportacaoTicket.de!,
      maxDate: variablesImportacaoTicket.ate!
    };
    FilterImportacaoTicket.filterImportacaoTicketVariables = JSON.parse(JSON.stringify(variablesImportacaoTicketInitial));
  }

  render() {
    return (
      <Grid container spacing={2} style={styles.container}>
        <Grid item xs={12} md={3}>
          <SelectCombo
            label="Unidade Conciliadora"
            name="concioperadora"
            nameQuery="listarItensUnidadesConciliadoras"
            query={ListarItensUnidadesConciliadorasDocument}
            value={this.state.concioperadora ? this.state.concioperadora : ""}
            handleChange={e => {
              // const valor = e.currentTarget.value;
              const label = (e.currentTarget as any).innerText;
              this.setState({ concioperadora: e.target.value as string }, () => {
                this.props.variables.concioperadora = this.state.concioperadora;
                this.props.variablesValorExibicao.concioperadora = label;
                const { minDate, maxDate, ...state } = this.state;
                this.context.setVariables({ ...state });
              });
            }}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <SelectCombo
            label="Terminal"
            name="terminal"
            nameQuery="listarItensTerminal"
            query={ListarItensTerminalDocument}
            value={this.state.terminal ? this.state.terminal : ""}
            handleChange={e => {
              // const valor = e.currentTarget.value;
              const label = (e.currentTarget as any).innerText;
              this.setState({ terminal: e.target.value as string }, () => {
                this.props.variables.terminal = this.state.terminal;
                this.props.variablesValorExibicao.terminal = label;
                const { minDate, maxDate, ...state } = this.state;
                this.context.setVariables({ ...state });
              });
            }}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <MuiPickersUtilsProvider utils={MomentUtils} locale={locale}>
            <KeyboardDatePicker
              autoOk
              variant="inline"
              style={{ width: "100%" }}
              label="De"
              name="de"
              id="de"
              invalidDateMessage="Data inválida"
              disableToolbar
              minDate={this.state.minDate}
              value={this.state.de}
              minDateMessage={`O período máximo para a pesquisa deve ser de 1 mês`}
              maxDateMessage="O período máximo para a pesquisa deve ser de 1 mês"
              onChange={e => {
                if (e && e.toDate()) {
                  const maxDate = moment(e.toDate())
                    .add(1, "month")
                    .toDate();
                  this.setState({ de: e.toDate(), maxDate }, () => {
                    this.props.variables.de = this.state.de;
                    this.props.variablesValorExibicao.de = this.state.de;
                    const { minDate, maxDate, ...state } = this.state;
                    this.context.setVariables({ ...state });
                  });
                } else {
                  this.setState({ de: null, maxDate: null }, () => {
                    this.props.variables.de = this.state.de;
                    this.props.variablesValorExibicao.de = this.state.de;
                    const { minDate, maxDate, ...state } = this.state;
                    this.context.setVariables({ ...state });
                  });
                }
              }}
              format="DD/MM/YYYY"
              placeholder="dd/mm/aaaa"
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={12} md={3}>
          <MuiPickersUtilsProvider utils={MomentUtils} locale={locale}>
            <KeyboardDatePicker
              autoOk
              variant="inline"
              style={{ width: "100%" }}
              label="Até"
              name="ate"
              id="ate"
              invalidDateMessage="Data inválida"
              disableToolbar
              minDateMessage={`O período máximo para a pesquisa deve ser de 1 mês`}
              maxDateMessage="O período máximo para a pesquisa deve ser de 1 mês"
              maxDate={this.state.maxDate}
              value={this.state.ate}
              onChange={e => {
                if (e && e.toDate()) {
                  const minDate = moment(e.toDate())
                    .subtract(1, "month")
                    .toDate();
                  this.setState({ ate: e.toDate(), minDate }, () => {
                    this.props.variables.ate = this.state.ate;
                    this.props.variablesValorExibicao.ate = this.state.ate;
                    const { minDate, maxDate, ...state } = this.state;
                    this.context.setVariables({ ...state });
                  });
                } else {
                  this.setState({ ate: null, minDate: null }, () => {
                    this.props.variables.ate = this.state.ate;
                    this.props.variablesValorExibicao.ate = this.state.ate;
                    const { minDate, maxDate, ...state } = this.state;
                    this.context.setVariables({ ...state });
                  });
                }
              }}
              format="DD/MM/YYYY"
              placeholder="dd/mm/aaaa"
            />
          </MuiPickersUtilsProvider>
        </Grid>
      </Grid>
    );
  }
}

export default FilterImportacaoTicket;
