import React from "react";
import ColumnCrudTableType from "../../../componentes/CRUD/columnCrudTableType";
import moment from "moment";
import { CallbackActionType } from "../../../componentes/CRUD/actionsTable";
import {
  ImportarTicketMutation,
  ImportarTicketMutationVariables,
  ImportarTodosTicketsMutation,
  ImportarTodosTicketsMutationVariables,
  ImportarTicketDocument,
  ImportarTodosTicketsDocument
} from "../../../graphql/components";
import Upload from "@material-ui/icons/CloudUpload";
import ArrowUp from "@material-ui/icons/ArrowUpward";

export const importarTicket: CallbackActionType<ImportarTicketMutation, ImportarTicketMutationVariables> = async (
  mutation,
  record,
  setMessage
) => {
  const tickets = record.map(r => {
    delete r["valor"];
    delete r["__typename"];
    return r;
  });
  const response = await mutation!({
    variables: { values: tickets }
  });
  if (response) {
    const { data } = response;
    if (data && data.importarTicket) {
      if (setMessage) {
        setMessage(true, "success", "Registro baixado com sucesso!");
      }
    }
  }
};

export const importarTodosTicket: CallbackActionType<
  ImportarTodosTicketsMutation,
  ImportarTodosTicketsMutationVariables
> = async (
  mutation,
  record,
  setMessage,
  openRightDrawer,
  openFullScreenDrawer,
  closeRightDrawer,
  closeFullScreenDrawer,
  client,
  history,
  filterVariables
) => {
  console.log(filterVariables);
  console.log("break");
  const response = await mutation!({ variables: { values: filterVariables } });
  if (response) {
    const { data } = response;
    if (data && data.importarTodosTickets) {
      if (setMessage) {
        setMessage(true, "success", "Registro baixado com sucesso!");
      }
    }
  }
};

export const variablesImportacaoTicket: {
  concioperadora: string | null;
  terminal: string | null;
  de: Date | null;
  ate: Date | null;
} = {
  concioperadora: null,
  terminal: null,
  de: moment()
    .subtract(1, "month")
    .toDate(),
  ate: moment().toDate()
};

export const variablesImportacaoTicketInitial = JSON.parse(JSON.stringify(variablesImportacaoTicket));

export const variablesImportacaoTicketExibicao: {
  concioperadora: string | null;
  terminal: string | null;
  de: Date | null;
  ate: Date | null;
} = {
  concioperadora: null,
  terminal: null,
  de: moment()
    .subtract(1, "month")
    .toDate(),
  ate: moment().toDate()
};

export const mapVariablesFiltroImportacaoTicket = {
  concioperadora: "Unidade Conciliadora",
  terminal: "Terminal",
  de: "Venda de",
  ate: "Venda até"
};

export const columnsOrderImportacaoTicket = [
  "datavenda",
  "totparcelas",
  "valor",
  "nsu",
  "tid",
  "autorizacao",
  "numterminal",
  "codexterno"
];

export const colunasImportacaoTicket: Array<ColumnCrudTableType> = [
  {
    name: "Data Venda",
    options: {
      filter: true,
      customBodyRender: (value: any) => moment(new Date(value)).format("DD/MM/YYYY")
    }
  },
  {
    name: "Parcela",
    options: {
      filter: true
    }
  },
  {
    name: "Valor",
    options: {
      filter: true
    }
  },
  {
    name: "NSU",
    options: {
      filter: true
    }
  },
  {
    name: "TID",
    options: {
      filter: true
    }
  },
  {
    name: "Autorizacao",
    options: {
      filter: true
    }
  },
  {
    name: "Terminal",
    options: {
      filter: true
    }
  },
  {
    name: "Cod. Cliente",
    options: {
      filter: true
    }
  }
];

export const actionsImportacaoTicket = [
  {
    titleAction: "Importar",
    iconAction: <ArrowUp />,
    actionFunction: importarTicket,
    mutation: ImportarTicketDocument
  },
  {
    titleAction: "Importar todos",
    iconAction: <Upload />,
    actionFunction: importarTodosTicket,
    mutation: ImportarTodosTicketsDocument
  }
];
