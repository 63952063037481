import React, { Component } from "react";
import { GetUserInfoProps, withGetUserInfo } from "../../graphql/components";
import Loading from "../Loading/Loading";
import { List, ListItem, ListItemText } from "@material-ui/core";
import { Tooltip, Typography, Button } from "@material-ui/core";
import PapperBlock from "../PapperBlock/PapperBlock";
import { withStyles } from "@material-ui/core/styles";

interface Props {
  classes?: any;
}

interface IState {
  errors?: boolean;
  loading?: boolean;
  data: any;
  arrowRef: any;
}

const styles = (theme: any) => ({
  lightTooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.93)",
    boxShadow: theme.shadows[1],
    fontSize: 11
  }
});

export class DadosUsuario extends Component<GetUserInfoProps<Props>, IState> {
  userInfo = (fullName: String) => {
    return (
      <PapperBlock title="Informações do usuário" icon="ios-trending-up">
        <List>
          <ListItem alignItems="flex-start">
            <ListItemText
              primary="Nome Completo"
              secondary={
                <React.Fragment>
                  <Typography component="span" color="textPrimary">
                    {fullName}
                  </Typography>
                </React.Fragment>
              }
            />
          </ListItem>
        </List>
      </PapperBlock>
    );
  };

  render() {
    if (this.props.data!.loading) {
      return <Loading />;
    } else if (this.props.data && this.props.data.getUserInfo) {
      return (
        <React.Fragment>
          <Tooltip
            classes={{ tooltip: this.props.classes.lightTooltip }}
            title={this.userInfo(this.props.data!.getUserInfo!.fullName!)}
          >
            <Button size="small" style={{ padding: 0 }}>
              <div style={{ margin: 0, textTransform: "capitalize", color: "#fff", width: "100%" }}>
                {this.props.data!.getUserInfo!.nomeAbreviado!.toString().toLowerCase()}
              </div>
            </Button>
          </Tooltip>
        </React.Fragment>
      );
    } else {
      return null;
    }
  }
}

const withGetUserInfoMutation = withGetUserInfo({});

const DadosUsuarioEnhanced = withGetUserInfoMutation(DadosUsuario);

export default withStyles(styles as any)(DadosUsuarioEnhanced);
