import React from "react";
import { withStyles, Theme } from "@material-ui/core/styles";
import MUIDataTable from "mui-datatables";
import ColumnCrudTableType from "./columnCrudTableType";
import * as _ from "lodash";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { withTheme } from "@material-ui/core/styles";
import getUserAgent, { UserAgentType } from "../../utils/userAgentUtil";

const textOptions = {
  textLabels: {
    body: {
      noMatch: "Nenhum resultado encontrado",
      toolTip: "Ordenar",
    },
    pagination: {
      next: "Próxima pagina",
      previous: "Página anterior",
      rowsPerPage: "Linhas por página:",
      displayRows: "de",
    },
    toolbar: {
      search: "Procurar",
      downloadCsv: "Download CSV",
      print: "Imprimir",
      viewColumns: "Exibir Colunas",
      filterTable: "Filtrar",
    },
    filter: {
      all: "TODOS",
      title: "FILTROS",
      reset: "RESET",
    },
    viewColumns: {
      title: "Exibir Colunas",
      titleAria: "Exibir/Esconder Colunas",
    },
    selectedRows: {
      text: "linha(s) selecionadas",
      delete: "Deletar",
      deleteAria: "Deletar linhas selecionadas",
    },
  },
};

export interface RowType {
  index: number;
  dataIndex: number;
}

export interface RowDeleted {
  data: Array<RowType>;
  lookup: { [key: number]: boolean };
}

interface Props {
  classes?: any;
  data: (object | string[] | number[])[];
  columns: Array<ColumnCrudTableType>;
  titulo: string;
  onRowsSelect?: (currentRowsSelected: Array<RowType>, allRowsSelected: Array<RowType>) => void;
  options?: {
    filterType?: "dropdown" | "checkbox" | "multiselect" | "textField";
    responsive?: "stacked" | "scrollMaxHeight" | "scrollFullHeight" | undefined;
    print?: boolean;
    download?: boolean;
    viewColumns?: boolean;
    filter?: boolean;
    searchable?: boolean;
    rowsPerPage?: number;
    page?: number;
    onRowsDelete?: (rowsDeleted: Array<any>) => void;
    selectableRows?: "none" | "multiple" | "single" | undefined;
    isRowSelectable?: (dataIndex: number) => boolean;
  };
  themeTable?: Object;
  theme: Theme;
}

type DefaultProps = {
  options: {
    filterType: "dropdown" | "checkbox" | "multiselect" | "textField";
    responsive: "stacked" | "scrollMaxHeight" | "scrollFullHeight" | undefined;
    print: boolean;
    download: boolean;
    viewColumns?: boolean;
    filter: boolean;
    rowsPerPage: number;
    page: number;
    selectableRows: "none" | "multiple" | "single" | undefined;
    isRowSelectable?: (dataIndex: number) => boolean;
  };
};

const styles = (theme: any) => ({
  table: {
    "& > div": {
      overflow: "auto",
    },
    "& table": {
      minWidth: 500,
      [theme.breakpoints.down("md")]: {
        "& td": {
          height: 40,
        },
      },
    },
  },
});

interface State {
  selected: RowType | null;
}

class Table extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      selected: null,
    };
  }
  static defaultProps: DefaultProps = {
    options: {
      filterType: "dropdown",
      responsive: "stacked",
      print: false,
      download: false,
      viewColumns: false,
      filter: false,
      rowsPerPage: 10,
      page: 0,
      selectableRows: "multiple",
      isRowSelectable: (dataIndex: number) => true,
    },
  };

  shouldComponentUpdate(props: Props) {
    let retorno = true;
    if (this.props.data.length === 0 || props.data.length === 0) {
      return true;
    }
    if (this.props.data.length === props.data.length) {
      if (_.isEqual(this.props.data, props.data)) {
        return false;
      }
    }

    return retorno;
  }

  render() {
    const { columns, data, titulo, options, onRowsSelect, theme, themeTable = {} } = this.props;
    const padding = getUserAgent() === UserAgentType.BROSER ? "4px 2px 4px 2px" : "4px 2px 4px 10px";
    (theme.overrides as any)["MuiTableCell"] = {
      root: { padding },
      body: {
        fontSize: 11,
        overflow: "overlay",
      },
      ...themeTable,
    };
    (theme.overrides as any)["MuiTable"] = {
      root: {
        marginBottom: 0,
      },
    };
    (theme.overrides as any)["MUIDataTableHeadCell"] = {
      data: {
        fontSize: 12,
      },
    };
    (theme.overrides as any)["MUIDataTableBodyCell"] = {
      cellStackedSmall: {
        fontWeight: "bold",
      },
    };
    if (getUserAgent() !== UserAgentType.BROSER) {
      options!["selectableRows"] = "none";
    }
    return (
      <MuiThemeProvider theme={theme}>
        <MUIDataTable
          title={titulo}
          data={data}
          columns={columns}
          options={{
            ...options,
            ...textOptions,
            onRowsSelect,
            onRowClick: (rowData: string[], rowMeta: { dataIndex: number; rowIndex: number }) => {
              if (getUserAgent() !== UserAgentType.BROSER && onRowsSelect) {
                if (!this.state.selected) {
                  this.setState({ selected: { dataIndex: rowMeta.dataIndex, index: rowMeta.rowIndex } }, () => {
                    onRowsSelect(
                      [{ dataIndex: rowMeta.dataIndex, index: rowMeta.rowIndex }],
                      [{ dataIndex: rowMeta.dataIndex, index: rowMeta.rowIndex }]
                    );
                  });
                } else {
                  this.setState({ selected: null }, () => {
                    onRowsSelect([], []);
                  });
                }
              }
              return undefined;
            },
            customToolbarSelect: (selectedRows, displayData, setSelectedRows) => null,
          }}
        />
      </MuiThemeProvider>
    );
  }
}

const TableCrud = withStyles(styles as any)(Table);

export default withTheme(TableCrud);
