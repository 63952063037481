import AppBar from "@material-ui/core/AppBar";
import Fab from "@material-ui/core/Fab";
import Hidden from "@material-ui/core/Hidden";
import { withStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import MenuIcon from "@material-ui/icons/Menu";
import classNames from "classnames";
import * as React from "react";
import { SyntheticEvent } from "react";
import DadosUsuario from "./DadosUsuario";
import styles from "./header-jss";
import "../../styles/custom/LogoSistema.css";
import UserMenu from "./UserMenu";
import IsVisSPCContext from "../../componentes/CRUD/isVisContext";
import LogoConcilia from "../LogoConcilia";
import logoSpc from "../../styles/images/concilia-solucoes/spc.png";

const elem: any = window.document.documentElement;
const document: any = window.document;

interface Props {
  classes: any;
  toggleDrawerOpen: (event: React.MouseEvent<HTMLElement, MouseEvent> | null | SyntheticEvent<{}, Event>) => void;
  margin: boolean;
  turnDarker: boolean;
  showTitle: boolean;
  gradient: boolean;
  position: string;
  mode?: string;
  title: string;
  changeMode?: any;
  openGuide?: any;
  history: any;
  isComplete: boolean;
}

interface IState {
  open: boolean;
  fullScreen: boolean;
  turnDarker: boolean;
  showTitle: boolean;
}

class Header extends React.Component<Props, IState> {
  state = {
    open: false,
    fullScreen: false,
    turnDarker: false,
    showTitle: false,
  };

  flagDarker = false;

  flagTitle = false;

  componentDidMount = () => {
    window.addEventListener("scroll", this.handleScroll);
  };

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = () => {
    const doc = document.documentElement;
    const scroll = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    const newFlagDarker = scroll > 30;
    const newFlagTitle = scroll > 40;
    if (this.flagDarker !== newFlagDarker) {
      this.setState({ turnDarker: newFlagDarker });
      this.flagDarker = newFlagDarker;
    }
    if (this.flagTitle !== newFlagTitle) {
      this.setState({ showTitle: newFlagTitle });
      this.flagTitle = newFlagTitle;
    }
  };

  openFullScreen = () => {
    this.setState({ fullScreen: true });
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.mozRequestFullScreen) {
      /* Firefox */
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) {
      /* Chrome, Safari & Opera */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      /* IE/Edge */
      elem.msRequestFullscreen();
    }
  };

  closeFullScreen = () => {
    this.setState({ fullScreen: false });
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    }
  };

  render() {
    const { classes, toggleDrawerOpen, margin, position, title, isComplete } = this.props;
    const { open, turnDarker, showTitle } = this.state;
    const setMargin = (sidebarPosition: any) => {
      if (sidebarPosition === "right-sidebar") {
        return classes.right;
      }
      return classes.left;
    };

    return (
      <AppBar
        className={classNames(
          classes.appBar,
          classes.floatingBar,
          margin && classes.appBarShift,
          setMargin(position),
          turnDarker && classes.darker,
          classes.gradientBg
        )}
      >
        <Toolbar disableGutters={!open}>
          {isComplete && (
            <IsVisSPCContext.Consumer>
              {(value) => {
                if (value && value.isVisSPC === "vis") {
                  return null;
                }
                return (
                  <Fab size="small" className={classes.menuButton} aria-label="Menu" onClick={toggleDrawerOpen}>
                    <MenuIcon />
                  </Fab>
                );
              }}
            </IsVisSPCContext.Consumer>
          )}
          <Hidden smDown>
            <div className={classes.headerProperties}>
              <Typography component="h2" className={classNames(classes.headerTitle, showTitle && classes.show)}>
                {title}
              </Typography>
            </div>
          </Hidden>

          <Hidden xsDown>
            <IsVisSPCContext.Consumer>
              {(value) => {
                if (value && value.isVisSPC === "vis") {
                  return null;
                }
                return (
                  <>
                    {value && value.isVisSPC === "spc" && (
                      <img
                        className="toplogo_sistema"
                        style={{ marginRight: 20 }}
                        src={logoSpc}
                        alt="Logo"
                        //style={right ? { float: "right", marginRight: 15 } : undefined}
                      />
                    )}
                    <DadosUsuario />
                  </>
                );
              }}
            </IsVisSPCContext.Consumer>
          </Hidden>
          <Hidden xsDown>
            <span className={classes.separatorV} />
          </Hidden>

          {/* {isComplete && (
            <Query<any, any> query={ListarItensConciliadoresDocument} errorPolicy="all">
              {({ data }) => {
                return <div> </div>;
              }}
            </Query>
          )} */}

          <LogoConcilia classe="toplogo_sistema" />

          <Hidden xsDown>
            <span className={classes.separatorV} />
          </Hidden>

          {isComplete && <UserMenu />}
        </Toolbar>
      </AppBar>
    );
  }
}

export default withStyles(styles as any)(Header);
