import { Grid } from "@material-ui/core";
import { Moment } from "moment";
import React, { PureComponent } from "react";
import ConciliaDatePicker from "../../../componentes/ConciliaDatePicker";
import { FilterProps } from "../../../componentes/CRUD/crudProps";
import { SelectCombo } from "../../../componentes/Select";
import { ListarItensTerminalVinculoDocument } from "../../../graphql/components";
import { variablesMovimentosFechadosVenda as variablesMovimentosFechados } from "./utilsMovimentosFechados";

type CustomState = {};

type State = typeof variablesMovimentosFechados & CustomState;

export default class FilterMovimentosFechados extends PureComponent<FilterProps, State> {
  constructor(props: FilterProps) {
    super(props);
    this.state = {
      ...variablesMovimentosFechados
    };
  }

  onChangeDe = (date: Moment) => {
    if (date) {
      this.setState({ datavendade: date.toDate() }, () => {
        this.props.variables.datavendade = date.toDate();
      });
    } else {
      this.setState({ datavendade: null }, () => {
        this.props.variables.datavendade = null;
      });
    }
  };

  onChangeAte = (date: Moment) => {
    if (date) {
      this.setState({ datavendaate: date.toDate() }, () => {
        this.props.variables.datavendaate = date.toDate();
      });
    } else {
      this.setState({ datavendaate: null }, () => {
        this.props.variables.datavendaate = null;
      });
    }
  };

  render() {
    return (
      <Grid container spacing={8} style={{ marginBottom: 30 }}>
        <Grid item xs={12} md={6}>
          <ConciliaDatePicker
            label="Venda De"
            name="datavendade"
            value={this.state.datavendade}
            onChange={this.onChangeDe}
            disabled={this.props.isFiltering}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <ConciliaDatePicker
            label="Venda Até"
            name="datavendaate"
            value={this.state.datavendaate}
            onChange={this.onChangeAte}
            disabled={this.props.isFiltering}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <SelectCombo
            label="Grupo Terminal"
            name="terminais"
            nameQuery="listarItensTerminalVinculo"
            query={ListarItensTerminalVinculoDocument}
            value={this.state.terminais ? this.state.terminais : ""}
            disabled={this.props.isFiltering}
            handleChange={e => {
              const valor = e.currentTarget.value as string;
              this.setState({ terminais: valor }, () => {
                this.props.variables.terminais = this.state.terminais;
                this.props.variablesValorExibicao.terminais = valor;
              });
            }}
          />
        </Grid>
      </Grid>
    );
  }
}
