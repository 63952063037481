import { Chip, FormControlLabel, Grid } from "@material-ui/core";
import green from "@material-ui/core/colors/green";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import { Theme, withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React, { PureComponent } from "react";
import { SelectCombo } from "../../../componentes/Select";
import { ListarOperadorasDocument } from "../../../graphql/components";

const styles = (theme: Theme) => ({
  demo: {
    height: "auto",
  },
  divider: {
    margin: `${theme.spacing(3)}px 0`,
  },
  field: {
    margin: `${theme.spacing(3)}px 5px`,
  },
  root: {
    color: green[600],
    "&$checked": {
      color: green[500],
    },
  },
  formControl: {
    margin: theme.spacing(3),
  },
  group: {
    margin: `0px 0`,
  },
  checked: {},
  size: {
    width: 40,
    height: 40,
  },
  sizeIcon: {
    fontSize: 20,
  },
  label: {
    width: "100%",
  },
});

interface IProps {
  changeValue: (event: number | null) => void;
  //changeValueStatus: ((event: React.ChangeEvent<{}>, value: string) => void) | undefined;
  changeValueStatus: (value: string) => void;
  classes?: any;
}

interface IState {
  values: {
    codoperadora: number | null;
    statusFiltro: string | undefined;
  };
}

class Status extends PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      values: {
        codoperadora: null,
        statusFiltro: "q",
      },
    };
  }

  alteraStatus = (event: React.ChangeEvent<{}>, value: string) => {
    this.setState({ values: { ...this.state.values, statusFiltro: value } });
    this.props.changeValueStatus(value);
  };

  descricao = (texto: string, status: string) => {
    return (
      <>
        <Chip className={status} />
        {texto}
      </>
    );
  };

  render() {
    const { classes, changeValue } = this.props;
    const {
      values: { statusFiltro },
    } = this.state;

    return (
      <React.Fragment>
        <Grid container alignItems="flex-start" direction="row" spacing={2}>
          <Grid item xs={12} md={12}>
            <SelectCombo
              label="Operadora"
              name="operadora"
              nameQuery="listarOperadoras"
              query={ListarOperadorasDocument}
              value={this.state.values.codoperadora ? this.state.values.codoperadora : undefined}
              handleChange={(e) => {
                // const valor = e.currentTarget.value;
                const value: number | null = e.target.value ? parseInt(e.target.value as string) : null;
                this.setState({ values: { ...this.state.values, codoperadora: value } }, () => {
                  // this.state.values.codoperadora = this.state.values.codoperadora;
                });
                changeValue(value);
              }}
            />
          </Grid>

          <Grid item xs={12} md={12}>
            <RadioGroup aria-label="status" name="status" value={statusFiltro} onChange={this.alteraStatus}>
              <Typography variant="subtitle2" color="inherit" className={classes.primeiroGrupo}>
                Todos os status
              </Typography>

              <FormControlLabel
                value="q"
                control={<Radio color="primary" />}
                className={classes.label}
                label={this.descricao("Todos os status", "q")}
              />

              <Typography variant="subtitle2" color="inherit" className={classes.demaisGrupos}>
                Cadastro do Cliente
              </Typography>

              <FormControlLabel
                value="AGUARDANDO_LIBERACAO_OPERADORA"
                control={<Radio color="primary" />}
                className={classes.label}
                label={this.descricao("Aguardando definição da(s) Operadora(s)", "AGUARDANDO_LIBERACAO_OPERADORA")}
              />
              <FormControlLabel
                value="AGUARDANDO_SENHA"
                control={<Radio color="primary" />}
                className={classes.label}
                label={this.descricao("Aguardando Senha", "AGUARDANDO_SENHA")}
              />
              <FormControlLabel
                value="DOCUMENTO_REJEITADO"
                control={<Radio />}
                className={classes.label}
                label={this.descricao("Documento Rejeitado", "DOCUMENTO_REJEITADO")}
              />
              <FormControlLabel
                value="AGUARDANDO_DOCUMENTO"
                control={<Radio />}
                className={classes.label}
                label={this.descricao("Aguardando Documentos", "AGUARDANDO_DOCUMENTO")}
              />
              <FormControlLabel
                value="AGUARDANDO_EC"
                control={<Radio />}
                className={classes.label}
                label={this.descricao("Aguardando Ec", "AGUARDANDO_EC")}
              />
              <FormControlLabel
                value="T"
                control={<Radio />}
                className={classes.label}
                label={this.descricao("Aguardando Taxas", "T")}
              />
              <FormControlLabel
                value="P"
                control={<Radio />}
                className={classes.label}
                label={this.descricao("Verificação Prévia dos Documentos", "P")}
              />
              <FormControlLabel
                value="NN"
                control={<Radio />}
                className={classes.label}
                label={this.descricao("Unidades Não Cadastradas", "NN")}
              />

              <Typography variant="subtitle2" color="inherit" className={classes.demaisGrupos}>
                Operadora
              </Typography>

              <FormControlLabel
                value="Z"
                control={<Radio color="primary" />}
                className={classes.label}
                label={this.descricao("Extrato VAN em Atraso", "Z")}
              />
              <FormControlLabel
                value="A"
                control={<Radio color="primary" />}
                className={classes.label}
                label={this.descricao("Aguardando Aprovação da Operadora", "A")}
              />
              <FormControlLabel
                value="F"
                control={<Radio color="primary" />}
                className={classes.label}
                label={this.descricao("Recebendo Extrato", "F")}
              />

              <Typography variant="subtitle2" color="inherit" className={classes.demaisGrupos}>
                Outros
              </Typography>

              <FormControlLabel
                value="SUSPENSO"
                control={<Radio color="primary" />}
                className={classes.label}
                label={this.descricao("Suspenso", "SUSPENSO")}
              />
              <FormControlLabel
                value="CANCELADO"
                control={<Radio color="primary" />}
                className={classes.label}
                label={this.descricao("Cancelado Conciliador", "CANCELADO")}
              />
              <FormControlLabel
                value="C"
                control={<Radio color="primary" />}
                className={classes.label}
                label={this.descricao("Cancelado EC", "C")}
              />
            </RadioGroup>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(Status);
