import { Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import ApolloClient from "apollo-client";
import React from "react";
import { PlanosTaxasDao } from "../../../../graphql/components";

interface Props {
  classes?: any;
  data: Array<PlanosTaxasDao>;
  subTaxas: (client: ApolloClient<any>, codigo: number | undefined) => void;
  client: ApolloClient<any>;
}

const TabelaTaxasPorParcela = ({ classes, data, subTaxas, client }: Props) => {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell align="right">Parcela Inicial</TableCell>
          <TableCell align="right">Parcela Final</TableCell>
          <TableCell align="right">Taxas em %</TableCell>
          <TableCell align="center">Ações</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map(row => (
          <TableRow key={row.codplanostaxas}>
            <TableCell align="right">{row.de}</TableCell>
            <TableCell align="right">{row.ate}</TableCell>
            <TableCell align="right">{row.taxa}</TableCell>
            <TableCell align="center">
              <Tooltip title="Deletar" placement="top" onClick={() => subTaxas(client, row.codplanostaxas)}>
                <DeleteIcon style={{ color: "gray", fontSize: 15, cursor: "pointer" }} />
              </Tooltip>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default TabelaTaxasPorParcela;
