
import Formulario from '../../model/formulario';
export const ESCOLHA_FORM_ACTIONS_TYPE = {
  ESCOLHA_FORM: "ESCOLHA_FORM"
};

export const selectFormulario = (formulario: Formulario) => ({
  type: ESCOLHA_FORM_ACTIONS_TYPE.ESCOLHA_FORM,
  payload: formulario
});
