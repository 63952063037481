import React from "react";
import { FullScreenDrawerProps } from "../../../componentes/CRUD/crudProps";
import SelectedRowsContext from "../../../componentes/CRUD/selectedRowsContext";
import Loading from "../../../componentes/Loading/Loading";
import { DetalharTicketComponent } from "../../../graphql/components";
import Administradora from "./tabelasDetalhe/administradora";
import ClientesOLX from "./tabelasDetalhe/clienteOLX";
import Clientes from "./tabelasDetalhe/clientes";
import withPanelTable from "./tabelasDetalhe/panelTableHOC";

class DetalharTicket extends React.PureComponent<FullScreenDrawerProps> {
  static contextType = SelectedRowsContext;

  render() {
    const ticket = this.context[0];
    return (
      <DetalharTicketComponent
        variables={{
          values: {
            tid: ticket.tid || undefined,
            nsu: ticket.nsu || undefined,
            autorizacao: ticket.autorizacao || undefined,
            datavenda: ticket.datavenda || undefined,
            nossonumero: ticket.codexterno || undefined,
            tipo: ticket.tipo || undefined,
            codexterno: ticket.codexterno || undefined,
            codoperadora: ticket.codoperadora || undefined,
            codpessoa: ticket.codpessoa || undefined
          }
        }}
      >
        {({ data, loading }) => {
          if (loading) {
            return <Loading />;
          }
          if (data && data.detalharTicket) {
            const AdministradoraPagamento = withPanelTable(
              Administradora,
              "Administradora Pagamento",
              data.detalharTicket.operadoraLinha
            );
            const AdministradoraLiquidacao = withPanelTable(
              Administradora,
              "Administradora Liquidação",
              data.detalharTicket.operadoraLiquidacaoLinha
            );
            const AdministradoraVenda = withPanelTable(
              Administradora,
              "Administradora Venda",
              data.detalharTicket.operadoraVendaLinha
            );
            const AdministradoraPrevisao = withPanelTable(
              Administradora,
              "Administradora Previsão",
              data.detalharTicket.operadoraPrevisaoLinha
            );
            const Antecipado = withPanelTable(Administradora, "Antecipado", data.detalharTicket.antecipadoLinha);

            const Cliente = withPanelTable(Clientes, "Cliente", data.detalharTicket.clientesLinha);
            const ClienteIntegrador = withPanelTable(Clientes, "Cliente Integrador", data.detalharTicket.clientesUpdateLinha);
            const ClienteDesconsideradas = withPanelTable(
              Clientes,
              "Cliente Desconsideradas",
              data.detalharTicket.clientesLinhaDesconsiderados
            );
            const ClienteCanceladas = withPanelTable(Clientes, "Cliente Canceladas", data.detalharTicket.clientesLinhaCancelados);
            const ClienteOLX = withPanelTable(ClientesOLX, "Cliente OLX", data.detalharTicket.clientesOlxLinha);
            return (
              <>
                <Cliente />
                <ClienteIntegrador />
                <ClienteDesconsideradas />
                <ClienteCanceladas />
                <ClienteOLX />
                <AdministradoraPagamento />
                <AdministradoraLiquidacao />
                <AdministradoraVenda />
                <AdministradoraPrevisao />
                <Antecipado />
              </>
            );
          }
          return null;
        }}
      </DetalharTicketComponent>
    );
  }
}

export default DetalharTicket;
