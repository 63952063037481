import ColumnCrudTableType from "../../../componentes/CRUD/columnCrudTableType";

export const variablesDadosNFe: { codpessoa: number | null } = {
  codpessoa: null
};

export const variablesDadosNFeExibicao: {
  codpessoa: number | null;
} = {
  codpessoa: null
};

export const mapVariablesDadosNFe = {
  codpessoa: "Empresa"
};

export const variablesDadosNFeInitial = JSON.parse(JSON.stringify(variablesDadosNFe));

export const columnsOrderDadosNFe = ["nome"];

export const colunasDadosNFe: Array<ColumnCrudTableType> = [
  {
    name: "Empresa",
    options: {
      filter: true
    }
  }
];
