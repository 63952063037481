import { DataProxy } from "apollo-cache";
import React from "react";
import { forkJoin } from "rxjs";
import { CallbackActionType } from "../../../../componentes/CRUD/actionsTable";
import ColumnCrudTableType from "../../../../componentes/CRUD/columnCrudTableType";
import {
  DeletePlanosConciliadoresTaxasMutation,
  DeletePlanosConciliadoresTaxasMutationVariables,
  ListarPlanosConciliadoresTaxasDocument,
  ListarPlanosConciliadoresTaxasQuery,
} from "../../../../graphql/components";

export const variablesPlanosConciliadoresTaxas: {
  nome: string | null;
  vigenciade: Date | null;
  vigenciaate: Date | null;
  taxa: number | null;
} = {
  nome: null,
  vigenciade: null,
  vigenciaate: null,
  taxa: null,
};

export const mapVariablesFiltroPlanosConciliadoresTaxas = {
  nome: "Nome",
  vigenciade: "De",
  vigenciaate: "Até",
  taxa: "Taxa",
};

export const deleteMutationPlanosConciliadoresTaxas: CallbackActionType<
  DeletePlanosConciliadoresTaxasMutation,
  DeletePlanosConciliadoresTaxasMutationVariables
> = (mutation, record, setMessage) => {
  if (mutation) {
    const deletesPromises: Array<any> = [];
    record.forEach((r) => {
      deletesPromises.push(
        mutation({
          variables: {
            values: { editValues: { codplanosconciliadorestaxas: r.codplanosconciliadores, codprodutos: r.codprodutos } },
          },
          update: (store: DataProxy, { data }) => {
            if (data!.deletePlanosConciliadoresTaxas) {
              const oldResult = store.readQuery<ListarPlanosConciliadoresTaxasQuery>({
                query: ListarPlanosConciliadoresTaxasDocument,
                variables: { values: { codplanosconciliadores: r.codplanosconciliadores } },
              });
              if (oldResult) {
                const index = oldResult.listarPlanosConciliadoresTaxas.findIndex(
                  (u) => u.codplanosconciliadores === r.codplanosconciliadores && u.codprodutos === r.codprodutos
                );
                oldResult.listarPlanosConciliadoresTaxas.splice(index, 1);
                const result = [...oldResult.listarPlanosConciliadoresTaxas];
                store.writeQuery({
                  query: ListarPlanosConciliadoresTaxasDocument,
                  data: result,
                  variables: { values: { codplanosconciliadores: r.codplanosconciliadores } },
                });
              }
            }
          },
        }).catch((error) => {
          return new Promise((resolve) => resolve(error.message));
        })
      );
    });
    forkJoin(deletesPromises).subscribe(
      (response) => {
        const totalErrors = response.filter((r) => typeof r === "string").length;
        if (totalErrors > 0 && totalErrors !== deletesPromises.length) {
          setMessage(
            true,
            "warning",
            "Alguns registros não foram excluídos. Dentro de 5 segundos iremos atualizar a página para manter os dados coerentes."
          );
          setTimeout(() => window.location.reload(), 6500);
        } else if (totalErrors === 0) {
          setMessage(true, "success", "Registro(s) excluídos com sucesso!");
        }
        return true;
      },
      (errors) => {
        console.log(errors);
      }
    );
  }
};

export const colunasPlanosTaxas: Array<ColumnCrudTableType> = [
  {
    name: "Plano",
    options: {
      filter: true,
    },
  },
  {
    name: "Op.",
    options: {
      filter: true,
    },
  },
  {
    name: "Produto",
    options: {
      filter: true,
    },
  },

  {
    name: "Tipo",
    options: {
      filter: true,
    },
  },
  {
    name: "BIN Cart.",
    options: {
      filter: true,
    },
  },

  {
    name: "Taxas",
    options: {
      filter: true,
      customBodyRender: (value: any) => <span dangerouslySetInnerHTML={{ __html: value }} />,
    },
  },
];

export const columnsOrderPlanosTaxas = [
  "plano",
  "nomeOperadora",
  "converterProduto",
  "converterProdutoTipo",
  "bincartao",
  "converterProdutoTaxas",
];
