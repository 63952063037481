import React from "react";
import { FieldProps, getIn } from "formik";
import { FormControl, InputLabel, FormHelperText, Input } from "@material-ui/core";
import NumberFormat from "react-number-format";
import { InputBaseProps } from "@material-ui/core/InputBase";

interface TextInputProps extends FieldProps {
  label: string;
  mask: (string | RegExp)[];
  id: string;
  disabled?: boolean;
  showPrefix?: boolean;
  isTax?: boolean;
}

interface MaskedProps extends InputBaseProps {
  mask: Array<string>;
}

const Masked = React.memo((prop: any) => {
  const { inputRef, mask, showPrefix, isTax, ...other } = prop;

  let thousandSeparator: string | undefined = ".";
  let decimalSeparator = ",";
  let prefix = showPrefix;
  if (isTax) {
    thousandSeparator = undefined;
    decimalSeparator = ".";
    prefix = false;
  }
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      decimalScale={2}
      thousandSeparator={thousandSeparator}
      decimalSeparator={decimalSeparator}
      prefix={prefix ? "R$ " : undefined}
      isNumericString
      mask=" "
    />
  );
});

const input = (props: TextInputProps) => {
  const fieldError = getIn(props.form.errors, props.field.name);
  const showError = getIn(props.form.touched, props.field.name) && !!fieldError;
  return (
    <FormControl error={showError} aria-describedby="component-error-text" fullWidth>
      <InputLabel htmlFor={props.id} shrink>
        {props.label}
      </InputLabel>
      <Input
        id={props.id}
        {...props.field}
        autoComplete="new-password"
        inputComponent={Masked}
        inputProps={{ mask: props.mask, showPrefix: props.showPrefix, isTax: props.isTax }}
        disabled={props.disabled}
      />
      {showError && <FormHelperText id={`${props.id}-error`}>{fieldError}</FormHelperText>}
    </FormControl>
  );
};

const FormikMaskedCurrency = React.memo(input);

export default FormikMaskedCurrency;
