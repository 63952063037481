import * as React from "react";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import { NavLink } from "react-router-dom";
import MainMenu from "./MainMenu";
import styles from "./sidebar-jss";
import logo from "../../styles/images/concilia-solucoes/concilia_logo_azul_claro.png";
import "../../styles/custom/SidebarContent.css";
import IsVisSPCContext from "../CRUD/isVisContext";

interface Props {
  classes: any;
  turnDarker?: boolean;
  drawerPaper?: boolean;
  leftSidebar?: boolean;
  loadTransition?: (isLoaded: boolean) => void;
  status: String;
  anchorEl: HTMLElement | ((element: HTMLElement) => HTMLElement) | null | undefined;
  openMenuStatus: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  closeMenuStatus: (event: React.SyntheticEvent<{}, Event>) => void;
  changeStatus: (status: String) => void;
  isLogin?: boolean;
}

interface State {
  transform: number;
  isLogin: boolean;
}

class SidebarContent extends React.Component<Props, State> {
  state = {
    transform: 0,
    nome: "Marcelo",
    isLogin: true,
  };

  componentDidMount = () => {
    // Scroll content to top
    const mainContent = document.getElementById("sidebar");
    mainContent!.addEventListener("scroll", this.handleScroll);
  };

  componentWillUnmount() {
    const mainContent = document.getElementById("sidebar");
    mainContent!.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = (event: any) => {
    const scroll = event.target!.scrollTop;
    this.setState({
      transform: scroll,
    });
  };

  render() {
    const { classes, turnDarker, drawerPaper, leftSidebar } = this.props;
    const { isLogin } = this.state;

    return (
      <div className={classNames(classes.drawerInner, !drawerPaper ? classes.drawerPaperClose : "")} id="drawerInner">
        <div className={drawerPaper ? classes.drawerHeaderOpen : classes.drawerHeader}>
          <NavLink to="/app" className={classNames(classes.brand, classes.brandBar, turnDarker && classes.darker)}>
            <img className="topbar_logo" src={logo} alt="Logo" />
          </NavLink>
        </div>
        <div
          id="sidebar"
          className={classNames(classes.menuContainer, leftSidebar && classes.rounded, isLogin && classes.withProfile)}
        >
          <IsVisSPCContext.Consumer>
            {(value) => {
              if (value && value.isVisSPC === "vis") {
                return null;
              }
              return <MainMenu />;
            }}
          </IsVisSPCContext.Consumer>
        </div>
      </div>
    );
  }
}

export default withStyles(styles as any)(SidebarContent);
