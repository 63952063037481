import { Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import Refresh from "@material-ui/icons/Refresh";
import ApolloClient from "apollo-client";
import React from "react";
import { PlanosConciliadoresTaxasDao } from "../../../../graphql/components";
import voca from "voca";

interface Props {
  classes?: any;
  data: Array<PlanosConciliadoresTaxasDao>;
  atualizaTaxas: (client: ApolloClient<any>, codigo: number | undefined) => void;
  subTaxas: (client: ApolloClient<any>, codigo: number | undefined) => void;
  client: ApolloClient<any>;
}

const TabelaTaxas = ({ classes, data, atualizaTaxas, subTaxas, client }: Props) => {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell align="right">Valor Inicial</TableCell>
          <TableCell align="right">Valor Final</TableCell>
          <TableCell align="right">Taxas em %</TableCell>
          <TableCell align="center">Ações</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map(row => (
          <TableRow key={row.codplanosconciliadorestaxas}>
            <TableCell align="right">{voca.replaceAll(voca.sprintf('%.2f', row.valorde), ".", ",")}</TableCell>
            <TableCell align="right">{voca.replaceAll(voca.sprintf('%.2f', row.valorate), ".", ",")}</TableCell>
            <TableCell align="right">{voca.replaceAll(voca.sprintf('%.2f', row.taxa), ".", ",")}</TableCell>
            <TableCell align="center">
              <Tooltip title="Atualizar" placement="top" onClick={() => atualizaTaxas(client, row.codplanosconciliadorestaxas)}>
                <Refresh style={{ color: "gray", fontSize: 15, cursor: "pointer", marginRight: 15 }} />
              </Tooltip>

              <Tooltip title="Deletar" placement="top" onClick={() => subTaxas(client, row.codplanosconciliadorestaxas)}>
                <DeleteIcon style={{ color: "gray", fontSize: 15, cursor: "pointer" }} />
              </Tooltip>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default TabelaTaxas;
