import React, { useContext } from "react";
import IsVisSPCContext from "../CRUD/isVisContext";
import logoMulti from "../../styles/images/concilia-solucoes/conciliador/conciliador_multi.png";
import logoVis from "../../styles/images/concilia-solucoes/conciliador/conciliador_vis.png";

const LogoConcilia: React.FC<{ classe: string; right?: boolean }> = ({ classe, right = false }) => {
  const isVis = useContext(IsVisSPCContext);
  let logo = logoMulti;
  if (isVis?.isVisSPC === "vis") {
    logo = logoVis;
  }
  return (
    <img
      className={classe}
      src={logo}
      title={`${process.env.REACT_APP_VERSION}`}
      alt="Logo"
      style={right ? { float: "right", marginRight: 15 } : undefined}
    />
  );
};

export default LogoConcilia;
