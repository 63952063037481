import MomentUtils from "@date-io/moment";
import { FormControl, FormHelperText } from "@material-ui/core";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { FormikErrors, FormikTouched } from "formik";
import locale from "moment/locale/pt-br";
import React from "react";
import { ParsableDate } from "@material-ui/pickers/constants/prop-types";

const styles = {
  picker: { minWidth: "325px" },
  formControl: {
    width: "100%"
  }
};

interface Props {
  value: Date | null;
  label: string;
  name: string;
  error?: FormikErrors<Date> | undefined;
  touched?: FormikTouched<Date> | undefined;
  onChange: (date: any, value?: string | null | undefined) => void;
  maxDate?: ParsableDate;
  minDate?: ParsableDate;
  disabled?: boolean;
}

const ConciliaDatePicker: React.FunctionComponent<Props> = ({
  value,
  onChange,
  label,
  name,
  maxDate,
  minDate,
  disabled,
  error,
  touched
}) => {
  return (
    <FormControl style={styles.formControl} error={error != null && touched != null}>
      <MuiPickersUtilsProvider utils={MomentUtils} locale={locale}>
        <KeyboardDatePicker
          maxDateMessage="Data inválida"
          autoOk
          fullWidth
          format="DD/MM/YYYY"
          label={label}
          margin="normal"
          KeyboardButtonProps={{
            "aria-label": "change date"
          }}
          variant="inline"
          name={name}
          id={name}
          invalidDateMessage="Data inválida"
          disableToolbar
          maxDate={maxDate}
          minDate={minDate}
          value={value}
          onChange={onChange}
          placeholder="dd/mm/aaaa"
          disabled={disabled}
        />
      </MuiPickersUtilsProvider>
      {error && touched && <FormHelperText id={`${name}-error`}>{error}</FormHelperText>}
    </FormControl>
  );
};

export default ConciliaDatePicker;
