import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import styles from './appStyles-jss';

interface OuterProps {
  classes: any;
  children: any;
};

class Outer extends React.Component<OuterProps> {
  render() {
    const {
      classes,
      children,
    } = this.props;
    return (
      <div className={classNames(classes.appFrameOuter, classes.gradientBg)}>
        <main className={classes.outerContent} id="mainContent">
          <div className={classes.petal} /> 
          {children}
        </main>
      </div>
    );
  }
}

export default (withStyles(styles as any)(Outer));
