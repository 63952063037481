import React from "react";

interface ListErrorsProps {
  errors: boolean;
}

class ListErrors extends React.Component<ListErrorsProps> {
  render() {
    const errors = this.props.errors;
    if (errors) {
      return (
        <div>
          <ul className="error-messages">
            <li> Usuário / Senha inválidos</li>
          </ul>
        </div>
      );
    } else {
      return null;
    }
  }
}

export default ListErrors;
