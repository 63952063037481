import { Card, Typography, withStyles } from "@material-ui/core";
import classNames from "classnames";
import { FormikActions } from "formik";
import React from "react";
import { useQuery } from "react-apollo";
import { RouteComponentProps } from "react-router";
import Loading from "../../../componentes/Loading/Loading";
import LogoConcilia from "../../../componentes/LogoConcilia";
import PapperBlock from "../../../componentes/PapperBlock/PapperBlock";
import {
  GetInfoUsuarrioTrocarSenhaDocument,
  GetInfoUsuarrioTrocarSenhaQuery,
  GetInfoUsuarrioTrocarSenhaQueryVariables,
  withUsuarioTrocarSenha,
  UsuarioTrocarSenhaProps
} from "../../../graphql/components";
import Routes from "../../../utils/routes";
import styles from "../../Templates/appStyles-jss";
import Decoration from "../../Templates/Decoration";
import NovaSenha, { FormValuesNovaSenha } from "./novaSenha";
import DefinirSenha, { FormValuesDefinirSenha } from "./definirSenha";
import SnackMessage from "../../../componentes/SnakBar";

interface Props extends RouteComponentProps {
  classes: any;
}

const ResetarSenha: React.FC<UsuarioTrocarSenhaProps<Props>> = ({ classes, location, history, mutate }) => {
  const [idTrocaSenha, setIdTrocarSenha] = React.useState("");
  const [usuarioGravado, setUsuarioGravado] = React.useState(false);
  const [snack, setSnack] = React.useState<{
    message: string;
    messageVariant: "success" | "warning" | "info" | "error";
    messageOpen: boolean;
  }>({
    message: "",
    messageVariant: "success",
    messageOpen: false
  });
  const id = new URLSearchParams(location.search).get("idTrocaSenha") || "";
  const { loading, data } = useQuery<GetInfoUsuarrioTrocarSenhaQuery, GetInfoUsuarrioTrocarSenhaQueryVariables>(
    GetInfoUsuarrioTrocarSenhaDocument,
    {
      variables: { param: id }
    }
  );

  React.useEffect(() => {
    setIdTrocarSenha(id);
    if (!loading && data?.getInfoUsuarrioTrocarSenha.goLogin) {
      history.replace(Routes.login.route);
    }
    if (!loading && !data) {
      history.replace(Routes.login.route);
    }
  }, [loading, data, history, idTrocaSenha, id]);

  const submit = async (values: FormValuesNovaSenha, actions: FormikActions<FormValuesNovaSenha>) => {
    const response = await mutate({
      variables: { idTrocaSenha, gravarResponsavel: false, confirmaSenha: values.confirmaSenha, senha: values.senha }
    });
    try {
      if (response.data?.usuarioTrocarSenha === "LOGGEDOUT") {
        history.replace(Routes.login.route);
      } else {
        setSnack({ message: "Senha alterada com sucesso!", messageOpen: true, messageVariant: "success" });
      }
    } catch (e) {
      console.log(e);
    }
    actions.setSubmitting(false);
  };

  const submitDefinirSenha = async (values: FormValuesDefinirSenha, actions: FormikActions<FormValuesDefinirSenha>) => {
    const response = await mutate({ variables: { idTrocaSenha, gravarResponsavel: true, responsavel: values.responsavel } });
    try {
      if (response.data?.usuarioTrocarSenha === "CRIAR_SENHA") {
        setUsuarioGravado(true);
      } else {
        setSnack({ message: "Senha alterada com sucesso!", messageOpen: true, messageVariant: "success" });
      }
    } catch (e) {
      console.log(e);
    }
    actions.setSubmitting(false);
  };

  if (loading) {
    return <Loading />;
  }

  const closeMessage = () => setSnack(sn => ({ ...sn, messageOpen: false }));

  return (
    <main className={classNames(classes.content, classes.contentPaddingLeft)} id="mainContent">
      <Decoration mode="light" gradient="true" decoration="true" bgPosition="full" horizontalMenu={false} />
      <section className={classNames(classes.mainWrap, classes.sidebarLayout)}>
        <LogoConcilia classe="toplogo_sistema" right />
        <div className={classes.pageTitle}>
          <Typography component="h4" className={classes.lightTitle} variant="h4">
            Altere sua senha
          </Typography>
        </div>
        <PapperBlock title="Altere sua senha">
          <Card elevation={20} style={{ padding: 15, margin: 15 }}>
            {(usuarioGravado ||
              (data?.getInfoUsuarrioTrocarSenha.senhaDefinida && !data?.getInfoUsuarrioTrocarSenha.expirado)) && (
              <NovaSenha submit={submit} />
            )}

            {!data?.getInfoUsuarrioTrocarSenha.senhaDefinida && !data?.getInfoUsuarrioTrocarSenha.expirado && (
              <DefinirSenha submit={submitDefinirSenha} />
            )}
          </Card>
        </PapperBlock>
      </section>
      <SnackMessage message={snack.message} variant={snack.messageVariant} open={snack.messageOpen} close={closeMessage} />
    </main>
  );
};

const withMutation = withUsuarioTrocarSenha<Props>({});

export default withStyles(styles as any)(withMutation(ResetarSenha));
