import { FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup } from "@material-ui/core";
import { Field, FormikProps } from "formik";
import React from "react";
import FormikInputText from "../../../componentes/MaskedInput/FomikInput";
import { GetPlanosConciliadoresQuery } from "../../../graphql/components";
import { FormValues } from "./newEditPlanosConciliadores";

type Props = {
  formikBag: FormikProps<FormValues>;
  data?: GetPlanosConciliadoresQuery;
};

const FormPlanosConciliadores = ({ formikBag, data }: Props) => {
  React.useEffect(() => {
    if (data) {
      formikBag.setValues({
        codplanosconciliadores: data.getPlanosConciliadores.editValues.codplanosconciliadores!,
        codpessoapai: data.getPlanosConciliadores.editValues.codpessoapai!,
        nome: data.getPlanosConciliadores.editValues.nome!,
        tarifacao: data.getPlanosConciliadores.editValues.tarifacao!
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <React.Fragment>
      <fieldset style={{ marginTop: 15, marginRight: 10 }}>
        <Grid container spacing={8} title="Planos & Taxas">
          <Grid item xs={12} md={12}>
            <Field id="nome" name="nome" component={FormikInputText} label="Nome" disabled={formikBag.isSubmitting} />
          </Grid>

          <Grid item xs={12} md={8}>
            <FormControl>
              <FormLabel>Tipo de Tarifação</FormLabel>
              <RadioGroup
                aria-label="tarifacao"
                id="tarifacao"
                row
                name="tarifacao"
                value={formikBag.values.tarifacao}
                onChange={formikBag.handleChange}
              >
                <FormControlLabel value="P" control={<Radio />} label="Parcela" disabled={formikBag.isSubmitting} />
                <FormControlLabel value="V" control={<Radio />} label="Valor" disabled={formikBag.isSubmitting} />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
      </fieldset>
    </React.Fragment>
  );
};

export default FormPlanosConciliadores;
