import React from "react";
import ThemeWrapper from "./ThemeWrapper";
import { Router, Switch, Route, withRouter, RouteComponentProps } from "react-router";
import LoginDedicated from "../Pages/Standalone/LoginDedicated";
import PrivateRouteMapped from "./privateRoute";
import Application from "./Application";
import NotFound from "../NotFound/NotFound";
import { createBrowserHistory } from "history";
import Error from "../Pages/Error";
import { IState } from "../../redux/reducers";
import { connect } from "react-redux";
import { Dispatch, bindActionCreators } from "redux";
import { hasNoError } from "../../redux/actions/errorAction";
import IsVisContext from "../../componentes/CRUD/isVisContext";
import Routes from "../../utils/routes";
import LoginForm from "../../componentes/Forms/LoginForm";
import ResetarSenha from "../Pages/ResetarSenha";
import { SignUpSuspense } from "./routesComponents";

const history = createBrowserHistory();

interface Props {
  hasError?: boolean;
}

interface ErrorProps extends RouteComponentProps {
  hasError?: boolean;
  dispatchHasNoError: typeof hasNoError;
}

const Rotas = ({ history, hasError, location, dispatchHasNoError }: ErrorProps) => {
  const [isVisSPC, setIsVisSPC] = React.useState<string>("");
  if (hasError && location.pathname !== "/error") {
    history.push("/error");
    dispatchHasNoError();
  }

  React.useEffect(() => {
    const isVis = new URLSearchParams(location.search).get("isVis");
    if (isVis && isVis === "true") {
      setIsVisSPC("vis");
    } else if (location.pathname.indexOf("spc") !== -1) {
      setIsVisSPC("spc");
    }
    // eslint-disable-next-line react-hooks/rules-of-hooks
  }, [location.pathname, location.search]);

  return (
    <IsVisContext.Provider
      value={{
        isVisSPC,
        changeIsVis: (isVisSPC: string) => {
          setIsVisSPC(isVisSPC);
        },
      }}
    >
      <Switch>
        <Route exact path="/" component={LoginDedicated} />
        <Route exact path={Routes.spc.route} component={() => <LoginForm loginConcilia={false} />} />
        <Route exact path="/error" component={Error} />
        <Route exact path="/resetar-senha" component={ResetarSenha} />
        <PrivateRouteMapped path="/app" component={Application} />
        <Route path={Routes.signUp.route} component={SignUpSuspense} />
        <Route component={NotFound} />
      </Switch>
    </IsVisContext.Provider>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  dispatchHasNoError: bindActionCreators(hasNoError, dispatch),
});

const RotasMapped = connect(
  null,
  mapDispatchToProps
)(Rotas);

const RotasMappedRouter = withRouter(RotasMapped);

const IndexRouters = ({ hasError }: Props) => (
  <ThemeWrapper>
    <Router history={history}>
      <RotasMappedRouter hasError={hasError} />
    </Router>
  </ThemeWrapper>
);

const mapStateToProps = (state: IState) => ({
  hasError: state.errorReducer.hasError,
});

const IndexRoutersMapped = connect(
  mapStateToProps,
  null
)(IndexRouters);

export default IndexRoutersMapped;
