export const UserAgentType = {
  ANDROID: "ANDROID",
  IPHONE: "IPHONE",
  BROSER: "BROWSER"
};

const getUserAgent = (): "ANDROID" | "IPHONE" | "BROWSER" => {
  const ua = window.navigator.userAgent.toLocaleUpperCase();
  let retorno = UserAgentType.BROSER;

  if (ua.indexOf(UserAgentType.ANDROID.toString()) !== -1) {
    retorno = UserAgentType.ANDROID;
  } else if (ua.indexOf(UserAgentType.IPHONE.toString()) !== -1) {
    retorno = UserAgentType.IPHONE;
  }
  return retorno as "ANDROID" | "IPHONE" | "BROWSER";
};

export const hideColumnsInMobile = () => {
  const userAgent = getUserAgent();
  if (userAgent === UserAgentType.BROSER) {
    return "true";
  }
  return "false";
};

export default getUserAgent;
