import React from "react";
import { RightDrawerProps } from "../../../componentes/CRUD/crudProps";
import { Card, Typography, Grid, Button } from "@material-ui/core";
import "./visualizarNota.css";
import Refresh from "@material-ui/icons/Autorenew";
import SelectedRowsContext from "../../../componentes/CRUD/selectedRowsContext";
import {
  GetInfoNotaComponent,
  FaturamentoMovimentoReenviarEmailNotaAceitarReenviarNotaComponent,
  FaturamentoMovimentoReenviarEmailNotaAceitarReenviarNotaMutation,
  FaturamentoMovimentoReenviarEmailNotaAceitarReenviarNotaMutationVariables
} from "../../../graphql/components";
import Loading from "../../../componentes/Loading/Loading";
import { MutationFunction } from "react-apollo";

const VisualizarNota: React.FC<RightDrawerProps> = ({ closeRightDrawer, setMessage }) => {
  const close = React.useCallback(() => closeRightDrawer(), [closeRightDrawer]);
  const [processando, setProcessando] = React.useState(false);

  const action = async (
    mutation: MutationFunction<
      FaturamentoMovimentoReenviarEmailNotaAceitarReenviarNotaMutation,
      FaturamentoMovimentoReenviarEmailNotaAceitarReenviarNotaMutationVariables
    >,
    operacao: string
  ) => {
    setProcessando(true);
    try {
      const response = await mutation();
      if (response && response.data && response.data.faturamentoMovimentoReenviarEmailNotaAceitarReenviarNota) {
        if (operacao === "reenviarEmail") {
          setMessage(true, "success", "Email reenviado com sucesso!");
        } else if (operacao === "reenviarNota") {
          setMessage(true, "success", "Nota reenviada com sucesso!");
        } else if (operacao === "aceitar") {
          setMessage(true, "success", "Nota aceita com sucesso!");
        }
      }
    } catch (e) {
    } finally {
      setProcessando(false);
    }
  };

  const selecionado = React.useContext(SelectedRowsContext);
  if (!selecionado || selecionado.length === 0) {
    return null;
  }
  return (
    <React.Fragment>
      <Typography className="title" variant="subtitle1">
        Faturamento Movimento
      </Typography>
      <GetInfoNotaComponent variables={{ values: { chave: selecionado[0].chave } }}>
        {({ loading, data, refetch }) => {
          if (loading) {
            return <Loading />;
          }
          if (data && data.getInfoNota) {
            return (
              <Card elevation={4} className="card">
                <Grid container spacing={8}>
                  <Grid item xs={12} md={9}>
                    <Typography variant="caption" className="rem66">
                      Status:
                    </Typography>
                  </Grid>
                  <Grid item xs={12} className="refresh" md={3} title="Atualizar">
                    <Refresh onClick={refetch.bind(null, { values: { chave: selecionado[0].chave } })} />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Typography variant="caption" color="textSecondary" className="rem81">
                      {data.getInfoNota.status}
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography variant="caption" className="rem66">
                      Nota:
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="caption" color="textSecondary" className="rem1">
                      {data.getInfoNota.numeronota}
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography variant="caption" className="rem66">
                      Protocolo:
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="caption" color="textSecondary" className="rem81">
                      {data.getInfoNota.protocolo}
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography variant="caption" className="rem66">
                      Mensagem:
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <textarea
                      rows={7}
                      className="textarea"
                      value={data.getInfoNota.mensagem ? data.getInfoNota.mensagem : ""}
                      maxLength={500}
                      readOnly
                    />
                  </Grid>
                  {data.getInfoNota.reenviaremail && (
                    <Grid item xs={12} md={6}>
                      <FaturamentoMovimentoReenviarEmailNotaAceitarReenviarNotaComponent
                        variables={{ values: { chave: selecionado[0].chave }, operacao: "reenviarEmail" }}
                      >
                        {mutation => (
                          <Button
                            variant="outlined"
                            color="primary"
                            className="button"
                            onClick={action.bind(null, mutation, "reenviarEmail")}
                            disabled={processando}
                          >
                            Reenv. email
                          </Button>
                        )}
                      </FaturamentoMovimentoReenviarEmailNotaAceitarReenviarNotaComponent>
                    </Grid>
                  )}
                  {data.getInfoNota.reenviarnota && (
                    <React.Fragment>
                      <Grid item xs={12} md={6}>
                        <FaturamentoMovimentoReenviarEmailNotaAceitarReenviarNotaComponent
                          variables={{ values: { chave: selecionado[0].chave }, operacao: "reenviarNota" }}
                        >
                          {mutation => (
                            <Button
                              variant="outlined"
                              color="primary"
                              className="button100"
                              onClick={action.bind(null, mutation, "reenviarNota")}
                              disabled={processando}
                            >
                              Reenv. nota
                            </Button>
                          )}
                        </FaturamentoMovimentoReenviarEmailNotaAceitarReenviarNotaComponent>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <FaturamentoMovimentoReenviarEmailNotaAceitarReenviarNotaComponent
                          variables={{ values: { chave: selecionado[0].chave }, operacao: "aceitar" }}
                        >
                          {mutation => (
                            <Button
                              variant="outlined"
                              color="primary"
                              className="button100"
                              onClick={action.bind(null, mutation, "aceitar")}
                              disabled={processando}
                            >
                              Aceitar
                            </Button>
                          )}
                        </FaturamentoMovimentoReenviarEmailNotaAceitarReenviarNotaComponent>
                      </Grid>
                    </React.Fragment>
                  )}
                  <Grid item xs={12} md={6}>
                    <Button variant="outlined" color="secondary" className="button100" onClick={close}>
                      Fechar
                    </Button>
                  </Grid>
                </Grid>
              </Card>
            );
          }
          return null;
        }}
      </GetInfoNotaComponent>
    </React.Fragment>
  );
};

export default VisualizarNota;
