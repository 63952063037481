import { LOAD_PAGE, TOGGLE_SIDEBAR } from "../actionConstants";
import ActionTypeDefault from "../actionTypeDefault";

// const actionDefault: ActionTypeDefault = {
//   type: "",
//   payload: null
// };

const initialState = {
  /* Settings for Themes and layout */
  theme: "blueCyanTheme",
  type: "light", // light or dark
  gradient: true, // true or false
  decoration: true, // true or false
  bgPosition: "half", // half, header, full
  layout: "left-sidebar", // left-sidebar, right-sidebar, top-navigation, mega-menu
  /* End settings */
  sidebarOpen: false,
  pageLoaded: false,
  subMenuOpen: [] as Array<any>
};

export default function reducer(state = initialState, action: ActionTypeDefault) {
  switch (action.type) {
    case TOGGLE_SIDEBAR:
      return {
        ...state,
        sidebarOpen: !state.sidebarOpen
      };
    case LOAD_PAGE:
      return {
        ...state,
        pageLoaded: action.payload
      };
    default:
      return state;
  }
}
