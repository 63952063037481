import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import React, { PureComponent } from "react";

interface IProps {
  series: any;
  categoria: any;
}

export class GraficoVendaHora extends PureComponent<IProps> {
  chartComponent: React.RefObject<HighchartsReact>;
  constructor(props: IProps) {
    super(props);
    this.chartComponent = React.createRef();
    this.state = {};
  }

  componentDidMount() {
    const container = this.chartComponent.current?.container.current;

    if (container) {
      container.style.height = "100%";
      container.style.width = "100%";
      this.chartComponent.current?.chart.reflow();
    }
  }

  convertResultCategoria = (values: Array<any>) => {
    const result: Array<any> = [];

    values.forEach(v => {
      result.push(v);
    });

    return result;
  };

  convertResultSeries = (values: Array<any>) => {
    const result: Array<any> = [];

    values.forEach(v => {
      result.push(v);
    });

    return result;
  };
  render() {
    return (
      <div>
        <HighchartsReact
          ref={this.chartComponent}
          highcharts={Highcharts}
          options={{
            credits: {
              enabled: false
            },
            chart: {
              type: "spline"
            },
            title: {
              text: ""
            },
            subtitle: {
              text: ""
            },
            xAxis: {
              categories: this.convertResultCategoria(this.props.categoria)
            },
            yAxis: {
              title: {
                text: "Quantidade de Vendas"
              },
              labels: {
                formatter: function() {
                  return this.value + "%";
                }
              }
            },
            tooltip: {
              shared: true
            },
            plotOptions: {
              spline: {
                marker: {
                  radius: 4,
                  lineColor: "#666666",
                  lineWidth: 1
                }
              }
            },
            series: [
              {
                type: "line" as any,
                name: "Hora",
                data: this.convertResultSeries(this.props.series)
              }
            ]
          }}
        />
      </div>
    );
  }
}

export default GraficoVendaHora;
