const smallTableStyle = (theme: any) => ({
  row: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default,
    },
  },
  table: {
    "& tr": {
      height: 20,
      "& td, th": {
        padding: "4px 6px",
        fontSize: 12,
      },
    },
  },
});

export const xSmallTableStyle = (theme: any) => ({
  row: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default,
    },
  },
  table: {
    "& tr": {
      height: 20,
      "& td, th": {
        padding: "4px 4px",
        fontSize: 10,
      },
    },
  },
});

export const xxSmallTableStyle = (theme: any) => ({
  row: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default,
    },
  },
  table: {
    "& tr": {
      height: 20,
      "& td, th": {
        padding: "4px 4px",
        fontSize: 9,
      },
    },
  },
});

export default smallTableStyle;
