import React from "react";
import { Formik, FormikProps, FormikActions } from "formik";
import { Grid, Typography, Paper, Button, Zoom, LinearProgress } from "@material-ui/core";
import * as Yup from "yup";
import ReactPasswordStrength from "react-password-strength";

const stringObrigatorio = "é obrigatório.";

const Schema = Yup.object().shape({
  senha: Yup.string()
    .label("Nova Senha")
    .required(`Nova Senha ${stringObrigatorio}`)
    .min(6, "Nova Senha deve ter no mínimo 6 caracteres")
    .matches(/^[a-zA-Z0-9_]*$/, "Senha não deve conter caracteres especiais como & # % @"),
  confirmaSenha: Yup.string()
    .required(`Confirma senha ${stringObrigatorio}`)
    .label("Confirma senha")
    .test("passwords-match", "As senhas não conferem", function(value) {
      return this.parent.senha === value;
    }),
});

const initialValues = {
  senha: "",
  confirmaSenha: "",
};

export type FormValuesNovaSenha = typeof initialValues;

interface Props {
  submit: (values: FormValuesNovaSenha, actions: FormikActions<FormValuesNovaSenha>) => void;
}

const NovaSenha: React.FC<Props> = ({ submit }) => {
  const changeCallbackSenha = (e: any, formikBag: FormikProps<FormValuesNovaSenha>) => {
    formikBag.setFieldValue("senha", e.password);
  };

  const changeCallbackConfirmaSenha = (e: any, formikBag: FormikProps<FormValuesNovaSenha>) => {
    formikBag.setFieldValue("confirmaSenha", e.password);
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={submit}
      validationSchema={Schema}
      render={(formikBag: FormikProps<FormValuesNovaSenha>) => (
        <form autoComplete="new-password" role="presentation" onSubmit={formikBag.handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                Nova Senha
              </Typography>
              <ReactPasswordStrength
                minScore={6}
                tooShortWord={"Curta"}
                scoreWords={["Muito Fraca", "Fraca", "Boa", "Forte", "Muito Forte"]}
                inputProps={{ id: "senha", name: "senha" }}
                changeCallback={(e: any) => changeCallbackSenha(e, formikBag)}
              />
              {formikBag.errors.senha}
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                Confirma Senha
              </Typography>
              <ReactPasswordStrength
                minScore={6}
                tooShortWord={"Curta"}
                scoreWords={["Muito Fraca", "Fraca", "Boa", "Forte", "Muito Forte"]}
                inputProps={{ id: "confirmasenha", name: "confirmasenha" }}
                changeCallback={(e: any) => changeCallbackConfirmaSenha(e, formikBag)}
              />
              {formikBag.errors.confirmaSenha}
            </Grid>
          </Grid>

          <Paper elevation={20} style={{ marginTop: 15 }}>
            <Button
              variant="text"
              color="primary"
              style={{ margin: 10, width: "92%" }}
              type="submit"
              disabled={!formikBag.isValid || formikBag.isSubmitting}
            >
              {formikBag.isSubmitting ? "Estamos processando sua requisição" : "Alterar Senha"}
            </Button>

            <Zoom in={formikBag.isSubmitting}>
              <LinearProgress color="secondary" />
            </Zoom>
          </Paper>
        </form>
      )}
    />
  );
};

export default NovaSenha;
