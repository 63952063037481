import React from "react";
import ErrorWrap from "../../../componentes/Error/ErrorWrap";

const Error = () => (
  <div>
    <ErrorWrap title="500" desc="Ocorreu um erro em nossos servidores :(" />
  </div>
);

export default Error;
