import { FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup } from "@material-ui/core";
import { Field, FormikProps } from "formik";
import React, { useState } from "react";
import FormikInputText from "../../../../componentes/MaskedInput/FomikInput";
import { FormValues } from "./newEditPlanosPOS";
import { GetPlanosPosQuery } from "../../../../graphql/components";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import FormikMaskedCurrency from "../../../../componentes/MaskedInput/FormikMaskedCurrency";

type Props = {
  formikBag: FormikProps<FormValues>;
  data?: GetPlanosPosQuery;
};

const numberMask = createNumberMask({
  prefix: "",
  suffix: "",
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ".",
  allowDecimal: true,
  decimalSymbol: ",",
  requireDecimal: true
});

const FormPlanosPos = ({ formikBag, data }: Props) => {
  const [statusDisabled] = useState(false);

  React.useEffect(() => {
    if (data) {
      formikBag.setValues({
        nome: data.getPlanosPOS.editValues.nome!,
        codplano: data.getPlanosPOS.editValues.codplano!,
        cobramensalidade: data.getPlanosPOS.editValues.cobramensalidade!,
        valormensalidade: data.getPlanosPOS.editValues.valormensalidade!,
        diacorte: data.getPlanosPOS.editValues.diacorte!
      });
    } else {
      formikBag.setFieldValue("cobramensalidade", "N");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <fieldset style={{ marginTop: 15, marginRight: 10 }}>
        <Grid container spacing={8} title="Planos Terminal">
          <Grid item xs={12} md={12}>
            <Field id="nome" name="nome" component={FormikInputText} label="Nome" />
          </Grid>

          <Grid item xs={12} md={8}>
            <FormControl>
              <FormLabel>Cobra mensalidade</FormLabel>
              <RadioGroup
                aria-label="cobramensalidade"
                id="cobramensalidade"
                row
                name="cobramensalidade"
                value={formikBag.values.cobramensalidade}
                onChange={formikBag.handleChange}
              >
                <FormControlLabel value="N" control={<Radio />} label="Não" disabled={statusDisabled} />
                <FormControlLabel value="S" control={<Radio />} label="Sim" disabled={statusDisabled} />
              </RadioGroup>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={12}>
            <FormControl aria-describedby="valormensalidade">
              <Field
                id="valormensalidade"
                name="valormensalidade"
                component={FormikMaskedCurrency}
                mask={numberMask}
                label="Valor"
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={12}>
            <Field id="diacorte" name="diacorte" component={FormikInputText} label="Dia Corte" />
          </Grid>
        </Grid>
      </fieldset>
    </React.Fragment>
  );
};

export default FormPlanosPos;
