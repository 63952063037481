import ColumnCrudTableType from "../../../componentes/CRUD/columnCrudTableType";
import moment from "moment";
import { forkJoin } from "rxjs";
import { DataProxy } from "apollo-cache";
import {
  DeleteUsuarioMutation,
  ListarUsuarioQuery,
  ListarUsuarioDocument,
  DeleteUsuarioMutationVariables
} from "../../../graphql/components";
import { CallbackActionType } from "../../../componentes/CRUD/actionsTable";

export const variablesUsuario: { codpessoa: number | undefined; login: string | undefined; codperfil: number | undefined } = {
  codpessoa: undefined,
  login: undefined,
  codperfil: undefined
};

export const deleteMutationUsuario: CallbackActionType<DeleteUsuarioMutation, DeleteUsuarioMutationVariables> = (
  mutation,
  record,
  setMessage
) => {
  if (mutation) {
    const deletesPromises: Array<any> = [];
    record.forEach(r => {
      deletesPromises.push(
        mutation({
          variables: { values: { codpessoa: r.codpessoa } },
          update: (store: DataProxy, { data }) => {
            if (data!.deleteUsuario) {
              const oldResult = store.readQuery<ListarUsuarioQuery>({
                query: ListarUsuarioDocument,
                variables: { values: variablesUsuario }
              });
              if (oldResult) {
                const index = oldResult.listarUsuario.findIndex(u => u.codpessoa === r.codpessoa);
                oldResult.listarUsuario.splice(index, 1);
                const result = [...oldResult.listarUsuario];
                store.writeQuery({
                  query: ListarUsuarioDocument,
                  data: result,
                  variables: variablesUsuario
                });
              }
            }
          }
        })
      );
    });
    forkJoin(deletesPromises).subscribe(response => {
      const totalErrors = response.filter(r => typeof r === "string").length;
      if (totalErrors > 0 && totalErrors !== deletesPromises.length) {
        setMessage(
          true,
          "warning",
          "Alguns registros não foram excluídos. Dentro de 5 segundos iremos atualizar a página para manter os dados coerentes."
        );
        setTimeout(() => window.location.reload(), 6500);
      } else if (totalErrors === 0) {
        setMessage(true, "success", "Registro(s) excluídos com sucesso!");
      }
    });
  }
};

export const columnsOrderUsuario = ["login", "nome", "validade"];

export const colunasUsuario: Array<ColumnCrudTableType> = [
  {
    name: "Login",
    options: {
      filter: true
    }
  },
  {
    name: "Nome",
    options: {
      filter: true
    }
  },
  {
    name: "Validade",
    options: {
      filter: true,
      customBodyRender: (value: any) => moment(new Date(value)).format("DD/MM/YYYY")
    }
  }
];
