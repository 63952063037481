export default class ConciliaCrypt {
  private _login: string;
  private _senha: string;

  constructor(encrypted: string) {
    let buffer = "";
    const chave1 = encrypted.substring(0, 4);
    const chave2 = encrypted.substring(encrypted.length - 4, encrypted.length);
    let txt = encrypted.substring(4, encrypted.length - 4);
    while (txt.length > 0) {
      buffer = buffer.concat(this.parteDecripta(parseInt(txt.substring(0, 8)), parseInt(chave1), parseInt(chave2)));
      txt = txt.substring(8, txt.length);
    }
    const splitted = buffer.split("##");
    this._login = splitted[0];
    this._senha = splitted[1];
  }

  private parteDecripta(text: number, chave1: number, chave2: number) {
    let principal = text;
    principal = principal / chave2 / chave1;
    return String.fromCharCode(principal);
  }

  get login() {
    return this._login;
  }

  get senha() {
    return this._senha;
  }
}
