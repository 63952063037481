import Avatar from "@material-ui/core/Avatar";
import Badge from "@material-ui/core/Badge";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Divider from "@material-ui/core/Divider";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import ListItemText from "@material-ui/core/ListItemText";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Slide from "@material-ui/core/Slide";
import { withStyles } from "@material-ui/core/styles";
import Info from "@material-ui/icons/Info";
import classNames from "classnames";
import React from "react";
import { GetNoticiasComponent } from "../../graphql/components";
import "../../styles/components/Messages.scss";
import Loading from "../Loading/Loading";
import styles from "./header-jss";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface NoticiasProps {
  classes: any;
  dark?: boolean;
}

interface INoticiasState {
  dark: boolean;
  anchorEl: any;
  openMenu: any;
  openNews: boolean;
  textNews: string;
  tituloNews: string;
}

class Noticias extends React.Component<NoticiasProps, INoticiasState> {
  state = {
    anchorEl: null,
    openMenu: null,
    dark: false,
    openNews: false,
    textNews: "",
    tituloNews: "",
  };

  handleMenu = (menu: any) => (event: any) => {
    const { openMenu } = this.state;
    this.setState({
      openMenu: openMenu === menu ? null : menu,
      anchorEl: event.currentTarget,
    });
  };

  handleClose = () => this.setState({ anchorEl: null, openMenu: null });

  toggleNews = () => this.setState((prevState) => ({ openNews: !prevState.openNews }));

  open = (texto: string, titulo: string, event: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
    this.setState({ anchorEl: null, openMenu: null, openNews: true, textNews: texto, tituloNews: titulo });

  render() {
    const { classes, dark } = this.props;
    const { anchorEl, openMenu, openNews } = this.state;
    return (
      <React.Fragment>
        <GetNoticiasComponent>
          {({ data, loading }) => {
            if (loading || !data) {
              return <Loading />;
            }
            return (
              <React.Fragment>
                <IconButton
                  aria-haspopup="true"
                  onClick={this.handleMenu("notification")}
                  color="inherit"
                  className={classNames(classes.notifIcon, dark ? classes.dark : classes.light)}
                >
                  <Badge
                    className={classes.badge}
                    badgeContent={data && data.getNoticias ? data.getNoticias.length : 0}
                    color="secondary"
                  >
                    <Icon className={classes.icon}>notifications</Icon>
                  </Badge>
                </IconButton>
                <Menu
                  id="menu-notification"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  className={classes.notifMenu}
                  PaperProps={{
                    style: {
                      width: 550,
                    },
                  }}
                  open={openMenu === "notification"}
                  onClose={this.handleClose}
                >
                  <MenuItem onClick={this.handleClose}>
                    <div className="messageInfo">
                      <ListItemText primary="Notícias" secondary="Fique atento às novidades do seu Conciliador" />
                    </div>
                  </MenuItem>
                  <Divider variant="inset" />
                  {data && data.getNoticias
                    ? data.getNoticias.map((noticia) => (
                        <MenuItem key={noticia.codnoticia}>
                          <div className="messageInfo" onClick={this.open.bind(this, noticia.texto!, noticia.titulo!)}>
                            <Avatar className="icon">
                              <Info />
                            </Avatar>
                            &nbsp; &nbsp;
                            <ListItemText primary={noticia.titulo} className={classes.textNotif} />
                          </div>
                        </MenuItem>
                      ))
                    : null}
                </Menu>
              </React.Fragment>
            );
          }}
        </GetNoticiasComponent>

        <Dialog
          fullScreen
          open={openNews}
          TransitionComponent={Transition as any}
          keepMounted
          onClose={this.toggleNews}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">{this.state.tituloNews}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              <span dangerouslySetInnerHTML={{ __html: this.state.textNews }} />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.toggleNews} color="primary" style={{ fontSize: 30 }}>
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

export default withStyles(styles as any)(Noticias);
