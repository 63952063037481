import { Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import Refresh from "@material-ui/icons/Refresh";
import dayjs from "dayjs";
import React from "react";
import Loading from "../../../../componentes/Loading/Loading";
import { xxSmallTableStyle } from "../../../../componentes/Table/smallTableStyle";
import { DeletarTicketComponent, OperadoraLinhaBean, ReprocessarTicketComponent } from "../../../../graphql/components";
import { TableProps } from "./panelTableHOC";

interface Props {
  classes?: any;
  data?: Array<OperadoraLinhaBean> | null;
}

const Administradora = ({ classes, data, loading, callMutation, callMutationDelete }: TableProps<Props>) => {
  return (
    <Table className={classes.table}>
      <TableHead>
        <TableRow>
          <TableCell>Nome Arq.</TableCell>
          <TableCell>Sts. Arq.</TableCell>
          <TableCell>Arq. Recebido</TableCell>
          <TableCell>Pagamento</TableCell>
          <TableCell>Venda</TableCell>
          <TableCell>Vlr</TableCell>
          <TableCell>Vlr Pg</TableCell>
          <TableCell>Crédt.</TableCell>
          <TableCell>Débt.</TableCell>
          <TableCell>Parc.</TableCell>
          <TableCell>Status</TableCell>
          <TableCell>Meio</TableCell>
          <TableCell>Prod.</TableCell>
          <TableCell>Bandeira</TableCell>
          <TableCell>Terminal</TableCell>
          <TableCell>NSU</TableCell>
          <TableCell>Autoriz.</TableCell>
          <TableCell>TID</TableCell>
          <TableCell>Ação</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data!.map(row => (
          <TableRow key={row.codarquivo}>
            <TableCell>{row.nomearquivo}</TableCell>
            <TableCell>{row.statusArquivo}</TableCell>
            <TableCell>{dayjs(row.datarecebimento).format("DD/MM/YYYY ")}</TableCell>
            <TableCell>{dayjs(row.datapagamento).format("DD/MM/YYYY ")}</TableCell>
            <TableCell>{dayjs(row.datavenda).format("DD/MM/YYYY ")}</TableCell>
            <TableCell>R${row.valorbruto ? row.valorbruto.toFixed(2) : null}</TableCell>
            <TableCell>R${row.valorliquidopago ? row.valorliquidopago.toFixed(2) : null}</TableCell>
            <TableCell>R${row.outroscreditos ? row.outroscreditos.toFixed(2) : null}</TableCell>
            <TableCell>R${row.outrosdebitos ? row.outrosdebitos.toFixed(2) : null}</TableCell>
            <TableCell>
              {row.parcela}/{row.totparcelas}
            </TableCell>
            <TableCell>{row.status}</TableCell>
            <TableCell>{row.meiocaptura}</TableCell>
            <TableCell>{row.produto}</TableCell>
            <TableCell>{row.bandeira}</TableCell>
            <TableCell>{row.numterminal}</TableCell>
            <TableCell>{row.nsu}</TableCell>
            <TableCell>{row.autorizacao}</TableCell>
            <TableCell>{row.tid}</TableCell>
            <TableCell style={{ padding: 0 }}>
              <>
                {loading && <Loading />}
                <ReprocessarTicketComponent>
                  {mutate => {
                    if (loading) {
                      return null;
                    }
                    return (
                      !loading && (
                        <Tooltip title="Reprocessar" placement="top" onClick={callMutation.bind(null, mutate, row)}>
                          <Refresh style={{ color: "gray", fontSize: 15, cursor: "pointer" }} />
                        </Tooltip>
                      )
                    );
                  }}
                </ReprocessarTicketComponent>
                <DeletarTicketComponent>
                  {mutate => {
                    if (loading) {
                      return null;
                    }
                    return (
                      !loading && (
                        <Tooltip title="Deletar" placement="top" onClick={callMutationDelete.bind(null, mutate, row)}>
                          <DeleteIcon style={{ color: "gray", fontSize: 15, cursor: "pointer" }} />
                        </Tooltip>
                      )
                    );
                  }}
                </DeletarTicketComponent>
              </>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default withStyles(xxSmallTableStyle as any)(Administradora);
