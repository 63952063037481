import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import React from "react";
import { formatterCurrency, formatterCurrencySemSigla } from "../../../../utils/formatacaoNumeros";
interface IProps {
  series: any;
}

export class GraficoVendaOperadora extends React.PureComponent<IProps> {
  convertResult = (values: Array<any>) => {
    const result: Array<any> = [];

    values.forEach(v => {
      if (v.sliced != null) {
        result.push({ name: v.name, y: v.y, sliced: v.sliced, selected: v.selected });
      } else {
        result.push({ name: v.name, y: v.y });
      }
    });

    return result;
  };

  render() {
    return (
      <div>
        <HighchartsReact
          highcharts={Highcharts}
          options={{
            credits: {
              enabled: false
            },
            title: {
              verticalAlign: "middle",
              floating: true,
              text: "",
              style: {
                fontSize: "10px"
              }
            },
            tooltip: {
              valueDecimals: 2,
              valuePrefix: "R$",
              formatter() {
                const valor = this.y.toFixed(2);
                const taxa = parseFloat(valor);
                return formatterCurrency(taxa);
              }
            },

            plotOptions: {
              pie: {
                allowPointSelect: true,
                cursor: "pointer",
                dataLabels: {
                  enabled: true,
                  formatter() {
                    const operadora = this.point.name;
                    const valor = this.point.percentage.toFixed(2);
                    const taxa = parseFloat(valor);
                    return operadora + ": " + formatterCurrencySemSigla(taxa) + " %";
                  }
                }
              }
            },

            series: [
              {
                //showInLegend: true,
                dataLabels: {
                  enabled: true,
                  padding: 0
                },
                // plotBackgroundColor: null,
                // plotBorderWidth: null,
                // plotShadow: false,
                type: "pie" as any,
                name: "Valor",
                colorByPoint: true,
                data: this.convertResult(this.props.series)
              }
            ]
          }}
        />
      </div>
    );
  }
}

export default GraficoVendaOperadora;
