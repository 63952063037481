import { Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import Refresh from "@material-ui/icons/Refresh";
import React from "react";
import { TableProps } from "./panelTableHOC";
import Loading from "../../../../componentes/Loading/Loading";
import { xSmallTableStyle } from "../../../../componentes/Table/smallTableStyle";
import { ClientesLinhaBean, DeletarTicketComponent, ReprocessarTicketComponent } from "../../../../graphql/components";
import dayjs from "dayjs";

interface Props {
  classes?: any;
  data?: Array<ClientesLinhaBean> | null;
}

const Clientes = ({ classes, data, loading, callMutation, callMutationDelete }: TableProps<Props>) => {
  return (
    <Table className={classes.table}>
      <TableHead>
        <TableRow>
          <TableCell>Nome Arq.</TableCell>
          <TableCell>Sts Arq.</TableCell>
          <TableCell>Arq. Recebido</TableCell>
          <TableCell>Pagamento</TableCell>
          <TableCell>Venda</TableCell>
          <TableCell>Vlr</TableCell>
          <TableCell>Parc.</TableCell>
          <TableCell>Nosso N.</TableCell>
          <TableCell>Bandeira</TableCell>
          <TableCell>Autoriz.</TableCell>
          <TableCell>TID</TableCell>
          <TableCell>Ação</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data!.map(row => (
          <TableRow key={row.codarquivo}>
            <TableCell>{row.nomearquivo}</TableCell>
            <TableCell>{row.statusArquivo}</TableCell>
            <TableCell>{dayjs(row.datarecebimento).format("DD/MM/YYYY")}</TableCell>
            <TableCell>{dayjs(row.dataprevistapagamento).format("DD/MM/YYYY")}</TableCell>
            <TableCell>{dayjs(row.datatransacao).format("DD/MM/YYYY")}</TableCell>
            <TableCell>R${row.valorbruto ? row.valorbruto.toFixed(2) : null}</TableCell>
            <TableCell>
              {row.numparcela}/{row.qtdparcelas}
            </TableCell>
            <TableCell>{row.nossonumero}</TableCell>
            <TableCell>{row.bandeira}</TableCell>
            <TableCell>{row.numeroautorizacao}</TableCell>
            <TableCell>{row.tid}</TableCell>
            <TableCell>
              <>
                {loading && <Loading />}
                <ReprocessarTicketComponent>
                  {mutate => {
                    if (loading) {
                      return null;
                    }
                    return (
                      !loading && (
                        <Tooltip title="Reprocessar" placement="top" onClick={callMutation.bind(null, mutate, row)}>
                          <Refresh style={{ color: "gray", fontSize: 15, cursor: "pointer" }} />
                        </Tooltip>
                      )
                    );
                  }}
                </ReprocessarTicketComponent>
                <DeletarTicketComponent>
                  {mutate => {
                    if (loading) {
                      return null;
                    }
                    return (
                      !loading && (
                        <Tooltip title="Deletar" placement="top" onClick={callMutationDelete.bind(null, mutate, row)}>
                          <DeleteIcon style={{ color: "gray", fontSize: 15, cursor: "pointer" }} />
                        </Tooltip>
                      )
                    );
                  }}
                </DeletarTicketComponent>
              </>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default withStyles(xSmallTableStyle as any)(Clientes);
