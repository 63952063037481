import { Grid } from "@material-ui/core";
import { Field, FormikProps } from "formik";
import React from "react";
import FormikInputText from "../../../componentes/MaskedInput/FomikInput";
import FormikCurrencyInput from "../../../componentes/MaskedInput/FormikCurrencyInput";
import { SelectCombo } from "../../../componentes/Select";
import { GetDadoNFeQuery, ListarCidadesDocument, ListarItensConciliadoresDocument } from "../../../graphql/components";
import { FormValues } from "./newEditDadosNFe";
import MaterialDropZone from "../../../componentes/DropZone";

type Props = {
  formikBag: FormikProps<FormValues>;
  data?: GetDadoNFeQuery;
};

function FormPerfil({ formikBag, data }: Props) {
  React.useEffect(() => {
    if (data) {
      formikBag.setValues({
        cnae: data.getDadoNFe.editValues.cnae!,
        codcidade: data.getDadoNFe.editValues.codcidade!,
        codigotributacaomunicipio: data.getDadoNFe.editValues.codigotributacaomunicipio!,
        codpessoa: data.getDadoNFe.editValues.codpessoa!,
        codpessoaemissao: data.getDadoNFe.editValues.codpessoaemissao!,
        cofins: data.getDadoNFe.editValues.cofins!.toString(),
        csll: data.getDadoNFe.editValues.csll!.toString(),
        desccnae: data.getDadoNFe.editValues.desccnae!,
        descservico: data.getDadoNFe.editValues.descservico!,
        descsubitem: data.getDadoNFe.editValues.descsubitem!,
        email: data.getDadoNFe.editValues.email!,
        incentivadorcultural: data.getDadoNFe.editValues.incentivadorcultural!,
        ir: data.getDadoNFe.editValues.ir!.toString(),
        iss: data.getDadoNFe.editValues.iss!.toString(),
        naturezaoperacao: data.getDadoNFe.editValues.naturezaoperacao!,
        opatantesimplesnacional: data.getDadoNFe.editValues.opatantesimplesnacional!,
        pis: data.getDadoNFe.editValues.pis!.toString(),
        porta: data.getDadoNFe.editValues.porta!,
        regimeespecialtributacao: data.getDadoNFe.editValues.regimeespecialtributacao!,
        senhaemail: data.getDadoNFe.editValues.senhaemail!,
        senhacertificado: data.getDadoNFe.editValues.senhacertificado!,
        smtp: data.getDadoNFe.editValues.smtp!,
        subitem: data.getDadoNFe.editValues.subitem!,
        valorminimocalculoir: data.getDadoNFe.editValues.valorminimocalculoir!.toString(),
        valorminimocalculopiscofinscsll: data.getDadoNFe.editValues.valorminimocalculopiscofinscsll!.toString(),
        logo: "",
        certificado: ""
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const convertUpload = (file: File, field: string) => {
    const reader = new FileReader();
    reader.onload = async () => {
      const bytesArray = reader.result;
      formikBag.setFieldValue(field, bytesArray as any);
    };
    reader.readAsBinaryString(file);
  };

  return (
    <fieldset style={{ marginTop: 15, marginRight: 10 }}>
      <Grid container spacing={2} title="Dados NFe">
        <Grid item xs={12} style={{ marginTop: 15 }}>
          <fieldset>
            <legend>Empresa</legend>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <SelectCombo
                  label="Empresa"
                  name="codpessoa"
                  nameQuery="listarItensConciliadores"
                  query={ListarItensConciliadoresDocument}
                  value={formikBag.values.codpessoa}
                  handleChange={formikBag.handleChange}
                  handleBlur={formikBag.handleBlur}
                  disabled={formikBag.isSubmitting}
                  errorText={formikBag.errors.codpessoa as string}
                  touched={formikBag.touched.codpessoa != null}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <SelectCombo
                  label="Empresa Emissora Nota Fiscal"
                  name="codpessoaemissao"
                  nameQuery="listarItensConciliadores"
                  query={ListarItensConciliadoresDocument}
                  value={formikBag.values.codpessoaemissao}
                  handleChange={formikBag.handleChange}
                  handleBlur={formikBag.handleBlur}
                  disabled={formikBag.isSubmitting}
                  errorText={formikBag.errors.codpessoaemissao as string}
                  touched={formikBag.touched.codpessoaemissao != null}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <SelectCombo
                  label="Cidade/Estado"
                  name="codcidade"
                  nameQuery="listarCidades"
                  query={ListarCidadesDocument}
                  value={formikBag.values.codcidade}
                  handleChange={formikBag.handleChange}
                  handleBlur={formikBag.handleBlur}
                  disabled={formikBag.isSubmitting}
                  errorText={formikBag.errors.codcidade as string}
                  touched={formikBag.touched.codcidade != null}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Field id="cnae" name="cnae" component={FormikInputText} label="CNAE" disabled={formikBag.isSubmitting} />
              </Grid>
              <Grid item xs={12} md={6}>
                <Field
                  id="subitem"
                  name="subitem"
                  component={FormikInputText}
                  label="CNAE Item"
                  disabled={formikBag.isSubmitting}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Field
                  id="descsubitem"
                  name="descsubitem"
                  component={FormikInputText}
                  label="Desc. CNAE ITEM"
                  disabled={formikBag.isSubmitting}
                  multiline
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Field
                  id="codigotributacaomunicipio"
                  name="codigotributacaomunicipio"
                  component={FormikInputText}
                  label="Cod. Trib. Município"
                  disabled={formikBag.isSubmitting}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Field
                  id="desccnae"
                  name="desccnae"
                  component={FormikInputText}
                  label="Desc. Trib. Município"
                  disabled={formikBag.isSubmitting}
                  multiline
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Field
                  id="descservico"
                  name="descservico"
                  component={FormikInputText}
                  label="Descritivo Seriv. Prestado"
                  disabled={formikBag.isSubmitting}
                  multiline
                />
              </Grid>
            </Grid>
          </fieldset>
          <fieldset style={{ marginTop: 20 }}>
            <legend>Taxas</legend>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Field id="pis" name="pis" component={FormikCurrencyInput} label="PIS" />
              </Grid>
              <Grid item xs={12} md={6}>
                <Field id="cofins" name="cofins" component={FormikCurrencyInput} label="COFINS" />
              </Grid>
              <Grid item xs={12} md={6}>
                <Field id="ir" name="ir" component={FormikCurrencyInput} label="IR" />
              </Grid>
              <Grid item xs={12} md={6}>
                <Field id="csll" name="csll" component={FormikCurrencyInput} label="CSLL" />
              </Grid>
              <Grid item xs={12} md={6}>
                <Field id="iss" name="iss" component={FormikCurrencyInput} label="ISS" />
              </Grid>
              <Grid item xs={12} md={6}>
                <Field
                  id="valorminimocalculoir"
                  name="valorminimocalculoir"
                  component={FormikCurrencyInput}
                  label="Valor(R$) minimo calculo IR"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Field
                  id="valorminimocalculopiscofinscsll"
                  name="valorminimocalculopiscofinscsll"
                  component={FormikCurrencyInput}
                  label="Valor(R$) minimo calculo Pis/Cofins/CSLL"
                />
              </Grid>
            </Grid>
          </fieldset>
          <fieldset style={{ marginTop: 20 }}>
            <legend>Arquivos</legend>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <b>Logo Empresa</b>
                <MaterialDropZone
                  acceptedFiles={["image/*"]}
                  files={[]}
                  showPreviews
                  maxSize={5000000}
                  filesLimit={1}
                  text="Arraste sua logo para esta tela ou clique aqui para selecioná-los."
                  uploadMethod={(files: Array<File>) => {
                    if (files && files.length > 0) {
                      convertUpload(files[0], "logo");
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <b>Certificado</b>
                <MaterialDropZone
                  acceptedFiles={["image/*"]}
                  files={[]}
                  showPreviews
                  maxSize={500000}
                  filesLimit={1}
                  text="Arraste seu certificado para esta tela ou clique aqui para selecioná-los."
                  uploadMethod={(files: Array<File>) => convertUpload(files[0], "certificado")}
                />
              </Grid>
            </Grid>
          </fieldset>
        </Grid>
      </Grid>
    </fieldset>
  );
}

export default FormPerfil;
