import DeleteIcon from "@material-ui/icons/Delete";
import GetApp from "@material-ui/icons/GetApp";
import Refresh from "@material-ui/icons/Refresh";
import React from "react";
import { useHistory } from "react-router";
import Wrapper from "../../componentes/CRUD";
import Loading from "../../componentes/Loading/Loading";
import {
  DeletarClientesDocument,
  DeletarManutencaoArquivosDocument,
  DeletePlanosConciliadoresDocument,
  DeletePlanosPosDocument,
  DeleteTaxasAntecipacaoDocument,
  DownloadArquivoConciliacaoEmAbertoDocument,
  DownloadExtratosOriginaisDocument,
  DownloadManutencaoArquivosDocument,
  GetAllDadosNFeDocument,
  GetClientesDocument,
  GetUsuariosMestresDocument,
  GetUsuariosMestresRevendaDocument,
  ListarConciliacaoEmAbertoDocument,
  ListarExtratosOriginaisDocument,
  ListarFaturamentoMovimentoDocument,
  ListarManutencaoArquivosDocument,
  ListarMovimentosFechadosDocument,
  ListarPerfilDocument,
  ListarPlanosConciliadoresDocument,
  ListarPlanosPosDocument,
  ListarTaxasAntecipacaoDocument,
  ListarTerminalDocument,
  ListarTicketsDocument,
  ListarUsuarioDocument,
  PesquisarTicketsDocument,
  ReprocessarManutencaoArquivosDocument,
} from "../../graphql/components";
import Routes from "../../utils/routes";
import {
  AjudaMapped,
  AlteraSenhaMapped,
  FilterConciliacaoEmAberto,
  FilterExtratosOriginais,
  FilterImportacaoTicket,
  FilterManutencaoArquivosUnico,
  FilterPesquisaTickets,
  HomeMapped,
  Integracao,
  NewEditCliente,
  NewEditPerfil,
  NewEditTaxasAntecipacao,
  NewEditTerminal,
  NewEditUsuario,
  Painel,
  Relatorios,
} from "../pageListAsync";
import Atendimento from "../Pages/Atendimento/atendimento";
import Treinamento from "../Pages/Treinamento/treinamento";
import { colunasClientes, deleteMutationClientes, variablesClientes } from "../Pages/Clientes/utilsClientes";
import FilterConciliacaoEmAbertoC from "../Pages/ConciliacaoEmAberto/filterConciliacaoEmAberto";
import {
  columnsOrderConciliacaoEmAberto,
  colunasConciliacaoEmAberto,
  downloadArquivoConciliacaoEmAberto,
  getMuiThemeConciliacaoEmAberto,
  mapVariablesFiltroConciliacaoEmAberto,
  variablesConciliacaoEmAberto,
  variablesValorExibicaoConciliacaoEmAberto,
} from "../Pages/ConciliacaoEmAberto/utilsConciliacaoEmAberto";
import FilterDadosNFe from "../Pages/DadosNFe/filterDadosNFe";
import NewEditDadosNFe from "../Pages/DadosNFe/newEditDadosNFe";
import {
  columnsOrderDadosNFe,
  colunasDadosNFe,
  mapVariablesDadosNFe,
  variablesDadosNFe,
  variablesDadosNFeExibicao,
} from "../Pages/DadosNFe/utilsDadosNFe";
import DashboardGrafico from "../Pages/DashBoard/dashboardGrafico";
import { mapVariablesDashBoard, variablesDashBoard, variablesDashBoardExibicao } from "../Pages/DashBoard/utilsDashBoard";
import NotAllowed from "../Pages/Error/notAllowed";
import FilterExtratosOriginaisC from "../Pages/ExtratosOriginais/filterExtratosOriginais";
import {
  baixarArquivoExtratosOriginais,
  colunasExtratosOriginais,
  mapVariablesFiltroExtratosOriginais,
  variablesExtratosOriginais,
  variablesExtratosOriginaisExibicao,
} from "../Pages/ExtratosOriginais/utilsExtratosOriginais";
import FaturamentoMovimentoNota from "../Pages/FaturamentoMovimento/faturamentoMovimentoNota";
import FilterFaturamentoMovimento from "../Pages/FaturamentoMovimento/filterFaturamentoMovimento";
import FilterFaturamentoMovimentoA from "../Pages/FaturamentoMovimento/filterMovimentosFechadosA";
import {
  columnsOrderFaturamentoMovimento,
  colunasFaturamentoMovimento,
  getActionsFaturamentoMovimento,
  mapVariablesFaturamentoMovimento,
  variablesFaturamentoMovimento,
  variablesFaturamentoMovimentoExibicao,
} from "../Pages/FaturamentoMovimento/utilsFaturamentoMovimento";
import FechaMovimentoPrevisao from "../Pages/FechaMovimentoPrevisao/fechaMovimentoPrevisao";
import FilterImportacaoTicketC from "../Pages/ImportacaoTicket/filterImportacaoTicket";
import {
  actionsImportacaoTicket,
  columnsOrderImportacaoTicket,
  colunasImportacaoTicket,
  mapVariablesFiltroImportacaoTicket,
  variablesImportacaoTicket,
  variablesImportacaoTicketExibicao,
} from "../Pages/ImportacaoTicket/utilsImportacaoTicket";
import InserirTicket from "../Pages/InserirTicket/inserirTicket";
import FilterArquivoUnico from "../Pages/manutencaoArquivosUnico/filterManutencaoArquivosUnico";
import {
  baixarArquivoManutencaoArquivoUnico,
  columnsOrderManutencaoArquivoUnico,
  colunasManutencaoArquivoUnico,
  deletarArquivoManutencaoArquivoUnico,
  getMuiThemeoManutencaoArquivoUnico,
  mapVariablesFiltroManutencaoArquivoUnico,
  reprocessarArquivoManutencaoArquivoUnico,
  variablesManutencaoArquivoUnico,
  variablesManutencaoArquivoUnicoExibicao,
} from "../Pages/manutencaoArquivosUnico/utilsManutencaoArquivosUnico";
import { PosicaoContratoClienteConciliador } from "../Pages/Monitor/PosicaoContratoClienteConciliador";
import FilterMovimentosFechados from "../Pages/MovimentosFechados/filterMovimentosFechados";
import {
  actionsTableMovimentosFechados,
  columnsOrderMovimentosFechados,
  colunasMovimentosFechados,
  mapVariablesFiltroMovimentosFechados,
  variablesMovimentosFechados,
  variablesMovimentosFechadosExibicao,
} from "../Pages/MovimentosFechados/utilsMovimentosFechados";
import Parametros from "../Pages/Parametros/parametros";
import { columnsOrderPerfil, colunasPerfil, variablesPerfil } from "../Pages/Perfil/utilsPerfil";
import DetalharTicket from "../Pages/PesquisaTicket/detalharTicket";
import FilterPesquisaTicketsC from "../Pages/PesquisaTicket/filterPesquisaTicket";
import {
  columnsOrderPesquisaTickets,
  colunasPesquisaTickets,
  getActionsTablePesquisaTickets,
  mapVariablesFiltroPesquisaTickets,
  variablesPesquisaTickets,
  variablesPesquisaTicketsExibicao,
} from "../Pages/PesquisaTicket/utilsPesquisaTickets";
import InserirRapido from "../Pages/PlanosConciliadores/inserirRapido";
import newEditPlanosConciliadores from "../Pages/PlanosConciliadores/newEditPlanosConciliadores";
import PlanosOpcao from "../Pages/PlanosConciliadores/planosOpcao";
import {
  actionsTableNotSelectedPlanosConciliadores,
  actionsTablePlanosConciliadores,
  columnsOrderPlanosConciliadores,
  colunasPlanosConciliadores,
  variablesPlanosConciliadores,
} from "../Pages/PlanosConciliadores/utilsPlanosConciliadores";
import newEditPlanosPOS from "../Pages/PlanosPOS/planos/newEditPlanosPOS";
import PlanosOpcaoPOS from "../Pages/PlanosPOS/planos/planosOpcaoPOS";
import {
  actionsTablePlanosPOS,
  columnsOrderPlanosPOS,
  colunasPlanosPOS,
  variablesPlanosPOS,
} from "../Pages/PlanosPOS/planos/utilsPlanosPOS";
import {
  colunasTaxasAntecipacao,
  deleteMutationTaxasAntecipacao,
  variablesTaxasAntecipacao,
} from "../Pages/TaxasAntecipacao/utilsTaxasAntecipacao";
import { columnsOrderTerminal, colunasTerminal, variablesTerminal } from "../Pages/Terminal/utilsTerminal";
import { columnsOrderUsuario, colunasUsuario, variablesUsuario } from "../Pages/Usuario/utilsUsuario";
import NewEditUsuarioMestre from "../Pages/UsuariosMestres/newEditUsuarioMestre";
import Tefs from "../Pages/UsuariosMestres/tefs";
import SignUp from "../Pages/SignUp";
import {
  actionsTableUsuariosMestres,
  columnsOrderUsuarioMestre,
  colunasUsuarioMestre,
} from "../Pages/UsuariosMestres/utilsUsuariosMestres";

export const SignUpSuspense = () => (
  <React.Suspense fallback={Loading}>
    <SignUp />
  </React.Suspense>
);

export const Home = () => (
  <React.Suspense fallback={Loading}>
    <HomeMapped />
  </React.Suspense>
);

export const Ajuda = () => (
  <React.Suspense fallback={Loading}>
    <AjudaMapped />
  </React.Suspense>
);

export const AlteraSenha = () => (
  <React.Suspense fallback={Loading}>
    <AlteraSenhaMapped />
  </React.Suspense>
);

export const Panel = () => (
  <React.Suspense fallback={Loading}>
    <Painel />
  </React.Suspense>
);

export const Integration = () => (
  <React.Suspense fallback={Loading}>
    <Integracao />
  </React.Suspense>
);

export const User = () => (
  <React.Suspense fallback={Loading}>
    <Wrapper
      titulo="Usuário"
      colunas={colunasUsuario}
      variables={variablesUsuario}
      columnsOrder={columnsOrderUsuario}
      query={{ query: ListarUsuarioDocument, queryName: "listarUsuario" }}
      NewComponent={NewEditUsuario}
    />
  </React.Suspense>
);

export const Profile = () => (
  <React.Suspense fallback={Loading}>
    <Wrapper
      titulo="Perfil"
      colunas={colunasPerfil}
      columnsOrder={columnsOrderPerfil}
      variables={variablesPerfil}
      query={{ query: ListarPerfilDocument, queryName: "listarPerfil" }}
      //deleteMutation={DeletePerfilDocument}
      //actionsTable={[{ actionFunction: deleteMutationPerfil }]}
      NewComponent={NewEditPerfil}
    />
  </React.Suspense>
);

export const Terminal = () => (
  <React.Suspense fallback={Loading}>
    <Wrapper
      titulo="Terminal"
      colunas={colunasTerminal}
      columnsOrder={columnsOrderTerminal}
      variables={variablesTerminal}
      query={{ query: ListarTerminalDocument, queryName: "listarTerminal" }}
      NewComponent={NewEditTerminal}
    />
  </React.Suspense>
);

export const Reports = () => (
  <React.Suspense fallback={Loading}>
    <Relatorios />
  </React.Suspense>
);

export const AntecipationFees = () => (
  <React.Suspense fallback={Loading}>
    <Wrapper
      titulo="Taxas de antecipação"
      colunas={colunasTaxasAntecipacao}
      variables={variablesTaxasAntecipacao}
      query={{ query: ListarTaxasAntecipacaoDocument, queryName: "listarTaxasAntecipacao" }}
      deleteMutation={DeleteTaxasAntecipacaoDocument}
      actionsTable={[{ actionFunction: deleteMutationTaxasAntecipacao }]}
      NewComponent={NewEditTaxasAntecipacao}
    />
  </React.Suspense>
);

export const ConciliatingClientContractPosition = () => (
  <React.Suspense fallback={Loading}>
    <PosicaoContratoClienteConciliador />
  </React.Suspense>
);

export const ClienteSuspense = () => (
  <React.Suspense fallback={Loading}>
    <Wrapper
      titulo="Cliente"
      colunas={colunasClientes}
      variables={variablesClientes}
      query={{ query: GetClientesDocument, queryName: "getClientes" }}
      deleteMutation={DeletarClientesDocument}
      actionsTable={[{ actionFunction: deleteMutationClientes }]}
      NewComponent={NewEditCliente}
    />
  </React.Suspense>
);

export const OpenConciliations = React.memo(() => (
  <React.Suspense fallback={Loading}>
    <Wrapper
      titulo="Conciliação em Aberto"
      colunas={colunasConciliacaoEmAberto}
      columnsOrder={columnsOrderConciliacaoEmAberto}
      themeTable={getMuiThemeConciliacaoEmAberto}
      variables={
        FilterConciliacaoEmAbertoC.filterConciliacaoEmAbertoVariables
          ? { ...FilterConciliacaoEmAbertoC.filterConciliacaoEmAbertoVariables }
          : { ...variablesConciliacaoEmAberto }
      }
      query={{ query: ListarConciliacaoEmAbertoDocument, queryName: "listarConciliacaoEmAberto" }}
      mapVariablesFiltro={mapVariablesFiltroConciliacaoEmAberto}
      actionsTable={[
        {
          titleAction: "Baixar Arquivo",
          iconAction: <GetApp />,
          actionFunction: downloadArquivoConciliacaoEmAberto,
          mutation: DownloadArquivoConciliacaoEmAbertoDocument,
        },
      ]}
      variablesValorExibicao={variablesValorExibicaoConciliacaoEmAberto}
      FilterComponent={FilterConciliacaoEmAberto}
    />
  </React.Suspense>
));

export const InsertTickets = () => (
  <React.Suspense fallback={Loading}>
    <InserirTicket />
  </React.Suspense>
);

export const GraphicDashboard = () => (
  <React.Suspense fallback={Loading}>
    <DashboardGrafico
      variablesValorExibicao={variablesDashBoardExibicao}
      variables={variablesDashBoard}
      mapVariablesFiltro={mapVariablesDashBoard}
      isFiltering={false}
    />
  </React.Suspense>
);

export const SearchTicketToImport = () => (
  <React.Suspense fallback={Loading}>
    <Wrapper
      titulo="Importação de Tickets"
      colunas={colunasImportacaoTicket}
      variables={
        FilterImportacaoTicketC.filterImportacaoTicketVariables
          ? { ...FilterImportacaoTicketC.filterImportacaoTicketVariables }
          : { ...variablesImportacaoTicket }
      }
      query={{ query: ListarTicketsDocument, queryName: "listarTickets" }}
      mapVariablesFiltro={mapVariablesFiltroImportacaoTicket}
      columnsOrder={columnsOrderImportacaoTicket}
      actionsTable={actionsImportacaoTicket}
      variablesValorExibicao={variablesImportacaoTicketExibicao}
      FilterComponent={FilterImportacaoTicket}
    />
  </React.Suspense>
);

export const MaintainingOriginalExtractFiles = () => (
  <React.Suspense fallback={Loading}>
    <Wrapper
      titulo="Extratos originais"
      colunas={colunasExtratosOriginais}
      variables={
        FilterExtratosOriginaisC.filterExtratosOriginaisVariables
          ? { ...FilterExtratosOriginaisC.filterExtratosOriginaisVariables }
          : { ...variablesExtratosOriginais }
      }
      query={{ query: ListarExtratosOriginaisDocument, queryName: "listarExtratosOriginais" }}
      mapVariablesFiltro={mapVariablesFiltroExtratosOriginais}
      actionsTable={[
        {
          titleAction: "Baixar Arquivo",
          iconAction: <GetApp />,
          actionFunction: baixarArquivoExtratosOriginais,
          mutation: DownloadExtratosOriginaisDocument,
        },
      ]}
      variablesValorExibicao={variablesExtratosOriginaisExibicao}
      FilterComponent={FilterExtratosOriginais}
    />
  </React.Suspense>
);

export const FileMaintenance = () => (
  <React.Suspense fallback={Loading}>
    <Wrapper
      titulo="Manutenção de Arquivos"
      colunas={colunasManutencaoArquivoUnico}
      variables={
        FilterArquivoUnico.filterManutencaoArquivosUnicosVariables
          ? { ...FilterArquivoUnico.filterManutencaoArquivosUnicosVariables }
          : { ...variablesManutencaoArquivoUnico }
      }
      query={{ query: ListarManutencaoArquivosDocument, queryName: "listarManutencaoArquivos" }}
      mapVariablesFiltro={mapVariablesFiltroManutencaoArquivoUnico}
      themeTable={getMuiThemeoManutencaoArquivoUnico}
      columnsOrder={columnsOrderManutencaoArquivoUnico}
      deleteMutation={DeletarManutencaoArquivosDocument}
      actionsTable={[
        {
          titleAction: "Baixar Arquivo",
          iconAction: <GetApp />,
          actionFunction: baixarArquivoManutencaoArquivoUnico,
          mutation: DownloadManutencaoArquivosDocument,
        },
        {
          titleAction: "Reprocessar",
          iconAction: <Refresh />,
          actionFunction: reprocessarArquivoManutencaoArquivoUnico,
          mutation: ReprocessarManutencaoArquivosDocument,
        },
        {
          titleAction: "Deletar",
          iconAction: <DeleteIcon />,
          actionFunction: deletarArquivoManutencaoArquivoUnico,
        },
      ]}
      variablesValorExibicao={variablesManutencaoArquivoUnicoExibicao}
      FilterComponent={FilterManutencaoArquivosUnico}
    />
  </React.Suspense>
);

export const TicketSearch = () => (
  <React.Suspense fallback={Loading}>
    <Wrapper
      titulo="Pesquisa de Tickets"
      colunas={colunasPesquisaTickets}
      variables={
        FilterPesquisaTicketsC.filterPesquisaTicketsVariables
          ? { ...FilterPesquisaTicketsC.filterPesquisaTicketsVariables }
          : { ...variablesPesquisaTickets }
      }
      query={{ query: PesquisarTicketsDocument, queryName: "pesquisarTickets" }}
      mapVariablesFiltro={mapVariablesFiltroPesquisaTickets}
      columnsOrder={columnsOrderPesquisaTickets}
      actionsTable={getActionsTablePesquisaTickets}
      variablesValorExibicao={variablesPesquisaTicketsExibicao}
      fullScreenTitle="Rastreabilidade do Ticket"
      FilterComponent={FilterPesquisaTickets}
      FullScreenDrawerComponent={DetalharTicket}
    />
  </React.Suspense>
);

export const NotAllowedSuspenseComponent = () => (
  <React.Suspense fallback={Loading}>
    <NotAllowed />
  </React.Suspense>
);

export const ComponentPlanos = () => (
  <React.Suspense fallback={Loading}>
    <Wrapper
      titulo="Planos & Taxas"
      colunas={colunasPlanosConciliadores}
      columnsOrder={columnsOrderPlanosConciliadores}
      variables={variablesPlanosConciliadores}
      query={{
        query: ListarPlanosConciliadoresDocument,
        queryName: "listarPlanosConciliadores",
      }}
      deleteMutation={DeletePlanosConciliadoresDocument}
      actionsTable={actionsTablePlanosConciliadores}
      actionsTableNotSelected={actionsTableNotSelectedPlanosConciliadores}
      fullScreenTitle="Planos Conciliadores Contrato"
      rightDrawerWidth={500}
      NewComponent={newEditPlanosConciliadores}
      RightDrawerComponent={InserirRapido}
      FullScreenDrawerComponent={PlanosOpcao}
    />
  </React.Suspense>
);

export const ComponentPlanosTerminal = () => (
  <React.Suspense fallback={Loading}>
    <Wrapper
      titulo="Planos POS"
      colunas={colunasPlanosPOS}
      columnsOrder={columnsOrderPlanosPOS}
      variables={variablesPlanosPOS}
      query={{
        query: ListarPlanosPosDocument,
        queryName: "listarPlanosPOS",
      }}
      deleteMutation={DeletePlanosPosDocument}
      actionsTable={actionsTablePlanosPOS}
      fullScreenTitle="Planos Terminal POS"
      rightDrawerWidth={500}
      NewComponent={newEditPlanosPOS}
      FullScreenDrawerComponent={PlanosOpcaoPOS}
    />
  </React.Suspense>
);

export const FaturamentoMovimento = () => (
  <React.Suspense fallback={Loading}>
    <Wrapper
      titulo={Routes.faturamentomovimento.titulo}
      colunas={colunasFaturamentoMovimento}
      variablesValorExibicao={variablesFaturamentoMovimentoExibicao}
      columnsOrder={columnsOrderFaturamentoMovimento}
      variables={variablesFaturamentoMovimento}
      mapVariablesFiltro={mapVariablesFaturamentoMovimento}
      query={{
        query: ListarFaturamentoMovimentoDocument,
        queryName: "listarFaturamentoMovimento",
      }}
      actionsTable={getActionsFaturamentoMovimento}
      rightDrawerWidth={500}
      RightDrawerComponent={FaturamentoMovimentoNota}
      FilterComponent={FilterFaturamentoMovimento}
      themeTable={getMuiThemeConciliacaoEmAberto}
    />
  </React.Suspense>
);

export const MovimentosFechados = (props: any) => (
  <React.Suspense fallback={Loading}>
    <Wrapper
      titulo={Routes.fechaMovimentoReimpressao.titulo}
      colunas={colunasFaturamentoMovimento}
      variablesValorExibicao={variablesFaturamentoMovimentoExibicao}
      columnsOrder={columnsOrderFaturamentoMovimento}
      variables={variablesFaturamentoMovimento}
      mapVariablesFiltro={mapVariablesFaturamentoMovimento}
      query={{
        query: ListarFaturamentoMovimentoDocument,
        queryName: "listarFaturamentoMovimento",
      }}
      actionsTable={getActionsFaturamentoMovimento}
      rightDrawerWidth={500}
      RightDrawerComponent={FaturamentoMovimentoNota}
      FilterComponent={FilterFaturamentoMovimentoA}
      themeTable={getMuiThemeConciliacaoEmAberto}
    />
  </React.Suspense>
);

export const FechaMovimentoPrevisaoSuspense = () => (
  <React.Suspense fallback={Loading}>
    <FechaMovimentoPrevisao />
  </React.Suspense>
);

export const ParametrosSuspense = () => (
  <React.Suspense fallback={Loading}>
    <Parametros />
  </React.Suspense>
);

export const AtendimentoSuspense = () => (
  <React.Suspense fallback={Loading}>
    <Atendimento />
  </React.Suspense>
);


export const TreinamentoSuspense = () => (
  <React.Suspense fallback={Loading}>
    <Treinamento />
  </React.Suspense>
);

export const MovimentosFechadosSuspense = () => (
  <React.Suspense fallback={Loading}>
    <Wrapper
      titulo={Routes.fechamovimento.titulo}
      colunas={colunasMovimentosFechados}
      variablesValorExibicao={variablesMovimentosFechadosExibicao}
      columnsOrder={columnsOrderMovimentosFechados}
      variables={variablesMovimentosFechados}
      mapVariablesFiltro={mapVariablesFiltroMovimentosFechados}
      query={{
        query: ListarMovimentosFechadosDocument,
        queryName: "listarMovimentosFechados",
      }}
      actionsTable={actionsTableMovimentosFechados}
      FilterComponent={FilterMovimentosFechados}
      themeTable={getMuiThemeConciliacaoEmAberto}
    />
  </React.Suspense>
);

export const UsuariosMestresSuspense = () => {
  const history = useHistory();
  const query = { query: GetUsuariosMestresDocument, queryName: "getUsuariosMestres" };
  let rightComponent: any | null = Tefs;

  if (history.location.pathname === Routes.buscaMestresRevenda.route) {
    query.query = GetUsuariosMestresRevendaDocument;
    query.queryName = "getUsuariosMestresRevenda";
    rightComponent = null;
  }

  return (
    <React.Suspense fallback={Loading}>
      <Wrapper
        titulo="Listar Usuários Mestres"
        colunas={colunasUsuarioMestre}
        columnsOrder={columnsOrderUsuarioMestre}
        variables={{}}
        query={query}
        //deleteMutation={DeletePerfilDocument}
        actionsTable={actionsTableUsuariosMestres}
        NewComponent={NewEditUsuarioMestre}
        RightDrawerComponent={rightComponent}
        rightDrawerWidth={260}
        hideAdd
      />
    </React.Suspense>
  );
};

export const DadosNFeSuspense = () => (
  <React.Suspense fallback={Loading}>
    <Wrapper
      titulo={Routes.dadosNFe.titulo}
      colunas={colunasDadosNFe}
      variablesValorExibicao={variablesDadosNFeExibicao}
      columnsOrder={columnsOrderDadosNFe}
      variables={variablesDadosNFe}
      mapVariablesFiltro={mapVariablesDadosNFe}
      query={{
        query: GetAllDadosNFeDocument,
        queryName: "getAllDadosNFe",
      }}
      FilterComponent={FilterDadosNFe}
      NewComponent={NewEditDadosNFe}
    />
  </React.Suspense>
);
