import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import React, { PureComponent } from "react";
import { formatterCurrencySemSigla, formatterCurrency } from "../../../../utils/formatacaoNumeros";

interface IProps {
  series: any;
}

export class GraficoVendaTipo extends PureComponent<IProps> {
  chartComponent: React.RefObject<HighchartsReact>;
  constructor(props: IProps) {
    super(props);
    this.chartComponent = React.createRef();
  }

  componentDidMount() {
    const container = this.chartComponent.current?.container.current;

    if (container) {
      container.style.height = "100%";
      container.style.width = "100%";
      this.chartComponent.current?.chart.reflow();
    }
  }
  convertResult = (values: Array<any>) => {
    const result: Array<any> = [];

    values.forEach(v => {
      if (v.sliced != null) {
        result.push({ name: v.name, y: v.y, sliced: v.sliced, selected: v.selected });
      } else {
        result.push({ name: v.name, y: v.y });
      }
    });

    return result;
  };

  render() {
    return (
      <div>
        <HighchartsReact
          ref={this.chartComponent}
          highcharts={Highcharts}
          options={{
            credits: {
              enabled: false
            },
            title: {
              verticalAlign: "middle",
              floating: true,
              text: "",
              style: {
                fontSize: "10px"
              }
            },
            tooltip: {
              valueDecimals: 2,
              valuePrefix: "R$",
              formatter() {
                const valor = this.y.toFixed(2);
                const taxa = parseFloat(valor);
                return formatterCurrency(taxa);
              }
            },
            plotOptions: {
              pie: {
                dataLabels: {
                  formatter() {
                    const operadora = this.point.name;
                    const valor = this.point.percentage.toFixed(2);
                    const taxa = parseFloat(valor);
                    return operadora + ": " + formatterCurrencySemSigla(taxa) + " %";
                  }
                }
              }
            },
            series: [
              {
                // plotBackgroundColor: null,
                // plotBorderWidth: null,
                // plotShadow: false,
                type: "pie" as any,
                name: "Valor",
                colorByPoint: true,
                data: this.convertResult(this.props.series)
              }
            ]
          }}
        />
      </div>
    );
  }
}

export default GraficoVendaTipo;
