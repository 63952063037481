import React from "react";
import { connect } from "react-redux";

import { withTheme, withStyles, createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import "../../styles/components/vendors/react-loading-bar/index.css";

import applicationTheme from "../../styles/theme/applicationTheme";

const styles = {
  root: {
    width: "100%",
    minHeight: "100%",
    marginTop: 0,
    zIndex: 1
  }
};

//export const AppContext = React.createContext();

class ThemeWrapper extends React.Component<ThemeWrapperProps, IThemeWrapperState> {
  constructor(props: ThemeWrapperProps) {
    super(props);
    this.state = {
      pageLoaded: true,
      theme: createMuiTheme(applicationTheme("blueCyanTheme", "light"))
    };
  }

  componentWillMount = () => {
    this.onProgressShow();
  };

  componentDidMount = () => {
    this.playProgress();
  };

  componentWillUnmount() {
    this.onProgressShow();
  }

  onProgressShow = () => {
    this.setState({ pageLoaded: true });
  };

  onProgressHide = () => {
    this.setState({ pageLoaded: false });
  };

  playProgress = () => {
    this.onProgressShow();
    setTimeout(() => {
      this.onProgressHide();
    }, 500);
  };

  render() {
    const { classes, children } = this.props;
    const { theme } = this.state;
    return (
      <MuiThemeProvider theme={theme}>
        <div className={classes.root}>{children}</div>
      </MuiThemeProvider>
    );
  }
}

interface ThemeWrapperProps {
  classes: any;
  children: any;
}

interface IThemeWrapperState {
  pageLoaded: boolean;
  theme: any;
}

const mapStateToProps = (state: any) => ({
  force: state
  //color: state.getIn([reducer, 'theme']),
  //mode: state.getIn([reducer, 'type']),
});

const ThemeWrapperMapped = connect(mapStateToProps, null)(ThemeWrapper);

export default withTheme(withStyles(styles)(ThemeWrapperMapped));
