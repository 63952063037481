export default function downloadUtils(path: string, data?: any) {
  if (data) {
    const blob = new Blob([data]);
    if (navigator.msSaveBlob) {
      // IE 10+
      navigator.msSaveBlob(blob, path);
    } else {
      const link = document.createElement("a");

      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        //link.setAttribute("download", path);
        link.style.visibility = "hidden";

        if (path && path.toLowerCase().indexOf("pdf") > -1) {
          link.target = "_blank";
        } else if(path && (path.toLowerCase().indexOf("txt") > -1 || path.toLowerCase().indexOf("csv") > -1)) {
            link.setAttribute("download", path);
            link.download = `${process.env.REACT_APP_HOST}/temp/${path}`.split("/").pop()!;
        }

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        link.remove();
      }
    }
  } else {
    const link = document.createElement("a");
    const uri = `${process.env.REACT_APP_HOST}/temp/${path}`;
    link.href = uri;
    
    //TXT -> fazer o fetch para realizar o download, porem está dando erro de cors
    // const uri = `${process.env.REACT_APP_HOST}/temp${path}`;
    // fetch(uri, { credentials: "include" })
    //   .then(res => res.text())
    //   .then(r => console.log(r));
    
    // eslint-disable-next-line
    if ((path && (path.toLowerCase().indexOf("pdf") > -1 || path.toLowerCase().indexOf("xls") > -1))
    ) {
      link.target = "_blank";
    } else {
      link.download = `${process.env.REACT_APP_HOST}/temp/${path}`.split("/").pop()!;
    }
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    link.remove();
  }
}
