export function formatterPercent(valor: number) {
    return new Intl.NumberFormat('pt-BR', { style: 'percent', currency: 'BRL' }).format(valor);
};

export function formatterCurrency(valor: number) {
    return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(valor);
}

export function formatterCurrencySemSigla(valor: number) {
    return new Intl.NumberFormat('pt-BR', { currency: 'BRL' }).format(valor);
}

export function formatterDecimal(valor: number) {
    return new Intl.NumberFormat('pt-BR', { style: 'decimal', currency: 'BRL' }).format(valor);
}
