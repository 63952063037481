import { DataProxy } from "apollo-cache";
import moment from "moment";
import { forkJoin } from "rxjs";
import { CallbackActionType } from "../../../../componentes/CRUD/actionsTable";
import ColumnCrudTableType from "../../../../componentes/CRUD/columnCrudTableType";
import {
  DeletePlanosTerminalMutation,
  DeletePlanosTerminalMutationVariables,
  ListarPlanosTerminalDocument,
  ListarPlanosTerminalQuery,
  ListarPlanosTerminalQueryVariables
} from "../../../../graphql/components";

export const variablesPlanosTerminal: {
  codPlanosTerminal: number | null;
} = {
  codPlanosTerminal: null
};

export const mapVariablesFiltroPlanosTerminal = {
  vigenciade: "De",
  vigenciaate: "Até"
};

export const deleteMutationPlanosTerminal: CallbackActionType<
  DeletePlanosTerminalMutation,
  DeletePlanosTerminalMutationVariables
> = (mutation, record, setMessage) => {
  if (mutation) {
    const deletesPromises: Array<any> = [];
    let codplanosterminal: number;
    record.forEach(r => {
      codplanosterminal = r.codplanosterminal;
      deletesPromises.push(
        mutation({
          variables: { values: { editValues: { codplanosterminal: r.codplanosterminal } } },
          update: (store: DataProxy, { data }) => {
            if (data!.deletePlanosTerminal) {
              const oldResult = store.readQuery<ListarPlanosTerminalQuery, ListarPlanosTerminalQueryVariables>({
                query: ListarPlanosTerminalDocument,
                variables: { values: { codplanosterminal: codplanosterminal } }
              });
              if (oldResult) {
                const index = oldResult.listarPlanosTerminal.findIndex(u => u.codplanosterminal === r.codplanosterminal);
                oldResult.listarPlanosTerminal.splice(index, 1);
                const result = [...oldResult.listarPlanosTerminal];
                store.writeQuery({
                  query: ListarPlanosTerminalDocument,
                  data: result,
                  variables: variablesPlanosTerminal
                });
              }
            }
          }
        }).catch(error => {
          return new Promise(resolve => resolve(error.message));
        })
      );
    });
    forkJoin(deletesPromises).subscribe(response => {
      const totalErrors = response.filter(r => typeof r === "string").length;
      if (totalErrors > 0 && totalErrors !== deletesPromises.length) {
        setMessage(
          true,
          "warning",
          "Alguns registros não foram excluídos. Dentro de 5 segundos iremos atualizar a página para manter os dados coerentes."
        );
        setTimeout(() => window.location.reload(), 6500);
      } else if (totalErrors === 0) {
        setMessage(true, "success", "Registro(s) excluídos com sucesso!");
      }
      return true;
    });
  }
};

export const colunasPlanosTerminal: Array<ColumnCrudTableType> = [
  {
    name: "Plano",
    options: {
      filter: true
    }
  },
  {
    name: "De",
    options: {
      filter: true,
      customBodyRender: (value: any) => moment(new Date(value)).format("DD/MM/YYYY")
    }
  },
  {
    name: "Até",
    options: {
      filter: true,
      customBodyRender: (value: any) => moment(new Date(value)).format("DD/MM/YYYY")
    }
  }
];

export const columnsOrderPlanosTerminal = ["plano", "vigenciaDe", "vigenciaAte"];

export const columnsOrder = ["plano", "vigenciaDe", "vigenciaAte"];
