import React, { PureComponent } from "react";
import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { formatterCurrency, formatterCurrencySemSigla } from "../../../../utils/formatacaoNumeros";

interface IProps {
  series: any;
}

export class GraficoMediaTicketOperadora extends PureComponent<IProps> {
  constructor(props: IProps) {
    super(props);
    this.state = {};
  }

  convertResultSeries = (values: Array<any>) => {
    const result: Array<any> = [];

    values.forEach(v => {
      if (v.sliced != null) {
        result.push({ name: v.name, y: v.y, sliced: v.sliced, selected: v.selected });
      } else {
        result.push({ name: v.name, y: v.y });
      }
    });

    return result;
  };

  render() {
    return (
      <div>
        <HighchartsReact
          highcharts={Highcharts}
          options={{
            credits: {
              enabled: false
            },
            chart: {
              type: "column"
            },
            title: {
              text: ""
            },
            subtitle: {
              text: ""
            },
            xAxis: {
              type: "category"
            },
            yAxis: {
              title: {
                text: "Valor R$"
              }
            },
            legend: {
              enabled: false
            },
            plotOptions: {
              series: {
                borderWidth: 0,
                dataLabels: {
                  enabled: true,
                  formatter() {
                    const valor = this.y === null ? "0" : this.y.toFixed(2);
                    const taxa = parseFloat(valor);
                    return formatterCurrencySemSigla(taxa);
                  }
                }
              }
            },

            tooltip: {
              //headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
              //pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>R$ {point.y:.2f}</b><br/>',
              formatter() {
                const operadora = this.point.name;
                const valor = this.y.toFixed(2);
                const taxa = parseFloat(valor);
                return "Operadora <br/>" + operadora + ": " + formatterCurrency(taxa);
              }
            },

            series: [
              {
                name: "Operadora",
                colorByPoint: true,
                type: "column",
                data: this.convertResultSeries(this.props.series)
              }
            ],
            exporting: {
              enabled: true
            }
          }}
        />
      </div>
    );
  }
}

export default GraficoMediaTicketOperadora;
