import React from "react";
import Wrapper from "../../../../componentes/CRUD";
import { FullScreenDrawerProps } from "../../../../componentes/CRUD/crudProps";
import SelectedRowsContext from "../../../../componentes/CRUD/selectedRowsContext";
import {
  DeletePlanosPosTaxasDocument,
  DeletePlanosTerminalDocument,
  GetFormularioPlanosOpcoesPlanosConciliadoresProps,
  ListarPlanosPosTaxasDocument,
  ListarPlanosTerminalDocument,
  withGetFormularioPlanosOpcoesPlanosConciliadores
} from "../../../../graphql/components";
import NewEditPlanosTaxas from "../planosTaxas/newEditPlanosTaxas";
import { columnsOrderPlanosPosTaxas, colunasPlanosPosTaxas, deleteMutationPlanosPosTaxas } from "../planosTaxas/utilsPlanosTaxas";
import NewEditPlanosTerminal from "../planosTerminal/newEditPlanosTerminal";
import {
  columnsOrderPlanosTerminal,
  colunasPlanosTerminal,
  deleteMutationPlanosTerminal
} from "../planosTerminal/utilsPlanosTerminal";

const PlanosOpcaoPOS: React.FunctionComponent<FullScreenDrawerProps<GetFormularioPlanosOpcoesPlanosConciliadoresProps>> = ({
  data
}) => {
  const context = React.useContext(SelectedRowsContext);
  const { codplano } = context[0];

  if (data && data.getFormularioPlanosOpcoesPlanosConciliadores) {
    switch (data.getFormularioPlanosOpcoesPlanosConciliadores.nomeFormulario) {
      case "TERMINAL": {
        return (
          <React.Fragment>
            <Wrapper
              titulo="Planos Terminal"
              colunas={colunasPlanosTerminal}
              variables={{ codplanos: codplano }}
              columnsOrder={columnsOrderPlanosTerminal}
              paramSize="x4Large"
              query={{
                query: ListarPlanosTerminalDocument,
                queryName: "listarPlanosTerminal"
              }}
              deleteMutation={DeletePlanosTerminalDocument}
              actionsTable={[{ actionFunction: deleteMutationPlanosTerminal }]}
              NewComponent={NewEditPlanosTerminal}
            />
          </React.Fragment>
        );
      }

      case "TAXASPOS": {
        return (
          <React.Fragment>
            <Wrapper
              titulo="Planos terminal taxas"
              colunas={colunasPlanosPosTaxas}
              variables={{ codplano: codplano }}
              columnsOrder={columnsOrderPlanosPosTaxas}
              query={{
                query: ListarPlanosPosTaxasDocument,
                queryName: "listarPlanosPOSTaxas"
              }}
              deleteMutation={DeletePlanosPosTaxasDocument}
              actionsTable={[{ actionFunction: deleteMutationPlanosPosTaxas }]}
              NewComponent={NewEditPlanosTaxas}
            />
          </React.Fragment>
        );
      }

      default:
        return null;
    }
  }
  return null;
};

const withMutation = withGetFormularioPlanosOpcoesPlanosConciliadores<FullScreenDrawerProps>();

export default withMutation(PlanosOpcaoPOS);
