import { Card, Grid, SwipeableDrawer, LinearProgress } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import React from "react";
import { Query } from "react-apollo";
import GenericVariables from "../../../componentes/CRUD/genericVariables";
import Loading from "../../../componentes/Loading/Loading";
import PapperBlock from "../../../componentes/PapperBlock/PapperBlock";
import SnackMessage from "../../../componentes/SnakBar";
import { writeDescFiltro } from "../../../componentes/Utils/pesquisa";
import { GetDashBoardDocument } from "../../../graphql/components";
import GraficoMediaTicketOperadora from "./componentes/graficoMediaTicketOperadora";
import GraficoTaxaMediaOperadora from "./componentes/graficoTaxaMediaOperadora";
import GraficoVendaDia from "./componentes/graficoVendaDia";
import GraficoVendaHora from "./componentes/graficoVendaHora";
import GraficoVendaOperadora from "./componentes/graficoVendaOperadora";
import GraficoVendaTipo from "./componentes/graficoVendaTipo";
import styles from "./dashboard-jss";
import "./estilo.css";
import FilterDashBoard from "./filterDashBoard";
import { variablesDashBoard, variablesDashBoardExibicao } from "./utilsDashBoard";
import * as Highcharts from "highcharts";
require("highcharts/modules/exporting")(Highcharts);
require("highcharts/modules/export-data")(Highcharts);

interface Props {
  classes?: any;
  width?: any;
  mapVariablesFiltro?: any;
  variablesValorExibicao?: GenericVariables;
  variables: GenericVariables;
  isFiltering: boolean;
}

interface State {
  hasCodCliente: boolean;
  message: string;
  messageVariant: "success" | "warning" | "error" | "info";
  messageOpen: boolean;
  marginRight?: number;
  codpessoa?: number;
  codoperadora?: number;
  codexterno?: number;
  loading: boolean;
  filterOpened: boolean;
  data: Array<any>;
  seriesVendaOperadora: Array<any>;
  seriesVendaTipo: Array<any>;
  categoriaVendaDia: Array<any>;
  seriesVendaDia: Array<any>;
  categoriaVendaHora: Array<any>;
  seriesVendaHora: Array<any>;
  seriesTicketMedio: Array<any>;
  categoriaTaxaMedia: Array<any>;
  seriesTaxaMedia: Array<any>;
}

class DashBoardGrafico extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      hasCodCliente: false,
      message: "",
      messageOpen: false,
      messageVariant: "success",
      loading: false,
      filterOpened: false,
      data: [],
      seriesVendaOperadora: [],
      seriesVendaTipo: [],
      categoriaVendaDia: [],
      seriesVendaDia: [],
      categoriaVendaHora: [],
      seriesVendaHora: [],
      seriesTicketMedio: [],
      categoriaTaxaMedia: [],
      seriesTaxaMedia: [],
    };
  }

  componentDidMount = () => {};

  closeMessage = () => this.setState({ messageOpen: false });

  openFilter = () => {
    this.setState({ filterOpened: true });
  };

  toggleFilter = () => {
    this.setState({ filterOpened: false });
  };

  pesquisando = (status: boolean) => {
    this.setState({ loading: status });
  };

  getDescricaoFiltro = () => {
    if (!this.props.mapVariablesFiltro) {
      return "Exibindo todos os registros.";
    }
    const retornoPrefix = "<b>Filtro: </b>";
    let retorno = "";
    Object.keys(this.props.mapVariablesFiltro).forEach((key: string, index: number) => {
      if (
        this.props.variables[key] != null &&
        this.props.variables[key]!.toString().length > 0 &&
        this.props.variables[key]!.toString() !== "[object Object]"
      ) {
        retorno += writeDescFiltro(this.props.variablesValorExibicao, key, this.props.mapVariablesFiltro);
      } else if (this.props.variables[key] != null && this.props.variables[key]!.toString().length > 0) {
        if (this.props.variables[key]! instanceof Object) {
          Object.keys(this.props.variables[key]!).forEach((subKey: string) => {
            if (
              (this.props.variables[key]! as any)[subKey] != null &&
              (this.props.variables[key]! as any)[subKey].toString().length > 0
            ) {
              retorno += writeDescFiltro(
                this.props.variablesValorExibicao![key]! as any,
                subKey,
                this.props.mapVariablesFiltro[key]
              );
            }
          });
        }
      }
    });
    return retorno.length > 0 ? `${retornoPrefix}${retorno}` : "";
  };

  search = async (refetchMethod: any) => {
    if (refetchMethod) {
      try {
        this.pesquisando(true);
        const response = await refetchMethod({ values: this.props.variables });
        this.setState({
          seriesVendaOperadora: response.data!.getDashBoard.seriesVendaOperadora,
          seriesVendaTipo: response.data!.getDashBoard.seriesVendaTipo,
          categoriaVendaDia: response.data!.getDashBoard.categoriaVendaDia,
          seriesVendaDia: response.data!.getDashBoard.seriesVendaDia,
          categoriaVendaHora: response.data!.getDashBoard.categoriaVendaHora,
          seriesVendaHora: response.data!.getDashBoard.seriesVendaHora,
          seriesTicketMedio: response.data!.getDashBoard.seriesTicketMedio,
          categoriaTaxaMedia: response.data!.getDashBoard.categoriaTaxaMedia,
          seriesTaxaMedia: response.data!.getDashBoard.seriesTaxaMedia,
        });
        this.toggleFilter();
      } catch (e) {
      } finally {
        this.pesquisando(false);
      }
    }
  };

  render() {
    const {
      message,
      messageVariant,
      messageOpen,
      filterOpened,
      seriesVendaOperadora,
      seriesVendaTipo,
      categoriaVendaDia,
      seriesVendaDia,
      categoriaVendaHora,
      seriesVendaHora,
      seriesTicketMedio,
      categoriaTaxaMedia,
      seriesTaxaMedia,
    } = this.state;

    return (
      <Query<any, any> variables={{ values: this.props.variables }} query={GetDashBoardDocument} errorPolicy="all">
        {({ data, loading, error, refetch }) => {
          if (loading) {
            return <Loading />;
          }

          if (!loading) {
            return (
              <React.Fragment>
                <SwipeableDrawer anchor="top" open={filterOpened} onClose={this.toggleFilter} onOpen={this.toggleFilter}>
                  <div style={{ minHeight: 250 }}>
                    <PapperBlock
                      title="Filtro"
                      icon="ios-search"
                      desc="Insira os filtros para efetuar uma pesquisa customizada"
                      whiteBg
                      stylesDesc={{ marginBottom: "20px" }}
                    >
                      <FilterDashBoard
                        variables={variablesDashBoard}
                        variablesValorExibicao={variablesDashBoardExibicao}
                        isFiltering={this.state.loading}
                      />

                      <div style={{ marginTop: "15px" }}>
                        <Button
                          disabled={this.state.loading}
                          variant="outlined"
                          color="primary"
                          onClick={this.search.bind(this, refetch)}
                          style={{ position: "absolute", bottom: 0, margin: "15px", right: 100 }}
                        >
                          Filtrar
                        </Button>
                        <Button
                          disabled={this.state.loading}
                          variant="outlined"
                          color="secondary"
                          onClick={this.toggleFilter}
                          style={{ position: "absolute", bottom: 0, margin: "15px", right: 0 }}
                        >
                          Fechar
                        </Button>
                      </div>
                    </PapperBlock>
                  </div>
                  {this.state.loading && <LinearProgress color="secondary" style={{ height: 5.5 }} />}
                </SwipeableDrawer>

                <PapperBlock title="DashBoard" openFilter={this.openFilter} desc={this.getDescricaoFiltro()}>
                  <Card elevation={20}>
                    <Grid container spacing={2}>
                      <Grid item md={6} xs={12}>
                        <PapperBlock
                          title={`${variablesDashBoardExibicao.registro === "Pagamento" ? "Pagamento" : "Venda"} / Operadora`}
                          desc={`Gráfico ${
                            variablesDashBoardExibicao.registro === "Pagamento" ? "Pagamento" : "Venda"
                          } por Operadora`}
                        >
                          <GraficoVendaOperadora
                            series={
                              seriesVendaOperadora.length === 0 ? data!.getDashBoard.seriesVendaOperadora : seriesVendaOperadora
                            }
                          />
                        </PapperBlock>
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <PapperBlock
                          title={`${variablesDashBoardExibicao.registro === "Pagamento" ? "Pagamento" : "Venda"} / Tipo`}
                          desc={`Gráfico ${variablesDashBoardExibicao.registro === "Pagamento" ? "Pagamento" : "venda"} por tipo`}
                        >
                          <GraficoVendaTipo
                            series={seriesVendaTipo.length === 0 ? data!.getDashBoard.seriesVendaTipo : seriesVendaTipo}
                          />
                        </PapperBlock>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item md={12} xs={12}>
                        <PapperBlock
                          title={`${variablesDashBoardExibicao.registro === "Pagamento" ? "Pagamento" : "Venda"} / Dia`}
                          desc={`Gráfico ${variablesDashBoardExibicao.registro === "Pagamento" ? "Pagamento" : "venda"} por dia`}
                        >
                          <GraficoVendaDia
                            series={seriesVendaDia.length === 0 ? data!.getDashBoard.seriesVendaDia : seriesVendaDia}
                            categoria={categoriaVendaDia.length === 0 ? data!.getDashBoard.categoriaVendaDia : categoriaVendaDia}
                          />
                        </PapperBlock>
                      </Grid>
                    </Grid>

                    <Grid container spacing={2}>
                      <Grid item md={12} xs={12}>
                        <PapperBlock
                          title={`${variablesDashBoardExibicao.registro === "Pagamento" ? "Pagamento" : "Venda"} / Hora`}
                          desc={`Gráfico ${variablesDashBoardExibicao.registro === "Pagamento" ? "Pagamento" : "venda"} por hora`}
                        >
                          <GraficoVendaHora
                            series={seriesVendaHora.length === 0 ? data!.getDashBoard.seriesVendaHora : seriesVendaHora}
                            categoria={
                              categoriaVendaHora.length === 0 ? data!.getDashBoard.categoriaVendaHora : categoriaVendaHora
                            }
                          />
                        </PapperBlock>
                      </Grid>
                    </Grid>

                    <Grid container spacing={2}>
                      <Grid item md={6} xs={12}>
                        <PapperBlock title="Taxa Média por Operadora" desc="Gráfico taxa média por operadora">
                          <GraficoTaxaMediaOperadora
                            series={seriesTaxaMedia.length === 0 ? data!.getDashBoard.seriesTaxaMedia : seriesTaxaMedia}
                            categoria={
                              categoriaTaxaMedia.length === 0 ? data!.getDashBoard.categoriaTaxaMedia : categoriaTaxaMedia
                            }
                          />
                        </PapperBlock>
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <PapperBlock title="Média de Ticket por Operadora" desc="Gráfico Média de Ticket por Operadora">
                          <GraficoMediaTicketOperadora
                            series={seriesTicketMedio.length === 0 ? data!.getDashBoard.seriesTicketMedio : seriesTicketMedio}
                          />
                        </PapperBlock>
                      </Grid>
                    </Grid>
                  </Card>
                  <SnackMessage message={message} variant={messageVariant} open={messageOpen} close={this.closeMessage} />
                </PapperBlock>
              </React.Fragment>
            );
          } else {
            return null;
          }
        }}
      </Query>
    );
  }
}

export default withStyles(styles as any)(DashBoardGrafico);
