export default class ConciliaEncrypt {

    private encrypted: string;

    constructor(encrypted: string) {
        this.encrypted = encrypted;
    }

    public encrypt(): string {
        
        const chaveMultiplica = Math.floor(Math.random() * 98) + 1;
        const chaveDivide = Math.floor(Math.random() * 98) + 1;

        const sChave1 = ("00000000" + chaveMultiplica);
        const sChave2 = ("00000000" + chaveDivide);

        let buff = "";

        buff = buff.concat(sChave1.substring(sChave1.length - 4, sChave1.length));        
        for (let i = 0; i < this.encrypted.length; i++) {

            buff = buff.concat(this.calculo(this.encrypted.charAt(i), chaveMultiplica, chaveDivide));

        }
        buff = buff.concat(sChave2.substring(sChave2.length - 4, sChave2.length));

        return buff;
    }

    private calculo(c: string, chave1: number, chave2: number): string {
        const parte = c.charCodeAt(0);
        const temp = ("000000000" + ((parte * chave1)*chave2));
        return temp.substring(temp.length - 8, temp.length);
    }

}