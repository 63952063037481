import { combineReducers } from "redux";
import menuSistema from "./modules/menu/menuSistema";
import ui from "../redux/modules/ui";
import loginReducer from "../redux/modules/loginReducer";
import allowReducer from "../redux/modules/allowReducer";
import painelCadastroReducer from "../redux/modules/painelCadastroReducer";
import errorReducer from "../redux/modules/errorReducer";
import escolhaFormularioReducer from "../redux/modules/escolhaFormularioReducer";

const reducers = {
  menuSistema,
  ui,
  loginReducer,
  allowReducer,
  painelCadastroReducer,
  escolhaFormularioReducer,
  errorReducer
};

const rootReducer = combineReducers(reducers);

export type IState = ReturnType<typeof rootReducer>;

export default rootReducer;
