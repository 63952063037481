import React, { Component } from "react";
import Collapse from "@material-ui/core/Collapse";
import styles from "./sidebar-jss";
import classNames from "classnames";
import { withTheme, withStyles, List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { loadCSS } from "fg-loadcss/src/loadCSS";
import convertIcon from "../../utils/iconUtils";
import Ionicon from "react-ionicons";

interface SidebarCategoryPropTypes {
  title: string;
  icon: string;
  children: any;
  classes: any;
  pai?: string;
  inset?: boolean;
  isClickable: boolean;
}

interface IState {
  collapse: boolean;
}

class SidebarCategory extends Component<SidebarCategoryPropTypes, IState> {
  constructor(props: Readonly<SidebarCategoryPropTypes>) {
    super(props);
    this.state = {
      collapse: true,
    };
  }

  componentDidMount() {
    loadCSS("https://use.fontawesome.com/releases/v5.1.0/css/all.css", document.querySelector("#insertion-point-jss"));
  }

  toggle = () => {
    this.setState((prevState) => ({ collapse: !prevState.collapse }));
  };

  render() {
    const { title, icon, children, classes, isClickable, inset } = this.props;
    const { collapse } = this.state;
    const ionicon = convertIcon(icon);
    return (
      <div>
        <ListItem
          button
          className={classNames(classes.head, icon ? classes.iconed : "", !collapse ? classes.opened : "")}
          onClick={this.toggle}
          id={title}
        >
          {icon && (
            <ListItemIcon className={classes.icon}>
              <Ionicon icon={ionicon} />
            </ListItemIcon>
          )}
          <ListItemText inset={inset} classes={{ primary: classes.primary }} primary={title} style={{ minWidth: "100px" }} />
          {!collapse ? isClickable && <ExpandLess /> : isClickable && <ExpandMore />}
        </ListItem>
        <Collapse component="div" className={classNames(classes.nolist)} in={!collapse} timeout="auto" unmountOnExit>
          <List className={classes.dense} component="nav" dense>
            {children}
          </List>
        </Collapse>
      </div>
    );
  }
}

export default withTheme(withStyles(styles as any)(SidebarCategory));
