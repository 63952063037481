import React from "react";
import { MenuItem, Select, FormHelperText, makeStyles, OutlinedInput, FormControl, InputLabel } from "@material-ui/core";
import { Query } from "react-apollo";
import Loading from "../Loading/Loading";
import classNames from "classnames";

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginTop: theme.spacing(1),
    width: "100%",
  },
  select: {
    marginTop: theme.spacing(1),
    height: 48,
    width: "100%",
  },
}));

interface Props {
  label: string;
  errorText?: string | undefined;
  touched?: boolean;
  handleChange:
    | ((event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>, child: React.ReactNode) => void)
    | undefined;
  value: string | number | boolean | object | (string | number | boolean | object)[] | undefined;
  handleBlur?: (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  name: string;
  query: any;
  variables?: any;
  nameQuery: string;
  required?: boolean;
  disabled?: boolean;
  queryOnFocus?: boolean;
  selectAll?: boolean;
}

export const SelectCombo = React.memo(
  ({
    errorText,
    touched,
    handleChange,
    value,
    handleBlur,
    name,
    query,
    variables,
    nameQuery,
    required,
    disabled,
    label,
    queryOnFocus = false,
    selectAll = false
  }: Props) => {
    const classes = useStyles();
    const inputLabel = React.useRef(null);
    const [labelWidth, setLabelWidth] = React.useState(0);
    const [loadingCC, setLoadingCC] = React.useState(false);
    const [skip, setSkip] = React.useState(queryOnFocus);
    React.useEffect(() => {
      if (inputLabel != null && inputLabel.current != null) {
        setLabelWidth((inputLabel.current as any).offsetWidth as number);
      }
    }, []);

    const onFocus = React.useCallback(
      (refetch: (variables?: any) => Promise<any>) => () => {
        if (queryOnFocus) {
          setLoadingCC(true);
          console.log("refetching");
          refetch(variables)
            .then((resp) => {
              setLoadingCC(false);
              setSkip(false);
            })
            .catch((e) => {
              console.log(e);
              setLoadingCC(false);
              setSkip(false);
            });
        }
      },
      [queryOnFocus, variables]
    );

    return (
      <Query<any, any> query={query} variables={variables} errorPolicy="all" skip={skip}>
        {({ data, loading, refetch }) => {
          if (loading) {
            return <Loading />;
          }
          console.log("data", data);
          if (!loading && data) {
            const list: Array<any> = data[nameQuery];
            if (list) {
              const items = list
                .filter((obj) => obj.valor !== "" && obj.valor != null)
                .map((obj) => (
                  <MenuItem key={obj.valor} value={obj.valor}>
                    {obj.label}
                  </MenuItem>
                ));
              
                if(selectAll) {
                  items.unshift(
                    <MenuItem value={-999} key="selectAll">
                      <em>Todos</em>
                    </MenuItem>
                  );
                }
              if (!required) {
                items.unshift(
                  <MenuItem value={undefined} key="">
                    <em>Selecione</em>
                  </MenuItem>
                );
              }
              return (
                <React.Fragment>
                  <FormControl
                    variant="outlined"
                    className={classNames(classes.formControl)}
                    error={errorText != null && touched != null}
                    onFocus={onFocus(refetch)}
                    id={"${name}-form-control"}
                  >
                    <InputLabel ref={inputLabel} htmlFor={`outlined-${name}`}>
                      {label}
                    </InputLabel>
                    <Select
                      fullWidth
                      disabled={disabled}
                      value={value ? value : ""}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name={name}
                      id={name}
                      onFocus={onFocus(refetch)}
                      className={classNames(classes.select)}
                      inputProps={{
                        name,
                        id: name,
                      }}
                      input={<OutlinedInput labelWidth={labelWidth} name={name} id={`outlined-${name}`} />}
                    >
                      {items}
                    </Select>
                    {errorText && touched && <FormHelperText id={`${name}-error`}>{errorText}</FormHelperText>}
                  </FormControl>
                  {loadingCC && <Loading />}
                </React.Fragment>
              );
            }
          }
          return (
            <React.Fragment>
              <FormControl variant="outlined" className={classNames(classes.formControl)} onFocus={onFocus(refetch)}>
                <InputLabel ref={inputLabel} htmlFor={`outlined-${name}`}>
                  {label}
                </InputLabel>
                <Select
                  fullWidth
                  disabled={disabled}
                  value=""
                  onChange={handleChange}
                  name={name}
                  id={name}
                  onFocus={onFocus(refetch)}
                  className={classNames(classes.select)}
                  inputProps={{
                    name,
                    id: name,
                  }}
                  input={<OutlinedInput labelWidth={labelWidth} name={name} id={`outlined-${name}`} />}
                >
                  <MenuItem value="">
                    <em>Selecione</em>
                  </MenuItem>
                </Select>
                {(errorText || touched) && <FormHelperText id={`${name}-error`}>{errorText}</FormHelperText>}
              </FormControl>
            </React.Fragment>
          );
        }}
      </Query>
    );
  }
);
