import MomentUtils from "@date-io/moment";
import { Card, Grid } from "@material-ui/core";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { FormikProps } from "formik";
import locale from "moment/locale/pt-br";
import React, { useState } from "react";
import DualListBox from "react-dual-listbox";
import "react-dual-listbox/lib/react-dual-listbox.css";
import SelectedRowsContext from "../../../../componentes/CRUD/selectedRowsContext";
import PapperBlock from "../../../../componentes/PapperBlock/PapperBlock";
import { SelectCombo } from "../../../../componentes/Select";
import SnackMessage from "../../../../componentes/SnakBar";
import { lang } from "../../../../componentes/Utils/languageDualList";
import {
  GetItensUnidadesPlanosComponent,
  GetPlanosTerminalQuery,
  ListarItensPlanosDocument
} from "../../../../graphql/components";
import "./formPlanosTerminal.css";
import { FormValues } from "./newEditPlanosTerminal";

type Props = {
  formikBag: FormikProps<FormValues>;
  data?: GetPlanosTerminalQuery;
};

const FormPlanosTerminal = ({ formikBag, data }: Props) => {
  const [message, setMessage] = useState("");
  const [messageOpen, setMessageOpen] = useState(false);
  const [messageVariant] = useState<"success" | "warning" | "error" | "info">("warning");
  const context = React.useContext(SelectedRowsContext);
  const { codplano } = context[0];

  React.useEffect(() => {
    if (data) {
      formikBag.setValues({
        codplanos: data.getPlanosTerminal.editValues.codplanos!,
        vigenciaDe: data.getPlanosTerminal.editValues.vigenciaDe!,
        vigenciaAte: data.getPlanosTerminal.editValues.vigenciaAte!,
        listTerminal: data.getPlanosTerminal.listTerminal!
      });
    } else {
      formikBag.setFieldValue("codplanos", codplano);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (selected: any) => {
    formikBag.setFieldValue("listTerminal", selected);
  };

  const closeMessage = React.useCallback(() => {
    setMessageOpen(false);
    setMessage("");
  }, []);

  return (
    <React.Fragment>
      <fieldset style={{ marginTop: 15, marginRight: 10 }}>
        <legend>Dados contrato</legend>
        <Grid container spacing={8} title="Contratos">
          <Grid item xs={12} md={4}>
            <SelectCombo
              label="Plano"
              errorText={formikBag.errors.codplanos}
              handleBlur={formikBag.handleBlur}
              handleChange={formikBag.handleChange}
              name="codplanos"
              disabled={true}
              value={formikBag.values.codplanos ? formikBag.values.codplanos! : ""}
              query={ListarItensPlanosDocument}
              touched={formikBag.touched.codplanos ? formikBag.touched.codplanos : false}
              nameQuery="listarItensPlanos"
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <MuiPickersUtilsProvider utils={MomentUtils} locale={locale}>
              <KeyboardDatePicker
                maxDateMessage="Data inválida"
                minDateMessage="Data inválida"
                autoOk
                variant="inline"
                allowKeyboardControl
                style={{ width: "100%" }}
                label="De"
                name="de"
                id="de"
                value={formikBag.values.vigenciaDe}
                disableToolbar
                onChange={e => {
                  if (e) {
                    formikBag.setFieldValue("vigenciaDe" as string, e.toDate());
                  } else {
                    formikBag.setFieldValue("vigenciaDe" as string, null);
                  }
                }}
                format="DD/MM/YYYY"
                placeholder="dd/mm/aaaa"
                disabled={formikBag.isSubmitting}
              />
            </MuiPickersUtilsProvider>
          </Grid>

          <Grid item xs={12} md={4}>
            <MuiPickersUtilsProvider utils={MomentUtils} locale={locale}>
              <KeyboardDatePicker
                maxDateMessage="Data inválida"
                minDateMessage="Data inválida"
                autoOk
                variant="inline"
                allowKeyboardControl
                style={{ width: "100%" }}
                label="Ate"
                name="ate"
                id="ate"
                value={formikBag.values.vigenciaAte}
                disableToolbar
                onChange={e => {
                  if (e) {
                    formikBag.setFieldValue("vigenciaAte" as string, e.toDate());
                  } else {
                    formikBag.setFieldValue("vigenciaAte" as string, null);
                  }
                }}
                format="DD/MM/YYYY"
                placeholder="dd/mm/aaaa"
                disabled={formikBag.isSubmitting}
              />
            </MuiPickersUtilsProvider>
          </Grid>

          {formikBag.values.codplanos && (
            <GetItensUnidadesPlanosComponent variables={{ codplanos: formikBag.values.codplanos }}>
              {({ data }) => {
                if (data && data.getItensUnidadesPlanos) {
                  return (
                    <React.Fragment>
                      <Grid item xs={12} md={12}>
                        <PapperBlock title="Terminal">
                          <Card elevation={20} style={{ padding: 15 }}>
                            <DualListBox
                              name="terminal"
                              canFilter
                              lang={lang}
                              showHeaderLabels={true}
                              showNoOptionsText={true}
                              filterPlaceholder={"Pesquisar..."}
                              options={data.getItensUnidadesPlanos}
                              selected={formikBag.values.listTerminal}
                              onChange={onChange}
                            />
                          </Card>
                        </PapperBlock>
                      </Grid>
                    </React.Fragment>
                  );
                }
                return null;
              }}
            </GetItensUnidadesPlanosComponent>
          )}
        </Grid>
      </fieldset>

      <SnackMessage message={message} variant={messageVariant} open={messageOpen} close={closeMessage} />
    </React.Fragment>
  );
};

export default FormPlanosTerminal;
