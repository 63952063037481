import Button from "@material-ui/core/Button";
import { Formik, FormikActions, FormikProps } from "formik";
import React, { PureComponent } from "react";
import * as Yup from "yup";
import NewEditProps from "../../../componentes/CRUD/crudProps";
import EditContext from "../../../componentes/CRUD/editContext";
import Loading from "../../../componentes/Loading/Loading";
import { GetDadoNFeComponent, SalvarDadosNFeProps, withSalvarDadosNFe } from "../../../graphql/components";
import FormDadosNFe from "./formDadosNFe";
import voca from "voca";

const stringObrigatorio = "é obrigatório.";

const PerfilSchema = Yup.object().shape({
  codpessoa: Yup.number().required(`Empresa ${stringObrigatorio}`),
  codpessoaemissao: Yup.number().required(`Empresa Emissora NotaFiscal ${stringObrigatorio}`),
  codcidade: Yup.number().required(`Cidade/Estado ${stringObrigatorio}`),
  cnae: Yup.string(),
  subitem: Yup.string().required(`CNAE Item ${stringObrigatorio}`),
  descsubitem: Yup.string(),
  codigotributacaomunicipio: Yup.string().required(`Cod. Trib. Município ${stringObrigatorio}`),
  desccnae: Yup.string(),
  descservico: Yup.string(),
  pis: Yup.string(),
  cofins: Yup.string(),
  ir: Yup.string(),
  csll: Yup.string(),
  iss: Yup.string(),
  valorminimocalculoir: Yup.string(),
  valorminimocalculopiscofinscsll: Yup.string(),
  naturezaoperacao: Yup.string(),
  regimeespecialtributacao: Yup.string(),
  opatantesimplesnacional: Yup.string(),
  incentivadorcultural: Yup.string().required(`Incentivador Cultural ${stringObrigatorio}`),
  senhacertificado: Yup.string(),
  email: Yup.string().required(`Email ${stringObrigatorio}`),
  smtp: Yup.string().required(`SMTP ${stringObrigatorio}`),
  porta: Yup.number().required(`Porta ${stringObrigatorio}`),
  senhaemail: Yup.string().required(`Senha Email ${stringObrigatorio}`),
  logo: Yup.string(),
  certificado: Yup.string()
});

const initialValues = {
  codpessoa: (null as unknown) as number,
  codpessoaemissao: (null as unknown) as number,
  codcidade: (null as unknown) as number,
  cnae: "",
  subitem: "",
  descsubitem: "",
  codigotributacaomunicipio: "",
  desccnae: "",
  descservico: "",
  pis: "",
  cofins: "",
  ir: "",
  csll: "",
  iss: "",
  valorminimocalculoir: "",
  valorminimocalculopiscofinscsll: "",
  naturezaoperacao: "",
  regimeespecialtributacao: "",
  opatantesimplesnacional: "",
  incentivadorcultural: "",
  senhacertificado: "",
  email: "",
  smtp: "",
  porta: (null as unknown) as number,
  senhaemail: "",
  logo: "",
  certificado: ""
};

export type FormValues = typeof initialValues;

class NewEditDadosNFe extends PureComponent<SalvarDadosNFeProps<NewEditProps>> {
  static contextType = EditContext;

  submit = async (values: FormValues, actions: FormikActions<FormValues>) => {
    try {
      const response = await this.props.mutate!({
        variables: {
          values: {
            editValues: {
              cnae: values.cnae,
              codcidade: values.codcidade,
              codigotributacaomunicipio: values.codigotributacaomunicipio,
              codpessoa: values.codpessoa,
              codpessoaemissao: values.codpessoaemissao,
              cofins: parseFloat(voca.replaceAll(voca.replaceAll(values.cofins, ".", ""), ",", ".")),
              csll: parseFloat(voca.replaceAll(voca.replaceAll(values.csll, ".", ""), ",", ".")),
              desccnae: values.desccnae,
              descservico: values.descservico,
              descsubitem: values.descsubitem,
              email: values.email,
              incentivadorcultural: values.incentivadorcultural,
              ir: parseFloat(voca.replaceAll(voca.replaceAll(values.ir, ".", ""), ",", ".")),
              iss: parseFloat(voca.replaceAll(voca.replaceAll(values.iss, ".", ""), ",", ".")),
              naturezaoperacao: values.naturezaoperacao,
              opatantesimplesnacional: values.opatantesimplesnacional,
              pis: parseFloat(voca.replaceAll(voca.replaceAll(values.pis, ".", ""), ",", ".")),
              porta: values.porta,
              regimeespecialtributacao: values.regimeespecialtributacao,
              senhacertificado: values.senhacertificado,
              senhaemail: values.senhaemail,
              smtp: values.smtp,
              subitem: values.subitem,
              valorminimocalculoir: parseFloat(voca.replaceAll(voca.replaceAll(values.valorminimocalculoir, ".", ""), ",", ".")),
              valorminimocalculopiscofinscsll: parseFloat(
                voca.replaceAll(voca.replaceAll(values.valorminimocalculopiscofinscsll, ".", ""), ",", ".")
              ),
              certificado: values.certificado,
              logo: values.logo
            },
            searchValues: {
              codpessoa: values.codpessoa
            }
          },
          insert: this.context ? false : true
        }
      });
      if (response && response.data && response.data.salvarDadosNFe) {
        this.props.setMessage(true, "success", "Registro salvo com sucesso!");
      }
    } catch (e) {
    } finally {
      actions.setSubmitting(false);
      this.props.close();
    }
  };

  render() {
    return (
      <div style={{ overflowY: "auto", height: "90%", overflowX: "hidden" }}>
        <Formik
          initialValues={initialValues}
          onSubmit={this.submit}
          validationSchema={PerfilSchema}
          render={(formikBag: FormikProps<FormValues>) => (
            <form autoComplete="new-password" role="presentation" onSubmit={formikBag.handleSubmit}>
              {!this.context && <FormDadosNFe formikBag={formikBag} />}
              {this.context && this.context.codpessoa && (
                <GetDadoNFeComponent fetchPolicy="no-cache" variables={{ values: { codpessoa: this.context.codpessoa } }}>
                  {({ data, loading }) => {
                    if (loading) {
                      return <Loading />;
                    }
                    if (data && data.getDadoNFe) {
                      return <FormDadosNFe formikBag={formikBag} data={data!} />;
                    } else {
                      return null;
                    }
                  }}
                </GetDadoNFeComponent>
              )}
              <div
                style={{
                  float: "right",
                  margin: 10,
                  display: "flex",
                  justifyContent: "space-between",
                  width: "180px",
                  flexDirection: "row"
                }}
              >
                <Button variant="contained" color="primary" disabled={formikBag.isSubmitting} type="submit">
                  Salvar
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  disabled={formikBag.isSubmitting}
                  type="reset"
                  onClick={() => formikBag.resetForm(initialValues)}
                >
                  Limpar
                </Button>
              </div>
            </form>
          )}
        />
      </div>
    );
  }
}

const withMutation = withSalvarDadosNFe<NewEditProps>({});

export default withMutation(NewEditDadosNFe);
