import { selectOperadoraToEdit, PAINEL_CADASTRO_ACTIONS_TYPE } from "../actions/painelCadastroActions";

type InitialState = {
  nomeOperadora: string | null;
  codOperadora: number | null;
};

export const initialState = {
  nomeOperadora: null,
  codOperadora: null
};

export default function reducer(state: InitialState = initialState, action: ReturnType<typeof selectOperadoraToEdit>) {
  switch (action.type) {
    case PAINEL_CADASTRO_ACTIONS_TYPE.SELECT_OPERADORA:
      return {
        ...state,
        nomeOperadora: action.payload.nomeOperadora,
        codOperadora: action.payload.codOperadora
      };
    default:
      return state;
  }
}
