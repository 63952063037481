import DeleteIcon from "@material-ui/icons/Delete";
import FlashOn from "@material-ui/icons/FlashOn";
import MonetizationOn from "@material-ui/icons/MonetizationOn";
import TrendingUp from "@material-ui/icons/TrendingUp";
import { DataProxy } from "apollo-cache";
import React from "react";
import { forkJoin } from "rxjs";
import ActionsTable, { CallbackActionType } from "../../../componentes/CRUD/actionsTable";
import ColumnCrudTableType from "../../../componentes/CRUD/columnCrudTableType";
import {
  DeletePlanosConciliadoresMutation,
  DeletePlanosConciliadoresMutationVariables,
  ListarPlanosConciliadoresDocument,
  ListarPlanosConciliadoresQuery
} from "../../../graphql/components";

export const variablesPlanosConciliadores: {
  codplanosconciliadores: number | null | undefined;
  nome: string | null | undefined;
  codpessoapai: number | null | undefined;
  tarifacao: string | null | undefined;
} = {
  codplanosconciliadores: undefined,
  nome: undefined,
  codpessoapai: undefined,
  tarifacao: undefined
};

export const deleteMutationPlanosConciliadores: CallbackActionType<
  DeletePlanosConciliadoresMutation,
  DeletePlanosConciliadoresMutationVariables
> = (mutation, record, setMessage) => {
  if (mutation) {
    const deletesPromises: Array<any> = [];
    record.forEach(r => {
      deletesPromises.push(
        mutation({
          variables: {
            values: {
              editValues: { codplanosconciliadores: r.codplanosconciliadores }
            }
          },
          update: (store: DataProxy, { data }) => {
            if (data!.deletePlanosConciliadores) {
              const oldResult = store.readQuery<ListarPlanosConciliadoresQuery>({
                query: ListarPlanosConciliadoresDocument,
                variables: { values: variablesPlanosConciliadores }
              });
              if (oldResult) {
                const index = oldResult.listarPlanosConciliadores.findIndex(
                  u => u.codplanosconciliadores === r.codplanosconciliadores
                );
                oldResult.listarPlanosConciliadores.splice(index, 1);
                const result = [...oldResult.listarPlanosConciliadores];
                store.writeQuery<ListarPlanosConciliadoresQuery>({
                  query: ListarPlanosConciliadoresDocument,
                  data: { listarPlanosConciliadores: result },
                  variables: variablesPlanosConciliadores
                });
              }
            }
          }
        })
      );
    });
    forkJoin(deletesPromises).subscribe(
      response => {
        const totalErrors = response.filter(r => typeof r === "string").length;
        if (totalErrors > 0 && totalErrors !== deletesPromises.length) {
          setMessage(
            true,
            "warning",
            "Alguns registros não foram excluídos. Dentro de 5 segundos iremos atualizar a página para manter os dados coerentes."
          );
          setTimeout(() => window.location.reload(), 6500);
        } else if (totalErrors === 0) {
          setMessage(true, "success", "Registro(s) excluídos com sucesso!");
        }
      },
      errors => {
        console.log(errors);
      }
    );
  }
};

export const columnsOrderPlanosConciliadores = ["nome", "tarifacao"];

export const colunasPlanosConciliadores: Array<ColumnCrudTableType> = [
  {
    name: "Nome",
    options: {
      filter: true
    }
  },
  {
    name: "Tarifação",
    options: {
      filter: true
    }
  }
];

export const planosContrato: CallbackActionType = async (
  mutation,
  record,
  setMessage,
  _,
  openFullScreenDrawer,
  closeRight,
  closeFull,
  client
) => {
  client.writeData({
    data: {
      getFormularioPlanosOpcoesPlanosConciliadores: {
        nomeFormulario: "CONTRATO",
        __typename: "getFormularioPlanosOpcoesPlanosConciliadores"
      }
    }
  });
  openFullScreenDrawer!();
};

export const planosTaxas: CallbackActionType = async (
  mutation,
  record,
  setMessage,
  _,
  openFullScreenDrawer,
  closeRight,
  closeFull,
  client
) => {
  client.writeData({
    data: {
      getFormularioPlanosOpcoesPlanosConciliadores: {
        nomeFormulario: "TAXAS",
        __typename: "getFormularioPlanosOpcoesPlanosConciliadores"
      }
    }
  });
  openFullScreenDrawer!();
};

export const visualizarInserirRapido: CallbackActionType = (mutation, record, setMessage, openRightDrawer) => {
  openRightDrawer!();
};

export const actionsTablePlanosConciliadores: Array<ActionsTable> = [
  {
    titleAction: "Planos Conciliadores",
    iconAction: <MonetizationOn />,
    actionFunction: planosContrato,
    isVisibleOnlySingleSelection: true
  },

  {
    titleAction: "Planos Taxas",
    iconAction: <TrendingUp />,
    actionFunction: planosTaxas,
    isVisibleOnlySingleSelection: true
  },

  {
    titleAction: "Deletar",
    iconAction: <DeleteIcon />,
    actionFunction: deleteMutationPlanosConciliadores
  }
];

export const actionsTableNotSelectedPlanosConciliadores: Array<ActionsTable> = [
  {
    titleAction: "Inserir Rápido",
    iconAction: <FlashOn />,
    actionFunction: visualizarInserirRapido,
    isVisibleOnlySingleSelection: true
  }
];
