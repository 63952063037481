import Button from "@material-ui/core/Button";
import { Formik, FormikActions, FormikProps } from "formik";
import React, { PureComponent } from "react";
import * as Yup from "yup";
import NewEditProps from "../../../../componentes/CRUD/crudProps";
import EditContext from "../../../../componentes/CRUD/editContext";
import SelectedRowsContext from "../../../../componentes/CRUD/selectedRowsContext";
import Loading from "../../../../componentes/Loading/Loading";

import FormPlanosTerminal from "./formPlanosTerminal";
import {
  SalvarPlanosTerminalProps,
  ListarPlanosTerminalDocument,
  GetPlanosTerminalComponent,
  GetPlanosTerminalQuery,
  GetPlanosTerminalQueryVariables,
  ListarPlanosTerminalQuery,
  GetPlanosTerminalDocument,
  PlanosTerminal,
  withSalvarPlanosTerminal
} from "../../../../graphql/components";

const stringObrigatorio = "é obrigatório.";

const PlanosTerminalSchema = Yup.object().shape({
  codplanos: Yup.number(),
  vigenciaDe: Yup.date().required(`Vigência "de" ${stringObrigatorio}`),
  vigenciaAte: Yup.date().required(`Vigência "até" ${stringObrigatorio}`),
  listterminal: Yup.array()
});

const initialValues = {
  codplanos: (null as unknown) as number,
  vigenciaDe: (null as unknown) as Date,
  vigenciaAte: (null as unknown) as Date,
  listTerminal: [] as Array<string>
};

export type FormValues = typeof initialValues;

class NewEditPlanosTerminal extends PureComponent<SalvarPlanosTerminalProps<NewEditProps>> {
  static contextType = SelectedRowsContext;

  submit = async (values: FormValues, actions: FormikActions<FormValues>, editContext: any | null) => {
    try {
      const response = await this.props.mutate!({
        variables: {
          values: {
            listTerminal: values.listTerminal,
            editValues: {
              codplanos: values.codplanos,
              vigenciaDe: values.vigenciaDe,
              vigenciaAte: values.vigenciaAte
            }
          },
          insert: editContext ? false : true
        },
        update: (store, result) => {
          if (result.data && result.data.salvarPlanosTerminal) {
            const data = store.readQuery<ListarPlanosTerminalQuery>({
              query: ListarPlanosTerminalDocument,
              variables: { values: { codplano: values.codplanos } }
            });

            if (editContext && editContext.codplanosterminal) {
              const edited = store.readQuery<GetPlanosTerminalQuery, GetPlanosTerminalQueryVariables>({
                query: GetPlanosTerminalDocument,
                variables: { values: { codplanosterminal: editContext.codplanosterminal } }
              });
              if (edited && edited.getPlanosTerminal.editValues.codplanosterminal) {
                const registro = data!.listarPlanosTerminal.find(
                  item => item.codplanosterminal === edited.getPlanosTerminal.editValues.codplanosterminal
                );
                if (registro) {
                  registro.codplanos = values.codplanos;
                  registro.vigenciaDe = values.vigenciaDe;
                  registro.vigenciaAte = values.vigenciaAte;
                  const newResult = data!.listarPlanosTerminal.slice(0);
                  store.writeQuery<ListarPlanosTerminalQuery>({
                    query: ListarPlanosTerminalDocument,
                    data: { listarPlanosTerminal: newResult },
                    variables: { values: { codplano: values.codplanos } }
                  });

                  edited.getPlanosTerminal.editValues.codplanos = values.codplanos;
                  edited.getPlanosTerminal.editValues.vigenciaDe = values.vigenciaDe;
                  edited.getPlanosTerminal.editValues.vigenciaAte = values.vigenciaAte;

                  store.writeQuery({
                    query: GetPlanosTerminalDocument,
                    data: { ...edited, __typename: "PlanosTerminal" },
                    variables: { values: { codplanosterminal: this.context.editValues.codplanosterminal } }
                  });
                }
              }
            } else {
              const planosTerminal: PlanosTerminal = {
                __typename: "PlanosTerminal",
                codplanos: values.codplanos,
                vigenciaDe: values.vigenciaDe,
                vigenciaAte: values.vigenciaAte
              };
              store.writeQuery<ListarPlanosTerminalQuery>({
                query: ListarPlanosTerminalDocument,
                data: { listarPlanosTerminal: [...data!.listarPlanosTerminal, planosTerminal] },
                variables: { values: { codplano: values.codplanos } }
              });
            }
          }
        }
      });

      if (response && response.data && response.data.salvarPlanosTerminal) {
        actions.setSubmitting(false);
        this.props.close();
        this.props.setMessage(true, "success", "Registro inserido com sucesso!");
      }
    } catch (e) {
    } finally {
      actions.setSubmitting(false);
    }
  };

  render() {
    return (
      <div style={{ overflowY: "auto", height: "90%", overflowX: "hidden" }}>
        {
          <EditContext.Consumer>
            {editContext => (
              <Formik
                initialValues={initialValues}
                onSubmit={(values: FormValues, actions: FormikActions<FormValues>) => this.submit(values, actions, editContext)}
                validationSchema={PlanosTerminalSchema}
                render={(formikBag: FormikProps<FormValues>) => (
                  <form autoComplete="new-password" role="presentation" onSubmit={formikBag.handleSubmit}>
                    {!editContext && <FormPlanosTerminal formikBag={formikBag} />}
                    {editContext && (
                      <GetPlanosTerminalComponent
                        variables={{
                          values: { codplanosterminal: (editContext! as any).codplanosterminal }
                        }}
                      >
                        {({ data, loading }) => {
                          if (loading) {
                            return <Loading />;
                          }
                          if (data && data.getPlanosTerminal) {
                            return <FormPlanosTerminal formikBag={formikBag} data={data!} />;
                          }
                          return null;
                        }}
                      </GetPlanosTerminalComponent>
                    )}
                    <div
                      style={{
                        float: "right",
                        margin: 10,
                        display: "flex",
                        justifyContent: "space-between",
                        width: "180px",
                        flexDirection: "row"
                      }}
                    >
                      <Button variant="contained" color="primary" disabled={formikBag.isSubmitting} type="submit">
                        Salvar
                      </Button>
                      <Button
                        variant="contained"
                        color="secondary"
                        disabled={formikBag.isSubmitting}
                        type="reset"
                        onClick={() => formikBag.resetForm(initialValues)}
                      >
                        Limpar
                      </Button>
                    </div>
                  </form>
                )}
              />
            )}
          </EditContext.Consumer>
        }
      </div>
    );
  }
}

const withMutation = withSalvarPlanosTerminal<NewEditProps>({ withRef: true });

export default withMutation(NewEditPlanosTerminal);
