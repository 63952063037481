import React from "react";
import { Card, Grid, Typography, Button } from "@material-ui/core";
import moment from "moment";
import { FormValuesTicket } from "./inserirTicket";

interface Props {
  ticket: FormValuesTicket;
  index: number;
  excluirTicket: (index: number) => void;
  disabled?: boolean;
}

const TicketCard = ({ ticket, index, excluirTicket, disabled }: Props) => (
  <Card elevation={20} style={{ margin: 10, padding: 15 }}>
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <Typography variant="caption" style={{ fontSize: "0.66rem" }}>
          Bandeira
        </Typography>
        <br />
        <Typography variant="caption" color="textSecondary" style={{ fontSize: "0.66rem" }}>
          {ticket.codBandeira}
        </Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <Typography variant="caption" style={{ fontSize: "0.66rem" }}>
          Parcelas
        </Typography>
        <br />
        <Typography variant="caption" color="textSecondary" style={{ fontSize: "0.66rem" }}>
          {ticket.quantidadeParcelas}
        </Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <Typography variant="caption" style={{ fontSize: "0.66rem" }}>
          Doc/NSU
        </Typography>
        <br />
        <Typography variant="caption" color="textSecondary" style={{ fontSize: "0.66rem" }}>
          {ticket.nsu}
        </Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <Typography variant="caption" style={{ fontSize: "0.66rem" }}>
          TID
        </Typography>
        <br />
        <Typography variant="caption" color="textSecondary" style={{ fontSize: "0.66rem" }}>
          {ticket.tid}
        </Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <Typography variant="caption" style={{ fontSize: "0.66rem" }}>
          Autoriz.
        </Typography>
        <br />
        <Typography variant="caption" color="textSecondary" style={{ fontSize: "0.66rem" }}>
          {ticket.autorizacao}
        </Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <Typography variant="caption" style={{ fontSize: "0.66rem" }}>
          Dt. Venda
        </Typography>
        <br />
        <Typography variant="caption" color="textSecondary" style={{ fontSize: "0.66rem" }}>
          {moment(ticket.datavenda).format("DD/MM/YYYY")}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="caption" style={{ fontSize: "0.66rem" }}>
          Valor
        </Typography>
        <br />
        <Typography variant="caption" color="textSecondary" style={{ fontSize: "0.66rem" }}>
          {ticket.valor ? ticket.valor : ""}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Button color="primary" size="small" onClick={() => excluirTicket(index)} disabled={disabled}>
          Excluir ticket
        </Button>
      </Grid>
    </Grid>
  </Card>
);

export default TicketCard;
