import Grid from "@material-ui/core/Grid";
import React, { PureComponent } from "react";
import { FilterProps } from "../../../componentes/CRUD/crudProps";
import FilterContext from "../../../componentes/CRUD/filterContext";
import { SelectCombo } from "../../../componentes/Select";
import { ListarItensConciliadoresDocument } from "../../../graphql/components";
import { variablesDadosNFe, variablesDadosNFeInitial } from "./utilsDadosNFe";

type State = typeof variablesDadosNFe;

class FilterDadosNFe extends PureComponent<FilterProps, State> {
  static contextType = FilterContext;
  public static filterDadosNFeVariables: any;
  constructor(props: FilterProps) {
    super(props);
    this.state = { ...variablesDadosNFeInitial };
    FilterDadosNFe.filterDadosNFeVariables = JSON.parse(JSON.stringify(variablesDadosNFeInitial));
  }

  render() {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12} style={{ marginBottom: 20 }}>
          <SelectCombo
            label="Empresa"
            name="codpessoa"
            nameQuery="listarItensConciliadores"
            query={ListarItensConciliadoresDocument}
            value={this.state.codpessoa ? this.state.codpessoa : ""}
            handleChange={e => {
              // const valor = e.currentTarget.value;
              const label = (e.currentTarget as any).innerText;
              const value: number | null = e.target.value ? parseInt(e.target.value as string) : null;
              this.setState({ ...this.state, codpessoa: value }, () => {
                this.props.variables.codpessoa = this.state.codpessoa;
                this.props.variablesValorExibicao.codpessoa = label;
                this.context.setVariables({ ...this.state });
              });
            }}
          />
        </Grid>
      </Grid>
    );
  }
}

export default FilterDadosNFe;
