import { Dialog, LinearProgress, Paper } from "@material-ui/core";
import React from "react";

interface Props {
  open: boolean;
  message: string;
}

const LoadingAction = ({ open, message }: Props) => (
  <Dialog open={open} disableBackdropClick>
    <Paper style={{ padding: 15 }}>
      {message}
      <LinearProgress color="secondary" style={{ height: 5.5 }} />
    </Paper>
  </Dialog>
);

export default LoadingAction;
