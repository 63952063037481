import DeleteIcon from "@material-ui/icons/Delete";
import MonetizationOn from "@material-ui/icons/MonetizationOn";
import TrendingUp from "@material-ui/icons/TrendingUp";
import { DataProxy } from "apollo-cache";
import React from "react";
import { forkJoin } from "rxjs";
import ActionsTable, { CallbackActionType } from "../../../../componentes/CRUD/actionsTable";
import ColumnCrudTableType from "../../../../componentes/CRUD/columnCrudTableType";
import {
  DeletePlanosPosMutation,
  DeletePlanosPosMutationVariables,
  ListarPlanosPosDocument,
  ListarPlanosPosQuery
} from "../../../../graphql/components";

export const variablesPlanosPOS: {
  codplano: number | null | undefined;
  nome: string | null | undefined;
  codpessoapai: number | null | undefined;
  cobramensalidade: string | null | undefined;
  valormensalidade: number | null | undefined;
  diacorte: number | null | undefined;
} = {
  codplano: undefined,
  nome: undefined,
  codpessoapai: undefined,
  cobramensalidade: undefined,
  valormensalidade: undefined,
  diacorte: undefined
};

export const deleteMutationPlanosPOS: CallbackActionType<DeletePlanosPosMutation, DeletePlanosPosMutationVariables> = (
  mutation,
  record,
  setMessage
) => {
  if (mutation) {
    const deletesPromises: Array<any> = [];
    record.forEach(r => {
      deletesPromises.push(
        mutation({
          variables: {
            values: {
              editValues: { codplano: r.codplano }
            }
          },
          update: (store: DataProxy, { data }) => {
            if (data!.deletePlanosPOS) {
              const oldResult = store.readQuery<ListarPlanosPosQuery>({
                query: ListarPlanosPosDocument,
                variables: { values: variablesPlanosPOS }
              });
              if (oldResult) {
                const index = oldResult.listarPlanosPOS.findIndex(u => u.codplano === r.codplano);
                oldResult.listarPlanosPOS.splice(index, 1);
                const result = [...oldResult.listarPlanosPOS];
                store.writeQuery<ListarPlanosPosQuery>({
                  query: ListarPlanosPosDocument,
                  data: { listarPlanosPOS: result },
                  variables: variablesPlanosPOS
                });
              }
            }
          }
        })
      );
    });
    forkJoin(deletesPromises).subscribe(
      response => {
        const totalErrors = response.filter(r => typeof r === "string").length;
        if (totalErrors > 0 && totalErrors !== deletesPromises.length) {
          setMessage(
            true,
            "warning",
            "Alguns registros não foram excluídos. Dentro de 5 segundos iremos atualizar a página para manter os dados coerentes."
          );
          setTimeout(() => window.location.reload(), 6500);
        } else if (totalErrors === 0) {
          setMessage(true, "success", "Registro(s) excluídos com sucesso!");
        }
      },
      errors => {
        console.log(errors);
      }
    );
  }
};

export const columnsOrderPlanosPOS = ["nome", "cobramensalidade", "valormensalidade", "diacorte"];

export const colunasPlanosPOS: Array<ColumnCrudTableType> = [
  {
    name: "Nome",
    options: {
      filter: true
    }
  },
  {
    name: "Mensalidade",
    options: {
      filter: true
    }
  },
  {
    name: "Valor",
    options: {
      filter: true
      //customBodyRender: (value: any) => <NumberFormat value={value} displayType={'text'} minimum thousandSeparator={true} prefix={'R$'} />
    }
  },
  {
    name: "Dia Corte",
    options: {
      filter: true
    }
  }
];

export const planosTerminal: CallbackActionType = async (
  mutation,
  record,
  setMessage,
  _,
  openFullScreenDrawer,
  closeRight,
  closeFull,
  client
) => {
  client.writeData({
    data: {
      getFormularioPlanosOpcoesPlanosConciliadores: {
        nomeFormulario: "TERMINAL",
        __typename: "getFormularioPlanosOpcoesPlanosConciliadores"
      }
    }
  });
  openFullScreenDrawer!();
};

export const planosTaxasPos: CallbackActionType = async (
  mutation,
  record,
  setMessage,
  _,
  openFullScreenDrawer,
  closeRight,
  closeFull,
  client
) => {
  client.writeData({
    data: {
      getFormularioPlanosOpcoesPlanosConciliadores: {
        nomeFormulario: "TAXASPOS",
        __typename: "getFormularioPlanosOpcoesPlanosConciliadores"
      }
    }
  });
  openFullScreenDrawer!();
};

export const visualizarInserirRapido: CallbackActionType = async (mutation, record, setMessage, openRightDrawer) => {
  openRightDrawer!();
};

export const actionsTablePlanosPOS: Array<ActionsTable> = [
  {
    titleAction: "Planos Terminal",
    iconAction: <MonetizationOn />,
    actionFunction: planosTerminal,
    isVisibleOnlySingleSelection: true
  },

  {
    titleAction: "Planos Taxas",
    iconAction: <TrendingUp />,
    actionFunction: planosTaxasPos,
    isVisibleOnlySingleSelection: true
  },

  {
    titleAction: "Deletar",
    iconAction: <DeleteIcon />,
    actionFunction: deleteMutationPlanosPOS
  }
];
