import BarChart from "@material-ui/icons/BarChart";
import Search from "@material-ui/icons/Search";
import DesconsiderarIcon from "@material-ui/icons/ThumbDownSharp";
import ReconsiderarIcon from "@material-ui/icons/ThumbUpSharp";
import moment from "moment";
import React from "react";
import ActionsTable, { CallbackActionType } from "../../../componentes/CRUD/actionsTable";
import ColumnCrudTableType from "../../../componentes/CRUD/columnCrudTableType";
import {
  DownloadAnalisePesquisaTicketDocument,
  DownloadAnalisePesquisaTicketMutation,
  DownloadAnalisePesquisaTicketMutationVariables,
  ReconsiderarDesconsiderarTicketDocument,
  ReconsiderarDesconsiderarTicketMutationFn
} from "../../../graphql/components";
import Ticket from "../../../model/ticket";
import downloadUtils from "../../../utils/downloadUtils";

export const baixarArquivoPesquisaTickets: CallbackActionType<
  DownloadAnalisePesquisaTicketMutation,
  DownloadAnalisePesquisaTicketMutationVariables
> = async (mutation, record, setMessage) => {
  record.map(async (r: any) => {
    const response = await mutation!({
      variables: {
        values: {
          autorizacao: r.autorizacao,
          codexterno: r.codexterno,
          codoperadora: r.codoperadora,
          codpessoa: r.codpessoa,
          datavenda: moment(r.datavenda).format("YYYY-MM-DD hh:mm:ss.S"),
          nsu: r.nsu,
          tid: r.tid
        }
      }
    });
    if (response) {
      const { data } = response;
      if (data) {
        downloadUtils(data.downloadAnalisePesquisaTicket!);
        if (setMessage) {
          setMessage(true, "success", "Registro baixado com sucesso!");
        }
      }
    }
  });
};

export const visualizarTicket: CallbackActionType = async (mutation, record, setMessage, _, openFullScreenDrawer) => {
  openFullScreenDrawer!();
};

const execute = async (
  record: Array<Ticket> | undefined,
  mutation: ReconsiderarDesconsiderarTicketMutationFn | null,
  setMessage: any,
  acao: string
) => {
  let hasFalse: Ticket | null = null;
  if (record && record.length > 0) {
    for (const ticket of record) {
      const response = await mutation!({
        variables: {
          codlinhasarqcli: ticket.codlinhasarqcli ? ticket.codlinhasarqcli.toString() : undefined,
          tipo: ticket.tipo || undefined,
          acao
        }
      });
      if (!response || !response.data || !response.data.reconsiderarDesconsiderarTicket) {
        hasFalse = ticket;
      }
    }
    if (hasFalse) {
      setMessage!(
        true,
        "warning",
        record.length === 1
          ? "Registro não reconsiderado!"
          : `O registro de autorização ${hasFalse.autorizacao} não foi processado corretamente.`
      );
    } else {
      setMessage!(true, "success", "Registro processado com sucesso!");
    }
  }
};

const reconsiderarTicket: CallbackActionType = async (mutation, record, setMessage) => {
  await execute(record, mutation, setMessage, "reconsiderar");
};

const desconsiderarTicket: CallbackActionType = async (mutation, record, setMessage, _, openFullScreenDrawer) => {
  await execute(record, mutation, setMessage, "desconsiderar");
};

export const variablesPesquisaTickets: {
  nsu: string | null;
  tid: string | null;
  autorizacao: string | null;
  terminal: string | null;
  nossonumero: string | null;
  datavenda: Date | null;
  datavendaate: Date | null;
  tipo: string;
} = {
  nsu: null,
  tid: null,
  autorizacao: null,
  terminal: null,
  nossonumero: null,
  datavenda: moment()
    .subtract(1, "day")
    .toDate(),
  datavendaate: moment().toDate(),
  tipo: "A"
};

export const variablesPesquisaTicketsInitial = JSON.parse(JSON.stringify(variablesPesquisaTickets));

export const variablesPesquisaTicketsExibicao: {
  nsu: string | null;
  tid: string | null;
  autorizacao: string | null;
  terminal: string | null;
  nossonumero: string | null;
  datavenda: Date | null;
  datavendaate: Date | null;
  tipo: string;
} = {
  nsu: null,
  tid: null,
  autorizacao: null,
  terminal: null,
  nossonumero: null,
  datavenda: moment()
    .subtract(1, "day")
    .toDate(),
  datavendaate: moment().toDate(),
  tipo: "Administradora"
};

export const mapVariablesFiltroPesquisaTickets = {
  nsu: "NSU",
  tid: "TID",
  autorizacao: "Autorização",
  terminal: "Terminal",
  nossonumero: "Nosso número",
  datavenda: "Data venda de",
  datavendaate: "Data venda até",
  tipo: "Tipo"
};

export const columnsOrderPesquisaTickets = ["nsu", "tid", "autorizacao", "codexterno", "datavenda", "tipo", "dataprocessamento"];

export const colunasPesquisaTickets: Array<ColumnCrudTableType> = [
  {
    name: "Doc/NSU",
    options: {
      filter: true
    }
  },
  {
    name: "Tid",
    options: {
      filter: true
    }
  },
  {
    name: "Autorização",
    options: {
      filter: true
    }
  },
  {
    name: "Código",
    options: {
      filter: true
    }
  },
  {
    name: "Data venda",
    options: {
      filter: true,
      customBodyRender: (value: any) => moment(new Date(value)).format("DD/MM/YYYY")
    }
  },
  {
    name: "Tipo",
    options: {
      filter: true
    }
  },
  {
    name: "Processamento",
    options: {
      filter: true,
      customBodyRender: (value: any) => moment(new Date(value)).format("DD/MM/YYYY")
    }
  }
];

export const getActionsTablePesquisaTickets = (records: Array<Ticket>): Array<ActionsTable> => {
  let retorno: Array<ActionsTable> = actionsTablePesquisaTickets;
  const exibeReconsiderar = records.every(record => record.origem === "LINHASARQUIVOCLIENTEDESCONSIDERADOS");
  const exibeDesconsiderar = records.every(record => record.origem === "LINHASARQUIVOCLIENTE");

  if (exibeDesconsiderar) {
    retorno = retorno.concat({
      titleAction: "Desconsiderar",
      iconAction: <DesconsiderarIcon />,
      actionFunction: desconsiderarTicket,
      confirmMessage: "Deseja realmente desconsiderar este ticket?",
      mutation: ReconsiderarDesconsiderarTicketDocument
    });
  }
  if (exibeReconsiderar) {
    retorno = retorno.concat({
      titleAction: "Reconsiderar",
      iconAction: <ReconsiderarIcon />,
      actionFunction: reconsiderarTicket,
      confirmMessage: "Deseja realmente reconsiderar este ticket?",
      mutation: ReconsiderarDesconsiderarTicketDocument
    });
  }
  return retorno;
};

const actionsTablePesquisaTickets: Array<ActionsTable> = [
  {
    titleAction: "Visualizar",
    iconAction: <Search />,
    actionFunction: visualizarTicket,
    isVisibleOnlySingleSelection: true
  },
  {
    titleAction: "Analisar",
    iconAction: <BarChart />,
    actionFunction: baixarArquivoPesquisaTickets,
    mutation: DownloadAnalisePesquisaTicketDocument
  }
];
