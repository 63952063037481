import React, { useState, useCallback } from "react";
import PapperBlock from "../../../componentes/PapperBlock/PapperBlock";
import {
  ListarParametrosComponent,
  SalvarParametrosComponent,
  SalvarParametrosMutation,
  SalvarParametrosMutationVariables
} from "../../../graphql/components";
import Loading from "../../../componentes/Loading/Loading";
import { Button } from "@material-ui/core";
import "./parametros.css";
import { MutationFunction } from "react-apollo";
import SnackMessage from "../../../componentes/SnakBar";

interface Props {
  texto?: string;
  setTexto: (value: string) => void;
}
const TextArea: React.FC<Props> = ({ texto, setTexto }) => {
  const [value, setValue] = useState(texto ? texto : "");

  return (
    <React.Fragment>
      <textarea
        rows={5}
        style={{ width: "100%" }}
        maxLength={500}
        value={value}
        onChange={e => {
          setValue(e.target.value);
          setTexto(e.target.value);
        }}
      />
      ({value ? value.length : 0} / 500)
    </React.Fragment>
  );
};

const Parametros = () => {
  const [texto, setTexto] = useState("");
  const [message, setMessage] = useState("");
  const [messageOpen, setMessageOpen] = useState(false);

  const closeMessage = useCallback(() => setMessageOpen(false), []);
  const save = useCallback(
    async (mutation: MutationFunction<SalvarParametrosMutation, SalvarParametrosMutationVariables>) => {
      const result = await mutation({ variables: { values: { texto }, insert: false } });

      if (result && result.data && result.data.salvarParametros) {
        setMessage("Parâmetros salvos com sucesso.");
        setMessageOpen(true);
      }
    },
    [texto]
  );

  return (
    <PapperBlock title="Parâmetros" desc="Texto Fecha Movimento.">
      <ListarParametrosComponent>
        {({ data, loading }) => {
          if (loading) {
            return <Loading />;
          }
          if (data && data.listarParametros) {
            return data.listarParametros
              .filter(parametro => parametro.grupo === "RELATORIO" && parametro.item === "FECHA_MOVIMENTO")
              .map(parametro => <TextArea key={parametro.texto} texto={parametro.texto} setTexto={setTexto} />) as any;
          }
          return null;
        }}
      </ListarParametrosComponent>
      <div className="button">
        <SalvarParametrosComponent>
          {mutation => (
            <Button variant="contained" color="primary" onClick={save.bind(null, mutation)}>
              Salvar
            </Button>
          )}
        </SalvarParametrosComponent>
      </div>
      <SnackMessage message={message} variant="success" open={messageOpen} close={closeMessage} />
    </PapperBlock>
  );
};

export default Parametros;
