import MomentUtils from "@date-io/moment";
import { Card, FormControl, Grid, SwipeableDrawer } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import LinearProgress from "@material-ui/core/LinearProgress";
import Zoom from "@material-ui/core/Zoom";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { Field, Formik, FormikActions, FormikProps } from "formik";
import locale from "moment/locale/pt-br";
import React from "react";
import { MutationFunction } from "react-apollo";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import voca from "voca";
import * as Yup from "yup";
import FormikInputText from "../../../componentes/MaskedInput/FomikInput";
import FormikCurrencyInput from "../../../componentes/MaskedInput/FormikCurrencyInput";
import PapperBlock from "../../../componentes/PapperBlock/PapperBlock";
import { SelectCombo } from "../../../componentes/Select";
import SnackMessage from "../../../componentes/SnakBar";
import {
  IncluirTicketComponent,
  IncluirTicketMutation,
  IncluirTicketMutationVariables,
  ListarBandeirasDocument,
  ListarItensConciliadoresCodExternoDocument,
  ListarItensConciliadoresDocument,
  ListarOperadorasDocument,
} from "../../../graphql/components";
import "./estilo.css";
import TicketCard from "./ticketCard";
import getUserAgent, { UserAgentType } from "../../../utils/userAgentUtil";

const numberMask = createNumberMask({
  prefix: "",
  suffix: "",
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ".",
  allowDecimal: true,
  decimalSymbol: ",",
  mask: " ",
});

const margin = 255;

const stringObrigatorio = "é obrigatório.";

const SchemaTicket = Yup.object().shape({
  codBandeira: Yup.number().required(`Bandeira ${stringObrigatorio}`),
  nsu: Yup.string(),
  tid: Yup.string(),
  autorizacao: Yup.string().required(`Autorização ${stringObrigatorio}`),
  quantidadeParcelas: Yup.number().required(`Total de Parcelas ${stringObrigatorio}`),
  datavenda: Yup.date().required(`Total de Parcelas ${stringObrigatorio}`),
  valor: Yup.string(),
});

const initialValuesDadosTicket = {
  codBandeira: ("" as unknown) as number,
  nsu: "",
  tid: "",
  autorizacao: "",
  quantidadeParcelas: ("" as unknown) as number,
  datavenda: (null as unknown) as Date,
  valor: ("" as unknown) as string,
};

const Schema = Yup.object().shape({
  codpessoa: Yup.number(),
  codoperadora: Yup.number(),
  codexterno: Yup.string(),
});

const initialValues = {
  codpessoa: "",
  codoperadora: "",
  codexterno: "",
};

type FormValues = typeof initialValues;
export type FormValuesTicket = typeof initialValuesDadosTicket;

interface Props {}

interface State {
  hasCodCliente: boolean;
  message: string;
  messageVariant: "success" | "warning" | "error" | "info";
  messageOpen: boolean;
  marginRight?: number;
  codpessoa?: number;
  codoperadora?: number;
  codexterno?: number;
  tickets: Array<FormValuesTicket>;
  loading: boolean;
}

export default class InserirTicket extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      hasCodCliente: false,
      message: "",
      messageOpen: false,
      messageVariant: "success",
      tickets: [],
      loading: false,
    };
  }

  closeMessage = () => this.setState({ messageOpen: false });

  excluirTicket = (index: number) => {
    const tickets = [...this.state.tickets];
    tickets.splice(index, 1);
    this.setState({ tickets, marginRight: tickets.length > 0 ? margin : undefined });
  };

  prepareInsert = (values: FormValues, actions: FormikActions<FormValues>) => {
    if (!values.codexterno) {
      this.setState({
        message: "Selecione um código de cliente",
        messageOpen: true,
        messageVariant: "warning",
        hasCodCliente: false,
      });
    } else {
      this.setState({
        hasCodCliente: true,
        codexterno: parseInt(values.codexterno),
        codoperadora: parseInt(values.codoperadora),
        codpessoa: parseInt(values.codpessoa),
      });
    }
  };

  addTicket = (values: FormValuesTicket, actions: FormikActions<FormValuesTicket>) => {
    const newTicket = {
      autorizacao: values.autorizacao,
      codBandeira: values.codBandeira,
      datavenda: values.datavenda,
      nsu: values.nsu,
      quantidadeParcelas: values.quantidadeParcelas,
      tid: values.tid,
      valor: values.valor,
    };
    this.setState((prevState) => ({
      ...prevState,
      open: true,
      marginRight: margin,
      tickets: [...prevState.tickets, newTicket],
    }));
    actions.setSubmitting(false);
    actions.resetForm();
  };

  salvarTickets = async (mutate: MutationFunction<IncluirTicketMutation, IncluirTicketMutationVariables>) => {
    this.setState({ loading: true });
    const tickets = this.state.tickets.map((ticket) => {
      const retorno: any = ticket;
      const valor = voca.replaceAll(voca.replaceAll(ticket.valor, ".", ""), ",", ".");
      retorno.valor = parseFloat(valor);
      return retorno;
    });
    try {
      await mutate({
        variables: {
          values: {
            codexterno: this.state.codexterno?.toString(),
            codoperadora: this.state.codoperadora,
            codpessoa: this.state.codpessoa,
          },
          tickets,
        },
      });
      const message = tickets.length > 1 ? "Tickets salvos com sucesso!" : "Ticket salvo com sucesso!";
      this.setState({
        loading: false,
        messageOpen: true,
        message,
        messageVariant: "success",
        tickets: [],
      });
    } catch (e) {
      this.setState({ loading: false });
    }
  };

  render() {
    const { message, messageVariant, messageOpen } = this.state;
    return (
      <PapperBlock
        title="Inserir Ticket"
        marginRight={
          this.state.tickets.length > 0 && getUserAgent() === UserAgentType.BROSER ? this.state.marginRight : undefined
        }
      >
        <Card elevation={20} style={{ padding: 15 }}>
          <Formik
            initialValues={initialValues}
            onSubmit={this.prepareInsert}
            validationSchema={Schema}
            render={(formikBag: FormikProps<FormValues>) => (
              <form autoComplete="new-password" role="presentation" id="first" onSubmit={formikBag.handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={4}>
                    <SelectCombo
                      label="Empresa"
                      errorText={formikBag.errors.codpessoa}
                      handleBlur={formikBag.handleBlur}
                      handleChange={formikBag.handleChange}
                      name="codpessoa"
                      value={formikBag.values.codpessoa}
                      query={ListarItensConciliadoresDocument}
                      nameQuery="listarItensConciliadores"
                      disabled={this.state.loading}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <SelectCombo
                      label="Operadora"
                      errorText={formikBag.errors.codoperadora}
                      handleBlur={formikBag.handleBlur}
                      handleChange={formikBag.handleChange}
                      name="codoperadora"
                      value={formikBag.values.codoperadora}
                      query={ListarOperadorasDocument}
                      nameQuery="listarOperadoras"
                      disabled={this.state.loading}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <SelectCombo
                      label="Cód. Cliente"
                      errorText={formikBag.errors.codexterno}
                      handleBlur={formikBag.handleBlur}
                      handleChange={async (e) => {
                        await formikBag.handleChange(e);
                        formikBag.submitForm();
                      }}
                      disabled={this.state.loading}
                      name="codexterno"
                      value={formikBag.values.codexterno}
                      query={ListarItensConciliadoresCodExternoDocument}
                      nameQuery="listarItensConciliadoresCodExterno"
                      variables={{
                        codpessoa: formikBag.values.codpessoa ? parseInt(formikBag.values.codpessoa) : null,
                        codOperadora: formikBag.values.codoperadora ? parseInt(formikBag.values.codoperadora) : null,
                      }}
                    />
                  </Grid>
                </Grid>
              </form>
            )}
          />
        </Card>
        <Zoom in={this.state.hasCodCliente}>
          <Card elevation={20} style={{ marginTop: 21, padding: 15 }}>
            <Formik
              initialValues={initialValuesDadosTicket}
              onSubmit={this.addTicket}
              validationSchema={SchemaTicket}
              render={(formikBag: FormikProps<FormValuesTicket>) => (
                <form autoComplete="new-password" role="presentation" id="second" onSubmit={formikBag.handleSubmit}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={3}>
                      <SelectCombo
                        label="Bandeira"
                        errorText={formikBag.errors.codBandeira}
                        handleBlur={formikBag.handleBlur}
                        handleChange={formikBag.handleChange}
                        name="codBandeira"
                        value={formikBag.values.codBandeira ? formikBag.values.codBandeira! : ""}
                        query={ListarBandeirasDocument}
                        nameQuery="listarBandeiras"
                        variables={{ codOperadora: this.state.codoperadora }}
                        disabled={this.state.loading}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <FormControl aria-describedby="nsu" style={{ width: "95%" }}>
                        <Field id="nsu" name="nsu" component={FormikInputText} label="Doc/NSU" disabled={this.state.loading} />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <FormControl aria-describedby="tid" style={{ width: "95%" }}>
                        <Field id="tid" name="tid" component={FormikInputText} label="TID" disabled={this.state.loading} />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <FormControl aria-describedby="autorizacao" style={{ width: "95%" }}>
                        <Field
                          id="autorizacao"
                          name="autorizacao"
                          component={FormikInputText}
                          label="Autorização"
                          disabled={this.state.loading}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <FormControl aria-describedby="nsu" style={{ width: "95%" }}>
                        <Field
                          id="quantidadeParcelas"
                          name="quantidadeParcelas"
                          component={FormikInputText}
                          label="Total de parcelas"
                          number
                          disabled={this.state.loading}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <MuiPickersUtilsProvider utils={MomentUtils} locale={locale}>
                        <KeyboardDatePicker
                          maxDateMessage="Data inválida"
                          minDateMessage="Data inválida"
                          autoOk
                          variant="inline"
                          format="DD/MM/YYYY"
                          invalidDateMessage="Data inválida"
                          id="datavenda"
                          label="Data da venda"
                          disableToolbar
                          maxDate={new Date()}
                          disableFuture
                          value={formikBag.values.datavenda as any}
                          onChange={(e) => {
                            if (e && e.toDate()) {
                              formikBag.setFieldValue("datavenda", e.toDate());
                            } else {
                              formikBag.setFieldValue("datavenda", null);
                            }
                          }}
                          placeholder="dd/mm/aaaa"
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <FormControl aria-describedby="valor" style={{ width: "95%" }}>
                        <Field
                          id="valor"
                          name="valor"
                          component={FormikCurrencyInput}
                          mask={numberMask}
                          label="Valor"
                          disabled={this.state.loading}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={3} container alignContent="center" alignItems="center" justify="center">
                      <Button
                        disabled={!formikBag.isValid || formikBag.isSubmitting || this.state.loading}
                        variant="contained"
                        color="primary"
                        style={{ marginTop: 10 }}
                        type="submit"
                      >
                        Adicionar Ticket
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              )}
            />
          </Card>
        </Zoom>
        <Zoom in={this.state.tickets.length > 0}>
          <Card elevation={20} style={{ marginTop: 5 }}>
            <IncluirTicketComponent>
              {(mutate) => (
                <Button
                  variant="text"
                  color="primary"
                  style={{ margin: 10, width: "92%" }}
                  onClick={this.salvarTickets.bind(this, mutate)}
                  disabled={this.state.loading}
                >
                  {this.state.loading ? "Estamos processando sua requisição" : "Salvar Tickets"}
                </Button>
              )}
            </IncluirTicketComponent>
            <Zoom in={this.state.loading}>
              <LinearProgress color="secondary" />
            </Zoom>
          </Card>
        </Zoom>
        <SwipeableDrawer
          variant={getUserAgent() === UserAgentType.BROSER ? "persistent" : "temporary"}
          anchor={"right"}
          open={this.state.tickets.length > 0}
          style={{ width: this.state.marginRight }}
          onOpen={() => console.log("open")}
          onClose={() => console.log("close")}
          classes={{ paperAnchorRight: "paper", paperAnchorDockedRight: "paper" }}
        >
          <div style={{ overflowY: "auto" }}>
            {this.state.tickets.map((ticket: FormValuesTicket, index: number) => (
              <TicketCard
                index={index}
                ticket={ticket}
                excluirTicket={this.excluirTicket}
                key={Math.random()}
                disabled={this.state.loading}
              />
            ))}
          </div>
        </SwipeableDrawer>
        <SnackMessage message={message} variant={messageVariant} open={messageOpen} close={this.closeMessage} />
      </PapperBlock>
    );
  }
}
