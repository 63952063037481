import React from "react";
import ColumnCrudTableType from "../../../componentes/CRUD/columnCrudTableType";
import moment from "moment";
import downloadUtils from "../../../utils/downloadUtils";
import ActionsTable, { CallbackActionType } from "../../../componentes/CRUD/actionsTable";
import BarChart from "@material-ui/icons/BarChart";
import TrendingUp from "@material-ui/icons/TrendingUp";
import {
  GerarRelatorioMovimentosFechadosDocument,
  FechaMovimentoReimpressaoBean,
  GerarRelatorioMovimentosFechadosMutation,
  GerarRelatorioMovimentosFechadosMutationVariables
} from "../../../graphql/components";

export const baixarAnaliticoMovimentosFechados: CallbackActionType<
  GerarRelatorioMovimentosFechadosMutation,
  GerarRelatorioMovimentosFechadosMutationVariables
> = async (mutation, record: Array<FechaMovimentoReimpressaoBean>, setMessage) => {
  let hasError = 0;
  for (let r of record) {
    const response = await mutation!({
      variables: { chave: r.chave, datainclusao: r.datainclusao, filtroate: r.filtroate, filtrode: r.filtrode, tipo: "A" }
    });

    if (response && response.data && response.data.gerarRelatorioMovimentosFechados) {
      downloadUtils(response.data.gerarRelatorioMovimentosFechados!);
    } else {
      hasError++;
    }
  }
  if (hasError === record.length) {
    setMessage!(true, "success", "Registro reprocessado com sucesso!");
  } else if (hasError > 0) {
    setMessage!(true, "success", "Registro reprocessado com sucesso!");
  } else {
    setMessage!(true, "success", "Registro reprocessado com sucesso!");
  }
};

export const baixarSinteticoMovimentosFechados: CallbackActionType<
  GerarRelatorioMovimentosFechadosMutation,
  GerarRelatorioMovimentosFechadosMutationVariables
> = async (mutation, record: Array<FechaMovimentoReimpressaoBean>, setMessage) => {
  let hasError = 0;
  for (let r of record) {
    const response = await mutation!({
      variables: { chave: r.chave, datainclusao: r.datainclusao, filtroate: r.filtroate, filtrode: r.filtrode, tipo: "S" }
    });

    if (response && response.data && response.data.gerarRelatorioMovimentosFechados) {
      downloadUtils(response.data.gerarRelatorioMovimentosFechados!);
    } else {
      hasError++;
    }
  }
  if (hasError === record.length) {
    setMessage!(true, "success", "Registro reprocessado com sucesso!");
  } else if (hasError > 0) {
    setMessage!(true, "success", "Registro reprocessado com sucesso!");
  } else {
    setMessage!(true, "success", "Registro reprocessado com sucesso!");
  }
};

export const variablesMovimentosFechados: {
  datageracaode: Date | null;
  datageracaoate: Date | null;
  terminais: string | null;
} = {
  datageracaode: moment().toDate(),
  datageracaoate: moment().toDate(),
  terminais: "99999999999999"
};

export const variablesMovimentosFechadosVenda: {
  datavendade: Date | null;
  datavendaate: Date | null;
  terminais: string | null;
} = {
  datavendade: moment().toDate(),
  datavendaate: moment().toDate(),
  terminais: "99999999999999"
};

export const variablesMovimentosFechadosExibicao: {
  datageracaode: Date;
  datageracaoate: Date;
  terminais: string;
} = {
  datageracaode: moment().toDate(),
  datageracaoate: moment().toDate(),
  terminais: ""
};

export const mapVariablesFiltroMovimentosFechados = {
  datageracaode: "Processado De",
  datageracaoate: "Processado Até",
  terminais: "Grupo Terminal"
};

export const columnsOrderMovimentosFechados = [
  "datainclusao",
  "filtrode",
  "filtroate",
  "valorbruto",
  "valorliquido",
  "creditos",
  "debitos",
  "total"
];

export const colunasMovimentosFechados: Array<ColumnCrudTableType> = [
  {
    name: "Processado",
    options: {
      filter: true,
      customBodyRender: (value: any) => (value ? moment(new Date(value)).format("DD/MM/YYYY hh:mm:ss") : "")
    }
  },
  {
    name: "Venda de",
    options: {
      filter: true,
      customBodyRender: (value: any) => (value ? moment(new Date(value)).format("DD/MM/YYYY hh:mm:ss") : "")
    }
  },
  {
    name: "Venda até",
    options: {
      filter: true,
      customBodyRender: (value: any) => (value ? moment(new Date(value)).format("DD/MM/YYYY hh:mm:ss") : "")
    }
  },
  {
    name: "Bruto",
    options: {
      filter: true
    }
  },
  {
    name: "Líquido",
    options: {
      filter: true
    }
  },
  {
    name: "Créditos",
    options: {
      filter: true
    }
  },
  {
    name: "Débitos",
    options: {
      filter: true
    }
  },
  {
    name: "Total",
    options: {
      filter: true
    }
  }
];

export const actionsTableMovimentosFechados: Array<ActionsTable> = [
  {
    actionFunction: baixarAnaliticoMovimentosFechados,
    iconAction: <BarChart />,
    titleAction: "Analítico",
    mutation: GerarRelatorioMovimentosFechadosDocument
  },
  {
    actionFunction: baixarSinteticoMovimentosFechados,
    iconAction: <TrendingUp />,
    titleAction: "Sintético",
    mutation: GerarRelatorioMovimentosFechadosDocument
  }
];
