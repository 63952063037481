import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { getTitulo } from "../../utils/routes";
import styles from "./appStyles-jss";
import LeftSidebarLayout from "./layouts/LeftSidebarLayout";

interface Props extends RouteComponentProps {
  classes: any;
  children: React.ReactNode;
  loadTransition?: (isLoaded: Boolean) => void;
  pageLoaded?: boolean;
  toggleDrawer?: any;
  mode?: string;
  changeMode?: any;
}

class Dashboard extends React.Component<Props> {
  state = {
    transform: 0,
    openGuide: false
  };

  componentDidMount = () => {
    // const { history } = this.props;

    window.addEventListener("scroll", this.handleScroll);

    // Set expanded sidebar menu
    // const currentPath = history.location.pathname;
    // initialOpen(currentPath);
    // Play page transition
    //loadTransition(true);

    // Execute all arguments when page changes
    // this.unlisten = history.listen(() => {
    //  window.scrollTo(0, 0);
    //   setTimeout(() => {
    //    loadTransition(true);
    //   }, 500);
    //  });
  };

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = () => {
    const doc = document.documentElement;
    const scroll = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    this.setState({
      transform: scroll
    });
  };

  handleOpenGuide = () => {
    this.setState({ openGuide: true });
  };

  handleCloseGuide = () => {
    this.setState({ openGuide: false });
  };

  render() {
    const { classes, children, loadTransition, pageLoaded, changeMode, history } = this.props;
    const { transform } = this.state;
    const titleException = ["/app", "/app/crm-dashboard", "/app/crypto-dashboard"];
    const path = history.location.pathname;
    const titulo = getTitulo(path);

    return (
      <div className={classNames(classes.appFrameInner, classes.sideNav, "light-mode")}>
        <LeftSidebarLayout
          loadTransition={loadTransition}
          changeMode={changeMode}
          pageLoaded={pageLoaded}
          mode="light"
          gradient="true"
          deco="true"
          bgPosition="full"
          transform={transform}
          place={titulo}
          titleException={titleException}
          handleOpenGuide={this.handleOpenGuide}
        >
          {children}
        </LeftSidebarLayout>
      </div>
    );
  }
}

// const mapStateToProps = (state: IState) => {
//   return {
//     pageLoaded: state.ui.pageLoaded,
//     mode: state.ui.type,
//     gradient: state.ui.gradient,
//     deco: state.ui.decoration,
//     layout: state.ui.layout,
//     bgPosition: state.ui.bgPosition
//   };
// };

// const mapDispatchToProps = (dispatch: Dispatch) => ({
//   loadTransition: bindActionCreators(playTransitionAction, dispatch)
// });

// const DashboardMaped = connect(mapStateToProps, mapDispatchToProps)(Dashboard);

export default withStyles(styles as any)(withRouter(Dashboard));
