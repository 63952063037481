import Button from "@material-ui/core/Button";
import { Theme, withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import React from "react";
import "./painelContrato.css";

const styles = (theme: Theme) => ({
  button: {
    margin: theme.spacing()
  }
});

type IProps = {
  classes: any;
  status: string;
};

const getSpecificNotification = (text: string, state: any, classes: any) => {
  switch (state) {
    case "AGUARDANDO_LIBERACAO_OPERADORA":
      return (
        <Tooltip title="Aguardando definição da(s) Operadora(s)">
          <Button className={`${state} ${classes.button}`} />
        </Tooltip>
      );

    case "AGUARDANDO_SENHA":
      return (
        <Tooltip title="Aguardando Senha">
          <Button className={`${state} ${classes.button}`} />
        </Tooltip>
      );

    case "DOCUMENTO_REJEITADO":
      return (
        <Tooltip title="Documento Rejeitado">
          <Button className={`${state} ${classes.button}`} />
        </Tooltip>
      );
    case "AGUARDANDO_DOCUMENTO":
      return (
        <Tooltip title="Aguardando Documentos">
          <Button className={`${state} ${classes.button}`} />
        </Tooltip>
      );

    case "AGUARDANDO_EC":
      return (
        <Tooltip title="Aguardando EC">
          <Button className={`${state} `} />
        </Tooltip>
      );

    case "T":
      return (
        <Tooltip title="Aguardando Taxas">
          <Button className={`${state} ${classes.button}`} />
        </Tooltip>
      );

    case "P":
      return (
        <Tooltip title="Verificação Prévia dos Documentos Documentos">
          <Button className={`${state} ${classes.button}`} />
        </Tooltip>
      );

    case "Z":
      return (
        <Tooltip title="Extrato de VAN em Atraso">
          <Button className={`${state} ${classes.button}`} />
        </Tooltip>
      );

    case "A":
      return (
        <Tooltip title="Aguardando Aprovação da Operadora">
          <Button className={`${state} ${classes.button}`} />
        </Tooltip>
      );

    case "F":
      return (
        <Tooltip title="Recebendo Extrato">
          <Button className={`${state} ${classes.button}`} />
        </Tooltip>
      );

    case "SUSPENSO":
      return (
        <Tooltip title="Suspenso">
          <Button className={`${state} ${classes.button}`} />
        </Tooltip>
      );

    case "CANCELADO":
      return (
        <Tooltip title="Cancelado">
          <Button className={`${state} ${classes.button}`} />
        </Tooltip>
      );

    case "CANCELADOEC":
      return (
        <Tooltip title="Cancelado EC">
          <Button className={`${state} ${classes.button}`} />
        </Tooltip>
      );

    case "NN":
      return (
        <Tooltip title="Unidades Não Cadastradas">
          <Button className={`${state} ${classes.button}`} />
        </Tooltip>
      );
    default:
      return null;
  }
};

function MonitorContrato(props: IProps) {
  const { classes, status } = props;

  return <div>{getSpecificNotification("status", status, classes)}</div>;
}

export default withStyles(styles)(MonitorContrato);
