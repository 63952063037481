import { DataProxy } from "apollo-cache";
import { forkJoin } from "rxjs";
import { CallbackActionType } from "../../../componentes/CRUD/actionsTable";
import ColumnCrudTableType from "../../../componentes/CRUD/columnCrudTableType";
import {
  DeletarClientesMutation,
  DeletarClientesMutationVariables,
  GetClientesDocument,
  GetClientesQuery
} from "../../../graphql/components";
import { FetchResult } from "apollo-link";

export const variablesClientes: { nome: string | null; status: string | null } = {
  nome: null,
  status: null
};

export const deleteMutationClientes: CallbackActionType<DeletarClientesMutation, DeletarClientesMutationVariables> = (
  mutation,
  record,
  setMessage
) => {
  if (mutation) {
    const deletesPromises: Array<any> = [];
    record.forEach(r => {
      deletesPromises.push(
        mutation({
          variables: { values: { editValues: { codpessoa: r.codpessoa, codpessoapai: r.codpessoapai } } },
          update: (
            store: DataProxy,
            { data }: FetchResult<DeletarClientesMutation, Record<string, any>, Record<string, any>>
          ) => {
            if (data!.deletarClientes) {
              const oldResult = store.readQuery<GetClientesQuery>({
                query: GetClientesDocument,
                variables: { values: variablesClientes }
              });
              if (oldResult) {
                const index = oldResult.getClientes.findIndex(u => u.codpessoa === r.codpessoa);
                oldResult.getClientes.splice(index, 1);
                const result = [...oldResult.getClientes];
                store.writeQuery<GetClientesQuery>({
                  query: GetClientesDocument,
                  data: { getClientes: result },
                  variables: variablesClientes
                });
              }
            }
          }
        })
      );
    });
    forkJoin(deletesPromises).subscribe(
      response => {
        const totalErrors = response.filter(r => typeof r === "string").length;
        if (totalErrors > 0 && totalErrors !== deletesPromises.length) {
          setMessage(
            true,
            "warning",
            "Alguns registros não foram excluídos. Dentro de 5 segundos iremos atualizar a página para manter os dados coerentes."
          );
          setTimeout(() => window.location.reload(), 6500);
        } else if (totalErrors === 0) {
          setMessage(true, "success", "Registro(s) excluídos com sucesso!");
        }
      },
      errors => {
        console.log(errors);
      }
    );
  }
};

export const columnsOrderClientes = ["nome", "status"];

export const colunasClientes: Array<ColumnCrudTableType> = [
  {
    name: "Nome",
    options: {
      filter: true
    }
  },
  {
    name: "Status",
    options: {
      filter: true
    }
  }
];
