import { Button, Fab, Grid, Paper, Step, StepLabel, Stepper, Tooltip, Typography, withStyles } from "@material-ui/core";
import { isWidthDown } from "@material-ui/core/withWidth";
import AddIcon from "@material-ui/icons/Add";
import ApolloClient from "apollo-client";
import { Field, FormikProps } from "formik";
import React, { Fragment, useState } from "react";
import { ApolloConsumer } from "react-apollo";
import SelectedRowsContext from "../../../../componentes/CRUD/selectedRowsContext";
import FormikInputText from "../../../../componentes/MaskedInput/FomikInput";
import PapperBlock from "../../../../componentes/PapperBlock/PapperBlock";
import { SelectCombo } from "../../../../componentes/Select";
import SnackMessage from "../../../../componentes/SnakBar";
import {
  GetItensProdutosByOperadoraPosDocument,
  GetPlanosPosTaxasQuery,
  ListarItensPlanosDocument,
  ListarItensProdutosDocument,
  ListarOperadorasDocument,
  ResultadoAddTaxasPosDocument,
  ResultadoSubTaxasPosDocument,
} from "../../../../graphql/components";
import { FormValues } from "./newEditPlanosTaxas";
import TabelaTaxasPorParcela from "./tabelaTaxasPorParcela";

type Props = {
  classes: any;
  formikBag: FormikProps<FormValues>;
  data?: GetPlanosPosTaxasQuery;
  width?: any;
};

const styles = (theme: any) => ({
  appBar: {
    position: "relative",
  },
  layout: {
    width: "auto",
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(3),
  },
  stepper: {
    padding: `${theme.spacing(3)}px 0 ${theme.spacing(5)}px`,
  },
  finishMessage: {
    textAlign: "center",
    maxWidth: 600,
    margin: "0 auto",
    "& h4": {
      color: theme.palette.primary.main,
      "& span": {
        textAlign: "center",
        display: "block",
        "& svg": {
          height: "auto",
          width: 148,
        },
      },
    },
  },
  buttons: {
    display: "flex",
    justifyContent: "center",
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(),
  },
});

const FormPlanosTaxas = ({ formikBag, data, width, classes }: Props) => {
  const [escolhaDisabled, setEscolhaDisabled] = useState(false);
  const [activeStep, setActiveStep] = useState(0);

  const [message, setMessage] = useState("");
  const [messageOpen, setMessageOpen] = useState(false);
  const [messageVariant, setMessageVariant] = useState<"success" | "warning" | "error" | "info">("warning");
  const context = React.useContext(SelectedRowsContext);
  const { codplano } = context[0];
  const steps = ["Operadora", "Produto", "Prosseguir"];
  React.useEffect(() => {
    if (data) {
      setActiveStep(steps.length);
      setEscolhaDisabled(true);
      formikBag.setValues({
        codplano: data.getPlanosPOSTaxas.editValues.codplano!,
        codoperadora: data.getPlanosPOSTaxas.codoperadora!,
        codprodutos: data.getPlanosPOSTaxas.editValues.codprodutos!,
        taxas: {
          de: data.getPlanosPOSTaxas.taxas.de!,
          ate: data.getPlanosPOSTaxas.taxas.ate!,
          taxa: data.getPlanosPOSTaxas.taxas.taxa!,
        },
        listDeTaxas: data.getPlanosPOSTaxas.listDeTaxas,
      });
    } else {
      setActiveStep(0);
      formikBag.setFieldValue("codplano", codplano);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getStepContent = React.useCallback(
    (step: number) => {
      switch (step) {
        case 0:
          return (
            <Grid item xs={12} md={4}>
              <SelectCombo
                label="Operadora"
                disabled={escolhaDisabled}
                errorText={formikBag.errors.codoperadora}
                handleBlur={formikBag.handleBlur}
                handleChange={formikBag.handleChange}
                name="codoperadora"
                value={formikBag.values.codoperadora ? formikBag.values.codoperadora! : ""}
                query={ListarOperadorasDocument}
                touched={formikBag.touched.codoperadora ? formikBag.touched.codoperadora : false}
                nameQuery="listarOperadoras"
              />
            </Grid>
          );
        case 1:
          return (
            <Grid item xs={12} md={4}>
              <SelectCombo
                label="Produto"
                disabled={escolhaDisabled}
                errorText={formikBag.errors.codprodutos}
                handleBlur={formikBag.handleBlur}
                handleChange={formikBag.handleChange}
                name="codprodutos"
                value={formikBag.values.codprodutos ? formikBag.values.codprodutos : ""}
                query={GetItensProdutosByOperadoraPosDocument}
                variables={{ codoperadora: formikBag.values.codoperadora, codplano: formikBag.values.codplano }}
                touched={formikBag.touched.codprodutos ? formikBag.touched.codprodutos : false}
                nameQuery="getItensProdutosByOperadoraPos"
              />
            </Grid>
          );
        case 2:
          return <Typography variant="subtitle1">Confirme sua escolha e continue.</Typography>;

        default:
          throw new Error("Unknown step");
      }
    },
    [escolhaDisabled, formikBag]
  );

  const handleNext = React.useCallback(() => {
    setActiveStep(activeStep + 1);
  }, [activeStep]);

  const handleBack = React.useCallback(() => {
    setActiveStep(activeStep - 1);
  }, [activeStep]);

  const closeMessage = React.useCallback(() => {
    setMessageOpen(false);
    setMessage("");
  }, []);

  const addTaxas = React.useCallback(
    async (client: ApolloClient<any>) => {
      //this.setState({ loading: true });

      const { data } = await client.query({
        query: ResultadoAddTaxasPosDocument,
        variables: {
          de: formikBag.values.taxas.de,
          ate: formikBag.values.taxas.ate,
          taxa: formikBag.values.taxas.taxa,
        },
      });
      //this.setState({ loading: false });

      if (data.resultadoAddTaxasPos.mensagem!) {
        setMessage(data.resultadoAddTaxasPos.mensagem);
        setMessageOpen(true);
        setMessageVariant("warning");
      }

      formikBag.setFieldValue("listDeTaxas", data.resultadoAddTaxasPos.listDeTaxas);
      formikBag.setFieldValue("de", data.resultadoAddTaxasPos.taxas.de);
      formikBag.setFieldValue("ate", data.resultadoAddTaxasPos.taxas.ate);
      formikBag.setFieldValue("taxa", data.resultadoAddTaxasPos.taxas.taxa);
    },
    [formikBag]
  );

  const subTaxas = React.useCallback(
    async (client: ApolloClient<any>, codigo: number | undefined) => {
      //this.setState({ loading: true });
      const { data } = await client.query({
        query: ResultadoSubTaxasPosDocument,
        variables: {
          codtaxa: codigo,
        },
      });
      //this.setState({ loading: false });

      if (data.resultadoSubTaxasPos.mensagem!) {
        setMessage(data.resultadoSubTaxasPos.mensagem);
        setMessageOpen(true);
        setMessageVariant("warning");
      }

      formikBag.setFieldValue("listDeTaxas", data.resultadoSubTaxasPos.listDeTaxas);
      formikBag.setFieldValue("de", data.resultadoSubTaxasPos.taxas.de);
      formikBag.setFieldValue("ate", data.resultadoSubTaxasPos.taxas.ate);
      formikBag.setFieldValue("taxa", data.resultadoSubTaxasPos.taxas.taxa);
    },
    [formikBag]
  );

  return (
    <React.Fragment>
      <PapperBlock title="Geral">
        {activeStep !== steps.length && (
          <Paper className={classes.paper}>
            <Fragment>
              {activeStep === steps.length ? (
                <div className={classes.finishMessage} />
              ) : (
                <Fragment>
                  <Grid container spacing={8}>
                    <Grid item xs={12} md={12}>
                      <Stepper activeStep={activeStep} className={classes.stepper} alternativeLabel={isWidthDown("sm", width)}>
                        {steps.map((label) => (
                          <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                          </Step>
                        ))}
                      </Stepper>
                      {getStepContent(activeStep)}
                    </Grid>
                    <Grid item xs={12} md={5} />
                  </Grid>
                  <div className={classes.buttons}>
                    {activeStep !== 0 && (
                      <Button onClick={handleBack} className={classes.button}>
                        Anterior
                      </Button>
                    )}
                    <Button variant="contained" color="primary" onClick={handleNext} className={classes.button} size="large">
                      {activeStep === steps.length - 1 ? "Proseguir" : "Próximo"}
                    </Button>
                  </div>
                </Fragment>
              )}
            </Fragment>
          </Paper>
        )}

        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <SelectCombo
              label="Plano"
              errorText={formikBag.errors.codplano}
              handleBlur={formikBag.handleBlur}
              handleChange={formikBag.handleChange}
              name="codplano"
              disabled={true}
              value={formikBag.values.codplano ? formikBag.values.codplano! : ""}
              query={ListarItensPlanosDocument}
              touched={formikBag.touched.codplano ? formikBag.touched.codplano : false}
              nameQuery="listarItensPlanos"
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <SelectCombo
              label="Operadora"
              disabled={true}
              errorText={formikBag.errors.codoperadora}
              handleBlur={formikBag.handleBlur}
              handleChange={formikBag.handleChange}
              name="codoperadora"
              value={formikBag.values.codoperadora ? formikBag.values.codoperadora! : ""}
              query={ListarOperadorasDocument}
              touched={formikBag.touched.codoperadora ? formikBag.touched.codoperadora : false}
              nameQuery="listarOperadoras"
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <SelectCombo
              label="Produto"
              disabled={true}
              errorText={formikBag.errors.codprodutos}
              handleBlur={formikBag.handleBlur}
              handleChange={formikBag.handleChange}
              name="codprodutos"
              value={formikBag.values.codprodutos ? formikBag.values.codprodutos! : ""}
              query={ListarItensProdutosDocument}
              touched={formikBag.touched.codprodutos ? formikBag.touched.codprodutos : false}
              nameQuery="listarItensProdutos"
            />
          </Grid>
        </Grid>
      </PapperBlock>

      {activeStep === steps.length && (
        <ApolloConsumer>
          {(client) => (
            <React.Fragment>
              <PapperBlock title="Taxas por parcela">
                <Grid container spacing={8}>
                  <Grid item xs={12} md={3}>
                    <Field id="taxas.de" name="taxas.de" component={FormikInputText} label="Parcela Inicial" />
                  </Grid>

                  <Grid item xs={12} md={3}>
                    <Field id="taxas.ate" name="taxas.ate" component={FormikInputText} label="Parcela Final" />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <Field id="taxas.taxa" name="taxas.taxa" component={FormikInputText} label="Taxa em %" />
                  </Grid>

                  <Grid item xs={12} md={2}>
                    <Tooltip title="Adicionar" placement="top" onClick={() => addTaxas(client as any)}>
                      <Fab color="primary" aria-label="add">
                        <AddIcon style={{ fontSize: 20, cursor: "pointer" }} />
                      </Fab>
                    </Tooltip>
                  </Grid>
                </Grid>

                <TabelaTaxasPorParcela data={formikBag.values.listDeTaxas} client={client as any} subTaxas={subTaxas} />
              </PapperBlock>
            </React.Fragment>
          )}
        </ApolloConsumer>
      )}

      <SnackMessage message={message} variant={messageVariant} open={messageOpen} close={closeMessage} />
    </React.Fragment>
  );
};

export default withStyles(styles as any)(FormPlanosTaxas);
