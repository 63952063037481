import React, { Fragment, useState } from "react";
import { Button, Fab, Grid, Paper, Step, StepLabel, Stepper, Tooltip, Typography, withStyles } from "@material-ui/core";
import { isWidthDown } from "@material-ui/core/withWidth";
import AddIcon from "@material-ui/icons/Add";
import ApolloClient from "apollo-client";
import { Field, FormikProps } from "formik";
import { ApolloConsumer } from "react-apollo";
import voca from "voca";
import SelectedRowsContext from "../../../../componentes/CRUD/selectedRowsContext";
import FormikInputText from "../../../../componentes/MaskedInput/FomikInput";
import FormikCurrencyInput from "../../../../componentes/MaskedInput/FormikCurrencyInput";
import PapperBlock from "../../../../componentes/PapperBlock/PapperBlock";
import { SelectCombo } from "../../../../componentes/Select";
import SnackMessage from "../../../../componentes/SnakBar";
import {
  GetItensProdutosByOperadoraDocument,
  GetPlanosConciliadoresTaxasQuery,
  ListarItensPlanosConciliadoresDocument,
  ListarItensProdutosDocument,
  ListarOperadorasDocument,
  ResultadoAddTaxasBndesDocument,
  ResultadoAddTaxasDocument,
  ResultadoAtualizaTaxasDocument,
  ResultadoSubTaxasDocument,
} from "../../../../graphql/components";
import { FormValues } from "./newEditPlanosTaxas";
import TabelaTaxasPorParcela from "./tabelaTaxasPorParcela";
import TabelaTaxasPorValor from "./tabelaTaxasPorValor";
import ToggleOptions, { ToggleOptionsValues } from "../../../../componentes/ToggleOptions";

const tipoTaxa: Array<ToggleOptionsValues> = [{ label: "%", value: "P" }, { label: "R$", value: "V" }];

type Props = {
  classes: any;
  formikBag: FormikProps<FormValues>;
  data?: GetPlanosConciliadoresTaxasQuery;
  width?: any;
};

const styles = (theme: any) => ({
  appBar: {
    position: "relative",
  },
  layout: {
    width: "auto",
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(3),
  },
  stepper: {
    padding: `${theme.spacing(3)}px 0 ${theme.spacing(5)}px`,
  },
  finishMessage: {
    textAlign: "center",
    maxWidth: 600,
    margin: "0 auto",
    "& h4": {
      color: theme.palette.primary.main,
      "& span": {
        textAlign: "center",
        display: "block",
        "& svg": {
          height: "auto",
          width: 148,
        },
      },
    },
  },
  buttons: {
    display: "flex",
    justifyContent: "center",
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(),
  },
});

const FormPlanosTaxas = ({ formikBag, data, width, classes }: Props) => {
  const [escolhaDisabled, setEscolhaDisabled] = useState(false);
  const [insert, setInsert] = useState(true);
  const [activeStep, setActiveStep] = useState(0);

  const [message, setMessage] = useState("");
  const [messageOpen, setMessageOpen] = useState(false);
  const [messageVariant, setMessageVariant] = useState<"success" | "warning" | "error" | "info">("warning");
  const context = React.useContext(SelectedRowsContext);
  const { codplanosconciliadores, tarifacao } = context[0];
  const steps = ["Operadora", "Produto", "Prosseguir"];
  React.useEffect(() => {
    if (data) {
      console.log(data.getPlanosConciliadoresTaxas.taxas.tipotaxa!);
      setInsert(false);
      setActiveStep(steps.length);
      setEscolhaDisabled(true);
      formikBag.setValues({
        codplanosconciliadores: data.getPlanosConciliadoresTaxas.editValues.codplanosconciliadores!,
        codoperadora: data.getPlanosConciliadoresTaxas.codoperadora!,
        codprodutos: data.getPlanosConciliadoresTaxas.editValues.codprodutos!,
        bincartao: data.getPlanosConciliadoresTaxas.editValues.bincartao!,
        tarifacao: data.getPlanosConciliadoresTaxas.tarifacao!,
        taxabndes: data.getPlanosConciliadoresTaxas.taxabndes!,

        de: data.getPlanosConciliadoresTaxas.taxas.de!,
        ate: data.getPlanosConciliadoresTaxas.taxas.ate!,
        valorde: data.getPlanosConciliadoresTaxas.taxas.valorde!,
        valorate: data.getPlanosConciliadoresTaxas.taxas.valorate!,
        taxa: String(data.getPlanosConciliadoresTaxas.taxas.taxa!),
        listDeTaxas: data.getPlanosConciliadoresTaxas.listDeTaxas,
        taxas: {
          tipotaxa: data.getPlanosConciliadoresTaxas.taxas.tipotaxa!,
        },
      });
    } else {
      setInsert(true);
      setActiveStep(0);
      formikBag.setFieldValue("codplanosconciliadores", codplanosconciliadores);
      formikBag.setFieldValue("tarifacao", tarifacao);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getStepContent = React.useCallback(
    (step: number) => {
      switch (step) {
        case 0:
          return (
            <Grid item xs={12} md={4}>
              <SelectCombo
                label="Operadora"
                disabled={escolhaDisabled}
                errorText={formikBag.errors.codoperadora}
                handleBlur={formikBag.handleBlur}
                handleChange={formikBag.handleChange}
                name="codoperadora"
                value={formikBag.values.codoperadora ? formikBag.values.codoperadora! : ""}
                query={ListarOperadorasDocument}
                touched={formikBag.touched.codoperadora ? formikBag.touched.codoperadora : false}
                nameQuery="listarOperadoras"
              />
            </Grid>
          );
        case 1:
          return (
            <Grid item xs={12} md={4}>
              <SelectCombo
                label="Produto"
                disabled={escolhaDisabled}
                errorText={formikBag.errors.codprodutos}
                handleBlur={formikBag.handleBlur}
                handleChange={formikBag.handleChange}
                name="codprodutos"
                value={formikBag.values.codprodutos ? formikBag.values.codprodutos : ""}
                query={GetItensProdutosByOperadoraDocument}
                variables={{
                  codoperadora: formikBag.values.codoperadora,
                  codplanosconciliadores: formikBag.values.codplanosconciliadores,
                }}
                touched={formikBag.touched.codprodutos ? formikBag.touched.codprodutos : false}
                nameQuery="getItensProdutosByOperadora"
              />
            </Grid>
          );
        case 2:
          return <Typography variant="subtitle1">Confirme sua escolha e continue.</Typography>;

        default:
          throw new Error("Unknown step");
      }
    },
    [formikBag, escolhaDisabled]
  );

  const handleNext = React.useCallback(() => {
    setActiveStep(activeStep + 1);
  }, [activeStep]);

  const handleBack = React.useCallback(() => {
    setActiveStep(activeStep - 1);
  }, [activeStep]);

  const closeMessage = React.useCallback(() => {
    setMessageOpen(false);
    setMessage("");
  }, []);

  const addTaxas = React.useCallback(
    (client: ApolloClient<any>) => async () => {
      const { data } = await client.query({
        fetchPolicy: "network-only",
        query: ResultadoAddTaxasDocument,
        variables: {
          de: formikBag.values.de,
          ate: formikBag.values.ate,
          taxa: parseFloat(voca.replaceAll(voca.replaceAll(formikBag.values.taxa, ".", ""), ",", ".")),
          tipotaxa: formikBag.values.taxas.tipotaxa,
          insert: insert,
        },
      });
      setInsert(false);
      if (data.resultadoAddTaxas.mensagem!) {
        setMessage(data.resultadoAddTaxas.mensagem);
        setMessageOpen(true);
        setMessageVariant("warning");
      } else {
        formikBag.setFieldValue("listDeTaxas", data.resultadoAddTaxas.listDeTaxas);
        formikBag.setFieldValue("de", null);
        formikBag.setFieldValue("ate", null);
        formikBag.setFieldValue("taxa", null);
      }
    },
    [formikBag, insert]
  );

  const addTaxasBndes = React.useCallback(
    (client: ApolloClient<any>) => async () => {
      const { data } = await client.query({
        fetchPolicy: "network-only",
        query: ResultadoAddTaxasBndesDocument,
        variables: {
          valorde: formikBag.values.valorde,
          valorate: formikBag.values.valorate,
          taxa: parseFloat(voca.replaceAll(voca.replaceAll(formikBag.values.taxa, ".", ""), ",", ".")),
          insert: insert,
        },
      });
      setInsert(false);
      if (data.resultadoAddTaxasBndes.mensagem!) {
        setMessage(data.resultadoAddTaxas.mensagem);
        setMessageOpen(true);
        setMessageVariant("warning");
      } else {
        formikBag.setFieldValue("listDeTaxas", data.resultadoAddTaxasBndes.listDeTaxas);
        formikBag.setFieldValue("valorde", null);
        formikBag.setFieldValue("valorate", null);
        formikBag.setFieldValue("taxa", null);
      }
    },
    [formikBag, insert]
  );

  const atualizaTaxas = React.useCallback(
    async (client: ApolloClient<any>, codigo: number | undefined) => {
      const { data } = await client.query({
        fetchPolicy: "network-only",
        query: ResultadoAtualizaTaxasDocument,
        variables: {
          codtaxa: codigo,
        },
      });

      if (data.resultadoAtualizaTaxas.mensagem!) {
        setMessage(data.resultadoAtualizaTaxas.mensagem);
        setMessageOpen(true);
        setMessageVariant("warning");
      } else {
        let valor: string = voca.replaceAll(data.resultadoAtualizaTaxas.taxas.taxa as string, ".", ",");
        formikBag.setFieldValue("listDeTaxas", data.resultadoAtualizaTaxas.listDeTaxas);
        formikBag.setFieldValue("de", data.resultadoAtualizaTaxas.taxas.de);
        formikBag.setFieldValue("ate", data.resultadoAtualizaTaxas.taxas.ate);
        formikBag.setFieldValue("taxa", valor);
      }
    },
    [formikBag]
  );

  const subTaxas = React.useCallback(
    async (client: ApolloClient<any>, codigo: number | undefined) => {
      const { data } = await client.query({
        fetchPolicy: "network-only",
        query: ResultadoSubTaxasDocument,
        variables: {
          codtaxa: codigo,
          tarifacao: "V",
        },
      });
      if (data.resultadoSubTaxas.mensagem!) {
        setMessage(data.resultadoAddTaxas.mensagem);
        setMessageOpen(true);
        setMessageVariant("warning");
      }

      formikBag.setFieldValue("listDeTaxas", data.resultadoSubTaxas.listDeTaxas);
      formikBag.setFieldValue("valorde", data.resultadoSubTaxas.taxas.valorde);
      formikBag.setFieldValue("valorate", data.resultadoSubTaxas.taxas.valorate);
      formikBag.setFieldValue("taxa", data.resultadoSubTaxas.taxas.taxa);
    },
    [formikBag]
  );

  return (
    <React.Fragment>
      <PapperBlock title="Geral">
        {activeStep !== steps.length && (
          <Paper className={classes.paper}>
            <Fragment>
              {activeStep === steps.length ? (
                <div className={classes.finishMessage} />
              ) : (
                <Fragment>
                  <Grid container spacing={8}>
                    <Grid item xs={12} md={12}>
                      <Stepper activeStep={activeStep} className={classes.stepper} alternativeLabel={isWidthDown("sm", width)}>
                        {steps.map((label) => (
                          <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                          </Step>
                        ))}
                      </Stepper>
                      {getStepContent(activeStep)}
                    </Grid>
                    <Grid item xs={12} md={5} />
                  </Grid>
                  <div className={classes.buttons}>
                    {activeStep !== 0 && (
                      <Button onClick={handleBack} className={classes.button}>
                        Anterior
                      </Button>
                    )}
                    <Button variant="contained" color="primary" onClick={handleNext} className={classes.button} size="large">
                      {activeStep === steps.length - 1 ? "Proseguir" : "Próximo"}
                    </Button>
                  </div>
                </Fragment>
              )}
            </Fragment>
          </Paper>
        )}

        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <SelectCombo
              label="Plano"
              errorText={formikBag.errors.codplanosconciliadores}
              handleBlur={formikBag.handleBlur}
              handleChange={formikBag.handleChange}
              name="codplanosconciliadores"
              disabled={true}
              value={formikBag.values.codplanosconciliadores ? formikBag.values.codplanosconciliadores! : ""}
              query={ListarItensPlanosConciliadoresDocument}
              touched={formikBag.touched.codplanosconciliadores ? formikBag.touched.codplanosconciliadores : false}
              nameQuery="listarItensPlanosConciliadores"
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <SelectCombo
              label="Operadora"
              disabled={true}
              errorText={formikBag.errors.codoperadora}
              handleBlur={formikBag.handleBlur}
              handleChange={formikBag.handleChange}
              name="codoperadora"
              value={formikBag.values.codoperadora ? formikBag.values.codoperadora! : ""}
              query={ListarOperadorasDocument}
              touched={formikBag.touched.codoperadora ? formikBag.touched.codoperadora : false}
              nameQuery="listarOperadoras"
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Field id="bincartao" name="bincartao" component={FormikInputText} label="Bin. de Cartão" />
          </Grid>
          <Grid item xs={12}>
            <SelectCombo
              label="Produto"
              disabled={true}
              errorText={formikBag.errors.codprodutos}
              handleBlur={formikBag.handleBlur}
              handleChange={formikBag.handleChange}
              name="codprodutos"
              value={formikBag.values.codprodutos ? formikBag.values.codprodutos : ""}
              query={ListarItensProdutosDocument}
              touched={formikBag.touched.codprodutos ? formikBag.touched.codprodutos : false}
              nameQuery="listarItensProdutos"
            />
          </Grid>
        </Grid>
      </PapperBlock>

      {activeStep === steps.length && (
        <ApolloConsumer>
          {(client) => (
            <React.Fragment>
              {formikBag.values.tarifacao === "P" && (
                <PapperBlock title="Taxas por parcela">
                  <Grid container spacing={2}>
                    <ToggleOptions
                      fieldToChange="taxas.tipotaxa"
                      formikBag={formikBag}
                      label="Registros de"
                      value={formikBag.values.taxas.tipotaxa}
                      values={tipoTaxa}
                      fieldSize={12}
                    />
                    <Grid item xs={12} md={3}>
                      <Field id="de" name="de" component={FormikInputText} label="Parcela Inicial" />
                    </Grid>

                    <Grid item xs={12} md={3}>
                      <Field id="ate" name="ate" component={FormikInputText} label="Parcela Final" />
                    </Grid>

                    <Grid item xs={12} md={2}>
                      <Field id="taxa" name="taxa" component={FormikCurrencyInput} label="Taxa em %" />
                    </Grid>

                    <Grid item xs={12} md={2}>
                      <Tooltip title="Adicionars" placement="top" onClick={addTaxas(client as any)}>
                        <Fab color="primary" aria-label="add">
                          <AddIcon style={{ fontSize: 20, cursor: "pointer" }} />
                        </Fab>
                      </Tooltip>
                    </Grid>
                  </Grid>
                  <TabelaTaxasPorParcela
                    data={formikBag.values.listDeTaxas}
                    client={client as any}
                    atualizaTaxas={atualizaTaxas}
                    subTaxas={subTaxas}
                  />
                </PapperBlock>
              )}

              {formikBag.values.tarifacao === "V" && (
                <PapperBlock title="Taxas por valor">
                  <Grid container spacing={8}>
                    <Grid item xs={12} md={3}>
                      <Field id="valorde" name="valorde" component={FormikInputText} label="Valor Inicial" />
                    </Grid>

                    <Grid item xs={12} md={3}>
                      <Field id="valorate" name="valorate" component={FormikInputText} label="Valor Final" />
                    </Grid>

                    <Grid item xs={12} md={4}>
                      <Field id="taxa" name="taxa" component={FormikInputText} label="Taxa em %" />
                    </Grid>

                    <Grid item xs={12} md={2}>
                      <Tooltip title="Adicionar" placement="top" onClick={() => addTaxasBndes(client as any)}>
                        <Fab color="primary" aria-label="add">
                          <AddIcon style={{ fontSize: 20, cursor: "pointer" }} />
                        </Fab>
                      </Tooltip>
                    </Grid>
                  </Grid>

                  <TabelaTaxasPorValor
                    data={formikBag.values.listDeTaxas}
                    client={client as any}
                    atualizaTaxas={atualizaTaxas}
                    subTaxas={subTaxas}
                  />
                </PapperBlock>
              )}
            </React.Fragment>
          )}
        </ApolloConsumer>
      )}

      <SnackMessage message={message} variant={messageVariant} open={messageOpen} close={closeMessage} />
    </React.Fragment>
  );
};

export default withStyles(styles as any)(FormPlanosTaxas);
