import React, { useEffect, useState } from "react";
import { GetLoginSenhaAtendimentoProps, withGetLoginSenhaAtendimento } from "../../../graphql/components";
import { useHistory } from "react-router";

interface LoginSenha {
  login?: string;
  senha?: string;
}

const Atendimento: React.FunctionComponent<GetLoginSenhaAtendimentoProps> = ({ data: { getLoginSenhaAtendimento } }) => {
  const [loginSenha, setLoginSenha] = useState<LoginSenha>({});
  const formRef = React.createRef<HTMLFormElement>();
  const history = useHistory();
  useEffect(() => {
    if (getLoginSenhaAtendimento) {
      setLoginSenha({ login: getLoginSenhaAtendimento.login, senha: getLoginSenhaAtendimento.senha });
    }
  }, [getLoginSenhaAtendimento]);

  useEffect(() => {
    if (formRef && formRef.current && loginSenha.login && loginSenha.senha) {
      formRef.current.submit();
      history.goBack();
    }
  }, [loginSenha, formRef, history]);

  return (
    <form
      ref={formRef}
      id="formAutenticacao"
      method="post"
      style={{ display: "none" }}
      action="https://concilia.movidesk.com/Account/Authenticate"
      target="_blank"
    >
      <input type="hidden" name="Token" id="token" value="88fc1864-075c-442b-8e7b-9b3b31837598" />
      <input type="hidden" name="UserName" id="username" value={loginSenha.login} />
      <input type="hidden" name="Password" value={loginSenha.senha} />
    </form>
  );
};

const withMutation = withGetLoginSenhaAtendimento({});

export default withMutation(Atendimento);
