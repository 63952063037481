import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import { LinearProgress } from "@material-ui/core";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface Props {
  titulo: string;
  msg: string;
  open: boolean;
  okCallback: () => void;
  close: () => void;
  disabled?: boolean;
}

const Confirm = ({ titulo, msg, okCallback, open, close, disabled }: Props) => {
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition as any}
      disableBackdropClick
      onClose={close}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">{titulo}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">{msg}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={close} color="secondary" disabled={disabled}>
          Cancelar
        </Button>
        <Button onClick={okCallback} color="primary" disabled={disabled}>
          Ok
        </Button>
      </DialogActions>
      {disabled && <LinearProgress color="secondary" style={{ height: 5.5 }} />}
    </Dialog>
  );
};

export default Confirm;
