import { error } from "../actionConstants";
import ActionTypeDefault from "../actionTypeDefault";

export interface InitialStateLogin {
  hasError: boolean;
}

export const initialState: InitialStateLogin = {
  hasError: false
};

export default function reducer(state = initialState, action: ActionTypeDefault) {
  switch (action.type) {
    case error.HAS_ERROR:
      return {
        ...state,
        hasError: true
      };

    case error.HAS_NO_ERROR:
      return {
        ...state,
        hasError: false
      };
    default:
      return state;
  }
}
