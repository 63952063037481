import { Grid, Table, TableHeaderRow } from "@devexpress/dx-react-grid-material-ui";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Theme, Typography } from "@material-ui/core";
import Slide from "@material-ui/core/Slide";
import { withStyles } from "@material-ui/core/styles";
import { fade } from "@material-ui/core/styles/colorManipulator";
import React, { PureComponent } from "react";
import Loading from "../../../../componentes/Loading/Loading";
import { GetInformacoesPosicaoContratoComponent } from "../../../../graphql/components";

const styles = (theme: Theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "fit-content"
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120
  },
  formControlLabel: {
    marginTop: theme.spacing()
  },
  tableStriped: {
    "& tbody tr:nth-of-type(odd)": {
      backgroundColor: fade(theme.palette.primary.main, 0.15)
    },
    overflowX: "auto"
  }
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const TableComponentBase = ({ classes, ...restProps }: any) => <Table.Table {...restProps} className={classes.tableStriped} />;

export const TableComponent = withStyles(styles as any, { name: "TableComponent" })(TableComponentBase);

interface Props {
  codPessoa: string | undefined;
  codExterno: string | undefined;
  operadora: string | undefined;
  status: string | undefined;
  open: boolean;
  handleClose: () => void;
}

interface IState {
  columns: any[];
  tableColumnExtensions: any[];
}

class InformacoesPosicaoContrato extends PureComponent<Props, IState> {
  constructor(props: Props) {
    super(props);

    this.state = {
      columns: [
        { name: "codExterno", title: "Código Externo", filterPlaceholder: "Oi" },
        { name: "operadora", title: "Operadora" },
        { name: "data", title: "Data" },
        { name: "status", title: "Interação" }
      ],
      tableColumnExtensions: [
        { columnName: "codExterno", width: "20%", align: "center", filterPlaceholder: "Oi" },
        { columnName: "operadora", width: "200", align: "center" },
        { columnName: "data", width: "160", align: "center" },
        { columnName: "status", width: "400", align: "left", wordWrapEnabled: true }
      ]
    };
  }
  render() {
    const { codPessoa, codExterno, operadora, status, open, handleClose } = this.props;
    const { columns, tableColumnExtensions } = this.state;
    return (
      <GetInformacoesPosicaoContratoComponent
        variables={{ codPessoa: codPessoa, codExterno: codExterno?.toString(), operadora: operadora, status: status }}
      >
        {({ data, loading }) => {
          if (loading || !data) {
            return <Loading />;
          }
          if (data!.getInformacoesPosicaoContrato) {
            return (
              <React.Fragment>
                <Dialog
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="form-dialog-title"
                  disableBackdropClick={true}
                  TransitionComponent={Transition as any}
                  fullWidth={true}
                  maxWidth="lg"
                >
                  <DialogTitle id="form-dialog-title" style={{ marginBottom: 10 }}>
                    Monitor
                    <Typography variant="subtitle1" gutterBottom>
                      Detalhes sobre a posição do cliente.
                    </Typography>
                  </DialogTitle>
                  <DialogContent>
                    <Grid rows={data!.getInformacoesPosicaoContrato!} columns={columns}>
                      <Table tableComponent={TableComponent} columnExtensions={tableColumnExtensions} />
                      <TableHeaderRow />
                    </Grid>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClose} color="secondary">
                      Fechar
                    </Button>
                  </DialogActions>
                </Dialog>
              </React.Fragment>
            );
          }
          return null;
        }}
      </GetInformacoesPosicaoContratoComponent>
    );
  }
}

export default withStyles(styles as any)(InformacoesPosicaoContrato);
