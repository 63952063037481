import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import { withStyles } from "@material-ui/core/styles";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import classNames from "classnames";
import React, { Fragment } from "react";
import styles from "./sidebar-jss";
import SidebarContent from "./SidebarContent";

interface Props {
  classes?: any;
  // toggleDrawerOpen?: (event: React.MouseEvent<HTMLElement, MouseEvent> | null | SyntheticEvent<{}, Event>) => void;
  toggleDrawerOpen: React.ReactEventHandler<{}>;
  dataMenu?: Array<any>;
  loadTransition?: any;
  turnDarker: boolean;
  open: boolean;
  leftSidebar: boolean;
}

interface State {
  status: String;
  anchorEl: HTMLElement | ((element: HTMLElement) => HTMLElement) | null | undefined;
  turnDarker: boolean;
}

class Sidebar extends React.Component<Props, State> {
  state = {
    status: "online",
    anchorEl: null,
    turnDarker: false,
  };

  // Initial header style
  flagDarker = false;

  componentDidMount = () => {
    window.addEventListener("scroll", this.handleScroll);
  };

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = () => {
    const doc = document.documentElement;
    const scroll = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    const newFlagDarker = scroll > 30;
    if (this.flagDarker !== newFlagDarker) {
      this.setState({ turnDarker: newFlagDarker });
      this.flagDarker = newFlagDarker;
    }
  };

  handleOpen = (event: any) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleChangeStatus = (status: String) => {
    this.setState({ status });
    this.handleClose();
  };

  render() {
    const { classes, open, toggleDrawerOpen, leftSidebar } = this.props;
    const { status, anchorEl, turnDarker } = this.state;
    return (
      <Fragment>
        <Hidden lgUp>
          <SwipeableDrawer
            onClose={toggleDrawerOpen!}
            onOpen={toggleDrawerOpen!}
            open={!open}
            anchor={leftSidebar ? "left" : "right"}
          >
            <div className={classes.swipeDrawerPaper}>
              <SidebarContent
                drawerPaper={open}
                leftSidebar={leftSidebar}
                status={status}
                anchorEl={anchorEl}
                openMenuStatus={this.handleOpen}
                closeMenuStatus={this.handleClose}
                changeStatus={this.handleChangeStatus}
              />
            </div>
          </SwipeableDrawer>
        </Hidden>
        <Hidden mdDown>
          <Drawer
            variant="permanent"
            onClose={toggleDrawerOpen}
            classes={{
              paper: classNames(classes.drawer, classes.drawerPaper, !open ? classes.drawerPaperClose : ""),
            }}
            open={open}
            anchor={leftSidebar ? "left" : "right"}
            id="drawer"
          >
            <SidebarContent
              drawerPaper={open}
              leftSidebar={leftSidebar}
              turnDarker={turnDarker}
              status={status}
              anchorEl={anchorEl}
              openMenuStatus={this.handleOpen}
              closeMenuStatus={this.handleClose}
              changeStatus={this.handleChangeStatus}
            />
          </Drawer>
        </Hidden>
      </Fragment>
    );
  }
}

export default withStyles(styles as any)(Sidebar);
