import React, { SyntheticEvent } from "react";
import Snackbar from "@material-ui/core/Snackbar";
import SnackContent from "./snackContent";

interface Props {
  variant: "success" | "warning" | "error" | "info";
  message: string;
  open: boolean;
  close: () => void;
}

interface State {
  open: boolean;
}

class SnackMessage extends React.Component<Props, State> {
  handleClose = (event: SyntheticEvent<any, Event>, reason: string) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ open: false });
  };

  render() {
    const { variant, message, open, close } = this.props;

    return (
      <div>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={open}
          //autoHideDuration={5000}
          onClose={close}
          style={{ zIndex: 8000000 }}
        >
          <SnackContent onClose={close} variant={variant} message={message} />
        </Snackbar>
      </div>
    );
  }
}

export default SnackMessage;
