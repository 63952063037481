import React from "react";
import Wrapper from "../../../componentes/CRUD";
import { FullScreenDrawerProps } from "../../../componentes/CRUD/crudProps";
import SelectedRowsContext from "../../../componentes/CRUD/selectedRowsContext";
import {
  DeletePlanosConciliadoresContratoDocument,
  GetFormularioPlanosOpcoesPlanosConciliadoresProps,
  ListarPlanosConciliadoresContratoDocument,
  ListarPlanosConciliadoresTaxasDocument,
  withGetFormularioPlanosOpcoesPlanosConciliadores,
  DeletePlanosConciliadoresTaxasDocument,
} from "../../../graphql/components";
import NewEditPlanosContrato from "./planosContrato/newEditPlanosContrato";
import {
  deleteMutationPlanosConciladoresContrato,
  colunasPlanosContrato,
  columnsOrderPlanosContrato,
} from "./planosContrato/utilsPlanosContrato";
import NewEditPlanosTaxas from "./planosTaxas/newEditPlanosTaxas";
import {
  colunasPlanosTaxas,
  columnsOrderPlanosTaxas,
  deleteMutationPlanosConciliadoresTaxas,
} from "./planosTaxas/utilsPlanosTaxas";

const PlanosOpcao: React.FunctionComponent<FullScreenDrawerProps<GetFormularioPlanosOpcoesPlanosConciliadoresProps>> = ({
  data,
}) => {
  const context = React.useContext(SelectedRowsContext);
  const { codplanosconciliadores } = context[0];

  if (data && data.getFormularioPlanosOpcoesPlanosConciliadores) {
    console.log(data.getFormularioPlanosOpcoesPlanosConciliadores.nomeFormulario);
    if (data.getFormularioPlanosOpcoesPlanosConciliadores.nomeFormulario === "CONTRATO") {
      return (
        <React.Fragment>
          <Wrapper
            titulo="Planos conciliadores contratos"
            colunas={colunasPlanosContrato}
            variables={{ codplanosconciliadores: codplanosconciliadores }}
            columnsOrder={columnsOrderPlanosContrato}
            paramSize="x4Large"
            query={{
              query: ListarPlanosConciliadoresContratoDocument,
              queryName: "listarPlanosConciliadoresContrato",
            }}
            deleteMutation={DeletePlanosConciliadoresContratoDocument}
            actionsTable={[{ actionFunction: deleteMutationPlanosConciladoresContrato }]}
            NewComponent={NewEditPlanosContrato}
          />
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <Wrapper
            titulo="Planos conciliadores taxas"
            colunas={colunasPlanosTaxas}
            variables={{ codplanosconciliadores: codplanosconciliadores }}
            columnsOrder={columnsOrderPlanosTaxas}
            query={{
              query: ListarPlanosConciliadoresTaxasDocument,
              queryName: "listarPlanosConciliadoresTaxas",
            }}
            deleteMutation={DeletePlanosConciliadoresTaxasDocument}
            actionsTable={[{ actionFunction: deleteMutationPlanosConciliadoresTaxas }]}
            NewComponent={NewEditPlanosTaxas}
          />
        </React.Fragment>
      );
    }
  }
  return null;
};

const withMutation = withGetFormularioPlanosOpcoesPlanosConciliadores<FullScreenDrawerProps>();

export default withMutation(PlanosOpcao);
