const convertResultToTable = (values: Array<any>, columnsOrder?: Array<string>) => {
  const result: Array<Array<string>> = [];

  if (!columnsOrder) {
    values.forEach(v => {
      result.push(
        Object.keys(v)
          .filter(key => key !== "__typename")
          .map(key => v[key])
      );
    });
    return result;
  } else {
    values.forEach(v => {
      result.push(columnsOrder.map(column => v[column]));
    });
    return result;
  }
};

export default convertResultToTable;
