import React from "react";
import { Helmet } from "react-helmet";
import { Route } from "react-router-dom";
import ErrorWrap from "../../componentes/Error/ErrorWrap";

const title = "Conciliador";
const description = "Conciliador";

const NotFound = () => (
  <Route>
    <div>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="twitter:title" content={title} />
        <meta property="twitter:description" content={description} />
      </Helmet>
      <ErrorWrap title="404" desc="Oops, Página desconhecida :(" />
    </div>
  </Route>
);

export default NotFound;
