import AccountCircle from "@material-ui/icons/AccountCircle";
import AttachMoney from "@material-ui/icons/AttachMoney";
import LockOutline from "@material-ui/icons/LockOutlined";
import SettingsCell from "@material-ui/icons/SettingsCell";
import * as H from "history";
import moment from "moment";
import React from "react";
import { CallbackActionType } from "../../../componentes/CRUD/actionsTable";
import ColumnCrudTableType from "../../../componentes/CRUD/columnCrudTableType";
import {
  LogarUsuarioMestreDocument,
  LogarUsuarioMestreMutation,
  LogarUsuarioMestreMutationVariables,



  LogarUsuarioMestreRevendaDocument,
  LogarUsuarioMestreRevendaMutation, TrocarSenhaDocument,
  TrocarSenhaMutation,
  TrocarSenhaMutationVariables
} from "../../../graphql/components";
import { notAllowConciliador } from "../../../redux/actions/allowAction";
import { authenticated } from "../../../redux/actions/loginAction";
import store from "../../../redux/configureStore";
import Routes from "../../../utils/routes";

export const variablesUsuario: { codpessoa: number | undefined; login: string | undefined; codperfil: number | undefined } = {
  codpessoa: undefined,
  login: undefined,
  codperfil: undefined
};

export const columnsOrderUsuarioMestre = ["login", "nome", "validade", "piloto"];

export const colunasUsuarioMestre: Array<ColumnCrudTableType> = [
  {
    name: "Login",
    options: {
      filter: true
    }
  },
  {
    name: "Nome",
    options: {
      filter: true
    }
  },
  {
    name: "Validade",
    options: {
      filter: true,
      customBodyRender: (value: any) => moment(new Date(value)).format("DD/MM/YYYY")
    }
  },
  {
    name: "Piloto",
    options: {
      filter: true
    }
  }
];

const logar: CallbackActionType<
  LogarUsuarioMestreMutation & LogarUsuarioMestreRevendaMutation,
  LogarUsuarioMestreMutationVariables
> = async (
  mutation,
  record,
  setMessage,
  openRightDrawer,
  openFullScreenDrawer,
  closeRightDrawer,
  closeFullScreenDrawer,
  client,
  history
) => {
  try {
    const result = await mutation!({
      variables: {
        searchValues: {
          codperfil: record[0].codperfil,
          codpessoa: record[0].codpessoa,
          codpessoapai: record[0].codpessoapai,
          login: record[0].login
        }
      }
    });
    const page = result.data?.logarUsuarioMestre ? result.data?.logarUsuarioMestre : result.data?.logarUsuarioMestreRevenda;
    store().configureStore.dispatch(authenticated(page!));
    store().configureStore.dispatch(notAllowConciliador(false));
    // store().configureStore.dispatch(reload());
    setMessage(true, "success", `Login realizado como o usuário ${record[0].login}`);

    setTimeout(async () => {
      history.push(Routes.home.route);
      await client.resetStore();
      await client.cache.reset();
      await client.clearStore();
      await client.resetStore();
      //window.location.reload();
      await store().persistor.flush();
      await store().persistor.purge();
    }, 600);
  } catch (e) {
    setMessage(true, "error", `Erro ao logar como o usuário ${record[0].login}`);
  }
};

const planoRS: CallbackActionType<LogarUsuarioMestreMutation, LogarUsuarioMestreMutationVariables> = (
  mutation,
  record,
  setMessage
) => {
  console.log(record);
};

const tefs: CallbackActionType<LogarUsuarioMestreMutation, LogarUsuarioMestreMutationVariables> = (
  mutation,
  record,
  setMessage,
  openRightDrawer
) => {
  openRightDrawer();
  console.log(record);
};

const trocarSenha: CallbackActionType<TrocarSenhaMutation, TrocarSenhaMutationVariables> = async (
  mutation,
  record,
  setMessage
) => {
  try {
    const novaSenha = await mutation!({ variables: { codpessoa: record[0].codpessoa } });
    setMessage(true, "success", `Sua nova senha é ${novaSenha.data?.trocarSenha}`);
  } catch (e) {
    setMessage(true, "error", "Erro ao alterar senha.");
  }
};

export const actionsTableUsuariosMestres = (records: Array<any>, history: H.History) => {
  const logarAction = {
    actionFunction: logar,
    confirmMessage: "Deseja realmente logar com este usuário?",
    iconAction: <AccountCircle />,
    isVisibleOnlySingleSelection: true,
    titleAction: "Logar",
    mutation:
      history.location.pathname === Routes.listarUsuariosMestres.route
        ? LogarUsuarioMestreDocument
        : LogarUsuarioMestreRevendaDocument
  };
  if (history.location.pathname === Routes.listarUsuariosMestres.route) {
    return [
      logarAction,
      {
        actionFunction: planoRS,
        iconAction: <AttachMoney />,
        isVisibleOnlySingleSelection: true,
        titleAction: "Plano R$"
      },
      {
        actionFunction: tefs,
        iconAction: <SettingsCell />,
        isVisibleOnlySingleSelection: true,
        titleAction: "Tefs"
      },
      {
        actionFunction: trocarSenha,
        iconAction: <LockOutline />,
        isVisibleOnlySingleSelection: true,
        titleAction: "Trocar senha",
        mutation: TrocarSenhaDocument
      }
    ];
  } else {
    return [logarAction];
  }
};
