import { withStyles } from "@material-ui/core/styles";
import React from "react";
import Recaptcha from "react-recaptcha";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { bindActionCreators, Dispatch } from "redux";
import { LoginProps, withLogin } from "../../graphql/components";
import { notAllowConciliador } from "../../redux/actions/allowAction";
import { authenticated } from "../../redux/actions/loginAction";
import "../../styles/custom/forms/LoginForm.css";
import ConciliaCrypt from "../../utils/conciliaCrypt";
import Routes from "../../utils/routes";
import IsVisContext from "../CRUD/isVisContext";
import SnackMessage from "../SnakBar";
import styles from "./user-jss";
import LoginConcilia from "./LoginConcilia";
import LoginSPC from "../../containers/Pages/LoginSPC";
import ConciliaEncrypt from "../../utils/conciliaEncrypt";
import configureStore from "../../redux/configureStore";
const EsqueciMinhaSenha = React.lazy(() => import("./esqueciMinhaSenha"));
const RedefinirMinhaSenha = React.lazy(() => import("./redefinirMinhaSenha"));

interface LoginFormProps extends RouteComponentProps {
  classes?: any;
  authenticate: typeof authenticated;
  notAllowConciliador: typeof notAllowConciliador;
  loginConcilia?: boolean;
}

interface StateLogin {
  showPassword: boolean;
  password: string;
  username: string;
  errors: boolean;
  loading: boolean;
  valueRecaptcha: string | null;
  esqueciMinhaSenhaOpen: boolean;
  openSnackMessage: boolean;
  variantSnackMessage: "success" | "warning" | "info" | "error";
  snackMessage: string;
  renderRecaptcha: boolean;
  idTrocaSenha: string | null;
  isTest: boolean;
}

class LoginForm extends React.Component<LoginProps<LoginFormProps>, StateLogin> {
  static contextType = IsVisContext;

  recaptchaRef: React.RefObject<Recaptcha>;
  loginRef: any;
  timeout: NodeJS.Timeout;
  static defaultProps: { loginConcilia: boolean };
  constructor(props: LoginProps<LoginFormProps>) {
    super(props);
    this.state = {
      showPassword: false,
      password: "",
      username: "",
      errors: false,
      loading: false,
      valueRecaptcha: "",
      esqueciMinhaSenhaOpen: false,
      openSnackMessage: false,
      variantSnackMessage: "success",
      snackMessage: "",
      renderRecaptcha: false,
      idTrocaSenha: null,
      isTest: false,
    };
    this.recaptchaRef = React.createRef();
    this.timeout = setInterval(() => {
      if (!this.state.loading && this.recaptchaRef.current) {
        this.recaptchaRef.current.reset();
        this.recaptchaRef.current.forceUpdate();
        this.recaptchaRef.current.execute();
        this.loginRef.focus();
      }
    }, 55000);
  }

  componentDidMount() {
    this.props.notAllowConciliador();
    //this.loginRef.focus();
    const encripted = this.useQuery().get("idsession");
    if (encripted) {
      const encryptClass = new ConciliaCrypt(encripted);
      this.executeLogin(encryptClass.login, encryptClass.senha);
    } else {
      const idTrocaSenha = this.useQuery().get("idTrocaSenha");
      if (idTrocaSenha) {
        this.setState({ idTrocaSenha });
      }
      if (process.env.REACT_APP_TEST === "false") {
        this.executeCaptcha();
      } else {
        this.setState({ isTest: true, valueRecaptcha: " " });
      }
    }
  }

  componentWillUnmount() {
    clearInterval(this.timeout);
  }

  change = (event: React.FormEvent<HTMLInputElement>) => {
    const { name, value } = event.currentTarget;

    this.setState(({
      [name]: value,
    } as unknown) as Pick<StateLogin, keyof StateLogin>);
  };

  closeEsqueciMinhaSenha = () => this.setState({ esqueciMinhaSenhaOpen: false });

  closeRedefinirMinhaSenha = () => this.setState({ idTrocaSenha: null });

  openEsqueciMinhaSenha = () => this.setState({ esqueciMinhaSenhaOpen: true });

  useQuery = () => {
    return new URLSearchParams(this.props.location.search);
  };

  handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (this.props.mutate && this.state.valueRecaptcha) {
      this.executeLogin(this.state.username, this.state.password);
    } else if (!this.state.valueRecaptcha) {
      this.recaptchaRef.current!.reset();
      this.recaptchaRef.current!.execute();
    }
  };

  executeLogin = async (username: string, password: string) => {
    try {
      this.setState({ errors: false, loading: true });
      const page = await this.props.mutate({
        variables: {
          password,
          username,
          values: { recaptcha: this.state.isTest ? "" : this.state.valueRecaptcha! },
        },
      });
      if (this.recaptchaRef.current) {
        this.recaptchaRef.current.reset();
        this.recaptchaRef.current.execute();
      }
      this.setState({ loading: false });
      if (page) {
        const toPage = page.data!.login!;
        if (toPage === "LOGGEDOUT") {
          this.setState({ errors: true, loading: false });
        } else if (toPage === "jsf") {
          const conciliaEncrypt = new ConciliaEncrypt(
            username
              .concat("##")
              .concat(password)
              .concat("##") + Date.now()
          ).encrypt();
          await configureStore().persistor.flush();
          await configureStore().persistor.purge();
          window.location.href = "/Conciliador/login/loginPass.jsf?pass=" + conciliaEncrypt;
        } else {
          this.props.authenticate(toPage);
          const isVis = this.useQuery().get("isVis");
          if (isVis && isVis === "true") {
            this.context.changeIsVis("vis");
            this.props.history.push(Routes.painel.route);
          } else if (this.props.location.pathname.indexOf("spc") !== -1) {
            this.context.changeIsVis("spc");
            this.props.history.push(Routes.home.route);
          } else {
            //this.context.changeIsVis(false);
            this.props.history.push(Routes.home.route);
          }
        }
      }
    } catch (e) {
      this.setState({ errors: true, loading: false });
      if (!this.props.loginConcilia) {
        this.setMessage("warning", "Login inválido");
      }
      console.log(e);
      if (this.recaptchaRef.current) {
        this.recaptchaRef.current.reset();
        this.recaptchaRef.current.execute();
      }
    }
  };

  handleClickShowPassword = () => {
    const { showPassword } = this.state;
    this.setState({ showPassword: !showPassword });
  };

  handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  verifyCallback = (value: string) => {
    this.setState({ valueRecaptcha: value }, () => {
      this.loginRef.focus();
    });
  };

  executeCaptcha = () => {
    setTimeout(() => {
      this.setState({ renderRecaptcha: true }, () => this.recaptchaRef.current!.execute());
    }, 300);
    this.loginRef.focus();
  };

  goToSignUp = () => {
    this.props.history.push(Routes.signUp.route);
  };

  closeSnackMessage = () => this.setState({ openSnackMessage: false });

  setLoginRef = (ref: any) => (this.loginRef = ref);

  setMessage = (variantSnackMessage: "success" | "warning" | "info" | "error", snackMessage: string) =>
    this.setState({ variantSnackMessage, snackMessage, openSnackMessage: true });

  log = (msg: string) => () => {
    console.log(msg);
  };

  render() {
    const { classes } = this.props;
    return (
      <>
        {this.props.loginConcilia ? (
          <LoginConcilia
            showPassword={this.state.showPassword}
            valueRecaptcha={this.state.valueRecaptcha}
            handleSubmit={this.handleSubmit}
            loading={this.state.loading}
            openEsqueciMinhaSenha={this.openEsqueciMinhaSenha}
            change={this.change}
            errors={this.state.errors}
            classes={classes}
            handleClickShowPassword={this.handleClickShowPassword}
            handleMouseDownPassword={this.handleMouseDownPassword}
            setLoginRef={this.setLoginRef}
            goToSignUp={this.goToSignUp}
          />
        ) : (
          <LoginSPC
            valueRecaptcha={this.state.valueRecaptcha}
            handleSubmit={this.handleSubmit}
            openEsqueciMinhaSenha={this.openEsqueciMinhaSenha}
            change={this.change}
            setLoginRef={this.setLoginRef}
          />
        )}
        {this.state.renderRecaptcha && (
          <Recaptcha
            hl="pt-BR"
            ref={this.recaptchaRef}
            sitekey="6Lf9xU8UAAAAAIUovl8DXe-aQQZjlOSLs3B4Wv-u"
            render="onload"
            size="invisible"
            verifyCallback={this.verifyCallback}
            onloadCallback={this.log("capthca loaded")}
            expiredCallback={this.log("captcha expiredCallback")}
          />
        )}
        <React.Suspense fallback={null}>
          <EsqueciMinhaSenha
            setMessage={this.setMessage}
            isOpen={this.state.esqueciMinhaSenhaOpen}
            handleClose={this.closeEsqueciMinhaSenha}
          />
          <RedefinirMinhaSenha
            setMessage={this.setMessage}
            isOpen={this.state.idTrocaSenha != null}
            handleClose={this.closeRedefinirMinhaSenha}
            idTrocaSenha={this.state.idTrocaSenha}
          />
          <SnackMessage
            close={this.closeSnackMessage}
            open={this.state.openSnackMessage}
            variant={this.state.variantSnackMessage}
            message={this.state.snackMessage}
          />
        </React.Suspense>
      </>
    );
  }
}

LoginForm.defaultProps = {
  loginConcilia: true,
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  authenticate: bindActionCreators(authenticated, dispatch),
  notAllowConciliador: bindActionCreators(notAllowConciliador, dispatch),
});

const LoginFormConnected = connect(
  null,
  mapDispatchToProps
)(LoginForm);

const withLoginMutation = withLogin<LoginFormProps>({});

// @ts-ignore
export default withStyles(styles as any)(withLoginMutation(withRouter(LoginFormConnected)));
