// Global UI Action
export const TOGGLE_SIDEBAR = "TOGGLE_SIDEBAR";
export const OPEN_SUBMENU = "OPEN_SUBMENU";
export const CLOSE_ALL_SUBMENU = "CLOSE_ALL_SUBMENU";
export const CHANGE_THEME = "CHANGE_THEME";
export const CHANGE_MODE = "CHANGE_MODE";
export const CHANGE_GRADIENT = "CHANGE_GRADIENT";
export const CHANGE_DECO = "CHANGE_DECO";
export const CHANGE_BG_POSITION = "CHANGE_BG_POSITION";
export const CHANGE_LAYOUT = "CHANGE_LAYOUT";
export const LOAD_PAGE = "LOAD_PAGE";
export const USER_INFO = "USER_INFO";
export const SET_TITLE = "SET_TITLE";
export const RELOAD = "RELOAD";

//NOT_AUTHENTICATED
export const login = {
  NOT_AUTHENTICATED: "NOT_AUTHENTICATED",
  AUTHENTICATED: "AUTHENTICATED"
};

export const allow = {
  ALLOWED: "ALLOWED",
  NOT_ALLOWED: "NOT_ALLOWED"
};

export const error = {
  HAS_ERROR: "HAS_ERROR",
  HAS_NO_ERROR: "HAS_NO_ERROR"
};

export const menuSistemaTypes = {
  REQUEST_MENU: "REQUEST_MENU",
  RECEIVE_MENU: "RECEIVE_MENU"
};
