import { allow } from "../actionConstants";
import ActionTypeDefault from "../actionTypeDefault";

export interface InitialStateLogin {
  isAllowed: boolean;
}

export const initialState: InitialStateLogin = {
  isAllowed: false
};

export default function reducer(state = initialState, action: ActionTypeDefault) {
  switch (action.type) {
    case allow.NOT_ALLOWED:
      return {
        ...state,
        isAllowed: false
      };

    case allow.ALLOWED:
      return {
        ...state,
        isAllowed: true
      };
    default:
      return state;
  }
}
