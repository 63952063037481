import { Checkbox, FormControlLabel, FormGroup, Grid, Paper } from "@material-ui/core";
import { Formik, FormikActions, FormikProps } from "formik";
import React, { ChangeEvent, useCallback } from "react";
import { withApollo, WithApolloClient } from "react-apollo";
import * as Yup from "yup";
import ButtonGerarRelatorio from "../../../componentes/ButtonGerarRelatorio";
import ConciliaDatePicker from "../../../componentes/ConciliaDatePicker";
import PapperBlock from "../../../componentes/PapperBlock/PapperBlock";
import {
  GetRelatorioFechaMovimentoPrevisaoDocument,
  GetRelatorioFechaMovimentoPrevisaoQuery,
  GetRelatorioFechaMovimentoPrevisaoQueryVariables
} from "../../../graphql/components";
import downloadUtils from "../../../utils/downloadUtils";
import "./fechaMovimentoPrevisao.css";

const FechaMovimentoPrevisaoSchema = Yup.object().shape({
  datavendade: Yup.date()
    .nullable(true)
    .required(`Venda De é obrigatório`),
  datavendaate: Yup.date()
    .nullable(true)
    .required(`Venda Até é obrigatório`),
  dias: Yup.array().required(`Dias é obrigatório`)
});

const initialValues = {
  datavendade: (null as unknown) as Date,
  datavendaate: (null as unknown) as Date,
  dias: [] as Array<string>
};

export type FormValues = typeof initialValues;

interface Props {}

function FechaMovimentoPrevisao({ client }: WithApolloClient<Props>) {
  // const [checked, setChecked] = useState<Array<string>>([]);
  const submit = useCallback(
    async (values: FormValues, actions: FormikActions<FormValues>) => {
      try {
        const response = await client.query<
          GetRelatorioFechaMovimentoPrevisaoQuery,
          GetRelatorioFechaMovimentoPrevisaoQueryVariables
        >({
          query: GetRelatorioFechaMovimentoPrevisaoDocument,
          variables: { filtro: { datavendade: values.datavendade, datavendaate: values.datavendaate }, dias: values.dias }
        });
        if (response && response.data && response.data.getRelatorioFechaMovimentoPrevisao) {
          downloadUtils(response.data.getRelatorioFechaMovimentoPrevisao);
        }
      } catch (e) {
      } finally {
        actions.setSubmitting(false);
      }
    },
    [client]
  );

  const change = (event: ChangeEvent<HTMLInputElement>, checked: boolean, formikBag: FormikProps<FormValues>) => {
    if (checked) {
      formikBag.setFieldValue("dias", [...formikBag.values.dias!, event.target.value]);
    } else {
      const index = formikBag.values.dias!.findIndex(v => v === event.target.value);
      formikBag.values.dias!.splice(index, 1);
      formikBag.setFieldValue("dias", formikBag.values.dias!.slice(0));
    }
  };

  return (
    <PapperBlock title="Fecha Movimento">
      <Formik
        initialValues={initialValues}
        onSubmit={submit}
        validationSchema={FechaMovimentoPrevisaoSchema}
        render={(formikBag: FormikProps<FormValues>) => (
          <form autoComplete="new-password" role="presentation" onSubmit={formikBag.handleSubmit}>
            <Paper className="paddingPaper">
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <ConciliaDatePicker
                    label="Venda De"
                    name="datavendade"
                    value={formikBag.values.datavendade}
                    error={formikBag.errors.datavendade}
                    touched={formikBag.touched.datavendade}
                    onChange={e => {
                      if (e && e.toDate()) {
                        formikBag.setFieldValue("datavendade", e.toDate());
                      } else {
                        formikBag.setFieldValue("datavendade", null);
                      }
                    }}
                    disabled={formikBag.isSubmitting}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <ConciliaDatePicker
                    label="Venda Até"
                    name="datavendaate"
                    value={formikBag.values.datavendaate}
                    error={formikBag.errors.datavendaate}
                    touched={formikBag.touched.datavendaate}
                    onChange={e => {
                      if (e && e.toDate()) {
                        formikBag.setFieldValue("datavendaate", e.toDate());
                      } else {
                        formikBag.setFieldValue("datavendaate", null);
                      }
                    }}
                    disabled={formikBag.isSubmitting}
                  />
                </Grid>
                <Grid item xs={12} style={{ marginTop: "20px" }} className="dias">
                  <fieldset>
                    <legend>Dias</legend>
                    <FormGroup row>
                      <FormControlLabel
                        control={
                          <Checkbox
                            id="Domingo"
                            value="1"
                            onChange={(e, checked) => change(e, checked, formikBag)}
                            disabled={formikBag.isSubmitting}
                          />
                        }
                        label="Domingo"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            id="Segunda"
                            value="2"
                            onChange={(e, checked) => change(e, checked, formikBag)}
                            disabled={formikBag.isSubmitting}
                          />
                        }
                        label="Segunda"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            id="Terça"
                            value="3"
                            onChange={(e, checked) => change(e, checked, formikBag)}
                            disabled={formikBag.isSubmitting}
                          />
                        }
                        label="Terça"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            id="Quarta"
                            value="4"
                            onChange={(e, checked) => change(e, checked, formikBag)}
                            disabled={formikBag.isSubmitting}
                          />
                        }
                        label="Quarta"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            id="Quinta"
                            value="5"
                            onChange={(e, checked) => change(e, checked, formikBag)}
                            disabled={formikBag.isSubmitting}
                          />
                        }
                        label="Quinta"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            id="Sexta"
                            value="6"
                            onChange={(e, checked) => change(e, checked, formikBag)}
                            disabled={formikBag.isSubmitting}
                          />
                        }
                        label="Sexta"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            id="Sábado"
                            value="7"
                            onChange={(e, checked) => change(e, checked, formikBag)}
                            disabled={formikBag.isSubmitting}
                          />
                        }
                        label="Sábado"
                      />
                    </FormGroup>
                  </fieldset>
                </Grid>
              </Grid>
              <ButtonGerarRelatorio isSubmitting={formikBag.isSubmitting} title="Pesquisar" />
            </Paper>
          </form>
        )}
      />
    </PapperBlock>
  );
}

export default withApollo(FechaMovimentoPrevisao);
