import React, { Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import { Link, Route } from "react-router-dom";
import styles from "./breadCrumb-jss";
import { getTitulo } from "../../utils/routes";
import { GetMenusComponent, Menu } from "../../graphql/components";

const getLabelPais = (menus: Array<Menu>, labelPai: string) => {
  const pai = menus.find(m => m.label === labelPai);
  let retorno = [pai!];
  if (pai!.pai) {
    retorno = [...retorno, ...getLabelPais(menus, pai!.pai!)];
  }

  return retorno;
};

const getParts = (menus: Array<Menu>, path: string) => {
  const menuAtual = menus.find(m => m.page === path);
  if (!menuAtual || !menuAtual.pai) {
    return [];
  }
  return getLabelPais(menus, menuAtual!.pai);
};

interface Props {
  classes: any;
  location: any;
  theme: String;
  separator: String;
}

const Breadcrumbs = (props: Props) => {
  const { classes, theme, separator, location } = props;
  const place = getTitulo(location.pathname);

  const InnerBreadCrumb = React.useMemo(
    () => (menus: Array<Menu>) => {
      const pathArray = location.pathname.split("/");
      const parts = getParts(menus, pathArray.pop());
      return (
        <p>
          Você está aqui:
          <span>
            {parts.reverse().map((part: Menu, partIndex: number) => {
              let path = ["", ...parts.slice(0, partIndex + 1)].join("/");
              if (part.page) {
                return (
                  <Fragment key={path}>
                    <Link to={path}>{part.label!}</Link>
                    {separator}
                  </Fragment>
                );
              }
              return (
                <Fragment key={path}>
                  {part.label!}
                  {separator}
                </Fragment>
              );
            })}
            &nbsp;
            {place}
          </span>
        </p>
      );
    },
    [location, place, separator]
  );
  return (
    <section className={classNames(theme === "dark" ? classes.dark : classes.light, classes.breadcrumbs)}>
      <GetMenusComponent fetchPolicy="cache-only">
        {({ data }) => {
          if (data && data.getMenus) {
            return <Route path="*">{InnerBreadCrumb(data.getMenus)}</Route>;
          }
          return null;
        }}
      </GetMenusComponent>
    </section>
  );
};

export default withStyles(styles as any)(Breadcrumbs);
