import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import React, { PureComponent } from "react";

interface IProps {
  series: any;
  categoria: any;
}

export class GraficoTaxaMediaOperadora extends PureComponent<IProps> {
  chartComponent: React.RefObject<HighchartsReact>;
  constructor(props: IProps) {
    super(props);
    this.chartComponent = React.createRef();
  }

  componentDidMount() {
    const container = this.chartComponent.current?.container.current;

    if (container) {
      container.style.height = "100%";
      container.style.width = "100%";
      this.chartComponent.current?.chart.reflow();
    }
  }

  convertResultSeries = (values: Array<any>) => {
    const result: Array<any> = [];

    values.forEach(v => {
      // let value: Array<any> = [];
      let dados: Array<any> = [];
      v.data.forEach((data: Array<any>) => {
        dados.push(data);
      });
      result.push({ type: "bar", name: v.name, data: dados });
    });

    return result;
  };

  convertResultCategoria = (values: Array<any>) => {
    const result: Array<any> = [];

    values.forEach(v => {
      result.push(v);
    });

    return result;
  };

  render() {
    return (
      <div>
        <HighchartsReact
          ref={this.chartComponent}
          highcharts={Highcharts}
          options={{
            credits: {
              enabled: false
            },
            chart: {
              type: "bar"
            },
            title: {
              text: ""
            },
            subtitle: {
              text: ""
            },
            xAxis: {
              categories: this.convertResultCategoria(this.props.categoria),
              title: {
                text: null
              }
            },
            yAxis: {
              min: 0,
              title: {
                text: "Média",
                align: "high"
              },
              labels: {
                overflow: "justify"
              }
            },
            tooltip: {
              valueSuffix: " %"
            },
            plotOptions: {
              bar: {
                dataLabels: {
                  enabled: true
                }
              }
            },
            legend: {
              layout: "horizontal",
              align: "center",
              verticalAlign: "bottom",
              x: 0,
              y: 10,
              floating: true,
              borderWidth: 1,
              shadow: true
            },
            series: this.convertResultSeries(this.props.series)
          }}
        />
      </div>
    );
  }
}

export default GraficoTaxaMediaOperadora;
