export const lang =  {
    availableFilterHeader: 'Filter available',
    availableHeader: 'Disponível',
    moveLeft: 'Mover esquerda',
    moveAllLeft: 'Mover todos para esquerda',
    moveRight: 'Mover direita',
    moveAllRight: 'Mover todos para direita',
    moveDown: 'Mover item para baixo',
    moveUp: 'Mover item para cima',
    noAvailableOptions: 'Não existem registros dispiníveis',
    noSelectedOptions: 'Não existem registros selecionados',
    selectedFilterHeader: 'Filter selected',
    selectedHeader: 'Registros Selecionados',
};