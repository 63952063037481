import Fade from "@material-ui/core/Fade";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import classNames from "classnames";
import React, { Fragment } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import BreadCrumb from "../../../componentes/BreadCrumb/BreadCrumb";
import IsVisSPCContext from "../../../componentes/CRUD/isVisContext";
import Header from "../../../componentes/Header/Header";
import Sidebar from "../../../componentes/Sidebar/Sidebar";
import styles from "../appStyles-jss";
import Decoration from "../Decoration";

interface Props extends RouteComponentProps {
  classes: any;
  children: any;
  history: any;
  loadTransition?: any;
  changeMode?: any;
  pageLoaded?: any;
  mode: any;
  gradient: any;
  deco: any;
  bgPosition: any;
  transform: any;
  place: any;
  titleException: any;
  handleOpenGuide?: any;
  isComplete?: boolean;
}

interface State {
  sidebarOpen: boolean;
}

class LeftSidebarLayout extends React.Component<Props, State> {
  static contextType = IsVisSPCContext;
  context!: React.ContextType<typeof IsVisSPCContext>;
  constructor(props: Props) {
    super(props);

    this.state = {
      sidebarOpen: false,
    };
  }

  toggleDrawerOpen = () => this.setState((prevState) => ({ sidebarOpen: !prevState.sidebarOpen }));

  render() {
    const {
      classes,
      children,
      loadTransition,
      mode,
      gradient,
      deco,
      history,
      bgPosition,
      changeMode,
      transform,
      place,
      titleException,
      handleOpenGuide,
      isComplete = true
    } = this.props;
    return (
      <Fragment>
        <Header
          toggleDrawerOpen={this.toggleDrawerOpen}
          turnDarker={transform > 30}
          showTitle={transform > 40}
          margin={this.state.sidebarOpen}
          gradient={gradient}
          position="left-sidebar"
          changeMode={changeMode}
          mode={mode}
          title={place}
          history={history}
          openGuide={handleOpenGuide}
          isComplete={isComplete}
        />
        {isComplete && <IsVisSPCContext.Consumer>
          {(value) => {
            if (value && value.isVisSPC === "vis") {
              return null;
            }
            return (
              <Sidebar
                toggleDrawerOpen={this.toggleDrawerOpen}
                open={this.state.sidebarOpen}
                loadTransition={loadTransition}
                turnDarker={transform > 30}
                leftSidebar
              />
            );
          }}
        </IsVisSPCContext.Consumer>}
        <main
          className={classNames(
            classes.content,
            !this.state.sidebarOpen && (!this.context?.isVisSPC || this.context?.isVisSPC === "" || this.context?.isVisSPC === "spc") ? classes.contentPaddingLeft : ""
          )}
          id="mainContent"
        >
          <Decoration mode={mode} gradient={gradient} decoration={deco} bgPosition={bgPosition} horizontalMenu={false} />
          <section className={classNames(classes.mainWrap, classes.sidebarLayout)}>
            {titleException.indexOf(history.location.pathname) < 0 && (
              <div className={classes.pageTitle}>
                <Typography
                  component="h4"
                  className={bgPosition === "header" ? classes.darkTitle : classes.lightTitle}
                  variant="h4"
                >
                  {place}
                </Typography>
                <BreadCrumb separator=" / " theme={bgPosition === "header" ? "dark" : "light"} location={history.location} />
              </div>
            )}
            {!true && <img src="/images/spinner.gif" alt="spinner" className={classes.circularProgress} />}
            <Fade in={true} mountOnEnter unmountOnExit {...(true ? { timeout: 700 } : {})} style={{ overflow: "auto" }}>
              <div className={!true ? classes.hideApp : ""}>
                {/* Application content will load here */}
                {children}
              </div>
            </Fade>
          </section>
        </main>
      </Fragment>
    );
  }
}

// const mapStateToProps = (state: IState) => {
//   return {
//     sidebarOpen: state.ui.sidebarOpen
//   };
// };

// const LeftSidebarLayoutMaped = connect(mapStateToProps, null)(LeftSidebarLayout);

export default withStyles(styles as any)(withRouter(LeftSidebarLayout));
