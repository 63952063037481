import ColumnCrudTableType from "../../../componentes/CRUD/columnCrudTableType";
import moment from "moment";
import downloadUtils from "../../../utils/downloadUtils";
import { CallbackActionType } from "../../../componentes/CRUD/actionsTable";
import {
  DownloadArquivoConciliacaoEmAbertoMutation,
  DownloadArquivoConciliacaoEmAbertoMutationVariables
} from "../../../graphql/components";

export const downloadArquivoConciliacaoEmAberto: CallbackActionType<
  DownloadArquivoConciliacaoEmAbertoMutation,
  DownloadArquivoConciliacaoEmAbertoMutationVariables
> = async (mutation, record, setMessage) => {
  let hasError = 0;
  for (let r of record) {
    const response = await mutation!({
      variables: { codarquivoDownload: r.codarquivo.toString() }
    });
    if (response) {
      const { data } = response;
      if (data && data.downloadArquivoConciliacaoEmAberto) {
        downloadUtils(data.downloadArquivoConciliacaoEmAberto);
      } else {
        hasError++;
      }
    }
  }
  if (hasError === record.length) {
    setMessage(true, "warning", "Arquivo(s) não baixados!");
  } else if (hasError > 0) {
    setMessage(true, "warning", "Erro ao baixar algum dos arquivos");
  } else {
    setMessage(true, "success", "Arquivo(s) baixado(s) com sucesso!");
  }
};

export const variablesValorExibicaoConciliacaoEmAberto: {
  searchValues: {
    codarquivo: number | null;
    nomearquivo: string | null;
    tipo: string | null;
    status: string | null;
    codoperadora: string | null;
    origem: string | null;
  };
  de: Date | null;
  ate: Date | null;
} = {
  searchValues: {
    codarquivo: null,
    nomearquivo: null,
    tipo: null,
    status: null,
    codoperadora: null,
    origem: null
  },
  de: moment()
    .subtract(1, "week")
    .toDate(),
  ate: moment().toDate()
};

export const variablesConciliacaoEmAbertoInitial = JSON.parse(JSON.stringify(variablesValorExibicaoConciliacaoEmAberto));

export const variablesConciliacaoEmAberto: {
  searchValues: {
    codarquivo: number | null;
    nomearquivo: string | null;
    tipo: string | null;
    status: string | null;
    codoperadora: number | null;
    origem: string | null;
  };
  de: Date | null;
  ate: Date | null;
} = {
  searchValues: {
    codarquivo: null,
    nomearquivo: null,
    tipo: null,
    status: null,
    codoperadora: null,
    origem: null
  },
  de: moment()
    .subtract(1, "week")
    .toDate(),
  ate: moment().toDate()
};

export const mapVariablesFiltroConciliacaoEmAberto = {
  searchValues: {
    codarquivo: "Cod. Arquivo",
    nomearquivo: "Nome Arquivo",
    tipo: "Tipo",
    status: "Status",
    codoperadora: "Operadora",
    origem: "Conciliador"
  },
  de: "Data Envio de",
  ate: "Data Envio até"
};

export const getMuiThemeConciliacaoEmAberto = {
  root: {
    padding: 5
  },
  body: {
    fontSize: 11
  }
};

export const columnsOrderConciliacaoEmAberto = [
  "titulo",
  "dataenvio",
  "datarecebimento",
  "dataprocessamento",
  "tipo",
  "status",
  "nomearquivo"
];

export const colunasConciliacaoEmAberto: Array<ColumnCrudTableType> = [
  {
    name: "Título",
    options: {
      filter: true
    }
  },
  {
    name: "Envio",
    options: {
      filter: true,
      customBodyRender: (value: any) => moment(new Date(value)).format("DD/MM/YYYY hh:mm:ss")
    }
  },
  {
    name: "Recebimento",
    options: {
      filter: true,
      customBodyRender: (value: any) => moment(new Date(value)).format("DD/MM/YYYY hh:mm:ss")
    }
  },
  {
    name: "Processamento",
    options: {
      filter: true,
      customBodyRender: (value: any) => moment(new Date(value)).format("DD/MM/YYYY hh:mm:ss")
    }
  },
  {
    name: "Tipo",
    options: {
      filter: true
    }
  },
  {
    name: "Status",
    options: {
      filter: true
    }
  },
  {
    name: "Arquivo",
    options: {
      filter: true
    }
  }
];
