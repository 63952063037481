import MomentUtils from "@date-io/moment";
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import locale from "moment/locale/pt-br";
import React, { PureComponent } from "react";
import { SelectCombo } from "../../../componentes/Select";
import { ListarItensConciliadoresDocument } from "../../../graphql/components";
import { variablesDashBoard, variablesDashBoardExibicao, variablesDashBoardInitial } from "./utilsDashBoard";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import FilterContext from "../../../componentes/CRUD/filterContext";

interface Props {
  variables: typeof variablesDashBoard;
  variablesValorExibicao: typeof variablesDashBoardExibicao;
  isFiltering: boolean;
}
type StateDate = {
  minDate: Date;
  maxDate: Date;
  registro: string;
};
type State = typeof variablesDashBoard & StateDate;

class FilterDashBoard extends PureComponent<Props, State> {
  static contextType = FilterContext;
  constructor(props: Props) {
    super(props);
    this.state = {
      ...variablesDashBoardInitial,
      minDate: variablesDashBoardInitial.de!,
      maxDate: variablesDashBoardInitial.ate!,
      registro: "VENDA",
    };
  }

  handleChange = (event: React.ChangeEvent<{}>, value: string) => {
    this.setState({ registro: value });
  };

  render() {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12} md={3}>
          <SelectCombo
            disabled={this.props.isFiltering}
            label="Conciliador"
            name="empresa"
            nameQuery="listarItensConciliadores"
            query={ListarItensConciliadoresDocument}
            value={this.state.empresa ? this.state.empresa : ""}
            handleChange={(e) => {
              console.log(e);
              const label = (e.currentTarget as any).innerText;
              const value = e.target.value ? (e.target.value as string) : null;
              this.setState({ ...this.state, empresa: value }, () => {
                this.props.variables.empresa = this.state.empresa;
                this.props.variablesValorExibicao.empresa = label;
                console.log(this.context);
                //this.context.setVariables({ ...this.state });
              });
            }}
          />
        </Grid>

        <Grid item xs={12} md={3}>
          <FormControl component="div">
            <FormLabel>Registros de:</FormLabel>
            <RadioGroup
              row
              aria-label="registro"
              name="registro"
              value={this.state.registro}
              onChange={(e: any) => {
                this.setState({ registro: e.target.value }, () => {
                  this.props.variables.registro = this.state.registro;
                  this.props.variablesValorExibicao.registro = this.state.registro === "PAGAMENTO" ? "Pagamento" : "Venda";
                });
              }}
            >
              <FormControlLabel value="PAGAMENTO" control={<Radio />} disabled={this.props.isFiltering} label="Pagamento" />
              <FormControlLabel value="VENDA" control={<Radio />} disabled={this.props.isFiltering} label="Venda" />
            </RadioGroup>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={3}>
          <MuiPickersUtilsProvider utils={MomentUtils} locale={locale}>
            <KeyboardDatePicker
              disabled={this.props.isFiltering}
              autoOk
              style={{ width: "100%" }}
              label="De"
              name="de"
              id="de"
              invalidDateMessage="Data inválida"
              disableToolbar
              variant="inline"
              value={this.state.de}
              minDateMessage={`O período máximo para a pesquisa deve ser de 1 mês`}
              maxDateMessage="O período máximo para a pesquisa deve ser de 1 mês"
              onChange={(e) => {
                if (e && e.toDate()) {
                  this.setState({ de: e.toDate() }, () => {
                    this.props.variables.de = this.state.de;
                    this.props.variablesValorExibicao.de = this.state.de;
                  });
                } else {
                  this.setState({ de: null }, () => {
                    this.props.variables.de = this.state.de;
                    this.props.variablesValorExibicao.de = this.state.de;
                  });
                }
              }}
              format="DD/MM/YYYY"
              placeholder="dd/mm/aaaa"
            />
          </MuiPickersUtilsProvider>
        </Grid>

        <Grid item xs={12} md={3}>
          <MuiPickersUtilsProvider utils={MomentUtils} locale={locale}>
            <KeyboardDatePicker
              disabled={this.props.isFiltering}
              autoOk
              style={{ width: "100%" }}
              label="Até"
              name="ate"
              id="ate"
              invalidDateMessage="Data inválida"
              disableToolbar
              variant="inline"
              minDateMessage={`O período máximo para a pesquisa deve ser de 1 mês`}
              maxDateMessage="O período máximo para a pesquisa deve ser de 1 mês"
              value={this.state.ate}
              onChange={(e) => {
                if (e && e.toDate()) {
                  this.setState({ ate: e.toDate() }, () => {
                    this.props.variables.ate = this.state.ate;
                    this.props.variablesValorExibicao.ate = this.state.ate;
                  });
                } else {
                  this.setState({ ate: null }, () => {
                    this.props.variables.ate = this.state.ate;
                    this.props.variablesValorExibicao.ate = this.state.ate;
                  });
                }
              }}
              format="DD/MM/YYYY"
              placeholder="dd/mm/aaaa"
            />
          </MuiPickersUtilsProvider>
        </Grid>
      </Grid>
    );
  }
}

export default FilterDashBoard;
