import Loadable from "react-loadable";
import Loading from "../componentes/Loading/Loading";

export const NewEditCliente = Loadable({
  loader: () => import("./Pages/Clientes/newEditClientes"),
  loading: Loading as any
});

export const Integracao = Loadable({
  loader: () => import("./Pages/Integracao/integracao"),
  loading: Loading as any
});

export const NotAllowed = Loadable({
  loader: () => import("./Pages/Error/notAllowed"),
  loading: Loading as any
});

export const Painel = Loadable({
  loader: () => import("./Pages/Painel/painel"),
  loading: Loading as any
});

export const HomeMapped = Loadable({
  loader: () => import("./Pages/Home/home"),
  loading: Loading as any
});

export const AjudaMapped = Loadable({
  loader: () => import("./Pages/Ajuda/ajuda"),
  loading: Loading as any
});

export const AlteraSenhaMapped = Loadable({
  loader: () => import("./Pages/AlteraSenha/alteraSenha"),
  loading: Loading as any
});

export const Relatorios = Loadable({
  loader: () => import("./Pages/Relatorios/relatorios"),
  loading: Loading as any
});

export const NewEditUsuario = Loadable({
  loader: () => import("./Pages/Usuario/newEditUsuario"),
  loading: Loading as any
});

export const NewEditPerfil = Loadable({
  loader: () => import("./Pages/Perfil/newEditPerfil"),
  loading: Loading as any
});

export const NewEditTerminal = Loadable({
  loader: () => import("./Pages/Terminal/newEditTerminal"),
  loading: Loading as any
});

export const NewEditTaxasAntecipacao = Loadable({
  loader: () => import("./Pages/TaxasAntecipacao/newEditTaxasAntecipacao"),
  loading: Loading as any
});

export const FilterConciliacaoEmAberto = Loadable({
  loader: () => import("./Pages/ConciliacaoEmAberto/filterConciliacaoEmAberto"),
  loading: Loading as any
});

export const FilterExtratosOriginais = Loadable({
  loader: () => import("./Pages/ExtratosOriginais/filterExtratosOriginais"),
  loading: Loading as any
});

export const FilterImportacaoTicket = Loadable({
  loader: () => import("./Pages/ImportacaoTicket/filterImportacaoTicket"),
  loading: Loading as any
});

export const Login = Loadable({
  loader: () => import("./Pages/Users/Login"),
  loading: Loading as any
});

export const LoginDedicated = Loadable({
  loader: () => import("./Pages/Standalone/LoginDedicated"),
  loading: Loading as any
});

export const FilterManutencaoArquivosUnico = Loadable({
  loader: () => import("./Pages/manutencaoArquivosUnico/filterManutencaoArquivosUnico"),
  loading: Loading as any
});

export const FilterPesquisaTickets = Loadable({
  loader: () => import("./Pages/PesquisaTicket/filterPesquisaTicket"),
  loading: Loading as any
});

export const NotFound = Loadable({
  loader: () => import("./NotFound/NotFound"),
  loading: Loading as any
});
export const NotFoundDedicated = Loadable({
  loader: () => import("./Pages/Standalone/NotFoundDedicated"),
  loading: Loading as any
});
export const Error = Loadable({
  loader: () => import("./Pages/Error"),
  loading: Loading as any
});
export const Maintenance = Loadable({
  loader: () => import("./Pages/Maintenance"),
  loading: Loading as any
});

export const DashboardGrafico = Loadable({
  loader: () => import("./Pages/DashBoard/dashboardGrafico"),
  loading: Loading as any
});
