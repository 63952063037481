import React from "react";
import { withStyles } from "@material-ui/core/styles";
import withWidth, { isWidthDown } from "@material-ui/core/withWidth";
import classNames from "classnames";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import ExpandIcon from "@material-ui/icons/CallMade";
import MinimizeIcon from "@material-ui/icons/CallReceived";
import styles from "./panel-jss";
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints";

interface Props {
  classes?: any;
  openForm: boolean;
  closeForm: () => void;
  children: React.ReactNode;
  width: Breakpoint;
  title: string;
  extraSize?: boolean;
  paramSize?: string;
}

interface State {
  expanded: boolean;
}

class FloatingPanelClass extends React.Component<Props, State> {
  state = {
    expanded: false
  };

  toggleExpand = () => {
    const { expanded } = this.state;
    this.setState({ expanded: !expanded });
  };

  render() {
    const { classes, openForm, closeForm, children, title, extraSize, width, paramSize = "large" } = this.props;
    const { expanded } = this.state;
    return (
      <div>
        <div
          className={classNames(
            classes.formOverlay,
            openForm && (isWidthDown("sm", width) || expanded) ? classes.showForm : classes.hideForm
          )}
        />
        <section
          className={classNames(
            !openForm ? classes.hideForm : classes.showForm,
            expanded ? classes.expanded : "",
            classes.floatingForm,
            classes.formTheme,
            extraSize && classes.large,
            !extraSize && classes[paramSize]
          )}
          style={{ padding: "20px" }}
        >
          <header>
            {title}
            <div className={classes.btnOpt}>
              <IconButton className={classes.expandButton} onClick={this.toggleExpand} aria-label="Expandir">
                {expanded ? <MinimizeIcon /> : <ExpandIcon />}
              </IconButton>
              <IconButton className={classes.closeButton} onClick={closeForm} aria-label="Close">
                <CloseIcon />
              </IconButton>
            </div>
          </header>
          {children}
        </section>
      </div>
    );
  }
}

const FloatingPanelResponsive = withWidth()(FloatingPanelClass);
const FloatingPanel = withStyles(styles as any)(FloatingPanelResponsive);
export default FloatingPanel;
