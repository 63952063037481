import React, { useEffect  } from "react";
import { useHistory } from "react-router";

function Treinamento(){
  const formTreinamento = React.createRef<HTMLFormElement>();
  const history = useHistory();

  useEffect(() => {
    if (formTreinamento && formTreinamento.current) {
      formTreinamento.current.submit();
      history.goBack();
    };
  }, [formTreinamento]);
  
  return(
      <form
        ref={formTreinamento}
        id="formTreinamento"
        method="get"
        style={{ display: "none" }}
        action="https://youtube.com/playlist"
        target="_blank">
        <input type="hidden" name="list" id="list" value="PLBf5e9SM2C0_f8GcfPcfdvicMgExew--j" />
      </form>
    );
    
}

export default Treinamento;
