import { Portal } from "@material-ui/core";
import { InMemoryCache } from "apollo-cache-inmemory";
import { ApolloClient } from "apollo-client";
import { onError } from "apollo-link-error";
import { createHttpLink } from "apollo-link-http";
import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import { ApolloProvider } from "react-apollo";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import Loading from "../../componentes/Loading/Loading";
import SnackMessage from "../../componentes/SnakBar";
import { notAuthenticated } from "../../redux/actions/loginAction";
import configureStore from "../../redux/configureStore";
import IndexRouters from "./indexRoute";
const store = configureStore();

interface State {
  client: any;
  erro: string;
  isOpen: boolean;
  errors: Array<string>;
  openSnackMessage: boolean;
}

interface Props {}

class App extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      client: null,
      erro: "",
      isOpen: false,
      errors: [],
      openSnackMessage: false,
    };
  }

  closeSnackMessage = () => this.setState({ openSnackMessage: false, errors: [] });

  convertMessage = (message: string) => {
    let retorno = message;
    if (message.indexOf("The DELETE statement conflicted with the REFERENCE constraint") > -1) {
      retorno = "Exclusão não realizada pois há dependências do registro.";
    } else if (message.indexOf("Exception while fetching data") > -1) {
      if (message.indexOf("br.com.chart.web.jsf.beans.basic.secure.NotPermittedException") > -1) {
        const index = message.lastIndexOf(":");
        retorno = message.substring(index + 1, message.length + 1).trim();
      } else {
        retorno = message.split(" : ")[1].trim();
      }
    }

    return retorno;
  };

  componentDidMount() {
    const errorLink = onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        for (const erro of graphQLErrors) {
          if (erro.message && erro.message.indexOf("Usuário não logado") !== -1) {
            store.configureStore.dispatch(notAuthenticated());
            this.state.client.cache.reset();
            this.state.client.clearStore();
            configureStore().persistor.flush();
            configureStore().persistor.purge();
          } else if (erro.message) {
            this.setState((prevState) => ({
              ...prevState,
              openSnackMessage: true,
              errors: [...prevState.errors, this.convertMessage(erro.message)],
            }));
          } else if ((erro as any).detailMessage) {
            this.setState((prevState) => ({
              ...prevState,
              openSnackMessage: true,
              errors: [...prevState.errors, (erro as any).detailMessage],
            }));
          } else if (erro && erro.message && erro.message && this.state.errors.findIndex((e) => e === erro.message) === -1) {
            this.setState((prevState) => ({
              ...prevState,
              openSnackMessage: true,
              errors: [...prevState.errors, this.convertMessage(erro.message)],
            }));
          } else if ((erro as any).detailMessage) {
            this.setState((prevState) => ({
              ...prevState,
              openSnackMessage: true,
              errors: [...prevState.errors, (erro as any).detailMessage],
            }));
          } else if (this.state.errors.findIndex((e) => e === erro.message) === -1) {
            this.setState((prevState) => ({
              ...prevState,
              openSnackMessage: true,
              errors: [...prevState.errors, this.convertMessage(erro.message)],
            }));
          }
        }
      } else if (networkError) {
        store.configureStore.dispatch({
          type: "HAS_ERROR",
        });
      }
    });

    const link = createHttpLink({
      uri: `${process.env.REACT_APP_HOST}/rest/graphql.gql`,
      credentials: "include",
    });

    const client = new ApolloClient({
      cache: new InMemoryCache(),
      link: errorLink.concat(link),
      queryDeduplication: false,
      resolvers: {},

      defaultOptions: {
        query: {
          errorPolicy: "all",
        },
      },
    });

    this.setState({ client });
  }

  render() {
    if (!this.state.client) {
      return null;
    }
    return (
      <ApolloProvider client={this.state.client}>
        <Provider store={store.configureStore}>
          <PersistGate loading={<Loading />} persistor={store.persistor}>
            <Portal>
              <React.Fragment>
                {this.state.errors.map((e) => (
                  <SnackMessage
                    close={this.closeSnackMessage}
                    open={this.state.openSnackMessage}
                    variant="warning"
                    message={e}
                    key={e + Math.random()}
                  />
                ))}
              </React.Fragment>
            </Portal>
            <IndexRouters />
          </PersistGate>
        </Provider>
      </ApolloProvider>
    );
  }
}

export default React.memo(App);
