import React from "react";
import { Route, Switch } from "react-router-dom";
import Routes from "../../utils/routes";
import { Error } from "../pageListAsync";
import Dashboard from "../Templates/Dashboard";
import AllowedRouteMapped from "./allowedRoute";
import {
  Ajuda,
  AlteraSenha,
  AntecipationFees,
  AtendimentoSuspense,
  TreinamentoSuspense,
  ComponentPlanos,
  ComponentPlanosTerminal,
  ConciliatingClientContractPosition,
  FaturamentoMovimento,
  FechaMovimentoPrevisaoSuspense,
  FileMaintenance,
  GraphicDashboard,
  Home,
  InsertTickets,
  Integration,
  MaintainingOriginalExtractFiles,
  MovimentosFechadosSuspense,
  NotAllowedSuspenseComponent,
  OpenConciliations,
  Panel,
  ParametrosSuspense,
  Profile,
  Reports,
  SearchTicketToImport,
  Terminal,
  TicketSearch,
  User,
  ClienteSuspense,
  MovimentosFechados,
  UsuariosMestresSuspense,
  DadosNFeSuspense,
} from "./routesComponents";

interface Props {
  changeMode?: any;
}

class Application extends React.PureComponent<Props> {
  render() {
    const { changeMode } = this.props;
    return (
      <Dashboard changeMode={changeMode}>
        <Switch>
          <Route exact path={Routes.home.route} component={Home} />
          <Route exact path={Routes.painel.route} component={Panel} />
          <AllowedRouteMapped exact path={Routes.ajuda.route} component={Ajuda} />
          <AllowedRouteMapped exact path={Routes.alteraSenha.route} component={AlteraSenha} />
          <AllowedRouteMapped exact path={Routes.integracao.route} component={Integration} />
          <AllowedRouteMapped exact strict path={Routes.usuario.route} component={User} />
          <AllowedRouteMapped exact path={Routes.perfil.route} component={Profile} />
          <AllowedRouteMapped exact path={Routes.terminal.route} component={Terminal} />
          <AllowedRouteMapped exact path={Routes.relatorios.route} component={Reports} />
          <AllowedRouteMapped exact path={Routes.taxasAntecipacao.route} component={AntecipationFees} />
          <AllowedRouteMapped exact path={Routes.manutencaoArquivosConciliacaoAberto.route} component={OpenConciliations} />
          <AllowedRouteMapped exact path={Routes.inclusaoTicket.route} component={InsertTickets} />
          <AllowedRouteMapped exact path={Routes.dashboard.route} component={GraphicDashboard} />
          <AllowedRouteMapped exact path={Routes.pesquisaImportacaoTicket.route} component={SearchTicketToImport} />
          <AllowedRouteMapped exact path={Routes.manutencaoArquivos.route} component={FileMaintenance} />
          <AllowedRouteMapped exact path={Routes.pesquisaTicket.route} component={TicketSearch} />
          <AllowedRouteMapped exact path={Routes.planosConciliadores.route} component={ComponentPlanos} />
          <AllowedRouteMapped exact path={Routes.planosTerminal.route} component={ComponentPlanosTerminal} />
          <AllowedRouteMapped exact path={Routes.faturamentomovimento.route} component={FaturamentoMovimento} />
          <AllowedRouteMapped exact path={Routes.fechaMovimentoPrevisao.route} component={FechaMovimentoPrevisaoSuspense} />
          <AllowedRouteMapped exact path={Routes.fechaMovimentoReimpressao.route} component={MovimentosFechados} />
          <AllowedRouteMapped exact path={Routes.fechamovimento.route} component={MovimentosFechadosSuspense} />
          <AllowedRouteMapped exact path={Routes.parametros.route} component={ParametrosSuspense} />
          <AllowedRouteMapped exact path={Routes.atendimento.route} component={AtendimentoSuspense} />
          <AllowedRouteMapped exact path={Routes.treinamento.route} component={TreinamentoSuspense} />
          <AllowedRouteMapped exact path={Routes.cliente.route} component={ClienteSuspense} />
          <AllowedRouteMapped exact path={Routes.listarUsuariosMestres.route} component={UsuariosMestresSuspense} />
          <AllowedRouteMapped exact path={Routes.buscaMestresOperadora.route} component={UsuariosMestresSuspense} />
          <AllowedRouteMapped exact path={Routes.buscaMestresRevenda.route} component={UsuariosMestresSuspense} />
          <AllowedRouteMapped exact path={Routes.dadosNFe.route} component={DadosNFeSuspense} />
          <AllowedRouteMapped
            exact
            path={Routes.posicaoContratoClienteConciliador.route}
            component={ConciliatingClientContractPosition}
          />

          <AllowedRouteMapped
            exact
            path={Routes.manutencaoArquivosExtratosOriginais.route}
            component={MaintainingOriginalExtractFiles}
          />
          <Route path={Routes.error.route} component={Error} />
          <Route path={Routes.notAllowed.route} component={NotAllowedSuspenseComponent} />
        </Switch>
      </Dashboard>
    );
  }
}

export default Application;
